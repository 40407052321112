import React from "react";
import Select from "react-select";

import Line from "components/Graphics/Line/Line";
import { formatDate } from "components/Utils/FormatDate";
import HorizontalBar from "components/Graphics/HorizontalBar/HorizontalBar";

import template from "template";
import { formater } from "libs/formater";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi";

import { Container, Row, Col } from "reactstrap";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score: [],
      lineDate: [],
      lineScore: 0,
      industryId: -1,
      comparison: "",
      companyName: "",
      sifNumber: null,
      scoreCard: false,
      listTypeScore: [],
      changeText: false,
      isAvailable: false,
      participated: true,
      firstRender: false,
      sifResponse: [null],
      sifSelectedText: {},
      substepPointList: [],
      industrySugested: {},
      userSifsResponse: [],
      scoreDate: new Date(),
      nationalAverageScore: 0,
      showAverageBrazil: false,
      industrySugestedList: [],
      formData: { profileId: "" },
      nationalAverageScoreLineBar: [],

      scoreNotFound: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let storageUserFirstName = window.localStorage.getItem(
      constants.LocalStorage.FirstName
    );
    storageUserFirstName =
      storageUserFirstName.charAt(0).toUpperCase() +
      storageUserFirstName.slice(1);

    let sifSelectItems = [];
    let typeScoreItems = [];

    if (this.state.sifResponse && this.state.sifResponse.length > 0) {
      try {
        sifSelectItems = this.state.sifResponse.map((item) => {
          return (
            <option
              value={item.id}
              key={"situation" + item.id}
              selected={this.state.formData.sifId === item.id ? true : false}
            >
              {item.sifNumber} - {item.corporateName}
            </option>
          );
        });
      } catch (err) {
        sifSelectItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    if (this.state.listTypeScore && this.state.listTypeScore.length > 0) {
      try {
        typeScoreItems = this.state.listTypeScore.map((item) => {
          return (
            <option
              value={item.id}
              key={"situation" + item.id}
              selected={
                this.state.formData.typeScoreId === item.id ? true : false
              }
            >
              {item.name}
            </option>
          );
        });
      } catch (err) {
        typeScoreItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    return (
      <Container
        fluid
        className={`${
          this.state.isAvailable && "minHeightFooter"
        } bg-body dashboard`}
      >
        {this.state.isAvailable ? (
          <>
            <Row className="pl-5 pt-5">
              <Col lg="6">
                <h1 className="text-dark dashboardTitleTwo">
                  Bem-vindo(a), {storageUserFirstName}!
                </h1>
              </Col>
            </Row>
            <Row className="pl-3 pb-4 pt-5">
              <Col lg="4">
                <div>
                  <label
                    htmlFor="profileId"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.vision}
                  </label>
                  <select
                    id="profileId"
                    ref="profileId"
                    name="profileId"
                    onChange={this.onInputChange}
                    className={"input-style form-control showFilterActive"}
                  >
                    <option
                      value=""
                      selected={this.state.formData.profileId ? false : true}
                    >
                      Selecione
                    </option>
                    <option
                      value="2"
                      selected={
                        this.state.formData.profileId != 2 ? false : true
                      }
                    >
                      Indústria
                    </option>
                    <option
                      value="3"
                      selected={
                        this.state.formData.profileId != 3 ? false : true
                      }
                    >
                      SIF
                    </option>
                  </select>
                </div>
              </Col>

              {this.state.formData.profileId == 2 && (
                <Col lg="4">
                  <label className="form-control-label label-input">
                    {this.stringTable.sif.form.industry}
                  </label>
                  <Select
                    placeholder=""
                    id="industryName"
                    className="select-industry"
                    options={this.state.industrySugestedList}
                    value={{
                      value: this.state.industrySugested.id,
                      label: this.state.industrySugested.tradeName,
                    }}
                    onChange={(event) => this.loadSugestedIndustry(event.value)}
                  />
                </Col>
              )}
              {this.state.formData.profileId == 3 && (
                <>
                  <Col lg="4">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.industry}
                    </label>
                    <Select
                      id="industryName"
                      className="select-industry"
                      placeholder=""
                      options={this.state.industrySugestedList}
                      value={{
                        value: this.state.industrySugested.id,
                        label: this.state.industrySugested.tradeName,
                      }}
                      onChange={(event) =>
                        this.loadSugestedIndustry(event.value)
                      }
                    />
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <div className="input-sif">
                      <label className="form-control-label label-input">
                        {this.stringTable.user.form.sifProfile.sif}
                      </label>
                      <select
                        id="sifId"
                        name="sifId"
                        onChange={(event) => {
                          this.onInputChange(event);
                          this.loadSifId(event.target.value);
                        }}
                        className={
                          "input-style form-control showFilterActive" +
                          (sifSelectItems.length === 0 ? " loadingSelect" : "")
                        }
                        disabled={sifSelectItems.length === 0 ? true : false}
                      >
                        <option
                          value=""
                          selected={this.state.formData.sifId ? false : true}
                        >
                          Selecione
                        </option>
                        {sifSelectItems.length === 0 || sifSelectItems}
                      </select>
                    </div>
                  </Col>
                </>
              )}
              <Col lg="4" md="12" sm="12">
                <div className="input-sif">
                  <label className="form-control-label label-input">
                    {this.stringTable.user.form.industryProfile.typeScore}
                  </label>
                  <select
                    id="scoreTypeId"
                    name="scoreTypeId"
                    onChange={this.onInputChange}
                    className={
                      "input-style form-control showFilterActive" +
                      (typeScoreItems.length === 0 ? " loadingSelect" : "")
                    }
                    disabled={typeScoreItems.length === 0 ? true : false}
                  >
                    <option
                      value=""
                      selected={this.state.formData.scoreTypeId ? false : true}
                    >
                      Selecione
                    </option>
                    {typeScoreItems.length === 0 || typeScoreItems}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="container-graphics">
              <Col lg="7">
                <div className="doughnut-line">
                  {this.state.scoreCard ? (
                    <div className="wrapperDoughnut">
                      <div className="text-dark ml-5 mt-5">
                        <span>{this.stringTable.dashboard.scoreCard}</span>
                      </div>
                    </div>
                  ) : (
                    <Col lg="8">
                      <div>
                        <HorizontalBar
                          text="Score"
                          width={150}
                          height={43}
                          suggestedMax={100}
                          legend={true}
                          value={this.state.score}
                          nationalAverageScore={
                            this.state.nationalAverageScoreLineBar
                          }
                        />
                        <span
                          style={{
                            display: "inline-block",
                          }}
                          className="ml-3 mt-3"
                        >
                          {this.state.scoreNotFound
                            ? "Score não encontrado"
                            : "Atualizado em " +
                              formatDate(this.state.scoreDate)}
                        </span>
                      </div>
                    </Col>
                  )}
                  <div className="pt-5">
                    <Line
                      firstValue={this.state.lineScore}
                      secondValue={this.state.nationalAverageScore}
                      lineDate={this.state.lineDate}
                      showNationalScore={
                        this.state.formData.profileId == 2 ||
                        this.state.formData.profileId == 3
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col lg="5">
                {this.state.substepPointList.length ? (
                  this.state.substepPointList.map(
                    (
                      { point, comparison, substepName, nationalAverageScore },
                      index
                    ) => {
                      return (
                        <HorizontalBar
                          key={index}
                          width={150}
                          height={35}
                          value={point}
                          legend={false}
                          suggestedMax={60}
                          text={substepName}
                          comparison={comparison}
                          nationalAverageScore={nationalAverageScore}
                        />
                      );
                    }
                  )
                ) : (
                  <>
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Certificação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Habilitação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Volume"
                      legend={false}
                      suggestedMax={60}
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Análise"
                      legend={false}
                      suggestedMax={60}
                    />
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className="availability">
            <h1>Não há dados disponíveis</h1>
          </div>
        )}
      </Container>
    );
  }

  componentDidMount() {
    this.loadSif();
    this.loadIndustry();
    this.getAvailability();
    this.loadSubstepPointChart(null, null);
    this.loadSimpleLineBar(null, null);
    this.loadScoreVsRoundChart(null, null);
    setTimeout(() => {
      this.setState({ changeText: false, firstRender: false, scoreCard: true });
    }, 300);
    this.getTypeScore();
  }

  getAvailability() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetAvailability;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ isAvailable: result.response.isAvailable });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getTypeScore() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Score.scoreTypeFind;
    let data = {
      name: null,
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({
          listTypeScore: result.response,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadSimpleLineBar(industryId, sifId) {
    const { formData } = this.state;
    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?industryId=${industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?sifId=${sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?scoreTypeId=${formData.scoreTypeId}`;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (result.success === false) {
          this.setState({
            scoreNotFound: true,
            score: 0,
            nationalAverageScoreLineBar: 0,
            changeText: false,
            firstRender: false,
            comparison: "",
            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                substepName: "Certificação",
                substepOrder: 1,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Habilitação",
                substepOrder: 2,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Volume",
                substepOrder: 3,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Análise",
                substepOrder: 4,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
            ],
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            score: 0,
            nationalAverageScoreLineBar: 0,
            scoreNotFound: true,
            comparison: "",
            changeText: false,
            firstRender: false,
            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                substepName: "Certificação",
                substepOrder: 1,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Habilitação",
                substepOrder: 2,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Volume",
                substepOrder: 3,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
              {
                substepName: "Análise",
                substepOrder: 4,
                comparison: "",
                point: 0,
                nationalAverageScore: 0,
              },
            ],
          });
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        if (formData.profileId == "3" || formData.profileId == "2")
          this.setState({
            changeText: true,
            firstRender: false,
          });

        const {
          score,
          sifNumber,
          scoreDate,
          comparison,
          corporateName,
          nationalAverageScore,
        } = result.response;

        this.setState({
          score: score,
          sifNumber,
          scoreDate,
          comparison,
          scoreNotFound: false,
          participated: !!score,
          companyName: corporateName,
          nationalAverageScoreLineBar: nationalAverageScore,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSubstepPointChart(industryId, sifId) {
    const { formData } = this.state;

    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?industryId=${industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?sifId=${sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?scoreTypeId=${formData.scoreTypeId}`;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null || result.response.length === 0) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ substepPointList: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadScoreVsRoundChart(industryId, sifId) {
    const { formData } = this.state;
    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?industryId=${industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?sifId=${sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?scoreTypeId=${formData.scoreTypeId}`;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null || result.response.length === 0) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let newScore = result.response.map((item) => item.score);
        let newNationalAverageScore = result.response.map((item) =>
          item.nationalAverageScore !== null ? item.nationalAverageScore : []
        );

        const lineDate = result.response.map((item) => item.scoreDate);

        this.setState({
          lineDate,
          lineScore: newScore,
          nationalAverageScore: newNationalAverageScore,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadIndustry() {
    let keyword = {
      keyword: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Find;
    let data = keyword;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let industryList = result.response.map((item) => {
          return {
            value: item.id,
            label:
              formater.template(item.cnpj, "00.000.000/0000-00") +
              " - " +
              item.tradeName,
          };
        });

        this.setState({ industrySugestedList: industryList });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadSif() {
    let dataAPI = {};
    let industryId = this.state.industryId;

    dataAPI.industryIds = [];
    dataAPI.keyword = null;

    if (industryId !== -1) {
      dataAPI.industryIds.push(industryId);

      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Find;
      let data = dataAPI;

      smoothApi.post(
        url,
        data,
        token,
        (result) => {
          if (!result || !result.response || result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ sifResponse: result.response });
        },
        (error, httpCode) => {
          if (httpCode === 401) {
            window.location.href = "/auth/Login";
          }
        }
      );
    }
  }

  loadSifId(sifId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      sifId;
    let data = null;
    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let res = result.response;
        let formData = this.state.formData;

        formData.sifNumber = res.sifNumber;
        formData.corporateName = res.corporateName;

        setTimeout(() => {
          this.setState({
            comparison: "",
            sifNumber: res.sifNumber,
            companyName: res.corporateName,
            sifSelectedText: {
              sifNumber: res.sifNumber,
              sifName: res.corporateName,
            },
            formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
          });
        }, 100);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSugestedIndustry(industryId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let res = result.response;

        delete formData.sifNumber;
        delete formData.corporateName;

        res.tradeName =
          formater.template(res.cnpj, "00.000.000/0000-00") +
          " - " +
          res.tradeName;

        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });

        this.setState({
          score: 0,
          lineScore: [],
          comparison: "",
          scoreCard: true,
          changeText: false,
          firstRender: false,
          industryId: res.id,
          userSifsResponse: [],
          industrySugested: res,
          nationalAverageScore: [],
          nationalAverageScoreLineBar: 0,
          substepPointList: substepPoint,
          companyName: res.headOfficeCorporateName,
          formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
        });

        this.loadSif();
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let substepPoint = this.state.substepPointList.map((item) => {
      return {
        ...item,
        point: 0,
        nationalAverageScore: 0,
      };
    });

    let value = "";
    value = target.value;

    this.setState({
      changeText: false,
      scoreDate: new Date(),
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (value === "") {
      this.setState({
        score: 0,
        lineScore: [],
        comparison: "",
        scoreCard: true,
        changeText: false,
        firstRender: false,
        showAverageBrazil: false,
        nationalAverageScore: [],
        substepPointList: substepPoint,
        nationalAverageScoreLineBar: 0,
        formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
      });
      return;
    }

    if (name === "scoreTypeId") {
      if (value === "") {
        setTimeout(() => {
          this.setState({
            score: 0,
            lineScore: [],
            scoreCard: true,
            comparison: "",
            showAverageBrazil: false,
            nationalAverageScore: [],
            substepPointList: substepPoint,
            nationalAverageScoreLineBar: 0,
            formData: Object.assign(this.state.formData, {
              scoreTypeId: "",
            }),
          });
        }, 200);
      }

      if (this.state.formData.profileId === "" && value !== "") {
        this.setState({ scoreCard: false, showAverageBrazil: true });
        this.loadScoreVsRoundChart(
          this.state.industryId,
          this.state.formData.sifId
        );
        this.loadSubstepPointChart(
          this.state.industryId,
          this.state.formData.sifId
        );
        this.loadSimpleLineBar(
          this.state.industryId,
          this.state.formData.sifId,
          false
        );
        return;
      }

      this.setState({ scoreCard: false, showAverageBrazil: false });
      this.loadScoreVsRoundChart(
        this.state.industryId,
        this.state.formData.sifId
      );
      this.loadSubstepPointChart(
        this.state.industryId,
        this.state.formData.sifId
      );
      this.loadSimpleLineBar(
        this.state.industryId,
        this.state.formData.sifId,
        false
      );
    }

    if (name === "sifId") {
      this.loadSifId(value);

      setTimeout(() => {
        this.setState({
          score: 0,
          comparison: "",
          scoreCard: true,
          lineScore: [],
          nationalAverageScore: [],
          nationalAverageScoreLineBar: 0,
          substepPointList: substepPoint,
          formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
        });
      }, 50);
    }

    if (name === "profileId") {
      if (value === "") {
        this.setState({ scoreCard: false });
        return;
      }

      if (value == "2") {
        this.setState({
          score: 0,
          lineScore: [],
          comparison: "",
          sifResponse: [],
          scoreCard: true,
          sifSelectedText: {},
          nationalAverageScore: [],
          substepPointList: substepPoint,
          nationalAverageScoreLineBar: 0,
          industrySugested: { id: 0, tradeName: "" },
          formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
        });
      }

      if (value == "3") {
        this.setState({ scoreCard: true });

        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });

        this.setState({
          score: 0,
          lineScore: [],
          comparison: "",
          sifSelectedText: {},
          nationalAverageScore: [],
          substepPointList: substepPoint,
          nationalAverageScoreLineBar: 0,
          industrySugested: { id: 0, tradeName: "" },
          formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
        });
        this.loadIndustry();
      }
    }
  }
}

export default Dashboard;
