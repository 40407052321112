export default Object.freeze({
  Language: function (lang) {
    let defaultLang = "pt-br";

    if (!lang) lang = defaultLang;

    if (lang === "pt-br") {
      return {
        standard: {
          logo: require("assets/img/brand/logo.png"),
          logo2: require("assets/img/brand/logo2.png"),
          logo3: require("assets/img/brand/logo3.png"),
          logo4: require('assets/img/brand/logo-abiec-2019-png.png'),

          mandatoryInput: " - obrigatório",
          registerSuccess: "Salvo com sucesso!",
          fieldRequiredError: "Os campos em vermelho são obrigatórios.",
          submitQuestionnaire:
            "Você confirma o envio das suas respostas? Após o envio, não será possível alterar suas respostas.",
          backButton: "Voltar",
          cleanButton: "Limpar",
          loading: "Enviando...",
          yes: "Sim",
          no: "Não",
          warning: "Aviso",
          deleteConfirmation: "Deseja realmente excluir esse registro?",
          confirmSubmit:
            "Você confirma o envio das justificativas e/ou alterações?",
          apiError:
            "Ocorreu um erro durante o registro. Por favor, verifique a mensagem e tente novamente.",
          apiSuccess: "Concluído com sucesso:",
          submitSuccess:
            "Análise realizada com sucesso, os usuários do SIF receberão e-mail com o status da análise.",
          cancel: "Cancelar",
          save: "Salvar",
          messageSuccessSif:
            "Informações enviadas com sucesso! Agradecemos sua participação.",
        },

        login: {
          forgotPassword: "Esqueci minha senha",
          inputEmail: "E-mail",
          inputPassword: "Senha",
          buttomEnter: "Entrar",

          message: {
            title1: "Login",
            title2: "Erro",
            passwordChanged: "Senha foi alterada com sucesso!",
          },
        },

        changePassword: {
          welcome: "Bem-vindo ao sistema!",
          registerUrPassword: "Cadastre sua nova senha:",
          inputPassword: "Nova senha",
          inputConfirm: "Confirmar",
          dialogErrorTitle: "Erro!",
          buttomAlter: "Alterar",
        },

        forgotPassword: {
          insertYourEmail1: "Por favor, insira seu E-mail e mandaremos",
          insertYourEmail2: "uma nova senha.",
          email: "E-mail",
          dialogErrorTitle: "Erro!",
          dialogSucessTitle: "Success!",
          linkReturn: "Voltar para o Login",
          buttomSend: "Enviar",
        },

        firstAcess: {
          welcome: "Bem-vindo ao sistema!",
          registerUrPassword: "Cadastre sua nova senha:",
          inputPassword: "Nova senha",
          inputConfirm: "Confirmar",
          dialogErrorTitle: "Erro!",
          buttomEnter: "Entrar",
        },

        index: {
          hello: "Olá",
          description:
            "Veja abaixo as informações do SIF vinculado à sua conta.",
        },

        user: {
          titleNewUser: "Novo usuário",
          titleChangeUser: "Editar usuário",
          titlePreview: "Visualizar usuário",
          titleUser: "Usuários",
          titleList: "Usuários cadastrados",
          buttonRegister: "Cadastrar um usuário",
          subtitleUser: "Gerencie os usuários atribuindo perfis de acesso",
          newSubtitleUserABIEC: "Informações do(s) usuário(s)",
          newSubtitleUserIndustry: "Informações do(s) usuário(s)",
          newSubtitleUser: "Informações cadastrais do usuário",
          subtitleNewUser: "Insira os dados e escolha o perfil adequado",
          newSubtitleNewUser: "Informações cadastrais do usuário",
          sendEmailFirstAccess: "Reenviar e-mail de primeiro acesso",
          sendEmailSuccess: "E-mail enviado com sucesso!",

          form: {
            name: "Nome",
            lastName: "Sobrenome",
            cpf: "CPF",
            email: "E-mail",
            profile: "Perfil",
            vision: "Visão",

            industryProfile: {
              industry: "Indústria",
              function: "Função",
              isResponsible: "É o responsável?",
              typeScore: "Tipo Score",
            },

            sifProfile: {
              sif: "SIF",
              SelectionSif: "Selecione o SIF",
              function: "Função",
              isResponsible: "É responsável?",
            },
          },

          filter: {
            profile: "Filtrar por perfil",
            keyword: "Buscar por número ou palavra-chave",
          },

          tooltip: {
            sendEmail:
              "Clique para reenviar o e-mail de primeiro acesso caso o usuário ainda não tenha recebido.",
          },

          message: {
            title1: "Usuário",
            title2: "Erro",
            userNotFound: "não encontrado!",
            successExclusion: "excluído com sucesso!",
            errorExclusion: "ao realizar exclusão do usuário.",
          },
        },

        sif: {
          titleNewSif: "Novo SIF",
          titleChangeSif: "Editar SIF",
          titleSif: "SIFs",
          titleList: "SIFs cadastrados",
          buttonRegister: "Cadastrar um SIF",
          subtitleSif: "Gerencie os SIFs",
          newSubtitleSif: "Informações do(s) SIF(s).",
          subtitleNewSif:
            "Insira as informações cadastrais do SIF. As informações de processos são preenchidas automaticamente, provenientes dos questionários respondidos por cada unidade.",
          newSubtitleNewSif: "Informações cadastrais do SIF.",
          form: {
            tabs: {
              registrationInformation: "Informações cadastrais",
              questionnairesAnswered: "Questionários respondidos",
              questionnaireNofFound:
                "Não há questionários respondidos para este SIF",
            },
            industry: "Indústria",
            isHeadOffice: "É Matriz?",
            corporateName: "Razão Social",
            cnpj: "CNPJ",
            responsible: "Responsável",
            email: "E-mail",
            function: "Função",
            productionProcess: "Processos de produção",
            lastRegister: "Último registro",
            sipoa: "SIPOA",
            sifNumber: "N° do SIF",
            cep: "CEP",
            state: "Estado",
            county: "Município",
            neighborhood: "Bairro",
            publicPlace: "Logradouro",
            numberHome: "Número",
            complement: "Complemento",
          },

          filter: {
            keyword: "Buscar por número ou palavra-chave",
          },

          tooltip: {
            responsible:
              "Você deve indicar o Responsável no cadastro de Usuários.",
          },

          message: {
            title1: "SIF",
            title2: "Erro",
            sifNotFound: "não encontrada!",
            successExclusion: "excluída com sucesso!",
            errorExclusion: "ao realizar exclusão da SIF.",
          },

          unfilledQuestionnaire:
            "É obrigatório responder a pergunta antes de avançar.",
          notAttachedDocuments:
            "Você não anexou documentos a sua resposta. Deseja avançar?",
          uploadFile:
            "Esta resposta exige o envio de um anexo. Por favor, faça o envio antes de avançar.",
        },

        industry: {
          titleNewIndustry: "Nova indústria",
          titleChangeIndustry: "Editar indústria",
          titleIndustry: "Indústrias",
          titleList: "Indústrias cadastradas",
          buttonRegister: "Cadastrar uma indústria",
          subtitleIndustry: "Gerencie os perfis das indústrias",
          newSUbtitleIndustry: "Informações da(s) indústria(s)",
          subtitleNewIndustry:
            "Insira os dados da indústria e selecione um usuário cadastrado como responsável",
          newSubtitleNewIndustry: "Informações cadastrais da indústria",
          form: {
            tradeName: "Nome fantasia",
            corporateName: "Razão Social da Matriz",
            cnpj: "CNPJ",
            Bi: "Link para relatório Power BI",
            responsible: "Responsável",
            email: "E-mail",
            function: "Função",
          },

          filter: {
            keyword: "Buscar por número ou palavra-chave",
          },

          tooltip: {
            responsible:
              "Você deve indicar o Responsável no cadastro de Usuários.",
          },

          message: {
            title1: "Indústria",
            title2: "Erro",
            industryNotFound: "não encontrada!",
            successExclusion: "excluída com sucesso!",
            errorExclusion: "ao realizar exclusão da indústria.",
          },
        },

        questionnaireSif: {
          sifSelection: {
            titleQuestionnaire: "Questionários",
            subtitleQuestionnaire: "Escolha a opção desejada",

            selectSif: "Selecione o SIF",
            hasPending: "Há pendências no último questionário respondido",
            verifyNow: "verifique agora.",

            btAnswerNewQuestionnaire: "Responder novo questionário",
            btViewCompletedQuestionnaires: "Ver questionários respondidos",

            answeredQuestionnaire: {
              titleSif: "SIF",
              subTitleSif: "Veja os questionários respondidos para este SIF",
            },
          },

          selectionQuestionnaireType: {
            title: "Responder questionário",
            subtitle: "Responda os dados para atualizar seu score.",

            paragraph1:
              "O Projeto Brazilian Beef Up coordenado pala Associação Brasileira das Indústrias Exportadoras de Carne (ABIEC) é de adesão voluntária. As informações fornecidas no formulário a seguir irão compor uma base de dados, estão protegidas por sigilo e confidencialidade e não poderão ser compartilhadas.",
            paragraph2:
              "Orientamos que esta Auto Avaliação seja endereçada aos responsáveis locais pela qualidade de cada estabelecimento envolvido, com o objetivo de obter respostas atuais e que retratem o status real de cada unidade.",
            paragraph3:
              "As informações que requerem comprovação via certificado/relatório só serão confirmadas mediante o envio da documentação válida correspondente. É de responsabilidade do estabelecimento fornecer informações verídicas para o cadastro. Após o preenchimento do formulário, a adesão ao projeto já é automaticamente confirmada.",
            paragraph4:
              "Em caso de dúvidas e esclarecimentos, permanecemos à disposição.",
            paragraphFinal: "Equipe ABIEC",
            agreeTerms:
              "Declaro que li os termos acima e aceito participar do programa.",

            questionnaire: "Questionário",

            questionType:
              "Quais os processos de produção realizados pela unidade selecionada?",

            startQuestionnaire: "Iniciar questionário",
          },

          currentScore: {
            title: "Questionário",
            subtitle: "Responda os dados para atualizar seu score.",
            text1: "Obrigado!",
            text2: "Seu score atual é",
            text3: "Importante!",
            text4: "Esta nota está sujeita a mudanças após a avaliação.",
            backHome: "Voltar para a Home",
          },

          sifAnswerList: {
            subtitleAnswer: "Veja as pendências do questionário respondido",
            newSubtitleAnswer: "Respostas do questionário.",
            typeQuestionnaire: "Tipo do questionário",
            score: "Score atual: ",
            exportPdf: "Exportar pdf",
            sendEmail: "Enviar e-mail de avaliação",
            sendEmailSuccess: "E-mail enviado com sucesso",

            sifEvaluationAnswered: {
              viewFile: "Anexo",
              approved: "Aprovado",
              returned: "Devolvido",
              pending: "Pendente",
              close: "Fechar",
              undo: "Desfazer",
              seeAnalysis: "Ver análise",
              abiecJustification: "Justificativa ABIEC",
              sifJustification: "Justificativa SIF",
              writeFeedback: "Escreva sua devolutiva",
              viewJsonFile: "Mostrar informações de leitura do(s) anexo(s)",
              hideJsonFile: "Esconder informações de leitura do(s) anexo(s)",
              analyzedByArtificialIntelligence: "Analisado pela I.A.",
              keepAnswer: "Manter resposta e justificar",
              changeAnswer: "Alterar resposta",
            },
          },
        },

        questionnaire: {
          titleNewRound: "Nova rodada",
          titleChangeRound: "Editar rodada",
          titleQuestionnaire: "Questionários",
          titleList: "Rodadas cadastradas",
          buttonRegister: "Nova rodada",
          buttonRoundCancel: "Cancelar rodada",
          subtitleQuestionnaire:
            "Crie e edite os questionários de cada tipo de processo de produção.",
          subtitleNewRound:
            "Configure o período e cadastre as perguntas dos questionários.",
          infoRoundForm: "Informações da rodada",
          statusDefault: "Em edição",

          error: {
            roundTitle: "Rodada",
            roundNotFound: "não encontrada!",

            questionnaireListingTitle: "Questionário",
            questionnaireListingNotFound: "não encontrado",
          },

          selectPeriod: {
            titlePeriodDefault: "R0000",
            subtitlePeriod:
              "Configure o período e cadastre as perguntas dos questionários.",
            roundInformation: "Informações da Rodada",
            questionnairesAnswered: "Status dos questionários",
          },

          questionnaireType: {
            titleTypeDefault: "R0000",
            subtitleType: "Aqui estão os tipos dos questionários",
            titleList:
              "Escolha o tipo do questionário para cadastrar as perguntas",
          },

          sifsNotAnswer: {
            title: "SIFs que não responderam",
            subtitle: "Veja os SIFs que não responderam os questionários",
            titleList: "Listagem de SIFs",

            filter: {
              industry: "Filtrar por indústria",
            },

            message: {
              title1: "SIF",
              title2: "Erro",
              questionnaireNotFound: "não encontrado!",
            },
          },

          answeredQuestionnaire: {
            subtitleAnswered:
              "Veja os questionários respondidos pelos responsáveis dos SIFs",
            titleList: "Questionários respondidos",

            buttomNotAnswer: "SIFs que não responderam",

            filter: {
              title: "Buscar por número do SIF ou Razão Social",
              type: "Filtrar por tipo",
              status: "Filtrar por status",
              startDate: "Período - Data de início",
              endDate: "Período - Data de término",
            },

            answerList: {
              subtitleAnswer:
                "Veja os questionários respondidos pelos responsáveis dos SIFs",
              newSubtitleAnswer: "Respostas do questionário",

              typeQuestionnaire: "Tipo do questionário",
              score: "Score atual: ",
              exportPdf: "Exportar pdf",
              sendEmail: "Enviar e-mail de avaliação",
              sendEmailSuccess: "E-mail enviado com sucesso",
              waitingValidation: "Questionário em Análise",
              onlyView: "❕ Somente visualização ❕",

              evaluationAnswered: {
                viewFile: "Anexo",
                approve: "Aprovar",
                return: "Devolver",
                approved: "Aprovado",
                returned: "Devolvido",
                undo: "Desfazer",
                abiecJustification: "Justificativa ABIEC",
                sifJustification: "Justificativa SIF",
                viewJsonFile: "Mostrar informações de leitura do(s) anexo(s)",
                hideJsonFile: "Esconder informações de leitura do(s) anexo(s)",
                analyzedByArtificialIntelligence: "Analisado pela I.A.",
                points: "pontos",
              },
            },
          },

          form: {
            id: "ID",
            startDate: "Data de início",
            endDate: "Data de término",
            status: "Status",
          },

          filter: {
            keyword: "Buscar por número ou palavra-chave",
          },

          message: {
            title1: "Rodada",
            title2: "Erro",
            questionnaireNotFound: "não encontrada!",
            successCancel: "cancelada com sucesso!",
            errorCancel: "ao realizar o cancelamento da rodada.",
          },

          answerOptions: {
            subTitle: "Cadastre as perguntas dos questionarios",
            typeSelect: "Tipo",
            typeScore: "Tipo Score",
            subStep: "Subetapa",
            enableUpload: "Habilitar anexo?",
            enableVerification: "Verificação ABIEC?",
            enabledIA: "Verificação I.A?",

            typeOptions: {
              default: "Selecione",
              select: "Seleção (resposta única)",
              checkbox: "Checkbox (mais de uma resposta)",
              text: "Texto (uma linha)",
              textarea: "Texto (várias linhas)",
              number: "Numérico",
            },

            inputTitle: "Enunciado",
            buttomAddAnwser: "Adicionar campo resposta",
            buttomAddAlternative: "Adicionar alternativa",
            buttomAdd: "Adicionar",
            buttonAddAlternative: "Adicionar alternativa",
            buttonAddQuestion: "Adicionar pergunta",
            registredAnswer: "Perguntas cadastradas",
            cancelEditing: "Cancelar edição",
            buttomSave: "Salvar",
            buttomEdit: "Editar pergunta",
            buttomSaveQuestions: "Salvar perguntas cadastradas",
            buttomSaveSteps: "Salvar etapa",
            buttomBack: "Voltar",
            validationWording: "Informe um enunciado válido",
            validationWording2: "O enunciado deve ter entre 3 e 500 caracteres",
            validationType: "Selecione um tipo",
            validationType2:
              "Esse tipo de pergunta requer ao menos uma alternativa",
            validationType3:
              "Esse tipo de pergunta requer ao menos duas respostas",
            validationOption:
              "As opções de resposta precisam ter entre 1 e 200 caracteres",
            validationOption2:
              "As opções de resposta precisam ter um score válido",
            validationModal:
              "Por favor verifique os erros abaixo e tente novamente:",
          },
        },

        fileAttachment: {
          FileSelected: "Arquivo Selecionado",
          title: "Anexar documentos de comprovação",
          subtitle:
            "Faça o upload de todos os documentos comprobatórios referentes à resposta selecionada acima, sendo estes passíveis de validação. Limite de 5MB. Formatos: JPG, JPEG, PNG, ou PDF.",
          titleFileInput: "Escolher arquivo",
          addButton: "Adicionar",
          removeButton: "Remover",
          invalidFormat: "Formato Inválido.",
          validFormat: "Anexar arquivos nos formatos JPG, JPEG, PNG ou PDF.",
          limiteFile: "Limite de 2 documentos por questão.",
          limiteSize: "Limite de tamanho de anexo atingido.",
        },

        dashboard: {
          scoreCard: "Utilize os filtros acima para visualizar o score.",
        },
      };
    }

    return null;
  },
});
