import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import "assets/scss/pages/Dashboard.scss";

class HorizonatalBarComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const value = this.props.value ? this.props.value : 0;
    const width = this.props.width ? this.props.width : 0;
    const height = this.props.height ? this.props.height : 0;
    const suggestedMax = this.props.suggestedMax ? this.props.suggestedMax : 0;
    const nationalAverageScore = this.props.nationalAverageScore
      ? this.props.nationalAverageScore
      : 0;
    const text = this.props.text ? this.props.text : "";
    const legend = this.props.legend ? this.props.legend : false;

    const dataHorizontalBar = {
      labels: [" "],
      datasets: [
        {
          label: "Empresa",
          maxBarThickness: 20,
          minBarLength: 0,
          data: [value],
          backgroundColor: "rgba(21, 74, 39, 0.5)",
          borderColor: "rgba(21, 74, 39)",
          borderWidth: 1,
        },
        {
          label: "Brasil",
          maxBarThickness: 20,
          minBarLength: 0,
          data: [nationalAverageScore],
          backgroundColor: "rgb(188, 137, 46, 0.5)",
          borderColor: "rgb(188, 137, 46)",
          borderWidth: 1,
        },
      ],
    };

    const optionsHorizontalBar = {
      legend: {
        display: legend,
      },
      tooltips: {
        enabled: true,
      },
      animation: false,
      responsive: true,
      tooltipEvents: [],
      tooltipCaretSize: 0,
      tooltipFontColor: "#444",
      tooltipTemplate: "<%= value %>",
      tooltipFillColor: "rgba(0,0,0,0)",
      onAnimationComplete: function () {
        this.showTooltip(this.datasets[0].bars, true);
      },
      scales: {
        xAxes: [
          {
            ticks: {
              stepSize: 20,
              beginAtZero: false,
              suggestedMin: 0,
              suggestedMax: suggestedMax,
            },
          },
        ],
      },
    };

    return (
      <>
        <h3 className="pl-3 pt-3" style={{ fontWeight: "bold", fontSize: 22 }}>
          {text}
        </h3>
        <HorizontalBar
          width={width}
          height={height}
          data={dataHorizontalBar}
          options={optionsHorizontalBar}
        />
      </>
    );
  }
}

export default HorizonatalBarComponent;
