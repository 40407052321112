import React from "react";

import { Link } from "react-router-dom";

import template from "template";
import constants from "variables/constants";
import messages from "variables/messages";

import { smoothApi } from "libs/smoothApi";

import { Container, Col, Row } from "reactstrap";

class Questionnaires extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      openConfirm: false,

      sifResponse: null,
      situationResponse: null,
      timer: null,

      formData: {},

      errorMessage: "",
      messageExclusion: "",
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let sifSelectItems = [];

    let hasNewQuestionnaire = false;
    let hasPendingReturn = false;
    let hasAnsweredQuestionnaire = false;

    if (this.state.sifResponse && this.state.sifResponse.length > 0) {
      try {
        sifSelectItems = this.state.sifResponse.map((item) => {
          return (
            <option
              selected={this.state.formData.sifId === item.id ? true : false}
              value={item.id}
              key={"situation" + item.id}
            >
              {item.sifNumber} - {item.corporateName}
            </option>
          );
        });
      } catch (err) {
        sifSelectItems = [];

        console.error("Falha ao interpretar resposta", err);
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.hasNewQuestionnaire)
        hasNewQuestionnaire = this.state.situationResponse.hasNewQuestionnaire;

      if (this.state.situationResponse.hasPendingReturn)
        hasPendingReturn = this.state.situationResponse.hasPendingReturn;

      if (this.state.situationResponse.hasAnsweredQuestionnaire)
        hasAnsweredQuestionnaire =
          this.state.situationResponse.hasAnsweredQuestionnaire;
    }

    return (
      <Container fluid className="minHeightFooter bd-body">
        <Row className="pt-6 pl-5">
          <Col lg="6">
            <h1 className="text-dark">
              {
                this.stringTable.questionnaireSif.sifSelection
                  .titleQuestionnaire
              }
            </h1>
            <small className="subtitle">
              {
                this.stringTable.questionnaireSif.sifSelection
                  .subtitleQuestionnaire
              }
            </small>
          </Col>
          <Col lg="6">
            <Link to="/industry/index">
              <button className="lightButtom float-lg-right mt-2">
                {this.stringTable.standard.backButton}
              </button>
            </Link>
          </Col>
        </Row>

        <Row lg="12" className="pt-6">
          <Col md="4" sm="4" lg="4" className="ml-5">
            <label htmlFor="sifId" className="form-control-label label-input">
              {this.stringTable.questionnaireSif.sifSelection.selectSif}
            </label>

            <select
              name="sifId"
              id="sifId"
              ref="sifId"
              disabled={sifSelectItems.length === 0 ? true : false}
              onChange={this.onInputChange}
              className={
                "input-style form-control showFilterActive" +
                (sifSelectItems.length === 0 ? "loadingSelect" : "")
              }
            >
              <option
                value=""
                selected={this.state.formData.sifId ? false : true}
              >
                Selecione
              </option>

              {sifSelectItems.length === 0 || sifSelectItems}
            </select>
          </Col>
        </Row>

        <Row
          lg="12"
          className={`pt-6 ml-1 pb-9 questionnaire ${
            !hasAnsweredQuestionnaire && "justify-content-center"
          } `}
        >
          {!hasAnsweredQuestionnaire || (
            <div className="ml-5 pb-9">
              <Link
                to={`/industry/Questionnaires/answeredQuestionnaire/questionnaireListing/?id=${this.state.formData.sifId}`}
              >
                <div className="q_card-period">
                  <div className="justify-content-center q_pt-card d-flex">
                    <h3 className="text-white">
                      {
                        this.stringTable.questionnaireSif.sifSelection
                          .btViewCompletedQuestionnaires
                      }
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {this.state.situationResponse !== null &&
          !hasPendingReturn &&
          !hasNewQuestionnaire &&
          !hasAnsweredQuestionnaire ? (
            <Row lg="12" className="pt-6 ml-5 pb-9">
              <div>
                <h1>Não há questionário cadastrado.</h1>
              </div>
            </Row>
          ) : null}
        </Row>
      </Container>
    );
  }

  componentDidMount() {
    this.loadSifsByResponsible();
    // this.getSituation();
  }

  loadSifsByResponsible() {
    this.setState({ sifResponse: null });

    let dataAPI = {};

    dataAPI.keyword = null;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Find;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.systemErrors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.systemErrors);
          return;
        }

        this.setState({ sifResponse: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  getSituation() {
    this.setState({ situationResponse: null });

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.Get +
      "/" +
      formData.sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.loog(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ situationResponse: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  onInputChange(event) {
    const { target } = event;

    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "sifId") {
      this.getSituation();
    }
  }
}

export default Questionnaires;
