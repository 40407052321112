import React from "react";
import Modal from "react-modal";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";

import Wizard from "components/Utils/Wizard";
import Loading from "components/Utils/Loading";
import AnswerText from "components/Sif/Answer/AnswerText.jsx";
import AnswerNumber from "components/Sif/Answer/AnswerNumber.jsx";
import FileAttachment from "components/FileAttachment/FileAttachment";
import AnswerTextArea from "components/Sif/Answer/AnswerTextArea.jsx";
import AnswerCheckbox from "components/Sif/Answer/AnswerCheckbox.jsx";
import OrderAndProgress from "components/Sif/Answer/OrderAndProgress.jsx";
import AnswerRadioButton from "components/Sif/Answer/AnswerRadioButton.jsx";

import { Container, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    Zindex: "999",
  },
};

class Answer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allFiles: [],

      onBack: false,
      onNext: false,

      totalFile: [],
      file: null,
      extensions: ["jpg", "jpeg", "png", "pdf"],
      name: "",

      token: [],
      responseImage: [],
      loading: false,

      loadingSubmit: false,

      initialStep: 1,

      currentStep: 1,
      currentSubStep: 1,
      filteredQuestions: [],
      finishQuestions: [],

      stepNames: [],

      questionOne: [],
      questionTwo: [],
      questionThree: [],
      questionFour: [],
      questionFive: [],

      answerOne: [],
      answerTwo: [],
      answerThree: [],
      answerFour: [],
      answerFive: [],

      previousAnswers: [],
      questionsAnswer: [],

      solvedQuestions: [],

      ListFiles: [],
      listFilesUploaded: [],

      modalIsOpen: false,

      questionnaireTypeId: 0,
      currentQuestion: 0,
      questionId: 0,
      sifId: 0,
      currentQuestiondId: 0,

      realForm: [],
      currentQuestionForm: [],

      wizardResponse: null,
      questionsResponse: [],

      formData: { anwserOptions: [], answerPost: {}, answer: [] },
      message: {
        visible: false,
        leftButtomText: "Ok",
        rightButtomText: null,
        text: "",
        title: "",
        closeCallBack: () => {},
        leftButtomClickCallBack: () => {},
        rightButtomClickCallBack: () => {},
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function") {
              _this.state.message.closeCallBack();
            }
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                visible: false,
                text: "",
                title: "",
                closeCallBack: null,
                leftButtomClickCallBack: () => {},
                rightButtomClickCallBack: () => {},
              },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.closeModal = this.closeModal.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stepNavigate = this.stepNavigate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.preHandleSubmit = this.preHandleSubmit.bind(this);
    this.removeFileUploaded = this.removeFileUploaded.bind(this);
    this.salvationOfQuestions = this.salvationOfQuestions.bind(this);

    this.questionsAnswer = [];
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizzardNodeConfiguration = this.state.stepNames.map((step, index) => {
      return wizzardConfiguration.Configuration.Formater(
        <>
          {step.order}. {step.name}
        </>,
        "#",
        this.getColorForStepId(step.id)
      );
    });

    let hasAttachment = false;
    let questionId = 0;

    let questionsAnswer = [];
    let renderQuestion = [];
    let totalStep = 0;

    if (this.state.questionsResponse) {
      if (this.state.questionsResponse.length > 0) {
        try {
          this.state.questionsResponse[
            this.state.currentQuestion
          ].currentQuestion = this.state.currentQuestion;

          questionsAnswer.push(
            this.state.questionsResponse[this.state.currentQuestion]
          );

          this.questionsAnswer.push(
            this.state.questionsResponse[this.state.currentQuestion]
          );

          totalStep = this.state.questionsResponse.reduce((acc, elem) => {
            return this.state.currentStep === elem.stepOrder
              ? (totalStep += 1)
              : totalStep;
          }, 0);
        } catch (ex) {
          this.questionsAnswer = [];
          questionsAnswer = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (questionsAnswer) {
      if (questionsAnswer.length > 0) {
        renderQuestion = questionsAnswer.map((item, index) => {
          let formData = this.state.formData;

          if (item.questionTypeId === 1) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { solvedQuestions, ListFiles } = this.state;

            let file = {};
            let response = [];
            let token = [];
            let resImage = [];

            if (solvedQuestions && solvedQuestions.length) {
              solvedQuestions.forEach((answer) => {
                if (item.questionId === answer.questionId) {
                  item.previousAnswers = answer.value.split();
                }
              });
            }

            if (ListFiles && ListFiles.length) {
              const filteredFiles = ListFiles.filter((itemFile) => {
                if (
                  item.hasAttachment &&
                  itemFile.questionId === item.questionId
                ) {
                  return itemFile;
                }
              });

              if (this.state.allFiles.length) {
                this.state.allFiles.forEach((files) => {
                  if (files.questionId === item.questionId) {
                    token.push(...files.documentTokens);
                  }
                });
              }

              if (filteredFiles.length) {
                filteredFiles.forEach((item) => {
                  file = {
                    response: [...item.responseImage],
                    token: [...token],
                    question: item.questionId,
                  };
                });
              }
            }

            if (Object.keys(file).length) {
              response = [...file.response];
            }

            response.forEach((res, index) => {
              if (res.questionId == questionId) {
                resImage.push(res);
              }
            });

            response = resImage;

            return (
              <div>
                <div>
                  <AnswerRadioButton
                    onChange={(value = false) => {
                      let valid = true;
                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value === "") valid = false;
                      } else if (typeof value === "boolean") {
                        if (!value) valid = false;
                      }

                      if (valid) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              hasAttachment: item.hasAttachment,
                              questionId: questionId,
                              value: value,
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        if (
                          this.state.currentSubStep ==
                          this.state.filteredQuestions.length
                        ) {
                          if (valid) {
                            this.setState({
                              finishQuestions: [
                                {
                                  value: value,
                                  questionId: item.questionId,
                                },
                              ],
                            });
                          } else {
                            this.setState({ finishQuestions: [] });
                          }
                        }
                        formData.answerRadioButton = value;
                      } else {
                        this.setState({
                          currentQuestionForm: [],
                          finishQuestions: [],
                        });
                      }

                      this.setState({ formData: formData });
                      setTimeout(() => {
                        this.salvationOfQuestions(item.questionId);
                      }, 100);
                    }}
                    stepOrder={item.stepOrder}
                    questionOrder={item.questionOrder}
                    previousAnswers={item.previousAnswers}
                    value={item.answerOptions}
                    item={item}
                    title={item.questionWording}
                    stepName={item.substepName}
                    questionScoreTypes={item.questionScoreTypes}
                    scoreTypeId={item.scoreTypeId}
                  />
                </div>
                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.questionId}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          if (item.questionTypeId === 2) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { solvedQuestions, ListFiles } = this.state;
            let file = {};
            let token = [];
            let response = [];
            let resImage = [];

            if (solvedQuestions && solvedQuestions.length) {
              solvedQuestions.forEach((answer) => {
                if (item.questionId === answer.questionId) {
                  item.previousAnswers = answer.formattedValue;
                }
              });
            }

            if (ListFiles && ListFiles.length) {
              const filteredFiles = ListFiles.filter((itemFile) => {
                if (
                  item.hasAttachment &&
                  itemFile.file !== null &&
                  itemFile.questionId === item.questionId
                ) {
                  return itemFile;
                }
              });

              if (this.state.allFiles.length) {
                this.state.allFiles.forEach((files) => {
                  if (files.questionId === item.questionId) {
                    token.push(...files.documentTokens);
                  }
                });
              }

              if (filteredFiles.length) {
                filteredFiles.forEach((item) => {
                  file = {
                    response: [...item.responseImage],
                    token: [...token],
                    question: item.questionId,
                  };
                });
              }
            }

            if (Object.keys(file).length) {
              response = [...file.response];
            }

            response.forEach((res, index) => {
              if (res.questionId == questionId) {
                resImage.push(res);
              }
            });

            response = resImage;

            return (
              <div>
                <div>
                  <AnswerCheckbox
                    onChange={(value, optionId, isDelete) => {
                      let valid = true;
                      let newValues = {};

                      item.answerOptions.forEach((options, index) => {
                        if (
                          item.answerOptions[index].answerOptionId ==
                          value[index]
                        ) {
                          newValues[index] = value[index];
                        }
                      });

                      value = newValues;

                      if (!value || value === "") valid = false;

                      if (valid && value[optionId] !== undefined) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              hasAttachment: item.hasAttachment,
                              questionId: item.questionId,
                              value: JSON.stringify(Object.values(value)),
                              type: "checkbox",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });
                        formData.anwserOptions.push(value[optionId]);
                      }

                      formData.anwserOptions.sort();
                      if (isDelete) {
                        formData.anwserOptions = Object.values(value);

                        if (formData.anwserOptions.length) {
                          this.setState({
                            currentQuestionForm: [
                              {
                                hasAttachment: item.hasAttachment,
                                questionId: item.questionId,
                                value: JSON.stringify(
                                  Object.values(formData.anwserOptions)
                                ),
                                type: "checkbox",
                                documentTokens:
                                  item.hasAttachment == true &&
                                  this.state.token.length
                                    ? this.state.token
                                    : [],
                              },
                            ],
                          });
                        } else {
                          this.setState({
                            currentQuestionForm: [],
                          });
                        }
                      }

                      this.setState({ formData: formData });

                      setTimeout(() => {
                        this.salvationOfQuestions(item.questionId);
                      }, 100);
                    }}
                    value={item.answerOptions}
                    stepOrder={item.stepOrder}
                    questionOrder={item.questionOrder}
                    previousAnswers={item.previousAnswers}
                    formData={this.state.formData.anwserOptions}
                    title={item.questionWording}
                    stepName={item.substepName}
                    item={item}
                    questionScoreTypes={item.questionScoreTypes}
                    scoreTypeId={item.scoreTypeId}
                  />
                </div>
                {item.hasAttachment === true && (
                  <div>
                    <FileAttachment
                      disabled={
                        token.length === 2 || this.state.loading === true
                      }
                      loading={this.state.loading}
                      questionId={item.questionId}
                      file={this.state.file}
                      name={this.state.name}
                      listItem={response}
                      removeFile={this.removeFile}
                      handleChange={this.handleChange}
                      uploadDocument={this.uploadDocument}
                      removeFileUploaded={this.removeFileUploaded}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (item.questionTypeId === 3) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { ListFiles, solvedQuestions } = this.state;

            let file = {};
            let previousText = [];
            let response = [];
            let token = [];
            let resImage = [];

            if (solvedQuestions && solvedQuestions.length) {
              solvedQuestions.forEach((answer) => {
                if (answer.questionId === item.questionId) {
                  previousText.push(answer.value);
                }
              });
            } else {
              previousText = [];
            }

            if (!previousText.length) {
              if (item.previousAnswers && item.previousAnswers.length) {
                item.previousAnswers.forEach((previous) => {
                  previousText = previous;
                });
              }
            }

            if (ListFiles && ListFiles.length) {
              const filteredFiles = ListFiles.filter((itemFile) => {
                if (
                  item.hasAttachment &&
                  itemFile.questionId == item.questionId
                ) {
                  return itemFile;
                }
              });

              if (this.state.allFiles.length) {
                this.state.allFiles.forEach((files) => {
                  if (files.questionId === item.questionId) {
                    token.push(...files.documentTokens);
                  }
                });
              }

              if (filteredFiles.length) {
                filteredFiles.forEach((item) => {
                  file = {
                    response: [...item.responseImage],
                    token: [...token],
                    question: item.questionId,
                  };
                });
              }
            }

            if (Object.keys(file).length) {
              response = [...file.response];
            }

            response.forEach((res, index) => {
              if (res.questionId == questionId) {
                resImage.push(res);
              }
            });

            response = resImage;

            return (
              <div>
                <div>
                  <AnswerText
                    onChange={(value = "") => {
                      let valid = true;
                      let formData = this.state.formData;

                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value === "" || value.length > 200) valid = false;
                      }

                      if (valid) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              hasAttachment: item.hasAttachment,
                              questionId: item.questionId,
                              value: value,
                              type: "onInputText",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        if (
                          this.state.currentSubStep ==
                          this.state.filteredQuestions.length
                        ) {
                          if (valid) {
                            this.setState({
                              finishQuestions: [
                                {
                                  value: value,
                                  questionId: item.questionId,
                                },
                              ],
                            });
                          } else {
                            this.setState({ finishQuestions: [] });
                          }
                        }

                        formData.answerText = value;
                      } else {
                        this.setState({
                          currentQuestionForm: [],
                          finishQuestions: [],
                        });
                      }

                      this.setState({ formData: formData });
                    }}
                    item={item}
                    value={previousText}
                    onBack={this.state.onBack}
                    stepOrder={item.stepOrder}
                    stepName={item.substepName}
                    title={item.questionWording}
                    scoreTypeId={item.scoreTypeId}
                    questionOrder={item.questionOrder}
                    scoreTypeName={item.scoreTypeName}
                    onBlur={this.salvationOfQuestions}
                    questionScoreTypes={item.questionScoreTypes}
                  />
                </div>

                {item.hasAttachment === true && (
                  <div>
                    <FileAttachment
                      disabled={
                        token.length == 2 || this.state.loading === true
                          ? true
                          : false
                      }
                      loading={this.state.loading}
                      questionId={item.questionId}
                      file={this.state.file}
                      name={this.state.name}
                      listItem={response}
                      removeFile={this.removeFile}
                      handleChange={this.handleChange}
                      uploadDocument={this.uploadDocument}
                      removeFileUploaded={this.removeFileUploaded}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (item.questionTypeId === 4) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { solvedQuestions, ListFiles } = this.state;

            let file = {};
            let previousText = [];
            let booleanPreviousText = false;
            let response = [];
            let token = [];
            let resImage = [];

            if (solvedQuestions && solvedQuestions.length) {
              solvedQuestions.forEach((answer) => {
                if (answer.questionId === item.questionId) {
                  previousText.push(answer.value);
                  booleanPreviousText = true;
                }
              });
            } else {
              previousText = [];
              booleanPreviousText = false;
            }

            if (!previousText.length) {
              if (item.previousAnswers && item.previousAnswers.length) {
                item.previousAnswers.forEach((previous) => {
                  previousText = previous;
                  booleanPreviousText = true;
                });
              }
            }

            if (ListFiles && ListFiles.length) {
              const filteredFiles = ListFiles.filter((itemFile) => {
                if (
                  item.hasAttachment &&
                  itemFile.questionId == item.questionId
                ) {
                  return itemFile;
                }
              });

              if (this.state.allFiles.length) {
                this.state.allFiles.forEach((files) => {
                  if (files.questionId === item.questionId) {
                    token.push(...files.documentTokens);
                  }
                });
              }

              if (filteredFiles.length) {
                filteredFiles.forEach((item) => {
                  file = {
                    response: [...item.responseImage],
                    token: [...token],
                    question: item.questionId,
                  };
                });
              }
            }

            if (Object.keys(file).length) {
              response = [...file.response];
            }

            response.forEach((res, index) => {
              if (res.questionId == questionId) {
                resImage.push(res);
              }
            });

            response = resImage;

            return (
              <div>
                <div>
                  <AnswerTextArea
                    onChange={(value) => {
                      let valid = true;
                      let formData = this.state.formData;

                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value === "" || value.length > 200) valid = false;
                      }
                      if (valid) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              hasAttachment: item.hasAttachment,
                              questionId: item.questionId,
                              value: value,
                              type: "onInputTextArea",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        if (
                          this.state.currentSubStep ==
                          this.state.filteredQuestions.length
                        ) {
                          if (valid) {
                            this.setState({
                              finishQuestions: [
                                {
                                  value: value,
                                  questionId: item.questionId,
                                },
                              ],
                            });
                          } else {
                            this.setState({ finishQuestions: [] });
                          }
                        }

                        formData.answerTextArea = value;
                      } else {
                        this.setState({
                          currentQuestionForm: [],
                          finishQuestions: [],
                        });
                      }

                      this.setState({ formData: formData });
                    }}
                    item={item}
                    value={previousText}
                    onBack={this.state.onBack}
                    stepOrder={item.stepOrder}
                    stepName={item.substepName}
                    title={item.questionWording}
                    scoreTypeId={item.scoreTypeId}
                    questionOrder={item.questionOrder}
                    onBlur={this.salvationOfQuestions}
                    booleanPreviousText={booleanPreviousText}
                    questionScoreTypes={item.questionScoreTypes}
                  />
                </div>

                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.questionId}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          if (item.questionTypeId === 5) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { solvedQuestions, ListFiles } = this.state;

            let file = {};
            let previousNumber = [];
            let response = [];
            let token = [];
            let resImage = [];

            if (solvedQuestions && solvedQuestions.length) {
              solvedQuestions.forEach((answer) => {
                if (answer.questionId === item.questionId) {
                  previousNumber.push(answer.value);
                }
              });
            } else {
              previousNumber = [];
            }

            if (!previousNumber.length) {
              if (item.previousAnswers && item.previousAnswers.length) {
                item.previousAnswers.forEach((previous) => {
                  previousNumber = previous;
                });
              }
            }

            if (ListFiles && ListFiles.length) {
              const filteredFiles = ListFiles.filter((itemFile) => {
                if (
                  item.hasAttachment &&
                  itemFile.questionId == item.questionId
                ) {
                  return itemFile;
                }
              });

              if (this.state.allFiles.length) {
                this.state.allFiles.forEach((files) => {
                  if (files.questionId === item.questionId) {
                    token.push(...files.documentTokens);
                  }
                });
              }

              if (filteredFiles.length) {
                filteredFiles.forEach((item) => {
                  file = {
                    response: [...item.responseImage],
                    token: [...token],
                    question: item.questionId,
                  };
                });
              }
            }

            if (Object.keys(file).length) {
              response = [...file.response];
            }

            response.forEach((res, index) => {
              if (res.questionId == questionId) {
                resImage.push(res);
              }
            });

            response = resImage;

            return (
              <div>
                <div>
                  <AnswerNumber
                    onChange={(value) => {
                      let valid = true;
                      let formData = this.state.formData;
                      if (typeof value === "object") {
                        if (!value.lenght > 0) valid = false;
                      } else if (typeof value === "string") {
                        if (value === "") valid = false;
                      }

                      if (valid) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              hasAttachment: item.hasAttachment,
                              questionId: item.questionId,
                              value: value,
                              type: "onInputNumber",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        if (
                          this.state.currentSubStep ==
                          this.state.filteredQuestions.length
                        ) {
                          if (valid) {
                            this.setState({
                              finishQuestions: [
                                {
                                  value: value,
                                  questionId: item.questionId,
                                },
                              ],
                            });
                          } else {
                            this.setState({ finishQuestions: [] });
                          }
                        }

                        formData.answerNumber = value;
                      } else {
                        this.setState({
                          currentQuestionForm: [],
                          finishQuestions: [],
                        });
                      }

                      this.setState({ formData: formData });
                    }}
                    item={item}
                    value={previousNumber}
                    onBack={this.state.onBack}
                    stepOrder={item.stepOrder}
                    stepName={item.substepName}
                    title={item.questionWording}
                    scoreTypeId={item.scoreTypeId}
                    onBlur={this.salvationOfQuestions}
                    questionOrder={item.questionOrder}
                    questionScoreTypes={item.questionScoreTypes}
                  />
                </div>
                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.questionId}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          formData.answer.push({ questionId: item.questionId });

          this.setState({ formData: formData });
        });
      }
    }

    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span className="float-lg-right x" onClick={this.closeModal}>
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.stringTable.standard.submitQuestionnaire}
          </h2>
          <div className="text-center">
            <button
              id="alertExclusion"
              className="alertExclusion btn btn-group darkButtom mr-4"
              onClick={this.handleSubmit}
            >
              {this.state.loadingSubmit ? <Loading load="Sim" /> : "Sim"}
            </button>
            <button
              className="btn btn-group mainColorButtom"
              onClick={this.closeModal}
            >
              Não
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            {!this.state.message.leftButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.leftButtomClickCallBack();
                }}
              >
                {this.state.message.leftButtomText}
              </button>
            )}
            {!this.state.message.rightButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.rightButtomClickCallBack();
                }}
              >
                {this.state.message.rightButtomText}
              </button>
            )}
          </div>
        </Modal>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">{"Questionário"}</h1>
              <small className="subtitle">
                {"Responda os dados para atualizar seu score."}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5 pb-5">
            <Col lg="12" xl="12">
              <div className="">
                <Wizard nodes={wizzardNodeConfiguration} />
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pb-5 pl-2">
            <Col lg="12" xl="12">
              <div className="">
                <h2 className="text-dark">
                  {
                    <OrderAndProgress
                      currentStep={this.state.currentSubStep}
                      steps={this.state.filteredQuestions.length}
                    ></OrderAndProgress>
                  }
                </h2>
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-5">
            <Col lg="12" className="">
              <div className="form-group">
                {renderQuestion.length ? (
                  renderQuestion
                ) : (
                  <div className="container-spinner">
                    <div class="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group float-right">
                <button
                  className="lightButtom mr-5"
                  onClick={() => {
                    this.handleQuestion("toBack");
                    this.setState({
                      onBack: true,
                      finishQuestions: [],
                    });

                    setTimeout(() => {
                      this.setState({
                        onBack: false,
                      });
                    }, 200);
                  }}
                >
                  {"Voltar"}
                </button>

                {this.getTypeButton(this.state.currentStep) ? (
                  <button
                    className="mainColorButtom"
                    onClick={() => {
                      let currentQuestion = [];

                      currentQuestion = this.getCurrentQuestion(questionId);

                      if (this.state.currentQuestionForm.length) {
                        currentQuestion = this.state.currentQuestionForm;
                      } else {
                        currentQuestion = this.state.finishQuestions;
                      }

                      if (!currentQuestion.length) {
                        currentQuestion = this.getCurrentQuestion(questionId);
                      }

                      if (!currentQuestion.length > 0) {
                        this.setState((state) => ({
                          waitingResponseSubmit: false,
                          message: {
                            ...state.message,
                            ...{
                              title: "Aviso",
                              leftButtomText: "Ok",
                              rightButtomText: "",
                              visible: true,
                              text: (
                                <>
                                  {this.stringTable.standard.apiError}

                                  <ul className="errorSumary">
                                    <li>
                                      {
                                        this.stringTable.sif
                                          .unfilledQuestionnaire
                                      }
                                    </li>
                                  </ul>
                                </>
                              ),
                            },
                          },
                        }));
                        return;
                      } else {
                        const { ListFiles } = this.state;
                        let fileOfQuestion = ListFiles.filter(
                          (item) => item.questionId == questionId
                        );

                        if (!fileOfQuestion.length && hasAttachment) {
                          this.setState((state) => ({
                            waitingResponseSubmit: false,

                            message: {
                              ...state.message,
                              ...{
                                title: "Aviso",
                                visible: true,
                                leftButtomText: "Sim",
                                rightButtomText: "Não",
                                leftButtomClickCallBack: () => {
                                  this.preHandleSubmit();
                                },
                                text: (
                                  <>
                                    {this.stringTable.standard.apiError}

                                    <ul className="errorSumary">
                                      <li>
                                        {
                                          this.stringTable.sif
                                            .notAttachedDocuments
                                        }
                                      </li>
                                    </ul>
                                  </>
                                ),
                              },
                            },
                          }));
                          return;
                        } else {
                          this.preHandleSubmit();
                        }
                        if (
                          this.state.currentQuestionForm &&
                          this.state.currentQuestionForm.length
                        ) {
                          if (
                            this.state.currentQuestionForm[0].type ===
                              "onInputText" ||
                            this.state.currentQuestionForm[0].type ===
                              "onInputNumber" ||
                            this.state.currentQuestionForm[0].type ===
                              "onInputTextArea"
                          ) {
                            this.salvationOfQuestions();
                          }
                        }
                      }
                    }}
                  >
                    {"Enviar"}
                  </button>
                ) : (
                  <button
                    className="mainColorButtom"
                    onClick={() => {
                      let currentQuestion = [];

                      currentQuestion = this.getCurrentQuestion(questionId);

                      if (currentQuestion.length) {
                        currentQuestion = currentQuestion;
                      } else {
                        currentQuestion = this.state.currentQuestionForm;
                      }

                      if (!currentQuestion.length > 0) {
                        this.setState((state) => ({
                          waitingResponseSubmit: false,
                          message: {
                            ...state.message,
                            ...{
                              title: "Aviso",
                              leftButtomText: "Ok",
                              rightButtomText: "",
                              visible: true,

                              text: (
                                <>
                                  {this.stringTable.standard.apiError}

                                  <ul className="errorSumary">
                                    <li>
                                      {
                                        this.stringTable.sif
                                          .unfilledQuestionnaire
                                      }
                                    </li>
                                  </ul>
                                </>
                              ),
                            },
                          },
                        }));
                        return;
                      } else {
                        const { ListFiles } = this.state;

                        let fileOfQuestion = ListFiles.filter(
                          (item) => item.questionId == questionId
                        );

                        if (!fileOfQuestion.length && hasAttachment) {
                          this.setState((state) => ({
                            waitingResponseSubmit: false,

                            message: {
                              ...state.message,
                              ...{
                                title: "Aviso",
                                visible: true,
                                leftButtomText: "Sim",
                                rightButtomText: "Não",
                                leftButtomClickCallBack: () => {
                                  this.handleQuestion("toNext");
                                  setTimeout(() => {
                                    this.setState({
                                      currentQuestionForm: [],
                                      token: [],
                                      totalFile: [],
                                      file: null,
                                      name: "",
                                      responseImage: [],
                                    });
                                  }, 100);
                                },
                                text: (
                                  <>
                                    {this.stringTable.standard.apiError}

                                    <ul className="errorSumary">
                                      <li>
                                        {
                                          this.stringTable.sif
                                            .notAttachedDocuments
                                        }
                                      </li>
                                    </ul>
                                  </>
                                ),
                              },
                            },
                          }));
                          return;
                        } else {
                          this.handleQuestion("toNext");
                          setTimeout(() => {
                            this.setState({
                              currentQuestionForm: [],
                              token: [],
                              totalFile: [],
                              file: null,
                              name: "",
                              responseImage: [],
                            });
                          }, 100);
                        }

                        if (
                          this.state.currentQuestionForm &&
                          this.state.currentQuestionForm.length
                        )
                          if (
                            this.state.currentQuestionForm[0].type ===
                              "onInputText" ||
                            this.state.currentQuestionForm[0].type ===
                              "onInputNumber" ||
                            this.state.currentQuestionForm[0].type ===
                              "onInputTextArea"
                          ) {
                            this.salvationOfQuestions();
                          }
                      }
                    }}
                  >
                    {"Avançar"}
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);

    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 2) {
        if (!isNaN(parseInt(urlValues[0])) || !isNaN(parseInt(urlValues[1]))) {
          this.setState({
            questionnaireTypeId: parseInt(urlValues[0]),
            sifId: parseInt(urlValues[1]),
          });
          valid = true;
        }
      }
    }

    if (!valid) {
      window.history.back();
    }
  }

  componentDidMount() {
    this.getQuestions();
    this.getStep();
    this.getPreviousAnswers();

    setTimeout(() => {
      this.filterPreviousAnswers();
    }, 700);
  }

  onInputChange(event) {
    const target = event.target;
    let name = "unknown";
    let value = "";
    let formData = this.state.formData;

    if (target.id) name = target.id;

    value = target.value;

    if (
      name === "questionTypeId" ||
      name === "hasAttachment" ||
      name === "hasValidation" ||
      name === "hasArtificialIntelligenceRecognition"
    ) {
      if (!isNaN(parseInt(value))) value = parseInt(value);
    }

    if (name === "questionTypeId") formData.anwserOptions = [];

    this.setState({ formData: { ...formData, ...{ [name]: value } } });
  }

  getCurrentQuestion(questionId) {
    const { solvedQuestions } = this.state;

    if (solvedQuestions.length) {
      const filteredQuestion = solvedQuestions.filter(
        (item) => item.questionId == questionId
      );

      this.setState({ currentQuestionForm: filteredQuestion });

      return filteredQuestion;
    } else {
      return [];
    }
  }

  salvationOfQuestions(questionId = 0) {
    const {
      realForm,
      currentQuestionForm,
      currentStep,
      token,

      solvedQuestions,
    } = this.state;

    if (questionId !== 0 && !currentQuestionForm.length > 0) {
      const filteredQuestionsAnswer = this.questionsAnswer.map((question) => {
        if (
          question.questionTypeId === currentStep &&
          question.questionId === questionId
        ) {
          question.previousAnswers = [];
        }

        return question;
      });

      let filterUndeletedQuestions = solvedQuestions.filter(
        (item) => item.questionId !== questionId
      );

      this.setState({
        solvedQuestions: filterUndeletedQuestions.length
          ? [...filterUndeletedQuestions]
          : [],
        currentQuestionForm: [],
        token: [],
        totalFile: [],
        file: null,
        name: "",
        questionsAnswer: [...filteredQuestionsAnswer],
      });

      return;
    }

    if (
      currentQuestionForm.length > 0 &&
      typeof currentQuestionForm[0].value === "string"
    ) {
      currentQuestionForm[0].value = currentQuestionForm[0].value.replace(
        /["[/\]"]/g,
        ""
      );
    }

    if (
      currentQuestionForm[0].type !== undefined &&
      currentQuestionForm[0].type === "checkbox"
    ) {
      currentQuestionForm[0].formattedValue =
        currentQuestionForm[0].value.split(",");
    }

    if (currentQuestionForm[0].hasAttachment == true) {
      currentQuestionForm[0].documentTokens = token.length ? [...token] : [];
    }

    delete currentQuestionForm[0].hasAttachment;

    const differentQuestions = solvedQuestions.filter(
      (item) => item.questionId !== currentQuestionForm[0].questionId
    );

    this.setState({
      realForm: [...realForm, ...currentQuestionForm],
      solvedQuestions: differentQuestions.length
        ? [...differentQuestions, ...currentQuestionForm]
        : [...currentQuestionForm],
    });

    switch (currentStep) {
      case 1:
        this.setState({
          answerOne: [...currentQuestionForm],
        });
        break;
      case 2:
        this.setState({
          answerTwo: [...currentQuestionForm],
        });
        break;
      case 3:
        this.setState({
          answerThree: [...currentQuestionForm],
        });
        break;
      case 4:
        this.setState({
          answerFour: [...currentQuestionForm],
        });
        break;
      case 5:
        this.setState({
          answerFive: [...currentQuestionForm],
        });
        break;

      default:
        break;
    }
  }

  getQuestions() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.GetCurrentQuestionnaire +
      "/" +
      this.state.questionnaireTypeId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        const stepsIds = result.response.map((item) => item.stepId);
        const min = Math.min(...stepsIds);

        console.warn({
          getQuestions: result.response,
        });
        this.setState({ currentStep: min, initialStep: min });

        this.getPreviousAnswers(result.response);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  handleQuestion(parameter) {
    const {
      currentStep,
      currentSubStep,
      filteredQuestions,
      questionOne,
      questionTwo,
      questionThree,
      questionFour,
      questionFive,
      initialStep,
    } = this.state;

    let question = this.state.currentQuestion;

    switch (parameter) {
      case "toBack":
        question -= 1;

        if (currentStep === initialStep && currentSubStep === 1) {
          setTimeout(() => {
            window.history.back();
          }, 300);
          return;
        }
        this.setState({ currentQuestion: question });

        if (
          currentSubStep <= filteredQuestions.length &&
          currentSubStep !== 1
        ) {
          this.setState({ currentSubStep: currentSubStep - 1 });

          return;
        }

        switch (currentStep) {
          case 5:
            if (questionFour.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 1, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 1);
                return;
              }
            } else if (questionThree.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 2, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 2);
                return;
              }
            } else if (questionTwo.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 3, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 3);
                return;
              }
            } else if (questionOne.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 4, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 4);
                return;
              }
            }
          case 4:
            if (questionThree.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 1, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 1);
                return;
              }
              return;
            } else if (questionTwo.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 2, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 2);
                return;
              }
              return;
            } else if (questionOne.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 3, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 3);
                return;
              }
              return;
            }
          case 3:
            if (questionTwo.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 1, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 1);
                return;
              }
              return;
            } else if (questionOne.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 2, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 2);
                return;
              }
              return;
            }
          case 2:
            if (questionOne.length) {
              if (currentSubStep <= filteredQuestions.length) {
                this.getActiveQuestionOfStepId(currentStep - 1, false);
                this.setState({ finishQuestions: [] });
                this.stepNavigate(this.state.currentStep - 1);
                return;
              }
              return;
            } else {
              if (currentSubStep <= filteredQuestions.length) {
                this.setState({ currentSubStep: filteredQuestions.length });
                this.getActiveQuestionOfStepId(currentStep);
                this.stepNavigate(this.state.currentStep);
                return;
              }
              return;
            }
          case 1:
            if (currentSubStep <= filteredQuestions.length) {
              this.setState({ currentSubStep: filteredQuestions.length });
              this.getActiveQuestionOfStepId(currentStep);
              this.setState({ finishQuestions: [] });
              this.stepNavigate(this.state.currentStep);
              return;
            }

          default:
            if (currentSubStep <= filteredQuestions.length) {
              this.setState({ currentSubStep: filteredQuestions.length });
              this.getActiveQuestionOfStepId(currentStep);
              this.stepNavigate(this.state.currentStep);
              return;
            }
            return;
        }

      case "toNext":
        question += 1;
        if (question < 0) question = 0;
        this.setState({ currentQuestion: question });

        if (currentSubStep < filteredQuestions.length) {
          this.setState({ currentSubStep: currentSubStep + 1 });
          return;
        }

        switch (currentStep) {
          case 1:
            if (questionTwo.length) {
              this.stepNavigate(this.state.currentStep + 1);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 1);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 1);
              return;
            } else if (questionThree.length) {
              this.stepNavigate(this.state.currentStep + 2);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 2);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 2);
              return;
            } else if (questionFour.length) {
              this.stepNavigate(this.state.currentStep + 3);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 3);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 3);
              return;
            } else if (questionFive.length) {
              this.stepNavigate(this.state.currentStep + 4);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 4);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 4);
              return;
            }

            return;

          case 2:
            if (questionThree.length) {
              this.stepNavigate(this.state.currentStep + 1);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 1);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 1);
              return;
            } else if (questionFour.length) {
              this.stepNavigate(this.state.currentStep + 2);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 2);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 2);
              return;
            } else if (questionFive.length) {
              this.stepNavigate(this.state.currentStep + 3);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 3);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 3);
              return;
            }
            return;
          case 3:
            if (questionFour.length) {
              this.stepNavigate(this.state.currentStep + 1);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 1);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 1);
              return;
            } else if (questionFive.length) {
              this.stepNavigate(this.state.currentStep + 2);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 2);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 2);
              return;
            }
            return;
          case 4:
            if (questionFive.length) {
              this.stepNavigate(this.state.currentStep + 1);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep + 1);
              this.setState({ currentSubStep: 1 });
              this.setState((state) => state.currentStep + 1);
              return;
            } else {
              this.stepNavigate(this.state.currentStep);
              this.setState({ finishQuestions: [] });
              this.getActiveQuestionOfStepId(this.state.currentStep);
              this.setState({ currentSubStep: this.state.currentSubStep });
              this.setState((state) => state.currentStep);
              return;
            }

          default:
            this.stepNavigate(this.state.currentStep);
            this.getActiveQuestionOfStepId(this.state.currentStep);
            this.setState({ currentSubStep: this.state.currentSubStep });
            this.setState((state) => state.currentStep);
            return;
        }

      default:
        console.error("Falha ao interpretar resposta");
    }
  }

  stepNavigate(stepId) {
    this.setState({ currentStep: Number(stepId) });
    this.getColorForStepId(stepId);
  }

  uploadDocument(questionId) {
    const { allFiles, currentQuestionForm, ListFiles, file, name } = this.state;

    function Equal(files, questionId) {
      return files.filter((item) => item.questionId === questionId);
    }

    function Different(files, questionId) {
      return files.filter((item) => item.questionId !== questionId);
    }

    const currentAttachment = Equal(ListFiles, questionId);
    const differentAttachment = Different(ListFiles, questionId);

    const allFilteredFilesOfThisIssue = Equal(allFiles, questionId);

    const allFilesFilteredFromAnotherQuestion = Different(allFiles, questionId);

    this.setState({ loading: true });

    let tokenStorage = window.localStorage.getItem(
      constants.LocalStorage.Token
    );
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Document.Document;

    const dataForm = new FormData();

    dataForm.append("SifId", this.state.sifId);
    dataForm.append("QuestionId", questionId);
    dataForm.append("File", this.state.file);

    const data = dataForm;

    smoothApi.post(
      url,
      data,
      tokenStorage,
      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ loading: false });

          return;
        }

        if (!result.success) {
          this.setState((state) => ({
            waitingResponseSubmit: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                leftButtomText: "Ok",
                rightButtomText: "",
                text: (
                  <>
                    {this.stringTable.standard.apiError}

                    <ul className="errorSumary">
                      {result.errors.map((error) => {
                        return <li>{error}</li>;
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));
          console.error(messages.Errors.ResponseError, result.errors);
          this.setState({ loading: false });
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          this.setState({ loading: false });
          return;
        }

        let currentToken = [];
        if (allFilteredFilesOfThisIssue.length < 2) {
          let currentQuestion = currentQuestionForm[0];

          if (currentQuestion.documentTokens.length === 1) {
            currentQuestion.documentTokens = [
              ...currentQuestion.documentTokens,
              result.response.token,
            ];
          } else {
            currentQuestion.documentTokens = [result.response.token];
          }

          currentToken = [...currentQuestion.documentTokens];

          if (allFilesFilteredFromAnotherQuestion.length) {
            this.setState({
              allFiles: [
                ...allFilesFilteredFromAnotherQuestion,
                currentQuestion,
              ],
              currentQuestionForm: [currentQuestion],
            });
          } else {
            this.setState({
              allFiles: [currentQuestion],
              currentQuestionForm: [currentQuestion],
            });
          }
        }

        result.response.questionId = questionId;

        let newArrayOfFiles = [];
        let itAllTogether = [];

        if (currentAttachment.length) {
          newArrayOfFiles = [
            {
              name: name,
              file: file,
              responseImage: [
                ...currentAttachment[0].responseImage,
                result.response,
              ],
              questionId: currentQuestionForm.length
                ? currentQuestionForm[0].questionId
                : questionId,
              token: [result.response.token],
            },
          ];
        } else {
          newArrayOfFiles = [
            {
              name: name,
              file: file,
              responseImage: [result.response],
              questionId: currentQuestionForm.length
                ? currentQuestionForm[0].questionId
                : questionId,
              token: [result.response.token],
            },
          ];
        }

        if (differentAttachment.length) {
          itAllTogether.push(...differentAttachment);
        }

        if (newArrayOfFiles.length) {
          itAllTogether.push(...newArrayOfFiles);
        }

        this.setState({
          listFilesUploaded: [...newArrayOfFiles],
          ListFiles: [...itAllTogether],
          token: [...currentToken],
          file: null,
          loading: false,
        });

        document.getElementById("button-file").value = "";

        return;
      },
      (error, httpCode) => {
        if (error) {
          this.setState({
            file: null,
            loading: false,
          });
        }

        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  removeFile() {
    document.getElementById("button-file").value = "";

    this.setState({
      file: "",
    });
  }

  removeFileUploaded(token, questionId) {
    const { ListFiles, allFiles } = this.state;

    let newListTokensFirst = [];

    let allRemainingFiles = [];

    let otherFilesRemaining = [];

    if (ListFiles.length) {
      ListFiles.forEach((item, index) => {
        const otherFiles = ListFiles[index].responseImage.filter(
          (res) => res.token !== token
        );

        const newListOfTokens = ListFiles[index].token.filter(
          (oldToken) => oldToken !== token
        );

        ListFiles[index].responseImage = otherFiles;
        ListFiles[index].token = newListOfTokens;
      });

      allRemainingFiles = allFiles.filter(
        (file) => file.questionId === questionId
      );

      otherFilesRemaining = allFiles.filter(
        (file) => file.questionId !== questionId
      );

      allRemainingFiles.forEach((file, index) => {
        newListTokensFirst = file.documentTokens.filter(
          (item) => item !== token
        );
        file.documentTokens = file.documentTokens.filter(
          (item) => item !== token
        );

        if (!file.documentTokens.length) {
          allRemainingFiles = [];
        }
      });

      let itAllTogether = [];
      if (otherFilesRemaining.length) {
        itAllTogether.push(...otherFilesRemaining);
      }

      if (allRemainingFiles.length) {
        itAllTogether.push(...allRemainingFiles);
      }

      if (newListTokensFirst.length) {
        this.setState({
          ListFiles: ListFiles,
          token: [...newListTokensFirst],
          allFiles: [...itAllTogether],
        });
      } else {
        this.setState({
          ListFiles: ListFiles,
          token: [],
          allFiles: [...itAllTogether],
        });
      }
    }
  }

  handleChange(event) {
    if (!event.target.files.length) return;

    const file = event.target.files[0];
    let { type, size } = file;

    if (size > 5242880) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            leftButtomText: "Ok",
            rightButtomText: "",
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.limiteSize}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    type = type.replace("image/", "");

    type = type.replace("application/", "");

    const foundType = this.state.extensions.find((extension) =>
      extension == type ? true : false
    );

    if (this.state.totalFile.length > 2) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            leftButtomText: "Ok",
            rightButtomText: "",
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.limiteFile}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    if (!foundType) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            leftButtomText: "Ok",
            rightButtomText: "",
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.invalidFormat}</li>
                  <li>{this.stringTable.fileAttachment.validFormat}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    this.setState({
      file: event.target.files[0],
      name: event.target.files[0].name,
    });
  }

  handleSubmit() {
    let { solvedQuestions, ListFiles } = this.state;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.Answer;

    solvedQuestions.forEach((questions, index) => {
      ListFiles.forEach((item) => {
        if (item.questionId === solvedQuestions[index].questionId) {
          const tokens = item.responseImage.map((item) => item.token);

          solvedQuestions[index].documentTokens = [...tokens];
        }
      });
    });

    solvedQuestions.sort((a, b) => {
      return a.questionId - b.questionId;
    });

    this.setState({ loadingSubmit: true });

    console.log("Questões salvas!", solvedQuestions);

    let data = {
      SifId: this.state.sifId,
      Answers: solvedQuestions,
      QuestionnaireTypeId: this.state.questionnaireTypeId,
    };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          this.setState({ loadingSubmit: false });

          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ loadingSubmit: false });

          console.error(messages.Errors.ResponseError, result.errors);
          this.setState((state) => ({
            waitingResponseSubmit: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                leftButtomText: "Ok",
                rightButtomText: "",
                visible: true,
                text: (
                  <>
                    {this.stringTable.standard.apiError}

                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));
          return;
        }

        if (result.response == null) {
          this.setState({ loadingSubmit: false });

          this.setState({ waitingResponseSubmit: false });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState((state) => ({
          modalIsOpen: false,
          loadingSubmit: false,
          waitingResponseSubmit: false,
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              leftButtomText: "Ok",
              rightButtomText: "",
              _defaultCloseAction: () => {
                setTimeout(() => {
                  window.location.href = "/sif/Questionnaires/SifSelection";
                }, 200);
              },
              text: (
                <>
                  Concluído com sucesso:
                  <ul className="errorSumary">
                    <li>{this.stringTable.standard.messageSuccessSif}</li>
                  </ul>
                </>
              ),
            },
          },
        }));
        return;

        // window.location.href = "/sif/Questionnaires/SifSelection";
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
          waitingResponseSubmit: false,
          modalIsOpen: false,
        });
      }
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  preHandleSubmit() {
    this.setState({ modalIsOpen: true });
  }

  getActiveQuestionOfStepId(stepId, firstLoad = true) {
    const { questionsResponse } = this.state;

    let filteredQuestions = [];
    let filteredItems = [];

    if (questionsResponse && questionsResponse.length) {
      filteredQuestions = questionsResponse.filter(
        (item) => item.stepId == stepId
      );
    }

    if (questionsResponse && questionsResponse.length) {
      questionsResponse.forEach((item, index) => {
        switch (item.stepId) {
          case 1:
            filteredItems = this.filterQuestionOfStepId(1);

            this.setState({
              questionOne: [...filteredItems],
            });
            break;
          case 2:
            filteredItems = this.filterQuestionOfStepId(2);
            this.setState({
              questionTwo: [...filteredItems],
            });
            break;
          case 3:
            filteredItems = this.filterQuestionOfStepId(3);

            this.setState({
              questionThree: [...filteredItems],
            });
            break;
          case 4:
            filteredItems = this.filterQuestionOfStepId(4);

            this.setState({
              questionFour: [...filteredItems],
            });
            break;
          case 5:
            filteredItems = this.filterQuestionOfStepId(5);

            this.setState({
              questionFive: [...filteredItems],
            });
            break;

          default:
            break;
        }
      });
    }

    this.setState({
      filteredQuestions,
    });

    if (!firstLoad) {
      this.setState({
        currentSubStep: filteredQuestions.length,
      });
    }

    return filteredQuestions;
  }

  getColorForStepId(stepId) {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    switch (stepId) {
      case 1:
        if (this.state.currentStep === 1) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 1 &&
          this.state.answerOne.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }

      case 2:
        if (this.state.currentStep === 2) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 2 &&
          this.state.answerTwo.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 3:
        if (this.state.currentStep === 3) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 3 &&
          this.state.answerThree.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 4:
        if (this.state.currentStep === 4) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 4 &&
          this.state.answerFour.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 5:
        if (this.state.currentStep === 5) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 5 &&
          this.state.answerFive.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      default:
        return wizzardConfiguration.Status.Disabled;
    }
  }

  getStep() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Step.Find;
    const data = {
      name: null,
    };

    smoothApi.post(url, data, token, (result) => {
      if (
        !result ||
        !result.systemErrors ||
        result.success == null ||
        !result.validationErrors
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        return;
      }

      if (!result.success) {
        console.error(messages.Errors.ResponseError, result.errors);
        return;
      }

      if (result.response == null) {
        console.error(messages.Errors.BlankResponse, result.errors);
        return;
      }

      this.setState({ stepNames: result.response });
    });
  }

  getPreviousAnswers(questionsResponse = []) {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.GetPreviousAnswers;
    const data = {
      QuestionnaireTypeId: this.state.questionnaireTypeId,
      SifId: this.state.sifId,
    };

    smoothApi.post(url, data, token, (result) => {
      if (
        !result ||
        !result.errors ||
        !result.response ||
        result.success == null
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        this.setState({ questionsResponse });

        return;
      }

      if (!result.success) {
        console.log(
          messages.Errors.ResponseError,
          result.systemErrors,
          result.validationErrors
        );
        this.setState({ questionsResponse });

        return;
      }

      if (result.response == null) {
        console.log(
          messages.Errors.BlankResponse,
          result.systemErrors,
          result.validationErrors
        );
        this.setState({ questionsResponse });

        return;
      }

      questionsResponse.forEach((item, index) => {
        if (
          this.filterPreviousAnswersBoolean(result.response, item.questionId)
        ) {
          questionsResponse[index].previousAnswers = this.filterPreviousAnswers(
            result.response,
            item.questionId
          );
        }
      });

      console.warn({
        "Já filtrado": questionsResponse,
      });

      this.setState({ questionsResponse });
      this.getActiveQuestionOfStepId(this.state.initialStep, true);
    });
  }

  filterPreviousAnswers(previousAnswers = [], questionId = "") {
    let answers = null;

    previousAnswers.forEach((item, index) => {
      if (item.questionId == questionId) {
        answers = item.answers;
      }
    });
    return answers;
  }

  filterPreviousAnswersBoolean(previousAnswers = [], questionId = "") {
    let answers = false;

    previousAnswers.forEach((item, index) => {
      if (item.questionId == questionId) {
        answers = true;
      }
    });
    return answers;
  }

  filterQuestionOfStepId(stepId) {
    const { questionsResponse } = this.state;

    const filtered = questionsResponse.filter((item) => item.stepId == stepId);

    return filtered;
  }

  getTypeButton(stepId) {
    const {
      currentSubStep,
      filteredQuestions,

      questionTwo,
      questionThree,
      questionFour,
      questionFive,
    } = this.state;

    let submit = false;

    switch (stepId) {
      case 1:
        if (
          currentSubStep === filteredQuestions.length &&
          !questionTwo.length &&
          !questionThree.length &&
          !questionFour.length &&
          !questionFive.length
        ) {
          submit = true;
        } else {
          submit = false;
        }
        break;
      case 2:
        if (
          currentSubStep === filteredQuestions.length &&
          !questionThree.length &&
          !questionFour.length &&
          !questionFive.length
        ) {
          submit = true;
        } else {
          submit = false;
        }
        break;
      case 3:
        if (
          currentSubStep === filteredQuestions.length &&
          !questionFour.length &&
          !questionFive.length
        ) {
          submit = true;
        } else {
          submit = false;
        }
        break;
      case 4:
        if (
          currentSubStep === filteredQuestions.length &&
          !questionFive.length
        ) {
          submit = true;
        } else {
          submit = false;
        }
        break;
      case 5:
        if (currentSubStep == filteredQuestions.length) {
          submit = true;
        } else {
          submit = false;
        }
        break;

      default:
        submit = false;
        break;
    }

    return submit;
  }

  nonDuplicated(array) {
    let nonDuplicateAccount = [];

    array.forEach((item, index) => {
      const filteredItem = nonDuplicateAccount.filter((item) => {
        return item.questionId === array[index].questionId;
      });

      if (!filteredItem.length) {
        nonDuplicateAccount.push(array[index]);
      }
    });

    return nonDuplicateAccount;
  }
}

export default Answer;
