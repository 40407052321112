import React from "react";
import { Row } from "reactstrap";

const DialogConfirm = (props) => {
  const open = !!props.open;
  const title = props.title ? props.title : "Aviso";
  const text = props.text ? props.text : "Deseja realmente fazer isso ?";
  const handleClose = props.handleClose ? props.handleClose : () => {};
  const success = props.success ? props.success : null;

  return (
    <>
      {!open || (
        <div>
          {success ? (
            <Row lg="12" className="justify-content-center pt-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {title} {text}
                </span>
              </div>
            </Row>
          ) : (
            <Row lg="12" className="justify-content-center pt-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-error.png")}
                />
                <span className="font-weight-600 pl-2">
                  {title} {text}
                </span>
              </div>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default DialogConfirm;
