import React from "react";
import "assets/scss/components.scss";

import Modal from "react-modal";
import Select from "react-select";
import { Link } from "react-router-dom";

import template from "template.js";

import { diruma } from "libs/diruma";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList.jsx";
import InputSearch from "components/Utils/InputSearch";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";
import Loading from "components/Utils/Loading.jsx";

import { Container, Row, Col, Form, UncontrolledTooltip } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class UserRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderSifList: false,
      addSifLoading: false,
      confirmExclusao: false,
      fieldRequiredError: false,
      showRegisterSuccess: false,
      showSendEmailSuccess: false,
      showLoading: false,
      openConfirm: false,
      deletedSugestionInput: false,

      indexSif: -1,
      industryId: -1,
      userIndustryId: -1,
      userId: -1,
      userSifId: 0,

      userSifsResponse: [],
      industrySugestedList: [],

      industrySugested: {
        id: 0,
        name: "",
      },
      formData: {},

      profileResponse: null,
      sifResponse: null,

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "firstName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "lastName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "cpf",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "email",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "profileId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.loadSugestedIndustry = this.loadSugestedIndustry.bind(this);
    this.sendEmailFirstAccess = this.sendEmailFirstAccess.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];

    let profileSelectItems = [];
    let sifSelectItems = [];

    if (this.state.userSifsResponse) {
      if (this.state.userSifsResponse.length > 0) {
        tableCollumns = [
          { name: "Sif", size: 30, property: "sif" },
          { name: "Função", size: 30, property: "jobTitle" },
          { name: "", size: 10, property: "excluirButton" },
        ];

        tableData = this.state.userSifsResponse;

        tableData = tableData.map((item, index) => {
          return {
            ...item,
            ...{
              excluirButton: (
                <button
                  disabled={this.state.onlyView ? true : false}
                  type="button"
                  className="btn btn-sm exc"
                  key={index}
                  onClick={() => this.openModal(index)}
                >
                  Excluir
                </button>
              ),
              sif: item.sifNumber + " - " + item.corporateName,
            },
          };
        });
      }
    }

    if (this.state.profileResponse) {
      if (this.state.profileResponse.length > 0) {
        try {
          profileSelectItems = this.state.profileResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["profileId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          profileSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.sifResponse) {
      if (this.state.sifResponse.length > 0) {
        try {
          sifSelectItems = this.state.sifResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["sifId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.sifNumber} - {item.corporateName}
              </option>
            );
          });
        } catch (ex) {
          sifSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }


    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este registro?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.userSifId}
                onClick={(e) => this.deleteSif(this.state.userSifId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.userId == -1 ? (
                <h1 className="text-dark">
                  {this.stringTable.user.titleNewUser}
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.stringTable.user.titleChangeUser}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.user.newSubtitleNewUser}
              </small>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="6">
                <div className="form-group">
                  <label
                    htmlFor="firstName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.name}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="firstName"
                    type="text"
                    id="firstName"
                    value={
                      this.state.formData["firstName"]
                        ? this.state.formData["firstName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="6">
                <div className="form-group">
                  <label
                    htmlFor="lastName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.lastName}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="lastName"
                    type="text"
                    id="lastName"
                    value={
                      this.state.formData["lastName"]
                        ? this.state.formData["lastName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row lg="12">
              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="cpf"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.cpf}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="cpf"
                    type="text"
                    id="cpf"
                    name="cpf"
                    value={
                      this.state.formData["cpf"]
                        ? this.state.formData["cpf"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="6">
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.email}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="email"
                    type="text"
                    id="email"
                    value={
                      this.state.formData["email"]
                        ? this.state.formData["email"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col md="3" sm="6">
                <label className="form-control-label label-input">
                  {this.stringTable.user.form.profile}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <select
                  name="profileId"
                  id="profileId"
                  className={
                    "input-style form-control showFilterActive" +
                    (profileSelectItems.length === 0 ? " loadingSelect" : "")
                  }
                  disabled={profileSelectItems.length === 0 ? true : false}
                  ref="profileId"
                  onChange={this.onInputChange}
                >
                  <option
                    value=""
                    selected={this.state.formData["profileId"] ? false : true}
                  >
                    Selecione
                  </option>
                  {profileSelectItems.length === 0 || profileSelectItems}
                </select>
              </Col>
            </Row>

            {this.state.formData["profileId"] == "2" ? (
              <>
                <Row lg="12">
                  <Col lg="6" className="mb-3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.industry}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <Select
                      className="select-industry"
                      id="industryName"
                      value={{
                        value: this.state.industrySugested.id || "",
                        label: this.state.industrySugested.name,
                      }}
                      options={this.state.industrySugestedList}
                      placeholder=""
                      onChange={(event) =>
                        this.loadSugestedIndustry(event.value)
                      }
                    />
                  </Col>
                </Row>

                <Row lg="12">
                  <Col md="9" sm="9">
                    <div className="form-group">
                      <label className="form-control-label label-input">
                        {this.stringTable.user.form.industryProfile.function}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="jobTitle"
                        type="text"
                        id="jobTitle"
                        value={
                          this.state.formData["jobTitle"]
                            ? this.state.formData["jobTitle"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}

            {this.state.formData["profileId"] == "3" ? (
              <>
                <Row lg="12">
                  <Col lg="6" className="mb-3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.industry}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <Select
                      className="select-industry"
                      id="industryName"
                      value={{
                        value: this.state.industrySugested.id || "",
                        label: this.state.industrySugested.name,
                      }}
                      options={this.state.industrySugestedList}
                      placeholder=""
                      onChange={(event) =>
                        this.loadSugestedIndustry(event.value)
                      }
                    />
                  </Col>

                  <Col md="6" sm="6">
                    <label className="form-control-label label-input">
                      {this.stringTable.user.form.sifProfile.sif}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <select
                      name="sifId"
                      id="sifId"
                      className={
                        "input-style form-control showFilterActive" +
                        (sifSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                      disabled={sifSelectItems.length === 0 ? true : false}
                      onChange={this.onInputChange}
                    >
                      <option
                        value=""
                        selected={this.state.formData["sifId"] ? false : true}
                      >
                        Selecione
                      </option>
                      {sifSelectItems.length === 0 || sifSelectItems}
                    </select>
                  </Col>
                </Row>

                <Row lg="12">
                  <Col md="9" sm="9">
                    <div className="form-group">
                      <label className="form-control-label label-input">
                        {this.stringTable.user.form.sifProfile.function}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="jobTitle"
                        type="text"
                        id="jobTitle"
                        value={
                          this.state.formData["jobTitle"]
                            ? this.state.formData["jobTitle"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="container-fluid mt-4">
                  <Row>
                    <Col>
                      <div className="float-lg-right">
                        <button
                          type="button"
                          className="mainColorButtom mr-5"
                          onClick={() => {
                            this.addSif();
                          }}
                        >
                          {this.state.addPaymentLoading ? (
                            <Loading load="Sim" />
                          ) : (
                            "Adicionar SIF"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row className="pt-4 pl-5">
                  <Col lg="12">
                    {this.state.renderSifList ? (
                      <>
                        <DataList
                          tableCollumns={tableCollumns}
                          tableData={tableData}
                        />
                      </>
                    ) : (
                      <>
                        {!this.state.addSifLoading || <h1> Carregando...</h1>}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Form>

          <div className="container-fluid mb-4 mt-6">
            <Row className="justify-content-center">
              <Link to={"/admin/User"} className="mr-5">
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
              <button
                className="darkButtom"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                {this.state.submitLoading ? <Loading load="Sim" /> : "Salvar"}
              </button>
            </Row>
          </div>

          {!this.state.showRegisterSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.standard.registerSuccess}
                </span>
              </div>
            </Row>
          )}

          {!this.state.showLoading || (
            <Row lg="12" className="justify-content-center pb-5">
              <span>{this.stringTable.standard.loading}</span>
            </Row>
          )}

          {!this.state.showSendEmailSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div className="">
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.user.sendEmailSuccess}
                </span>
              </div>
            </Row>
          )}

          {this.state.fieldRequiredError ? (
            <section className="container">
              <Row>
                <Col col="md-12">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg
                        ? "msgVisibleExclusion"
                        : "")
                    }
                  >
                    <DialogConfirm
                      open={this.state.fieldRequiredError}
                      title={" "}
                      text={this.stringTable.standard.fieldRequiredError}
                      handleClose={() => {
                        this.setState({ fieldRequiredError: false });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            <></>
          )}

          {!this.state.formData["firstAccess"] === true || (
            <>
              <UncontrolledTooltip
                delay={0}
                trigger="hover focus"
                target="tooltipHelpSendEmail"
              >
                {this.stringTable.user.tooltip.sendEmail}
              </UncontrolledTooltip>

              <Row lg="12" className="justify-content-center pb-6">
                <div>
                  <a
                    onClick={() => {
                      this.sendEmailFirstAccess();
                    }}
                  >
                    <span className="linkText2">
                      {this.stringTable.user.sendEmailFirstAccess}
                    </span>
                  </a>
                  <img
                    id="tooltipHelpSendEmail"
                    className="pl-2"
                    alt="..."
                    src={require("assets/img/brand/help.png")}
                  />
                </div>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ userId: id });
  }

  componentDidMount() {
    this.loadSelects();
    this.getUserMethod();
  }

  loadSifId(sifId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let formData = this.state.formData;

        formData.sifNumber = ret.sifNumber;
        formData.corporateName = ret.corporateName;

        this.setState({ formData: formData });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  addSif() {
    this.setState({
      renderSifList: true,
      addSifLoading: true,
      indexSif: this.state.indexSif + 1,
    });

    let formData = this.state.formData;
    let userSifList = this.state.userSifsResponse;
    let isRepeated = false;
    let sif = {};

    sif.index = this.state.indexSif + 1;

    if (formData.sifId) {
      sif.sifId = formData.sifId;
      sif.sifNumber = formData.sifNumber;
      sif.corporateName = formData.corporateName;
    }

    if (formData.jobTitle) sif.jobTitle = formData.jobTitle;

    if (userSifList.length > 0) {
      userSifList.map((item) => {
        let sifIdSelected = formData.sifId;

        if (item.sifId.toString().indexOf(sifIdSelected) === 0) {
          isRepeated = true;

          return;
        }
      });
    }

    if (sif.sifId && sif.jobTitle) {
      if (!isRepeated) {
        userSifList.push(sif);

        delete formData.sifId;
        delete formData.jobTitle;

        this.setState({
          userSifsResponse: userSifList,
          formData: formData,
          addSifLoading: false,
        });
      } else {
        this.setState((state) => ({
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                  <span className="errorText"> mensagem </span> e tente
                  novamente:
                  <ul className="errorSumary">
                    <li>Este SIF já foi adicionado.</li>
                  </ul>
                </>
              ),
            },
          },
          addSifLoading: false,
        }));
      }
    } else {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                <span className="errorText"> mensagem </span> e tente novamente:
                <ul className="errorSumary">
                  <li>Todos os campos do perfil SIF são obrigatórios.</li>
                </ul>
              </>
            ),
          },
        },
        addSifLoading: false,
      }));

      return;
    }
  }

  deleteSif(index) {
    let userSifList = this.state.userSifsResponse;

    userSifList.splice(index, 1);

    if (userSifList.length <= 0) this.setState({ renderSifList: false });

    this.setState({
      modalIsOpen: false,
      userSifsResponse: userSifList,
      addSifLoading: false,
    });
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });
      }.bind(this),
      4000
    );
  }

  openModal(id) {
    this.setState({ userSifId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "cpf":
          value = formater.template(target.value, "000.000.000-00");
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "sifId") this.loadSifId(value);

    if (name === "profileId") {
      if (value !== "2") {
        this.setState({ industrySugested: { id: 0, name: "" }, industryId: 0 });
      }

      if (value !== "3") this.setState({ sifResponse: null });
    }
  }

  getUserMethod() {
    if (this.state.userId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.User.Get +
        "/" +
        this.state.userId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let industrySifSugested = {};
          let userSifsResponse = this.state.userSifsResponse;

          if (ret.userIndustry) {
            ret.userIndustry.name =
              formater.template(ret.userIndustry.cnpj, "00.000.000/0000-00") +
              " - " +
              ret.userIndustry.industryTradeName;

            ret.jobTitle = ret.userIndustry.jobTitle;
            industrySifSugested.id = ret.userIndustry.industryId;
            industrySifSugested.name =
              formater.template(ret.userIndustry.cnpj, "00.000.000/0000-00") +
              " - " +
              ret.userIndustry.industryTradeName;

            delete ret.userIndustry.industryTradeName;

            this.setState({
              industrySugested: ret.userIndustry,
              industryId: ret.userIndustry.industryId,
              userIndustryId: ret.userIndustry.id,
            });
          }

          if (ret.userSifs) {
            if (ret.userSifs.length > 0) {
              userSifsResponse = ret.userSifs.map((item, index) => {
                if (index === 0) {
                  industrySifSugested.id = item.industryId;
                  industrySifSugested.name =
                    formater.template(item.industryCnpj, "00.000.000/0000-00") +
                    " - " +
                    item.industryTradeName;
                }

                delete item.industryId;
                delete item.industryTradeName;

                return item;
              });

              this.setState({
                userSifsResponse: userSifsResponse,
                renderSifList: true,
                addSifLoading: true,
                industryId: industrySifSugested.id,
              });
            }

            this.loadSif();
          }

          ret.cpf = formater.template(ret.cpf, "000.000.000-00");

          this.setState({
            formData: ret,
            industrySugested: industrySifSugested,
          });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState({ fieldRequiredError: true });

      return;
    }

    this.setState({ submitLoading: true });

    let formData = this.state.formData;
    let userSifs = this.state.userSifsResponse;

    if (this.state.userId != -1) formData.id = this.state.userId;

    formData.cpf = formater.number(formData.cpf);

    formData.userIndustry = {};
    formData.userSifs = [];

    if (formData.profileId == "2") {
      if (!this.state.deletedSugestionInput) {
        if (this.state.userIndustryId !== -1)
          formData.userIndustry.id = this.state.userIndustryId;

        if (this.state.industryId !== -1)
          formData.userIndustry.industryId = this.state.industryId;

        formData.userIndustry.jobTitle = formData.jobTitle;
      } else {
        this.setState({
          deletedSugestionInput: false,
          industryId: -1,
          industrySugested: {
            id: 0,
            name: "",
          },
        });
      }
    }

    if (formData.profileId == "3") {
      formData.userSifs = userSifs.map((item) => {
        delete item.index;
        delete item.tradeName;

        // if (item.corporateName)
        //   delete item.corporateName;

        // if (item.sifNumber)
        //   delete item.sifNumber;

        return item;
      });
    }

    if (!formData.profileId) formData.profileId = 0;

    if (formData.id) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.User.Put +
        "/" +
        formData.id;
      let data = formData;

      smoothApi.put(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: true,
            openConfirm: true,
            messageSubmit: "salvo com sucesso ",
          });
          // this.getUserMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    } else {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.User.Post;
      let data = formData;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          let ret = result;

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: true,
            openConfirm: true,
            userId: ret.response.id,
            messageSubmit: "salvo com sucesso ",
          });
          this.getUserMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    }
  }

  sendEmailFirstAccess() {
    this.setState({ showLoading: true });

    let userId = {
      userId: this.state.userId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.User.SendFirstAccessEmail;
    let data = userId;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        this.setState({ showSendEmailSuccess: true, showLoading: false });
        this.timeOutSubmit();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadIndustry();
    this.loadSif();
    this.loadProfile();
  }

  loadIndustry() {
    let keyword = {
      keyword: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Find;
    let data = keyword;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let industryList = result.response.map(function (item, indice) {
          return {
            value: item.id,
            label:
              formater.template(item.cnpj, "00.000.000/0000-00") +
              " - " +
              item.tradeName,
          };
        });

        this.setState({ industrySugestedList: industryList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSif() {
    let dataAPI = {};
    let industryId = this.state.industryId;

    dataAPI.industryIds = [];

    dataAPI.keyword = null;

    if (industryId !== -1) {
      dataAPI.industryIds.push(industryId);

      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Find;
      let data = dataAPI;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (!result || !result.response || result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ sifResponse: result.response });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  loadProfile() {
    this.setState({ profileResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Profile.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ profileResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSugestedIndustry(industryId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let formData = this.state.formData;
        let ret = result.response;

        ret.name =
          formater.template(ret.cnpj, "00.000.000/0000-00") +
          " - " +
          ret.tradeName;

        delete formData.sifNumber;
        delete formData.corporateName;
        delete ret.tradeName;

        this.setState({
          industrySugested: ret,
          industryId: ret.id,
          deletedSugestionInput: false,
          formData: formData,
          userSifsResponse: [],
        });

        this.loadSif();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({ formData: {} });
  }
}

export default UserRegistration;
