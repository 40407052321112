import React from "react";
import "assets/scss/components.scss";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";

import { diruma } from "libs/diruma";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi.js";

import Loading from "components/Utils/Loading.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class IndustryRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidUrl: false,
      limitTextQuestion: false,
      errorMessage: "",
      messageExclusion: "",
      confirmExclusao: false,
      openConfirm: false,
      industryId: -1,
      formData: {},
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
    this.timer = null;

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "tradeName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "headOfficeCorporateName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "cnpj",
          validations: [diruma.validations.cnpj],
          customValidations: [],
        },
        {
          ref: "email",
          validations: [diruma.validations.email],
          customValidations: [],
        },
      ],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let profileSelectItems = [];
    let jobTitleSelectItems = [];

    if (this.state.profileResponse) {
      if (this.state.profileResponse.length > 0) {
        try {
          profileSelectItems = this.state.profileResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["profileId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          profileSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.jobTitleResponse) {
      if (this.state.jobTitleResponse.length > 0) {
        try {
          jobTitleSelectItems = this.state.jobTitleResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["jobTitleId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          jobTitleSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.industryId == -1 ? (
                <h1 className="text-dark">
                  {this.stringTable.industry.titleNewIndustry}
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.stringTable.industry.titleChangeIndustry}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.industry.newSubtitleNewIndustry}
              </small>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="6">
                <div className="form-group">
                  <label
                    htmlFor="tradeName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.industry.form.tradeName}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="tradeName"
                    type="text"
                    id="tradeName"
                    value={
                      this.state.formData["tradeName"]
                        ? this.state.formData["tradeName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="6">
                <div className="form-group">
                  <label
                    htmlFor="headOfficeCorporateName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.industry.form.corporateName}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="headOfficeCorporateName"
                    type="text"
                    id="headOfficeCorporateName"
                    value={
                      this.state.formData["headOfficeCorporateName"]
                        ? this.state.formData["headOfficeCorporateName"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row lg="12">
              <Col lg="4">
                <div className="form-group">
                  <label
                    htmlFor="cnpj"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.industry.form.cnpj}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className="form-control input-style"
                    ref="cnpj"
                    type="text"
                    id="cnpj"
                    name="cnpj"
                    value={
                      this.state.formData["cnpj"]
                        ? this.state.formData["cnpj"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="8">
                <div className="form-group ">
                  <label
                    htmlFor="powerBiReportUrl"
                    className="form-control-label label-input "
                  >
                    {this.stringTable.industry.form.Bi}
                    {/* <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small> */}
                  </label>
                  <input
                    className="form-control input-style LinkBi"
                    ref="powerBiReportUrl"
                    type="text"
                    id="powerBiReportUrl"
                    name="powerBiReportUrl"
                    value={
                      this.state.formData["powerBiReportUrl"]
                        ? this.state.formData["powerBiReportUrl"]
                        : ""
                    }
                    onChange={(event) => {
                      this.setState({
                        formData: Object.assign(this.state.formData, {
                          powerBiReportUrl: event.target.value,
                        }),
                      });

                      this.debounce(event.target.value, 500);
                    }}
                  />
                </div>
                {this.state.limitTextQuestion && (
                  <span className="validationErrorTwo font-weight-900">
                    Você excedeu o limite de 300 caracteres.
                  </span>
                )}

                {this.state.invalidUrl && (
                  <span className="validationErrorTwo font-weight-900">
                    Link para relatório Power BI inválido.
                  </span>
                )}
              </Col>
            </Row>
          </Form>

          <div className="container-fluid mb-4 mt-6">
            <Row className="justify-content-center">
              <Link to={"/admin/Industry"} className="mr-5">
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
              <button
                className="darkButtom"
                disabled={this.state.invalidUrl || this.state.limitTextQuestion}
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                {this.state.submitLoading ? <Loading load="Sim" /> : "Salvar"}
              </button>
            </Row>
          </div>

          {!this.state.showRegisterSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.standard.registerSuccess}
                </span>
              </div>
            </Row>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ industryId: id });
  }

  componentDidMount() {
    this.getIndustryMethod();
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });
      }.bind(this),
      4000
    );
  }

  openModal(id) {
    this.setState({ personId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "cnpj":
          value = formater.template(target.value, "00.000.000/0000-00");
          break;

        default:
          value = target.value;
      }
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "profileId") {
      if (value !== "2")
        this.setState({ industrySugested: null, industryId: 0 });
    }
  }

  debounce(value, wait) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (value.length > 300) {
        this.setState({
          limitTextQuestion: true,
        });
        const div = document.getElementsByClassName("LinkBi")[0];

        if (div) {
          div.classList.add("validationError");
        }
      } else if (!this.isURL(value)) {
        this.setState({
          invalidUrl: true,
        });
        const div = document.getElementsByClassName("LinkBi")[0];

        if (div) {
          div.classList.add("validationError");
        }
      } else {
        const div = document.getElementsByClassName("LinkBi")[0];

        if (div) {
          div.classList.remove("validationError");
        }

        this.setState({
          invalidUrl: false,
          limitTextQuestion: false,
        });
      }

      if (value === "") {
        const div = document.getElementsByClassName("LinkBi")[0];

        if (div) {
          div.classList.remove("validationError");
        }

        this.setState({
          invalidUrl: false,
          limitTextQuestion: false,
        });
      }
    }, wait);
  }

  isURL(str) {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  }

  getIndustryMethod() {
    if (this.state.industryId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Industry.Get +
        "/" +
        this.state.industryId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;

          ret.cnpj = formater.template(ret.cnpj, "00.000.000/0000-00");

          this.setState({ formData: ret });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let formData = this.state.formData;

    formData.cnpj = formater.number(formData.cnpj);

    if (formData.id) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Industry.Put +
        "/" +
        formData.id;
      let data = formData;

      smoothApi.put(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
            }));

            return;
          }

          this.setState({
            submitLoading: false,
            showRegisterSuccess: true,
            openConfirm: true,
            messageSubmit: "salvo com sucesso ",
          });
          this.getIndustryMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    } else {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Industry.Post;
      let data = formData;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
            }));

            return;
          }

          let ret = result;

          this.setState({
            submitLoading: false,
            showRegisterSuccess: true,
            openConfirm: true,
            industryId: ret.response.id,
            messageSubmit: "salvo com sucesso ",
          });
          this.getIndustryMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    }
  }

  clearFormState() {
    this.setState({ formData: {} });
  }
}

export default IndustryRegistration;
