import React from "react";
import "assets/scss/pages/questionnaire.scss";

import messages from "variables/messages";
import constants from "variables/constants";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

class SifSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      openConfirm: false,

      sifResponse: null,
      situationResponse: null,
      timer: null,

      formData: {},

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
  }

  render() {
    let sifSelectItems = [];
    let hasNewQuestionnaire = false;
    let hasPendingReturn = false;
    let hasAnsweredQuestionnaire = false;

    if (this.state.sifResponse) {
      if (this.state.sifResponse.length > 0) {
        try {
          sifSelectItems = this.state.sifResponse.map((item, index) => {
            return (
              <option
                selected={
                  this.state.formData["sifId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.sifNumber} - {item.corporateName}
              </option>
            );
          });
        } catch (ex) {
          sifSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.situationResponse) {
      if (this.state.situationResponse.hasNewQuestionnaire)
        hasNewQuestionnaire = this.state.situationResponse.hasNewQuestionnaire;

      if (this.state.situationResponse.hasPendingReturn)
        hasPendingReturn = this.state.situationResponse.hasPendingReturn;

      if (this.state.situationResponse.hasAnsweredQuestionnaire)
        hasAnsweredQuestionnaire =
          this.state.situationResponse.hasAnsweredQuestionnaire;
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container
          fluid
          className="minHeightFooter bg-body bg-body pb-10 vh90  "
        >
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {
                  this.stringTable.questionnaireSif.sifSelection
                    .titleQuestionnaire
                }
              </h1>
              <small className="subtitle">
                {
                  this.stringTable.questionnaireSif.sifSelection
                    .subtitleQuestionnaire
                }
              </small>
            </Col>
            <Col lg="6">
              <Link to="/sif/index">
                <button className="lightButtom float-lg-right mt-2">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row lg="12" className="pt-6">
            <Col md="4" sm="4" lg="4" className="ml-5">
              <label className="form-control-label label-input">
                {this.stringTable.questionnaireSif.sifSelection.selectSif}
              </label>
              <select
                name="sifId"
                id="sifId"
                ref="sifId"
                className={
                  "input-style form-control showFilterActive" +
                  (sifSelectItems.length === 0 ? " loadingSelect" : "")
                }
                disabled={sifSelectItems.length === 0 ? true : false}
                onChange={this.onInputChange}
              >
                <option
                  value=""
                  defaultValue={this.state.formData["sifId"] ? false : true}
                >
                  Selecione
                </option>
                {sifSelectItems.length === 0 || sifSelectItems}
              </select>
            </Col>
          </Row>

          {this.state.situationResponse !== null &&
          !hasPendingReturn &&
          !hasNewQuestionnaire &&
          !hasAnsweredQuestionnaire ? (
            <Row lg="12" className="pt-6 ml-5">
              <div>
                <h1>Não há questionário cadastrado.</h1>
              </div>
            </Row>
          ) : null}

          {!hasPendingReturn || (
            <Row lg="12" className="pt-5 ml-5">
              <div>
                <img
                  className=""
                  alt="..."
                  src={require("assets/img/brand/icon-alert.png")}
                />
                <span className="text-dark ml-3 font-weight-600">
                  {this.stringTable.questionnaireSif.sifSelection.hasPending},
                  <Link
                    to={`/sif/Questionnaires/answeredQuestionnaire/SifQuestionnaireListing/?id=${this.state.formData.sifId}`}
                  >
                    <span className="linkText ml-2">
                      {this.stringTable.questionnaireSif.sifSelection.verifyNow}
                    </span>
                  </Link>
                </span>
              </div>
            </Row>
          )}

          <Row lg="12" className="pt-6 ml-5 pb-9 questionnaire">
            {!hasNewQuestionnaire || (
              <div>
                <Link
                  to={`/sif/Questionnaires/selectionQuestionnaireType/?id=${this.state.formData.sifId}`}
                >
                  <div className="q_card-period ">
                    <div className="justify-content-center q_pt-card d-flex">
                      <h3 className="text-white">
                        {
                          this.stringTable.questionnaireSif.sifSelection
                            .btAnswerNewQuestionnaire
                        }
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {!hasAnsweredQuestionnaire || (
              <div className="ml-5">
                <Link
                  to={`/sif/Questionnaires/answeredQuestionnaire/sifQuestionnaireListing/?id=${this.state.formData.sifId}`}
                >
                  <div className="q_card-period">
                    <div className="justify-content-center q_pt-card d-flex">
                      <h3 className="text-white">
                        {
                          this.stringTable.questionnaireSif.sifSelection
                            .btViewCompletedQuestionnaires
                        }
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </Row>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.loadSifsByResponsible();
  }

  loadSifsByResponsible() {
    this.setState({ sifResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.GetByUser;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sifResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getSituation() {
    this.setState({ situationResponse: null });

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.Get +
      "/" +
      formData.sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ situationResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({ formData: {} });
  }

  openModal(id) {
    this.setState({ roundId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "sifId") {
      this.getSituation();
    }
  }
}

export default SifSelection;
