import Index from "views/pages/Admin/Dashboard/Dashboard";
import IndexSif from "views/pages/Sif/Dashboard/Dashboard";
import indexMap from "views/pages/Map/Dashboard/Dashboard";
import indexIndustry from "views/pages/Industry/Dashboard/Dashboard";

import NotFoundAuth from "views/pages/Auth/NotFoundAuth.jsx";
import Login from "views/pages/Auth/Login.jsx";
import LoginEsqueciSenha from "views/pages/Auth/LoginEsqueciSenha.jsx";
import LoginAlteraSenha from "views/pages/Auth/LoginChangePassword.jsx";
import LoginPrimeiroAcesso from "views/pages/Auth/LoginPrimeiroAcesso.jsx";

import NotFoundAdmin from "views/pages/Admin/NotFoundAdmin.jsx";

import NotFoundSif from "views/pages/Sif/NotFoundSif.jsx";

import User from "views/pages/Admin/User.jsx";
import UserRegistration from "views/pages/Admin/User/userRegistration.jsx";

import Industry from "views/pages/Admin/Industry.jsx";
import IndustryRegistration from "views/pages/Admin/Industry/industryRegistration.jsx";

import Questionnaires from "views/pages/Admin/Questionnaires.jsx";
import RoundRegistration from "views/pages/Admin/Questionnaires/roundRegistration.jsx";
import SelectPeriod from "views/pages/Admin/Questionnaires/selectPeriod.jsx";
import QuestionnaireType from "views/pages/Admin/Questionnaires/questionnaireType.jsx";
import AnswerOptions from "views/pages/Admin/Questionnaires/AnswerOptions.jsx";
import QuestionnaireListing from "views/pages/Admin/Questionnaires/AnsweredQuestionnaire/questionnaireListing.jsx";
import SifsNotAnswer from "views/pages/Admin/Questionnaires/AnsweredQuestionnaire/sifsNotAnswer.jsx";
import AnswerList from "views/pages/Admin/Questionnaires/AnsweredQuestionnaire/answerList.jsx";

// PERFIL SIF

import SifSelection from "views/pages/Sif/Questionnaires/SifSelection.jsx";
import SifCurrentScore from "views/pages/Sif/Questionnaires/sifCurrentScore.jsx";
import SelectionQuestionnaireType from "views/pages/Sif/Questionnaires/selectionQuestionnaireType.jsx";
import SifQuestionnaireListing from "views/pages/Sif/Questionnaires/AnsweredQuestionnaire/sifQuestionnaireListing.jsx";
import SifAnswerList from "views/pages/Sif/Questionnaires/AnsweredQuestionnaire/sifAnswerList.jsx";
import UpdateAnswere from "views/pages/Sif/Questionnaires/AnsweredQuestionnaire/sifUpdateAnswers";

// END PERFIL SIF

import Sif from "views/pages/Admin/Sifs.jsx";
import SifRegistration from "views/pages/Admin/Sifs/sifRegistration.jsx";
import sifPreview from "views/pages/Sif/Sifs/sifPreview";
import sifList from "views/pages/Sif/Sifs/sifList";

// PERFIL INDUSTRY
import SifListIndustry from "views/pages/Industry/Sifs/sifList";
import SiftPreviewIndustry from "views/pages/Industry/Sifs/sifPreview";
import UserIndustry from "views/pages/Industry/User/User";
import UserPreviewIndustry from "views/pages/Industry/User/userPreview";
import IndustryList from "views/pages/Industry/Industry/Industry";
import IndustryPreview from "views/pages/Industry/Industry/IndustryPreview";
import QuestionnairesIndustry from "views/pages/Industry/Questionnaires/Questionnaires";
import QuestionnaireListinngIndustry from "views/pages/Industry/Questionnaires/AnsweredQuestionnaire/questionnaireListing";
import QuestionnaireAnsweredIndustry from "views/pages/Industry/Questionnaires/AnsweredQuestionnaire/AnswerList";

import TipoCurso from "views/pages/Admin/Cadastros/TipoCurso.jsx";
import Profile from "views/pages/Admin/Profile.jsx";

import Answer from "views/pages/Sif/Questionnaires/Answer.jsx";

var routes = [
  {
    path: "/Login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginEsqueciSenha",
    name: "Esqueci minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginEsqueciSenha,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginAlteraSenha",
    name: "Alterar minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginAlteraSenha,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/LoginPrimeiroAcesso",
    name: "Alterar minha senha",
    icon: "ni ni-key-25 text-info",
    component: LoginPrimeiroAcesso,
    layout: "/auth",
    visible: false,
  },

  // PROFILE ABIEC
  {
    path: "/index",
    name: "Home",
    icon: "fas fa-home",
    component: Index,
    layout: "/admin",
    visible: true,
    breadcumb: "Home Teste",
  },
  {
    path: "/User",
    name: "Usuários",
    icon: "fas fa-user-plus",
    component: User,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/User/UserRegistration",
    name: "Cadastro de Usuários",
    component: UserRegistration,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/sifs",
    name: "SIFs",
    icon: "fas fa-map-marker-alt",
    component: Sif,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Sifs/SifRegistration",
    name: "Cadastro de SIF",
    component: SifRegistration,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Industry",
    name: "Indústrias",
    icon: "fas fa-industry",
    component: Industry,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Industry/IndustryRegistration",
    name: "Cadastro de Indústrias",
    component: IndustryRegistration,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/Questionnaires",
    name: "Questionários",
    icon: "fas fa-list-alt",
    component: Questionnaires,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/Questionnaires/RoundRegistration",
    name: "Cadastro de Rodadas",
    component: RoundRegistration,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/SelectPeriod",
    name: "Seleção de Período",
    component: SelectPeriod,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/QuestionnaireType",
    name: "Tipo de Questionário",
    component: QuestionnaireType,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/AnswerOptions",
    name: "Respostas do Questionário",
    component: AnswerOptions,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/AnsweredQuestionnaire/QuestionnaireListing",
    name: "Questionários Respondidos",
    component: QuestionnaireListing,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/AnsweredQuestionnaire/sifsNotAnswer",
    name: "Sifs que não responderam",
    component: SifsNotAnswer,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/Questionnaires/AnsweredQuestionnaire/AnswerList",
    name: "Aprovação e Devolução de Respostas dos Questionários",
    component: AnswerList,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/Cadastros/tipoCurso",
    name: "TipoCurso",
    component: TipoCurso,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    visible: false,
  },

  // PROFILE SIF

  {
    path: "/index",
    name: "Home",
    icon: "fas fa-home",
    component: IndexSif,
    layout: "/sif",
    visible: true,
    breadcumb: "Home Teste",
  },
  {
    path: "/Sifs/SifList",
    name: "SIFs",
    component: sifList,
    icon: "fas fa-map-marker-alt",
    layout: "/sif",
    visible: true,
  },
  {
    path: "/Sifs/SifPreview",
    name: "Visualização do SIF",
    component: sifPreview,
    layout: "/sif",
    visible: false,
  },
  {
    path: "/Questionnaires/SifSelection",
    name: "Questionário",
    icon: "fas fa-list-alt",
    component: SifSelection,
    layout: "/sif",
    visible: true,
  },

  {
    path: "/Questionnaires/AnsweredQuestionnaire/SifQuestionnaireListing",
    name: "Questionários Respondidos por Sif",
    component: SifQuestionnaireListing,
    layout: "/sif",
    visible: false,
  },

  {
    path: "/Questionnaires/SelectionQuestionnaireType",
    name: "Seleção de Tipos de Questionários",
    component: SelectionQuestionnaireType,
    layout: "/sif",
    visible: false,
  },

  {
    path: "/Questionnaires/AnsweredQuestionnaire/UpdateAnswere",
    name: "Alteração na Resposta",
    component: UpdateAnswere,
    layout: "/sif",
    visible: false,
  },

  {
    path: "/Questionnaires/AnsweredQuestionnaire/SifAnswerList",
    name: "Aprovação e Devolução de Respostas dos Questionários SIF",
    component: SifAnswerList,
    layout: "/sif",
    visible: false,
  },
  {
    path: "/Questionnaires/SifCurrentScore",
    name: "Score Atual",
    component: SifCurrentScore,
    layout: "/sif",
    visible: false,
  },
  {
    path: "/Questionnaires/Answer",
    name: "Questoes",
    // icon: "ni ni-key-25 text-info",
    component: Answer,
    layout: "/sif",
    visible: false,
  },

  // PROFILE INDUSTRY
  {
    path: "/index",
    name: "Home",
    component: indexIndustry,
    icon: "fas fa-home",
    layout: "/industry",
    visible: true,
  },
  {
    path: "/Sifs/SifList",
    name: "Sifs",
    component: SifListIndustry,
    icon: "fas fa-map-marker-alt",
    layout: "/industry",
    visible: true,
  },
  {
    path: "/Sifs/SifPreview",
    name: "Preview",
    component: SiftPreviewIndustry,
    icon: "fas fa-map-marker-alt",
    layout: "/industry",
    visible: false,
  },
  {
    path: "/User",
    name: "Usuário",
    icon: "fas fa-user-plus",
    component: UserIndustry,
    layout: "/industry",
    visible: true,
  },
  {
    path: "/User/userPreview",
    name: "user preview",
    component: UserPreviewIndustry,
    icon: "fas fa-list-alt",
    layout: "/industry",
    visible: false,
  },
  {
    path: "/Industry/IndustryList",
    name: "Indústrias",
    component: IndustryList,
    icon: "fas fa-industry",
    layout: "/industry",
    visible: true,
  },
  {
    path: "/Industry/IndustryPreview",
    name: "Indústrias",
    component: IndustryPreview,
    icon: "fas fa-industry",
    layout: "/industry",
    visible: false,
  },
  {
    path: "/Questionnaires",
    name: "Questionários",
    component: QuestionnairesIndustry,
    icon: "fas fa-list-alt",
    layout: "/industry",
    visible: true,
  },
  {
    path: "/Questionnaires/answeredQuestionnaire/questionnaireListing",
    name: "Questionários",
    component: QuestionnaireListinngIndustry,
    icon: "fas fa-list-alt",
    layout: "/industry",
    visible: false,
  },
  {
    path: "/Questionnaires/answeredQuestionnaire/AnswerList",
    name: "Questionários",
    component: QuestionnaireAnsweredIndustry,
    icon: "fas fa-list-alt",
    layout: "/industry",
    visible: false,
  },

  // PERFIL MAPA
  {
    path: "/index",
    name: "Home",
    icon: "fas fa-home",
    component: indexMap,
    layout: "/mapa",
    visible: true,
  },

  //ERRORS
  {
    path: "*",
    name: "NotFoundAdmin",
    icon: "fas fa-times",
    component: NotFoundAdmin,
    layout: "/admin",
    visible: false,
  },

  {
    path: "*",
    name: "NotFoundAuth",
    icon: "fas fa-times",
    component: NotFoundAuth,
    layout: "/auth",
    visible: false,
  },
  {
    path: "*",
    name: "NotFoundSif",
    icon: "fas fa-times",
    component: NotFoundSif,
    layout: "/sif",
    visible: false,
  },
];
export default routes;
