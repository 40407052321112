import React from "react";
import "assets/scss/components.scss";

import { Link } from "react-router-dom";

import template from "template.js";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList.jsx";
import ButtonGroup from "components/Utils/ButtonGroup";
import InputSearch from "components/Utils/InputSearch";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class SifRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderDataList: true,
      questionnaireNofFound: false,
      renderLoading: false,
      deletedSugestionInput: false,

      answerQuestionnaireResponse: null,
      sipoaResponse: null,
      stateResponse: null,

      sifId: -1,
      industryId: -1,
      countyId: -1,
      currentPage: 0,

      formData: {},
      industrySugested: {},
      countySugested: {},
      sugestedCountyList: {},

      errorMessage: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.loadSugestedIndustry = this.loadSugestedIndustry.bind(this);
    this.onTabHandler = this.onTabHandler.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    let sipoaSelectItems = [];
    let stateSelectItems = [];

    let countySugestedList = [];

    function dateFormat(date) {
      let day = "";
      let month = "";
      let year = "";
      let dateFormated = "";

      day = new Date(date).getDate().toString();
      month = (new Date(date).getMonth() + 1).toString();
      year = new Date(date).getFullYear().toString();

      dateFormated =
        (day.toString().length === 1 ? "0" + day : day) +
        "/" +
        (month.toString().length === 1 ? "0" + month : month) +
        "/" +
        (year.toString().length === 1 ? "0" + year : year);

      return dateFormated;
    }

    if (
      this.state.answerQuestionnaireResponse &&
      this.state.answerQuestionnaireResponse.list &&
      this.state.answerQuestionnaireResponse.total
    ) {
      tableCollumns = [
        { name: "N°. SIF", size: 30, property: "sifNumber" },
        { name: "Razão Social", size: 50, property: "sifCorporateName" },
        { name: "Tipo", size: 110, property: "questionnaireTypeName" },
        { name: "Data", size: 50, property: "date" },
        { name: "Status", size: 40, property: "roundStatus" },
      ];

      tableData = this.state.answerQuestionnaireResponse.list;
      tableData = tableData.map((item) => {
        let statusColor = "";
        let questionnaireTypeNameTreated = "";

        questionnaireTypeNameTreated =
          "Q" + item.questionnaireTypeId + " - " + item.questionnaireTypeName;

        switch (item.answerStatusId) {
          case 1:
            statusColor = "q_text-blue";
            break;

          case 2:
            statusColor = "q_text-green";
            break;

          case 3:
            statusColor = "q_text-red";
            break;

          default:
            statusColor = "text-dark";
            break;
        }

        let answerDate = dateFormat(item.answerDate);

        return {
          ...item,
          ...{
            questionnaireTypeName: questionnaireTypeNameTreated,
            date: answerDate,
            roundStatus: (
              <span className={statusColor}>{item.answerStatusName}</span>
            ),
          },
        };
      });

      total = this.state.answerQuestionnaireResponse.total;
    }

    if (this.state.sugestedCountyList) {
      if (this.state.sugestedCountyList.length > 0) {
        try {
          this.state.sugestedCountyList.forEach((item) => {
            countySugestedList.push({
              id: item.id,
              name: item.name,
            });
          });
        } catch (ex) {
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.sipoaResponse) {
      if (this.state.sipoaResponse.length > 0) {
        try {
          sipoaSelectItems = this.state.sipoaResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["sipoaId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          sipoaSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.stateResponse) {
      if (this.state.stateResponse.length > 0) {
        try {
          stateSelectItems = this.state.stateResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["stateId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          stateSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let pageChangeHandler = (index) => {
      this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">{this.stringTable.sif.titleNewSif}</h1>
              <small className="subtitle">
                {this.stringTable.sif.newSubtitleNewSif}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="mt-6 ml-5">
            <Col lg="2" className="mr-tabs">
              <div
                className="cursor-pointer"
                id="registrationInformation"
                name="registrationInformation"
                onClick={() => {
                  this.onTabHandler("registrationInformation");
                }}
              >
                <h2
                  className={
                    "ml--3 ws-nowrap" +
                    (this.state.formData["registrationInformation"]
                      ? " tabsAfterClick"
                      : " tabsBeforeClick")
                  }
                >
                  {this.stringTable.sif.form.tabs.registrationInformation}
                </h2>
                {!(this.state.formData["registrationInformation"] === true) || (
                  <img
                    className="rectangle-red"
                    alt="..."
                    src={require("assets/img/brand/rectangle-red.png")}
                  />
                )}
              </div>
            </Col>

            <Col lg="2" className="ml-4">
              <div
                className="cursor-pointer"
                id="questionnairesAnswered"
                name="questionnairesAnswered"
                onClick={() => {
                  let sifId = this.state.sifId;

                  if (sifId) {
                    if (sifId > 0) this.onTabHandler("questionnairesAnswered");
                  }
                }}
              >
                <h2
                  className={
                    "ml--3 ws-nowrap" +
                    (this.state.formData["questionnairesAnswered"]
                      ? " tabsAfterClick"
                      : " tabsBeforeClick")
                  }
                >
                  {this.stringTable.sif.form.tabs.questionnairesAnswered}
                </h2>
                {!(this.state.formData["questionnairesAnswered"] === true) || (
                  <img
                    className="rectangle-red"
                    alt="..."
                    src={require("assets/img/brand/rectangle-red.png")}
                  />
                )}
              </div>
            </Col>
          </Row>

          {!(this.state.formData["registrationInformation"] === true) || (
            <>
              <Form className="pt-6 pl-5">
                <Row lg="12">
                  <Col lg="9" className="">
                    <InputSearch
                      title="Indústria"
                      fieldId="industryName"
                      pageHandler="sifs"
                      value={this.state.industrySugested}
                      changeHandler={(id) => {
                        this.loadSugestedIndustry(id);
                      }}
                      deleteHandler={() => {
                        this.setState({ deletedSugestionInput: true });
                      }}
                      sugestedList={this.state.sugestedIndustryList}
                      industryId={this.state.industryId}
                      disabled={true}
                      hasPhoto={false}
                      isMandatory={true}
                    />
                  </Col>

                  <Col lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.isHeadOffice}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <ButtonGroup
                      id="isHeadOffice"
                      option1="Não"
                      option2="Sim"
                      key="isHeadOffice"
                      activeIndex={this.state.formData["isHeadOffice"]}
                      dflex="Sim"
                    />
                  </Col>
                </Row>

                <Row lg="12" className="pt-4">
                  <Col lg="9">
                    <div className="form-group">
                      <label
                        htmlFor="corporateName"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.corporateName}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={true}
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="corporateName"
                        type="text"
                        id="corporateName"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested
                                .headOfficeCorporateName
                            : this.state.formData["corporateName"]
                        }
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="cnpj"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.cnpj}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={true}
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="cnpj"
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested.cnpj
                            : this.state.formData["cnpj"]
                        }
                      />
                    </div>
                  </Col>
                </Row>

                {/* <Row lg="12" className="pt-4">
                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="corporateName"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.email}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={true}
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="corporateName"
                        type="text"
                        id="corporateName"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested
                                .headOfficeCorporateName
                            : this.state.formData["corporateName"]
                        }
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="cnpj"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.function}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={true}
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="cnpj"
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested.cnpj
                            : this.state.formData["cnpj"]
                        }
                      />
                    </div>
                  </Col>
                </Row> */}

                <Row lg="!2" className="pt-4">
                  <Col md="3" sm="3" lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.sipoa}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <select
                      name="sipoaId"
                      id="sipoaId"
                      ref="sipoaId"
                      className={
                        "input-style form-control showFilterActive" +
                        (sipoaSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                      disabled={true}
                    >
                      <option
                        value=""
                        selected={this.state.formData["sipoaId"] ? false : true}
                      >
                        Selecione
                      </option>
                      {sipoaSelectItems.length === 0 || sipoaSelectItems}
                    </select>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="sifNumber"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.sifNumber}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="sifNumber"
                        type="text"
                        disabled={true}
                        id="sifNumber"
                        name="sifNumber"
                        value={
                          this.state.formData["sifNumber"]
                            ? this.state.formData["sifNumber"]
                            : ""
                        }
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="zipCode"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.cep}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="zipCode"
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        disabled={true}
                        value={
                          this.state.formData["zipCode"]
                            ? this.state.formData["zipCode"]
                            : ""
                        }
                      />
                    </div>
                  </Col>

                  <Col md="3" sm="3" lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.state}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <select
                      name="stateId"
                      id="stateId"
                      ref="stateId"
                      className={
                        "input-style form-control showFilterActive" +
                        (stateSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                      disabled={true}
                    >
                      <option
                        value=""
                        selected={this.state.formData["stateId"] ? false : true}
                      >
                        Selecione
                      </option>
                      {stateSelectItems.length === 0 || stateSelectItems}
                    </select>
                  </Col>
                </Row>

                <Row lg="!2" className="pt-4">
                  <Col lg="6" className="">
                    <InputSearch
                      title={this.stringTable.sif.form.county}
                      fieldId="count"
                      pageHandler="sifs"
                      sugestedList={countySugestedList}
                      value={(() => {
                        if (this.state.formData && this.state.formData.cityId) {
                          let treatedId = parseInt(this.state.formData.cityId);
                          if (!isNaN(treatedId)) {
                            let countySearch = countySugestedList.filter(
                              (item) => item.id === treatedId
                            );
                            if (countySearch.length === 1)
                              return countySearch[0];
                          }
                        }
                        return null;
                      })()}
                      disabled={true}
                      hasPhoto={false}
                      isMandatory={true}
                    />
                  </Col>

                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="neighborhood"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.neighborhood}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="neighborhood"
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        disabled={true}
                        value={
                          this.state.formData["neighborhood"]
                            ? this.state.formData["neighborhood"]
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row lg="!2" className="pt-4">
                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="address"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.publicPlace}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="address"
                        type="text"
                        id="address"
                        disabled={true}
                        name="address"
                        value={
                          this.state.formData["address"]
                            ? this.state.formData["address"]
                            : ""
                        }
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="number"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.numberHome}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="number"
                        type="text"
                        id="number"
                        name="number"
                        disabled={true}
                        value={
                          this.state.formData["number"]
                            ? this.state.formData["number"]
                            : ""
                        }
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="complement"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.complement}
                      </label>
                      <input
                        className="form-control input-style"
                        ref="complement"
                        type="text"
                        id="complement"
                        disabled={true}
                        name="complement"
                        value={
                          this.state.formData["complement"]
                            ? this.state.formData["complement"]
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Form>

              <div className="container-fluid mb-4 mt-6">
                <Row className="justify-content-center">
                  <Link to={"/industry/Sifs/SifList"} className="mr-5">
                    <button type="button" className="lightButtom">
                      {this.stringTable.standard.backButton}
                    </button>
                  </Link>
                </Row>
              </div>
            </>
          )}

          {!(this.state.formData["questionnairesAnswered"] === true) || (
            <>
              <Row className="pt-4 pl-5">
                <Col lg="12" className="questionnaire">
                  {this.state.renderDataList ? (
                    <>
                      <Row lg="12" className="justify-content-center mt-7 mb-6">
                        <div className="">
                          <h1 className="titleText">
                            {
                              this.stringTable.questionnaire
                                .answeredQuestionnaire.titleList
                            }
                          </h1>
                        </div>
                      </Row>
                      <DataList
                        loading={this.state.renderLoading}
                        tableCollumns={tableCollumns}
                        tableData={tableData}
                        paging={true}
                        currentPage={this.state.currentPage}
                        pageSize={constants.Pagination.DefaultPageSize}
                        total={total}
                        pageChangeHandler={(index) => {
                          pageChangeHandler(index);
                        }}
                        exportHandler={exportHandler}
                        exporting={true}
                        exportProcessing={this.state.exporting}
                      />
                    </>
                  ) : (
                    <>
                      {this.state.questionnaireNofFound || (
                        <h1> Carregando...</h1>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {this.state.questionnaireNofFound ? (
                <section className="container mt-4">
                  <Row>
                    <Col col="md-12">
                      <div
                        id="msgExclusion"
                        className={
                          " " +
                          (this.state.isVisibleDeleteMsg
                            ? "msgVisibleExclusion"
                            : "")
                        }
                      >
                        <DialogConfirm
                          open={this.state.questionnaireNofFound}
                          title={" "}
                          text={
                            this.stringTable.sif.form.tabs.questionnaireNofFound
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <></>
              )}
            </>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ sifId: id });
  }

  componentDidMount() {
    this.loadSelects();
    this.getSifMethod();
  }

  loadSelects() {
    this.loadIndustry();
    this.loadSipoa();
    this.loadState();
  }

  onTabHandler(name) {
    if (name === "registrationInformation") {
      this.setState({
        formData: Object.assign(
          this.state.formData,
          { ["questionnairesAnswered"]: false },
          { ["registrationInformation"]: true }
        ),
      });
    }

    if (name === "questionnairesAnswered") {
      this.setState({
        formData: Object.assign(
          this.state.formData,
          { ["registrationInformation"]: false },
          { ["questionnairesAnswered"]: true }
        ),
      });
    }
  }

  getSifMethod() {
    let formData = this.state.formData;

    formData.registrationInformation = true;

    this.setState({ formData: formData });

    if (this.state.sifId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Sif.Get +
        "/" +
        this.state.sifId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let formData = this.state.formData;

          ret.cnpj = formater.template(ret.cnpj, "00.000.000/0000-00");
          ret.zipCode = formater.template(ret.zipCode, "00000-000");

          if (ret.isHeadOffice) ret.isHeadOffice = "1";
          else ret.isHeadOffice = "0";

          if (ret.stateId) this.loadCounty(ret.stateId);

          if (ret.industryTradeName) {
            let industrySugested = {};

            industrySugested.name = ret.industryTradeName;
            industrySugested.headOfficeCorporateName = ret.corporateName;
            industrySugested.cnpj = formater.template(
              ret.cnpj,
              "00.000.000/0000-00"
            );

            this.setState({
              industrySugested: industrySugested,
              industryId: ret.industryId,
            });
          }

          if (ret.cityId) {
            let citySugested = {};

            citySugested.name = ret.cityName;

            formData.cityId = ret.cityId;
            this.setState({
              countySugested: citySugested,
              countyId: ret.cityId,
            });
          }

          formData = { ...this.state.formData, ...ret };

          this.setState({ formData: formData });

          this.loadDataList(0, true);
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  loadSipoa() {
    this.setState({ sipoaResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sipoa.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sipoaResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadState() {
    this.setState({ stateResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.State.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ stateResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadCounty(stateId) {
    this.setState({ countySugestedList: null });

    let dataAPI = {};

    dataAPI.stateIds = [];

    if (stateId) dataAPI.stateIds.push(parseInt(stateId));

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.City.Find;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sugestedCountyList: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ renderLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ renderLoading: false });

    let sifId = this.state.sifId;

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      sifId: sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Answer.AnsweredPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            renderDataList: false,
            roundInEdition: true,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            renderDataList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        if (result.response.list.length <= 0) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          renderLoading: false,
          renderDataList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          renderDataList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  loadSugestedIndustry(industryId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let ret = result.response;

        ret.cnpj = formater.template(ret.cnpj, "00.000.000/0000-00");

        this.setState({
          industrySugested: ret,
          industryId: ret.id,
          deletedSugestionInput: false,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadIndustrySelected() {
    let industryId = this.state.industryId;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let industrySugested = this.state.industrySugested;

        industrySugested.name = ret.tradeName;
        industrySugested.headOfficeCorporateName = ret.headOfficeCorporateName;
        industrySugested.cnpj = formater.template(
          ret.cnpj,
          "00.000.000/0000-00"
        );

        this.setState({ industrySugested: industrySugested });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadIndustry() {
    let keyword = {
      keyword: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Industry.Find;
    let data = keyword;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let industryList = result.response.map(function (item, indice) {
          return {
            id: item.id,
            name: item.tradeName,
          };
        });

        this.setState({ sugestedIndustryList: industryList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default SifRegistration;
