import React from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import template from "template.js";
import constants from "variables/constants";

import Loading from "components/Utils/Loading.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";

class LoginEsqueciSenha extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      setSuccessConfirm: false,
      openConfirm: false,
      errorMessageEmail: "",
      loadingEmail: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.submitEmail = this.submitEmail.bind(this);
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img alt="Logo" src={this.stringTable.standard.logo} />
            </div>
            <Form role="form" onSubmit={this.submitEmail}>
              <Row className="pb-4">
                <Col xs="12" className="text-dark text-center">
                  <small className="ft-16">
                    {" "}
                    {
                      this.stringTable.forgotPassword.insertYourEmail1
                    } <br /> {this.stringTable.forgotPassword.insertYourEmail2}{" "}
                  </small>
                </Col>
              </Row>
              <FormGroup className="mb-1">
                <InputGroup className="input-group-alternative roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-dark text-input"
                    placeholder={this.stringTable.forgotPassword.email}
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </InputGroup>
                <br />
                {this.state.setSuccessConfirm || (
                  <DialogConfirm
                    open={this.state.openConfirm}
                    title={" "}
                    text={this.state.errorMessageEmail}
                    handleClose={() => {
                      this.setState({ openConfirm: false });
                    }}
                  />
                )}
                {!this.state.setSuccessConfirm || (
                  <DialogConfirm
                    open={this.state.openConfirm}
                    title={" "}
                    text={this.state.errorMessageEmail}
                    success={true}
                    handleClose={() => {
                      this.setState({ openConfirm: false });
                    }}
                  />
                )}
              </FormGroup>

              <div className="text-center">
                <Button className="darkButtom" type="submit" value="Post">
                  {this.state.loadingEmail ? (
                    <Loading load={true} />
                  ) : (
                    this.stringTable.forgotPassword.buttomSend
                  )}
                </Button>
              </div>
              <Row className="mt-3">
                <Col xs="12" className="text-white text-center">
                  <Link to="/auth/Login">
                    <h4 className="linkText">
                      {this.stringTable.forgotPassword.linkReturn}
                    </h4>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Container>
        </Col>
      </>
    );
  }

  submitEmail(e) {
    e.preventDefault();

    this.setState({
      setSuccessConfirm: false,
      openConfirm: false,
      errorMessageEmail: "",
    });
    const { email } = this.state;
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Auth.ForgotPass;

    if (email) {
      this.setState({ loadingEmail: true });

      axios
        .post(url, {
          email,
        })
        .then((response) => {
          if (
            !response ||
            !response.data ||
            !response.data.errors ||
            response.data.success == null
          ) {
            this.setState({
              openConfirm: true,
              setSuccessConfirm: false,
              errorMessageEmail: "Resposta fora do padrão esperado",
              loadingEmail: false,
            });
            return;
          }

          if (!response.data.success) {
            this.setState({
              openConfirm: true,
              setSuccessConfirm: false,
              errorMessageEmail: response.data.errors.join(".<br/>"),
              loadingEmail: false,
            });
            console.log(response.data.errors);
            return;
          }

          this.setState({
            openConfirm: true,
            setSuccessConfirm: true,
            errorMessageEmail: "E-mail enviado",
            loadingEmail: false,
          });
        })

        .catch((error) => {
          this.setState({
            renderPersonList: false,
            errorMessage: "Erro ao tentar consultar dados",
            loadingEmail: false,
          });
        });
    } else {
      this.setState({
        openConfirm: true,
        setSuccessConfirm: false,
        errorMessageEmail: "Digite um e-mail válido",
        loadingEmail: false,
      });
    }
  }
}

export default LoginEsqueciSenha;
