import React from "react";

import template from "template.js";
import { formater } from "libs/formater";
import constants from "variables/constants";

import { Row, Col } from "reactstrap";

class EvaluationAnswered extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonReturn: "",
      collapseOpen: false,
    };
  }

  render() {
    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    const attachedFilesUrls = this.props.attachedFileUrl
      ? this.props.attachedFileUrl
      : [];
    const value = this.props.value ? this.props.value : {};
    const approved = this.props.approved;
    const edit = this.props.edit == null ? false : this.props.edit;
    const valid = this.props.valid == null ? false : this.props.valid;
    const isArtificialIntelligence =
      this.props.isArtificialIntelligence == null
        ? false
        : this.props.isArtificialIntelligence;

    const onEditHandler = this.props.onEditHandler
      ? this.props.onEditHandler
      : () => {};
    const onAproveHandler = this.props.onAproveHandler
      ? this.props.onAproveHandler
      : () => {};
    const onSaveHandler = this.props.onSaveHandler
      ? this.props.onSaveHandler
      : () => {};
    const onCancelHandler = this.props.onCancelHandler
      ? this.props.onCancelHandler
      : () => {};
    const onUndoHandler = this.props.onUndoHandler
      ? this.props.onUndoHandler
      : () => {};
    const onChange = this.props.onChange ? this.props.onChange : () => {};

    const hasValidation = this.props.hasValidation
      ? this.props.hasValidation
      : false;

    const isReadOnly = this.props.isReadOnly ? this.props.isReadOnly : false;

    let attachedFiles = [];
    let certificateJson = [];
    let questionAnswers = [];

    let toggleCollapse = () => {
      this.setState({ collapseOpen: !this.state.collapseOpen });
    };

    if (value) {
      if (value.questionAnswers.length > 0) {
        try {
          questionAnswers = value.questionAnswers.map((item, index) => {
            return (
              <div key={index}>
                <div className="pt-2">
                  <h3 className="text-dark font-weight-normal">{item}</h3>
                </div>
              </div>
            );
          });
        } catch (ex) {
          questionAnswers = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (attachedFilesUrls) {
      if (attachedFilesUrls.length > 0) {
        try {
          attachedFiles = attachedFilesUrls.map((item, index) => {
            let fileNumber = index + 1;

            return (
              <div key={index}>
                <a href={item.fileUrl} target="_blank" className="mr-2">
                  <button type="button" className="btn btn-sm gray">
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .answerList.evaluationAnswered.viewFile
                    }{" "}
                    {fileNumber}
                  </button>
                </a>
              </div>
            );
          });
        } catch (ex) {
          attachedFiles = [];
          console.error("Falha ao interpretar resposta", ex);
        }

        try {
          attachedFilesUrls.map((item, index) => {
            let templateName = "";
            let templateResponse = {};

            if (item.isTemplateRecognized) {
              templateResponse = JSON.parse(item.templateResponse);

              templateName = templateResponse.template;
            }

            if (item.isContentRecognized) {
              certificateJson.push(
                JSON.parse(item.contentResponse).map((item2, index2) => {
                  return (
                    <div key={index2}>
                      {!this.state.collapseOpen || (
                        <>
                          <div>
                            {index2 === 0 ? (
                              <label className="pt-4 pl-6 font-weight-bold">
                                {templateName}
                              </label>
                            ) : (
                              ""
                            )}

                            <Row lg="12" className=" pl-6">
                              <Col lg="12">
                                <div className="dataList">
                                  <div className="dataRow">
                                    <div className="item">
                                      <span className="pr-2 font-weight-600">
                                        {item2.field}:
                                      </span>
                                      <span className="">{item2.value}</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })
              );
            }
          });
        } catch (ex) {
          certificateJson = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let onInputChange = (event) => {
      const target = event.target;
      const id = target.id;
      let value = "";

      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        switch (id) {
          case "reason":
            value = formater.maxLenght(target.value, 200);
            break;

          default:
            value = target.value;
        }
      }

      this.setState({
        reasonReturn: value,
      });

      onChange(value);
    };

    let onUndoReason = () => {
      this.setState({ reasonReturn: "" });

      onUndoHandler();
    };

    let onSaveReason = () => {
      let reason = this.state.reasonReturn;

      onSaveHandler(reason);
    };

    let onCancelReason = () => {
      this.setState({ reasonReturn: "" });

      onCancelHandler();
    };

    return (
      <>
        <div className="questionnaire">
          {!value.stepName || (
            <Row lg="12" className="pt-2 pl-6">
              <Col lg="12" xl="12">
                <div className="">
                  <h3 className="text-dark">
                    {value.stepOrder}. {value.stepName}
                  </h3>
                </div>
              </Col>
            </Row>
          )}
          <Row lg="12" className="pt-2 pl-6">
            <Col lg="7" xl="7">
              <div className="pt-2">
                <span className="q_text-question">
                  {value.stepOrder}.{value.questionOrder}{" "}
                  {value.questionWording}
                </span>
              </div>
            </Col>

            <>
              {approved !== true &&
              !value.wasAnalyzed &&
              hasValidation === true ? (
                <>
                  <Col lg="5" xl="5">
                    <div className=" q_wrapper-attachment float-right mr-4 display-flex flex-direction-column align-items-center">
                      {isArtificialIntelligence || (
                        <div>
                          {isReadOnly === false && (
                            <>
                              <button
                                type="button"
                                className="btn btn-sm lightGreen"
                                onClick={onAproveHandler}
                              >
                                {
                                  this.stringTable.questionnaire
                                    .answeredQuestionnaire.answerList
                                    .evaluationAnswered.approve
                                }
                              </button>

                              <button
                                type="button"
                                className="btn btn-sm red"
                                onClick={onEditHandler}
                              >
                                {
                                  this.stringTable.questionnaire
                                    .answeredQuestionnaire.answerList
                                    .evaluationAnswered.return
                                }
                              </button>
                            </>
                          )}
                        </div>
                      )}

                      <div className="attachment">
                        {attachedFiles.length === 0 || attachedFiles}
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <> </>
              )}
            </>

            {!(hasValidation === false) || (
              <>
                <Col lg="5" xl="5">
                  <div className="float-right mr-4">
                    <span className="q_text-green mr-3 font-weight-600">
                      {
                        this.stringTable.questionnaire.answeredQuestionnaire
                          .answerList.evaluationAnswered.approved
                      }
                    </span>

                    {!edit || (
                      <button
                        type="button"
                        className="btn btn-sm gray"
                        onClick={onUndoReason}
                      >
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.undo
                        }
                      </button>
                    )}
                  </div>
                </Col>
              </>
            )}

            {!(approved === true && valid && hasValidation === true) || (
              <>
                <Col lg="5" xl="5">
                  <div className="float-right mr-4">
                    <span className="q_text-green mr-3 font-weight-600">
                      {
                        this.stringTable.questionnaire.answeredQuestionnaire
                          .answerList.evaluationAnswered.approved
                      }
                    </span>

                    {!edit || (
                      <button
                        type="button"
                        className="btn btn-sm gray"
                        onClick={onUndoReason}
                      >
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.undo
                        }
                      </button>
                    )}

                    <div className="mt-3 d-flex">
                      {attachedFiles.length === 0 || attachedFiles}
                    </div>
                  </div>
                </Col>
              </>
            )}

            {approved !== true &&
            valid &&
            value.wasAnalyzed &&
            hasValidation === true ? (
              <>
                <Col lg="5" xl="5">
                  <div className="float-right mr-4">
                    <span className="q_text-red mr-3 font-weight-600">
                      {
                        this.stringTable.questionnaire.answeredQuestionnaire
                          .answerList.evaluationAnswered.returned
                      }
                    </span>

                    <button
                      type="button"
                      className="btn btn-sm gray"
                      onClick={onUndoReason}
                    >
                      {
                        this.stringTable.questionnaire.answeredQuestionnaire
                          .answerList.evaluationAnswered.undo
                      }
                    </button>
                    <div className="mt-3">
                      {attachedFiles.length === 0 || attachedFiles}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <> </>
            )}
          </Row>

          {questionAnswers.length === 0 || (
            <>
              <Row lg="12" className="pt-2 pl-6">
                <Col lg="7" xl="7">
                  {questionAnswers}
                </Col>

                {!isArtificialIntelligence || (
                  <>
                    <Col lg="5" xl="5">
                      <div className="float-right mr-4 mt-2">
                        <span className="q_text-green mr-3 font-weight-600">
                          {
                            this.stringTable.questionnaire.answeredQuestionnaire
                              .answerList.evaluationAnswered
                              .analyzedByArtificialIntelligence
                          }
                        </span>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}

          {certificateJson.length === 0 || (
            <>
              <Row lg="12" className="pt-2 pl-6">
                <Col lg="12" xl="12">
                  <div
                    className="linkText float-right mr-4"
                    onClick={toggleCollapse}
                  >
                    {!this.state.collapseOpen ? (
                      <>
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.viewJsonFile
                        }
                        <i
                          style={{ transform: "rotate(180deg)" }}
                          className="ml-2 fas fa-arrow-up"
                        ></i>
                      </>
                    ) : (
                      <>
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.hideJsonFile
                        }
                        <i className="ml-2 fas fa-arrow-up"></i>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {certificateJson}
            </>
          )}

          <Row lg="12" className="pt-2 pl-6">
            <Col lg="7" xl="7">
              <div className="pt-2">
                <h3 className="q_text-red font-weight-600">
                  {value.questionPoint}{" "}
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.evaluationAnswered.points
                  }{" "}
                  {/* <div className="mb-3"> */}
                  {value.questionScoreTypes.map((item, index) => {
                    return (
                      <span
                        className={"ml-2"}
                        style={{
                          fontWeight: "bold",
                          color: item.scoreTypeId === 1 ? "#5dcaaa" : "#4cb6f2",
                        }}
                      >
                        ({item.scoreTypeName})
                      </span>
                    );
                  })}
                  {/* </div> */}
                </h3>
              </div>
            </Col>
          </Row>

          {value.returnReason ? (
            value.returnReason.backReason ? (
              <Row lg="12" className="pt-2 pl-6">
                <Col lg="7" xl="7">
                  <div className="pt-2">
                    <div>
                      <span className="text-dark mr-3 font-weight-600">
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.abiecJustification
                        }
                      </span>
                    </div>
                    <div className="mt-2">
                      <span className="q_text-question">
                        {value.returnReason.insertedDate} -{" "}
                        {value.returnReason.backReason}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <> </>
            )
          ) : (
            <> </>
          )}

          {value.returnReason ? (
            value.returnReason.justification ? (
              <Row lg="12" className="pt-2 pl-6">
                <Col lg="7" xl="7">
                  <div className="pt-2">
                    <div>
                      <span className="text-dark mr-3 font-weight-600">
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .answerList.evaluationAnswered.sifJustification
                        }
                      </span>
                    </div>
                    <div className="mt-2">
                      <span className="q_text-question">
                        {value.returnReason.justification.insertedDate} -{" "}
                        {value.returnReason.justification.description}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <> </>
            )
          ) : (
            <> </>
          )}

          {(edit && value.tempIsApproved !== true) ||
          (value.tempIsApproved !== true && value.wasAnalyzed) ||
          (value.tempIsApproved !== true && !value.wasAnalyzed && !valid) ? (
            <>
              <Row lg="12" className="pt-3 pl-6">
                <Col lg="12" xl="12">
                  <h3 className="text-dark">
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .answerList.evaluationAnswered.return
                    }
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </h3>
                  <textarea
                    disabled={value.wasAnalyzed && valid ? true : false}
                    ref="reason"
                    type="text"
                    rows="5"
                    className={
                      "form-control text-dark" +
                      (!valid ? " q_error-style" : "")
                    }
                    value={this.state.reasonReturn}
                    id="reason"
                    name="reason"
                    placeholder="Escreva sua justificativa"
                    onChange={onInputChange}
                  />
                  {!valid && value.errorValidation ? (
                    <small className="q_text-red">
                      {value.errorValidation}
                    </small>
                  ) : (
                    <> </>
                  )}
                </Col>
              </Row>

              {approved !== true && !value.wasAnalyzed ? (
                <Row lg="12" className="pt-3 pl-6">
                  <Col lg="12" xl="12">
                    <button
                      type="button"
                      className="btn btn-sm white"
                      onClick={onCancelReason}
                    >
                      {this.stringTable.standard.cancel}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm lightGreen"
                      onClick={onSaveReason}
                    >
                      {this.stringTable.standard.save}
                    </button>
                  </Col>
                </Row>
              ) : (
                <> </>
              )}
            </>
          ) : (
            <> </>
          )}
        </div>
      </>
    );
  }
}

export default EvaluationAnswered;
