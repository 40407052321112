import React from "react";
import "assets/scss/pages/questionnaire.scss";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";
import { Container, Row, Col } from "reactstrap";

class SelectionQuestionnaireType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      openConfirm: false,

      sifResponse: null,
      situationResponse: null,
      roundResponse: null,
      questionnaireTypeResponse: null,
      timer: null,

      sifId: -1,

      formData: { agreeTerms: false },
      selectedOptionType: null,

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let sifNumber = "";
    let roundSequence = "";
    let typeSelectItems = [];

    if (this.state.questionnaireTypeResponse) {
      if (this.state.questionnaireTypeResponse.length > 0) {
        try {
          typeSelectItems = this.state.questionnaireTypeResponse.map(
            (item, index) => {
              return (
                <div key={index}>
                  <div className="custom-control custom-radio mb-3">
                    <input
                      type="radio"
                      className="custom-control-input input-radio mt-4"
                      key={item.id}
                      id={item.id}
                      name="questionnaireType"
                      value={item.id}
                      checked={
                        parseInt(this.state.selectedOptionType) === item.id
                      }
                      onChange={this.onInputChange}
                    />
                    <label
                      className="custom-control-label text-label-input"
                      htmlFor={item.id}
                    >
                      {item.name}
                    </label>
                  </div>
                </div>
              );
            }
          );
        } catch (ex) {
          typeSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.sifResponse) {
      if (this.state.sifResponse.sifNumber)
        sifNumber = this.state.sifResponse.sifNumber;
    }

    if (this.state.roundResponse) {
      if (this.state.roundResponse.sequence)
        roundSequence = "R0" + this.state.roundResponse.sequence;
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body questionnaire">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {
                  this.stringTable.questionnaireSif.sifSelection
                    .answeredQuestionnaire.titleSif
                }
                <>
                  {sifNumber === "" || " " + sifNumber + " - "}
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .title
                  }
                </>
              </h1>
              <small className="subtitle">
                {" "}
                {
                  this.stringTable.questionnaireSif.selectionQuestionnaireType
                    .subtitle
                }
              </small>
            </Col>
            <Col lg="6">
              <Link to="/sif/index">
                <button className="lightButtom float-lg-right mt-2">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5 ">
            <Col lg="11">
              <div className="pb-5">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .paragraph1
                  }
                </span>
              </div>
              <div className="pb-5">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .paragraph2
                  }
                </span>
              </div>
              <div className="pb-5">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .paragraph3
                  }
                </span>
              </div>
              <div className="">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .paragraph4
                  }
                </span>
              </div>
              <div className="">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .paragraphFinal
                  }
                </span>
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5">
            <Col lg="6">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input input-checkbox mt-4"
                  id="agreeTerms"
                  onChange={this.onInputChange}
                  checked={this.state.formData["agreeTerms"] ? true : false}
                />
                <label
                  className="custom-control-label text-label-input"
                  htmlFor="agreeTerms"
                >
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .agreeTerms
                  }
                </label>
              </div>
            </Col>
          </Row>

          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {
                  this.stringTable.questionnaireSif.selectionQuestionnaireType
                    .questionnaire
                }
                <>{roundSequence === "" || " " + roundSequence}</>
              </h1>
            </Col>
          </Row>

          <Row lg="12" className="pt-3 pl-5 mb-4">
            <Col lg="6">
              <div className="mb-4">
                <span className="q_text-dark">
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .questionType
                  }
                </span>
              </div>

              {typeSelectItems.length === 0 || typeSelectItems}
            </Col>
          </Row>

          <Row lg="12" className="pb-6 pl-6">
            <Col lg="12" className="">
              <div className="form-group">
                <button
                  onClick={() => {
                    window.location.href = "/sif/index";
                  }}
                  type="button"
                  className="lightButtom mr-4"
                >
                  {this.stringTable.standard.cancel}
                </button>
                <button
                  className="mainColorButtom"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {
                    this.stringTable.questionnaireSif.selectionQuestionnaireType
                      .startQuestionnaire
                  }
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ sifId: id });
  }

  componentDidMount() {
    this.loadSelects();
  }

  loadSelects() {
    this.loadSif();
    this.loadRound();
    this.loadQuestionnaireType();
  }

  handleSubmit() {
    let formData = this.state.formData;
    let selectedOptionType = this.state.selectedOptionType;

    let validations = {};
    validations.sucess = true;
    validations.errors = [];

    if (!selectedOptionType || selectedOptionType <= 0) {
      validations.sucess = false;
      validations.errors.push("Escolha um dos Processos de Produção.");
    }

    if (!formData.agreeTerms) {
      validations.sucess = false;
      validations.errors.push(
        "Você precisa Ler os Termos e aceitar participar do Programa."
      );
    }

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique as mensagens destacadas em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    } else {
      window.location.href =
        "/sif/Questionnaires/Answer/?id=" +
        this.state.selectedOptionType +
        "-" +
        this.state.sifId;
    }
  }

  loadSif() {
    this.setState({ sifResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      this.state.sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sifResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadRound() {
    this.setState({ roundResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.GetActive;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ roundResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadQuestionnaireType() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.getCurrentQuestionnaireTypes;

    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ questionnaireTypeResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({ formData: {} });
  }

  openModal(id) {
    this.setState({ roundId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    if (target.type === "radio") {
      this.setState({
        selectedOptionType: name,
      });
    } else {
      this.setState({
        formData: Object.assign(this.state.formData, { [name]: value }),
      });
    }
  }
}

export default SelectionQuestionnaireType;
