import React from "react";
import { Link } from "react-router-dom";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";

import { smoothApi } from "libs/smoothApi.js";

import DataList from "components/Utils/DataList.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class Sif extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sifLoading: true,
      renderSifList: true,
      sifNotFound: false,
      errorSifNotFound: false,
      errorMessage: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      currentPage: 0,
      formData: {},
      searchMode: false,
      sifResponse: null,
      exporting: false,
      timer: null,
      filterLoading: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    if (
      this.state.sifResponse &&
      this.state.sifResponse.list &&
      this.state.sifResponse.total
    ) {
      tableCollumns = [
        { name: "N° do SIF", size: 50, property: "sifNumber" },
        { name: "Indústria", size: 50, property: "industryTradeName" },
        { name: "Razão Social", size: 50, property: "corporateName" },
        { name: "", size: 30, property: "visualizarButton" },
      ];

      tableData = this.state.sifResponse.list;
      tableData = tableData.map((item) => {
        return {
          ...item,
          ...{
            visualizarButton: (
              <Link to={`/industry/Sifs/SifPreview/?id=${item.id}`}>
                <button className="btn btn-sm editButton">Visualizar</button>
              </Link>
            ),
          },
        };
      });

      total = this.state.sifResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">{this.stringTable.sif.titleSif}</h1>
              <small className="subtitle">
                {this.stringTable.sif.newSubtitleSif}
              </small>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="12">
                <div className="form-group">
                  <label
                    htmlFor="keyword"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.sif.filter.keyword}
                  </label>
                  <input
                    className="form-control input-style"
                    ref="keyword"
                    type="text"
                    id="keyword"
                    placeholder="Digite aqui"
                    value={
                      this.state.formData["keyword"]
                        ? this.state.formData["keyword"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            {!this.state.filterLoading || <h1> Carregando...</h1>}
            {this.state.sifNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openSifNotFound}
                        title={this.stringTable.sif.message.title1}
                        text={this.stringTable.sif.message.sifNotFound}
                        handleClose={() => {
                          this.setState({ openSifNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorSifNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorSifNotFound}
                        title={this.stringTable.sif.message.title1}
                        text={this.stringTable.sif.message.sifNotFound}
                        handleClose={() => {
                          this.setState({ openErrorSifNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            <Row lg="12" className="justify-content-center mt-7 mb-6">
              <div className="">
                <h1 className="titleText">{this.stringTable.sif.titleList}</h1>
              </div>
            </Row>
            <Row className="pt-4 pl-5">
              <Col lg="12">
                {this.state.renderSifList ? (
                  <DataList
                    loading={this.state.sifLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                ) : (
                  <>
                    {this.state.sifNotFound || this.state.errorSifNotFound || (
                      <h1> Carregando...</h1>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.loadDataList(0, true);
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ sifLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ sifLoading: false });

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.PagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          sifLoading: false,
          renderSifList: true,
          errorMessage: "",
          sifResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          renderSifList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "keyword" || name === "profileId") {
      let timer = this.state.timer;

      this.setState({ timer: clearTimeout(timer), filterLoading: true });

      this.setState({
        timer: setTimeout(() => {
          this.submitSearch(this.state.currentPage);
        }, 2000),
      });
    }
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage == undefined || currentPage == null) currentPage = 0;

    this.setState({
      sifLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;

    if (formData.profileId) formData.profileIds = [formData.profileId];
    else formData.profileIds = [];

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.PagedFind;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total == 0) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            sifNotFound: true,
            openSifNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Indústria não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          sifLoading: false,
          filterLoading: false,
          renderSifList: true,
          sifNotFound: false,
          openSifNotFound: false,
          errorSifNotFound: false,
          openErrorSifNotFound: false,
          errorMessage: "",
          sifResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          filterLoading: false,
          renderSifList: false,
          errorSifNotFound: true,
          openErrorSifNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Sif.Download +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Sif;
