import React from "react";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import Loading from "components/Utils/Loading.jsx";
import SifEvaluationAnswered from "components/Utils/SifEvaluationAnswered.jsx";

import { Container, Row, Col } from "reactstrap";
import { Answers } from "store/modules/UpdateAnswers/UpdateAnswers";
import { MirrorJustification } from "store/modules/Mirror/Mirror";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    Zindex: "999",
  },
};
class SifAnswerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundId: -1,
      questionnaireTypeId: -1,
      sifId: -1,

      mirrorUpdated: [],

      modalIsOpen: false,

      submitLoading: false,
      updatedLoading: false,
      submitSendEmail: false,
      showSendEmailSuccess: false,

      formData: {},
      answeredQuestionnaireResponse: null,
      mirror: [],

      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.closeModal = this.closeModal.bind(this);
    this.updateReturnedAnswers = this.updateReturnedAnswers.bind(this);
    this.disableButtonSave = this.disableButtonSave.bind(this);
  }

  render() {
    const Store = Answers.currentState;

    let answeredQuestionnaireItems = [];
    let stepName = "";

    let foodSafety = "Segurança de alimentos";

    let newQuestionAnswers = [];
    const StoreMirrorJustification = MirrorJustification.currentState;

    function ClearStoreQuestionnaireMirror(eventMirror) {
      eventMirror.mirror = [];
    }

    function ClearStoreQuestionnaireAnswers(eventAnswers) {
      eventAnswers.Answers = [];
      eventAnswers.newAnswers = [];
      eventAnswers.oldAnswers = [];
      eventAnswers.answerId = 0;
      eventAnswers.responseImage = [];
    }

    if (this.state.mirror) {
      if (this.state.mirror.length > 0) {
        try {
          answeredQuestionnaireItems = this.state.mirror.map((item, index) => {
            let mirror = this.state.mirror;
            let formData = this.state.formData;
            let mirrorUpdated = this.state.mirrorUpdated;

            if (StoreMirrorJustification.mirror.length) {
              StoreMirrorJustification.mirror.map((StoreMirror) => {
                if (StoreMirror.answerId === mirror[index].answerId) {
                  mirror[index] = StoreMirror;
                }
              });
            }

            let filesResponse = [];

            if (Store.responseImage.length) {
              Store.responseImage.forEach(
                (StoreResponseImage, indexResponse) => {
                  if (StoreResponseImage.answerId == mirror[index].answerId) {
                    filesResponse.push(Store.responseImage[indexResponse]);
                    mirror[index].documents = [...filesResponse];
                  }
                }
              );
            }

            if (Store.Answers.length) {
              Store.Answers.map((store) => {
                if (store.answerId === mirror[index].answerId) {
                  if (store.answerOptions && store.type == "checkbox") {
                    store.answerOptions.answerOptions.map((answerOption, i) => {
                      if (
                        store.notFormetedValue[i] !== undefined &&
                        answerOption.id == store.notFormetedValue[i] &&
                        store.type == "checkbox"
                      ) {
                        newQuestionAnswers.push(answerOption.description);
                      }

                      mirror[index].questionAnswers = newQuestionAnswers;
                    });
                  } else if (store.type == "input") {
                    mirror[index].questionAnswers = [store.value];
                  } else if (store.type == "radio") {
                    store.answerOptions.answerOptions.map((answerRadio) => {
                      if (
                        answerRadio.id == store.value &&
                        store.type == "radio"
                      ) {
                        newQuestionAnswers.push(answerRadio.description);
                      }
                      mirror[index].questionAnswers = newQuestionAnswers;
                    });
                  }

                  newQuestionAnswers = [];
                  mirror[index].disableKeepAnswer = store.updated;
                }
              });
            }

            if (mirror[index].stepName === foodSafety) {
              if (mirror[index].substepName != null) {
                mirror[index].stepName =
                  mirror[index].stepName + " - " + mirror[index].substepName;
              }
            }

            try {
              if (mirror[index].stepName === stepName) {
                mirror[index].stepName = "";
              } else {
                stepName = mirror[index].stepName;
              }
            } catch (err) {
              console.log("erro", err, mirror[index]);
            }


            return (
              <div key={index}>
                <SifEvaluationAnswered
                  disabled={mirror[index].isReadOnly}
                  onSaveHandler={(reason) => {
                    if (mirror[index].isApproved === false) {
                      let valid = true;
                      let errorValidation = "";

                      if (
                        reason === "" ||
                        reason.length < 5 ||
                        reason.length > 200
                      ) {
                        if (reason.length < 5)
                          errorValidation = "Digite pelo menos 5 caracteres.";

                        if (reason === "")
                          errorValidation = "O campo está vazio.";

                        valid = false;
                      } else {
                        mirror[index].isApproved = false;
                        mirror[index].newSifJustify = true;

                        if (mirror[index].returnReason.justification) {
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            formData.todayDate;
                          mirror[index].returnReason.justification.description =
                            reason;
                        } else {
                          mirror[index].returnReason.justification = {};
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            formData.todayDate;
                          mirror[index].returnReason.justification.description =
                            reason;
                        }
                      }

                      mirror[index].valid = valid;
                      mirror[index].errorValidation = errorValidation;

                      if (mirrorUpdated.length) {
                        this.setState({
                          mirrorUpdated: Object.assign([
                            ...this.state.mirrorUpdated,
                            mirror[index],
                          ]),
                        });
                      } else {
                        this.setState({
                          mirrorUpdated: [mirror[index]],
                        });
                      }

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onSeeAnalisys={() => {
                    if (mirror[index].isApproved === false) {
                      mirror[index].edit = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onCloseHandler={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].edit = false;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onCancelHandler={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].keepAnswer = false;

                      if (mirror[index].newSifJustify === true) {
                        if (mirror[index].returnReason.justification) {
                          mirror[index].returnReason.justification.description =
                            mirror[
                              index
                            ].returnReason.justification.backDescription;
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            mirror[
                              index
                            ].returnReason.justification.backInsertedDate;
                        }
                        mirror[index].newSifJustify = false;
                        mirror[index].edit = false;
                      }
                    }

                    let removeMirror = [];

                    if (this.state.mirrorUpdated.length) {
                      removeMirror = this.state.mirrorUpdated.filter(
                        (item) => item.answerId !== mirror[index].answerId
                      );
                    }

                    if (removeMirror.length) {
                      this.setState({
                        mirrorUpdated: [...removeMirror],
                        mirror: mirror,
                      });
                    } else {
                      this.setState({ mirror: mirror, mirrorUpdated: [] });
                    }
                  }}
                  onKeepAnswer={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].keepAnswer = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onChangeAnswer={() => {}}
                  onChange={(value) => {
                    let valid = true;

                    if (value === "" || value.lenght > 200) valid = false;

                    mirror[index].valid = valid;
                    mirror[index].edit = true;

                    this.setState({ mirror: mirror });
                  }}
                  attachedFileUrl={mirror[index].documents}
                  isArtificialIntelligence={
                    mirror[index].isRecognizedByArtificialIntelligence
                  }
                  edit={mirror[index].edit}
                  valid={mirror[index].valid}
                  value={mirror[index]}
                  approved={mirror[index].isApproved}
                  hasValidation={mirror[index].hasValidation}
                  questionId={mirror[index].questionId}
                  answerId={mirror[index].answerId}
                  disableKeepAnswer={mirror[index].disableKeepAnswer}
                  sifId={this.state.sifId}
                />
                <Row lg="12" className="questionnaire">
                  <div className="q_separator-question">
                    <hr />
                  </div>
                </Row>
              </div>
            );
          });
        } catch (ex) {
          answeredQuestionnaireItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.stringTable.standard.confirmSubmit}
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group darkButtom mr-4"
                onClick={this.updateReturnedAnswers}
              >
                {this.state.updatedLoading ? <Loading load="Sim" /> : "Sim"}
              </button>
              <button
                className="btn btn-group mainColorButtom"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>
        </>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter questionnaire bg-body mb-8">
          <Row className="pt-6 pl-6">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {
                    this.stringTable.questionnaire.questionnaireType
                      .titleTypeDefault
                  }
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.state.formData["roundSequence"]} -{" "}
                  {this.state.formData["sifNumber"]}
                </h1>
              )}
              <small className="subtitle">
                {
                  this.stringTable.questionnaireSif.sifAnswerList
                    .newSubtitleAnswer
                }
              </small>
            </Col>
            <Col lg="6">
              <Link
                to={
                  "/sif/Questionnaires/answeredQuestionnaire/sifQuestionnaireListing/?id=" +
                  this.state.sifId
                }
                className="float-right"
                onClick={() => {
                  Answers.update((event) =>
                    ClearStoreQuestionnaireAnswers(event)
                  );
                  MirrorJustification.update((event) =>
                    ClearStoreQuestionnaireMirror(event)
                  );
                }}
              >
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row lg="12" className="pt-8 pl-6 pb-2">
            <Col lg="12" xl="12">
              <div className="">
                <h3 className="text-dark">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.typeQuestionnaire
                  }
                </h3>
              </div>
              <div className="pt-2">
                <h3 className="text-dark font-weight-normal">
                  {this.state.formData["questionnaireTypeName"]}
                </h3>
              </div>
            </Col>
          </Row>
          <Row lg="12" className="">
            <div className="q_separator-question">
              <hr />
            </div>
          </Row>

          {answeredQuestionnaireItems.length === 0 ||
            answeredQuestionnaireItems}

          <div className="container-fluid mb-4 mt-6">
            <Row className="justify-content-center">
              {this.state.answeredQuestionnaireResponse !== null &&
              this.state.answeredQuestionnaireResponse.isReadOnly == true ? (
                <button
                  disabled={this.disableButtonSave()}
                  style={{ display: "none" }}
                  className="mainColorButtom ml-4"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {this.state.submitLoading ? <Loading load="Sim" /> : "Salvar"}
                </button>
              ) : (
                <button
                  disabled={this.disableButtonSave()}
                  className="mainColorButtom ml-4"
                  onClick={() => {
                    this.setState({ modalIsOpen: true });
                  }}
                >
                  {this.state.submitLoading ? <Loading load="Sim" /> : "Salvar"}
                </button>
              )}
            </Row>
          </div>

          {!this.state.showRegisterSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.standard.registerSuccess}
                </span>
              </div>
            </Row>
          )}

          {!this.state.showSendEmailSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.sendEmailSuccess
                  }
                </span>
              </div>
            </Row>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 3) {
        if (
          !isNaN(parseInt(urlValues[0])) &&
          !isNaN(parseInt(urlValues[1])) &&
          !isNaN(parseInt(urlValues[2]))
        ) {
          this.setState({
            roundId: parseInt(urlValues[0]),
            questionnaireTypeId: parseInt(urlValues[1]),
            sifId: parseInt(urlValues[2]),
          });
          valid = true;
        }
      }
    }

    if (!valid) window.location.href = "/admin/Questionnaires";
  }

  loadSelects() {
    this.getAnsweredQuestionnaire();
  }

  componentDidMount() {
    this.loadSelects();
  }

  getAnsweredQuestionnaire() {
    let dataAPI = {
      roundId: this.state.roundId,
      questionnaireTypeId: this.state.questionnaireTypeId,
      sifId: this.state.sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.ReviewFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let formData = this.state.formData;
        let mirror = this.state.mirror;

        function dateFormat(date) {
          let day = "";
          let month = "";
          let year = "";
          let dateFormated = "";

          day = new Date(date).getDate().toString();
          month = (new Date(date).getMonth() + 1).toString();
          year = new Date(date).getFullYear().toString();

          dateFormated =
            (day.toString().length === 1 ? "0" + day : day) +
            "/" +
            (month.toString().length === 1 ? "0" + month : month) +
            "/" +
            (year.toString().length === 1 ? "0" + year : year);

          return dateFormated;
        }

        formData.todayDate = dateFormat(new Date());
        formData.currentScore = ret.currentScore;
        formData.questionnaireTypeName = ret.questionnaireTypeName;
        formData.roundSequence = "R0" + ret.roundSequence;
        formData.roundStatusId = ret.roundStatusId;
        formData.sifNumber = ret.sifNumber;
        formData.hasReview = ret.hasReview;

        if (ret.answers) {
          mirror = ret.answers.map((item, index) => {
            let sifJustify = false;

            if (item.returnReason) {
              item.returnReason.insertedDate = dateFormat(
                item.returnReason.insertedDate
              );

              if (item.returnReason.justification) {
                sifJustify = true;
                item.returnReason.justification.backDescription =
                  item.returnReason.justification.description;
                item.returnReason.justification.backInsertedDate = dateFormat(
                  item.returnReason.justification.insertedDate
                );
                item.returnReason.justification.insertedDate = dateFormat(
                  item.returnReason.justification.insertedDate
                );
              }
            }

            item.valid = true;
            item.keepAnswer = false;
            item.edit = false;
            item.newSifJustify = false;
            item.sifJustify = sifJustify;
            item.isReadOnly = ret.isReadOnly;
            item.disableKeepAnswer = false;

            return item;
          });
        }

        this.setState({ answeredQuestionnaireResponse: ret, mirror: mirror });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  updateMirrorJustification(event, mirrors) {
    const StoreMirrorJustification = MirrorJustification.currentState;

    if (StoreMirrorJustification.mirror.length) {
      event.mirror = [...StoreMirrorJustification.mirror, ...mirrors];
    } else {
      event.mirror = [...mirrors];
    }
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let mirror = this.state.mirror;
    let formData = this.state.formData;
    let mirrorUpdated = this.state.mirrorUpdated;
    let review = {};

    if (mirrorUpdated.length) {
      MirrorJustification.update((event) =>
        this.updateMirrorJustification(event, mirrorUpdated)
      );
    }

    let validations = {};
    validations.sucess = true;
    validations.errors = [];

    review.answerReviews = [];

    review.answerReviews = mirror.map((item) => {
      return item;
    });

    if (!validations.sucess) {
      this.setState((state) => ({
        submitLoading: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Questionnaire.JustifyAnswer;
    let data = review;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            submitLoading: false,

            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },

            showRegisterSuccess: false,
          }));

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            submitLoading: false,
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },

            showRegisterSuccess: false,
          }));

          return;
        }
        this.setState((state) => ({
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Informações enviadas com sucesso!
                  <ul className="errorSumary">
                    <li>Agradecemos sua participação.</li>
                  </ul>
                </>
              ),
            },
          },
          submitLoading: false,
          showRegisterSuccess: false,
        }));

        return;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          showRegisterSuccess: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  updateReturnedAnswers() {
    this.setState({
      updatedLoading: true,
    });

    const { mirror } = this.state;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.UpdateReturnedAnswer;

    let Justify = [];
    mirror.length &&
      mirror.forEach((item, index) => {
        if (
          item.returnReason !== null &&
          item.returnReason.justification !== null
        ) {
          Justify.push({
            AnswerId: item.answerId,
            Justification: item.returnReason.justification.description,
          });
        }
      });

    let data = {
      sifId: this.state.sifId,
      ToChange: [...Answers.currentState.Answers],
      ToJustify: [...Justify],
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            updatedLoading: false,

            showRegisterSuccess: false,
          }));

          return;
        }

        // if (result.response == null && result.success) {
        //   console.log(messages.Errors.BlankResponse, result.errors);
        //   return;
        // }

        this.setState((state) => ({
          updatedLoading: false,
          modalIsOpen: false,
          message: {
            ...state.message,
            ...{
              _defaultCloseAction: () => {
                window.history.back();
              },
              title: "Aviso",
              visible: true,
              text: (
                <>
                  Informações enviadas com sucesso!
                  <ul className="errorSumary">
                    <li>Agradecemos sua participação.</li>
                  </ul>
                </>
              ),
            },
          },
          showRegisterSuccess: false,
        }));

        return;
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  disableButtonSave() {
    const { mirror } = this.state;

    let AllJustifyAndAnswers = [];

    let Justify = [];
    mirror.length &&
      mirror.forEach((item, index) => {
        if (
          item.returnReason !== null &&
          item.returnReason.justification !== null
        ) {
          Justify.push({
            AnswerId: item.answerId,
            Justification: item.returnReason.justification.description,
          });
        }
      });

    if (Answers.currentState.Answers.length) {
      AllJustifyAndAnswers = [...Answers.currentState.Answers];
    } else {
      AllJustifyAndAnswers = [];
    }

    if (Justify.length) {
      AllJustifyAndAnswers = [...Justify];
    }

    if (!AllJustifyAndAnswers.length) {
      return true;
    } else {
      return false;
    }
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });
      }.bind(this),
      4000
    );
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
}

export default SifAnswerList;
