import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList.jsx";

import { Container, Row, Col } from "reactstrap";
import { Answers } from "store/modules/UpdateAnswers/UpdateAnswers";
import { MirrorJustification } from "store/modules/Mirror/Mirror";
class SifQuestionnaireListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderLoading: true,
      renderDataList: true,
      questionnaireNofFound: false,
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      confirmExclusao: false,
      openConfirm: false,
      registerLoading: false,
      changeLoading: false,
      exporting: false,

      answerQuestionnaireResponse: null,
      sifResponse: null,

      sifId: -1,
      currentPage: 0,

      formData: {},

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
  }

  render() {
    let sifNumber = "";

    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    function ClearStoreQuestionnaireMirror(eventMirror) {
      eventMirror.mirror = [];
    }

    function ClearStoreQuestionnaireAnswers(eventAnswers) {
      eventAnswers.Answers = [];
      eventAnswers.newAnswers = [];
      eventAnswers.oldAnswers = [];
      eventAnswers.answerId = 0;
      eventAnswers.responseImage = [];
    }

    function dateFormat(date) {
      let day = "";
      let month = "";
      let year = "";
      let dateFormated = "";

      day = new Date(date).getDate().toString();
      month = (new Date(date).getMonth() + 1).toString();
      year = new Date(date).getFullYear().toString();

      dateFormated =
        (day.toString().length === 1 ? "0" + day : day) +
        "/" +
        (month.toString().length === 1 ? "0" + month : month) +
        "/" +
        (year.toString().length === 1 ? "0" + year : year);

      return dateFormated;
    }

    if (
      this.state.answerQuestionnaireResponse &&
      this.state.answerQuestionnaireResponse.list &&
      this.state.answerQuestionnaireResponse.total
    ) {
      tableCollumns = [
        { name: "Rodada", size: 30, property: "roundSequence" },
        { name: "Tipo", size: 110, property: "questionnaireTypeName" },
        { name: "Data", size: 50, property: "date" },
        { name: "Status", size: 40, property: "roundStatus" },
        { name: "", size: 40, property: "button" },
      ];

      tableData = this.state.answerQuestionnaireResponse.list;
      tableData = tableData.map((item) => {
        let statusColor = "";
        let questionnaireTypeNameTreated = "";
        let roundSequenceTreated = "";

        questionnaireTypeNameTreated =
          "Q" + item.questionnaireTypeId + " - " + item.questionnaireTypeName;
        roundSequenceTreated = "R0" + item.roundSequence;

        switch (item.answerStatusId) {
          case 1:
            statusColor = "q_text-blue";
            break;

          case 2:
            statusColor = "q_text-green";
            break;

          case 3:
            statusColor = "q_text-red";
            break;

          default:
            statusColor = "text-dark";
            break;
        }

        let answerDate = dateFormat(item.answerDate);

        return {
          ...item,
          ...{
            button: (
              <Link
                to={`/sif/Questionnaires/answeredQuestionnaire/sifAnswerList/?id=${item.roundId}-${item.questionnaireTypeId}-${item.sifId}`}
                onClick={() => {
                  Answers.update((event) =>
                    ClearStoreQuestionnaireAnswers(event)
                  );
                  MirrorJustification.update((event) =>
                    ClearStoreQuestionnaireMirror(event)
                  );
                }}
              >
                <button className="btn btn-sm view">Visualizar</button>
              </Link>
            ),
            questionnaireTypeName: questionnaireTypeNameTreated,
            date: answerDate,
            roundSequence: roundSequenceTreated,
            roundStatus: (
              <span className={statusColor}>{item.answerStatusName}</span>
            ),
          },
        };
      });

      total = this.state.answerQuestionnaireResponse.total;
    }

    if (this.state.sifResponse) {
      if (this.state.sifResponse.sifNumber)
        sifNumber = this.state.sifResponse.sifNumber;
    }

    let pageChangeHandler = (index) => {
      this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-9">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {
                  this.stringTable.questionnaireSif.sifSelection
                    .answeredQuestionnaire.titleSif
                }
                {sifNumber === "" || " " + sifNumber}
              </h1>
              <small className="subtitle">
                {
                  this.stringTable.questionnaireSif.sifSelection
                    .answeredQuestionnaire.subTitleSif
                }
              </small>
            </Col>
            <Col lg="6">
              <Link
                to="/sif/Questionnaires/sifSelection"
                className="float-right"
              >
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row className="pt-4 pl-5">
            <Col lg="12" className="questionnaire">
              {this.state.renderDataList ? (
                <>
                  <Row lg="12" className="justify-content-center mt-7 mb-6">
                    <div className="">
                      <h1 className="titleText">
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .titleList
                        }
                      </h1>
                    </div>
                  </Row>

                  <DataList
                    loading={this.state.renderLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                </>
              ) : (
                <>
                  {this.state.questionnaireNofFound || <h1> Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ sifId: id });
  }

  componentDidMount() {
    this.loadSelects();
  }

  loadSif() {
    this.setState({ sifResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      this.state.sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sifResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadDataList(0, true);
    this.loadSif();
  }

  clearFormState() {
    let formData = this.state.formData;

    this.setState({ formData: formData });
    this.loadDataList(0, false);
  }

  confirmExclusion(bool) {
    this.loadDataList(0, false);

    if (bool === "true") {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ sifId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ renderLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ renderLoading: false });

    let sifId = this.state.sifId;

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      sifId: sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnsweredPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            renderDataList: false,
            roundInEdition: true,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            renderDataList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        if (result.response.list.length <= 0) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          renderLoading: false,
          renderDataList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          renderDataList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  export() {
    if (this.state.formData == null) return;

    let formData = this.state.formData;

    formData.sifId = this.state.sifId;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnwseredExport;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        const fileKey = result.response.fileKey;

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Answer.AnwseredDownload +
            "?fileKey=" +
            fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default SifQuestionnaireListing;
