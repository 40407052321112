import React from "react";

class AnswerCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      checkboxSelectItems: [],
      updated: false,
    };

    this.questionId = 0;
  }

  render() {
    const defaultTitle = "Questão";
    const defaultSubTitle = "Selecione todas as opções que se aplicam.";
    const defaultValue = "Informe as opções de respostas.";
    const defaultOnChange = () => {};

    let displayNone = this.props.display ? true : false;
    let title = this.props.title ? this.props.title : defaultTitle;
    let subTitle = this.props.subTitle ? this.props.subTitle : defaultSubTitle;
    let stepName = this.props.stepName ? this.props.stepName : "";
    let questionOrder = this.props.questionOrder
      ? this.props.questionOrder
      : "";
    let stepOrder = this.props.stepOrder ? this.props.stepOrder : "";
    let questionScoreTypes = this.props.questionScoreTypes
      ? this.props.questionScoreTypes
      : [];
    let scoreTypeId = this.props.scoreTypeId ? this.props.scoreTypeId : 0;

    let value = this.props.value ? this.props.value : defaultValue;
    let formData = this.props.formData ? this.props.formData : {};
    let onChange = this.props.onChange ? this.props.onChange : defaultOnChange;

    if (typeof title !== "string" && typeof title !== "object")
      title = defaultTitle;

    if (typeof subTitle !== "string" && typeof subTitle !== "object")
      subTitle = defaultSubTitle;

    if (value && value.length) {
      value.forEach((item, index) => {
        if (item.id) {
          value[index].answerOptionId = item.id;
        }
      });
    }

    let onInputChange = (event) => {
      const target = event.target;
      const id = parseInt(target.id);
      const name = event.target.name;
      let value = "";
      let isDelete = false;
      let valueCheckBox = {};
      let formDataI = this.state.formData;

      if (Object.keys(formDataI).length == 0) {
        formData.map((item, index) => {
          Object.assign(valueCheckBox, { [index]: item });
        });
      } else {
        valueCheckBox = formDataI;
      }

      if (target.type === "checkbox") {
        value = target.checked;
      } else value = target.value;

      if (value) {
        this.setState({
          formData: Object.assign(valueCheckBox, { [id]: name }),
        });
      } else {
        delete valueCheckBox[id];

        isDelete = true;

        this.setState({
          formData: valueCheckBox,
        });
      }

      onChange(valueCheckBox, id, isDelete);
    };

    if (value) {
      if (value.length > 0) {
        let formDataT = formData;

        try {
          this.state.checkboxSelectItems = value.map((item, index) => {
            let isChecked = false;

            if (formDataT.length > 0) {
              let newArray = formDataT.filter(
                (questionId) => parseInt(questionId) === item.answerOptionId
              );

              if (newArray.length > 0) isChecked = true;
            }

            return (
              <div key={index}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input input-checkbox"
                    id={index}
                    name={item.answerOptionId}
                    onChange={onInputChange}
                    checked={
                      parseInt(this.state.formData[index]) ===
                      item.answerOptionId
                        ? true
                        : false
                    }
                  />
                  <label className="custom-control-label pb-3" htmlFor={index}>
                    {item.description}
                  </label>
                  <br />
                </div>
              </div>
            );
          });
        } catch (ex) {
          this.state.checkboxSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <div key={stepName}>
        <div
          className="mb-3"
          style={{
            display: displayNone && "none",
            fontWeight: "bold",
            color: "#e64b3c",
          }}
        >
          <span>{stepName}</span>
        </div>
        <div className="mb-3">
          {questionScoreTypes.map((item, index) => {
            return (
              <span
                className={index === 1 && "ml-2"}
                style={{
                  fontWeight: "bold",
                  color: item.scoreTypeId === 1 ? "#5dcaaa" : "#4cb6f2",
                }}
              >
                ({item.scoreTypeName})
              </span>
            );
          })}
        </div>
        <div className="answerInput">
          <div className="title" style={{ display: displayNone && "none" }}>
            <span>
              {stepOrder}.{questionOrder} {title}
            </span>
          </div>
          <div className="subTitle" style={{ display: displayNone && "none" }}>
            <span>{subTitle}</span>
          </div>

          {this.state.checkboxSelectItems.length === 0 ||
            this.state.checkboxSelectItems}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    let item = this.props.item ? this.props.item : {};
    if (item.questionId !== this.questionId) {
      let previousAnswers =
        this.props.previousAnswers && this.props.previousAnswers.length
          ? this.props.previousAnswers
          : [];
      let answerOptions =
        this.props.value && this.props.value.length ? this.props.value : [];
      let item = this.props.item ? this.props.item : {};

      let itemsCheckBox = {};

      this.questionId = item.questionId;

      answerOptions.forEach((options, index) => {
        previousAnswers.forEach((item, i) => {
          if (answerOptions[index].answerOptionId == item) {
            itemsCheckBox[index] = item;
          }
        });
      });

      let onChange = this.props.onChange ? this.props.onChange : () => {};
      let isDelete = false;

      this.setState({
        formData: { ...itemsCheckBox },
      });

      setTimeout(() => {
        this.questionId = item.questionId;

        previousAnswers.forEach((item, index) => {
          item = this.state.formData;

          index = Object.keys(item)[index];

          onChange(item, index, isDelete);
        });
      }, 100);
    } else {
      console.warn("Finalizou!");
    }
  }
  componentDidMount() {
    let previousAnswers =
      this.props.previousAnswers && this.props.previousAnswers.length
        ? this.props.previousAnswers
        : [];
    let answerOptions =
      this.props.value && this.props.value.length ? this.props.value : [];
    let item = this.props.item ? this.props.item : {};

    let itemsCheckBox = {};

    this.questionId = item.questionId;

    answerOptions.forEach((options, index) => {
      previousAnswers.forEach((item, i) => {
        if (answerOptions[index].answerOptionId == item) {
          itemsCheckBox[index] = item;
        }
      });
    });

    let onChange = this.props.onChange ? this.props.onChange : () => {};
    let isDelete = false;

    this.setState({
      formData: { ...itemsCheckBox },
    });

    setTimeout(() => {
      previousAnswers.forEach((item, index) => {
        item = this.state.formData;

        index = Object.keys(item)[index];

        onChange(item, index, isDelete);
      });
    }, 100);
  }

  componentWillUnmount() {
    this.setState({ formData: {}, checkboxSelectItems: [] });
  }
}

export default AnswerCheckbox;
