import React from "react";

import { Link } from "react-router-dom";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList.jsx";

import { Container, Row, Col } from "reactstrap";

class QuestionnaireListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderLoading: true,
      renderDataList: true,
      questionnaireNofFound: false,
      exporting: false,
      answerQuestionnaireResponse: null,
      sifResponse: null,
      sifId: -1,
      currentPage: 0,
      formData: {},
      errorMessage: "",
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.loadDataList = this.loadDataList.bind(this);
  }

  render() {
    let sifNumber = "";

    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    function dateFormat(date) {
      let day = "";
      let month = "";
      let year = "";
      let dateFormated = "";

      day = new Date(date).getDate().toString();
      month = (new Date(date).getMonth() + 1).toString();
      year = new Date(date).getFullYear().toString();

      dateFormated =
        (day.toString().length === 1 ? "0" + day : day) +
        "/" +
        (month.toString().length === 1 ? "0" + month : month) +
        "/" +
        (year.toString().length === 1 ? "0" + year : year);

      return dateFormated;
    }

    if (
      this.state.answerQuestionnaireResponse &&
      this.state.answerQuestionnaireResponse.list &&
      this.state.answerQuestionnaireResponse.total
    ) {
      tableCollumns = [
        { name: "Rodada", size: 30, property: "roundSequence" },
        { name: "Tipo", size: 110, property: "questionnaireTypeName" },
        { name: "Data", size: 50, property: "date" },
        { name: "Status", size: 40, property: "roundStatus" },
        { name: "", size: 40, property: "button" },
      ];

      tableData = this.state.answerQuestionnaireResponse.list;
      tableData = tableData.map((item) => {
        let statusColor = "";
        let questionnaireTypeNameTreated = "";
        let roundSequenceTreated = "";

        questionnaireTypeNameTreated =
          "Q" + item.questionnaireTypeId + " - " + item.questionnaireTypeName;
        roundSequenceTreated = "R0" + item.roundSequence;

        switch (item.answerStatusId) {
          case 1:
            statusColor = "q_text-blue";
            break;

          case 2:
            statusColor = "q_text-green";
            break;

          case 3:
            statusColor = "q_text-red";
            break;

          default:
            statusColor = "text-dark";
            break;
        }

        let answerDate = dateFormat(item.answerDate);

        return {
          ...item,
          ...{
            button: (
              <Link
                to={`/industry/Questionnaires/answeredQuestionnaire/AnswerList/?id=${item.roundId}-${item.questionnaireTypeId}-${item.sifId}`}
              >
                <button className="btn btn-sm view">Visualizar</button>
              </Link>
            ),
            questionnaireTypeName: questionnaireTypeNameTreated,
            date: answerDate,
            roundSequence: roundSequenceTreated,
            roundStatus: (
              <span className={statusColor}>{item.answerStatusName}</span>
            ),
          },
        };
      });

      total = this.state.answerQuestionnaireResponse.total;
    }

    if (this.state.sifResponse) {
      if (this.state.sifResponse.sifNumber)
        sifNumber = this.state.sifResponse.sifNumber;
    }

    let pageChangeHandler = (index) => {
      this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <Container fluid className="minHeightFooter bg-body mb-9">
        <Row className="pt-6 pl-5">
          <Col lg="6">
            <h1 className="text-dark">
              {
                this.stringTable.questionnaireSif.sifSelection
                  .answeredQuestionnaire.titleSif
              }
              {sifNumber === "" || " " + sifNumber}
            </h1>
            <small className="subtitle">
              {
                this.stringTable.questionnaireSif.sifSelection
                  .answeredQuestionnaire.subTitleSif
              }
            </small>
          </Col>
          <Col lg="6">
            <Link to="/industry/Questionnaires" className="float-right">
              <button type="button" className="lightButtom">
                {this.stringTable.standard.backButton}
              </button>
            </Link>
          </Col>
        </Row>

        <Row className="pt-4 pl-5">
          <Col lg="12" className="questionnaire">
            {this.state.renderDataList ? (
              <>
                <Row lg="12" className="justify-content-center mt-7 mb-6">
                  <div className="">
                    {this.state.answerQuestionnaireResponse !== null &&
                    this.state.answerQuestionnaireResponse.list.length ? (
                      <h1 className="titleText">
                        {
                          this.stringTable.questionnaire.answeredQuestionnaire
                            .titleList
                        }
                      </h1>
                    ) : (
                      <h1 className="titleText">
                        Não há questionários respondidos.
                      </h1>
                    )}
                  </div>
                </Row>

                <DataList
                  loading={this.state.renderLoading}
                  tableCollumns={tableCollumns}
                  tableData={tableData}
                  paging={true}
                  currentPage={this.state.currentPage}
                  pageSize={constants.Pagination.DefaultPageSize}
                  total={total}
                  pageChangeHandler={(index) => {
                    pageChangeHandler(index);
                  }}
                  exportHandler={exportHandler}
                  exporting={true}
                  exportProcessing={this.state.exporting}
                />
              </>
            ) : (
              <>{this.state.questionnaireNofFound || <h1> Carregando...</h1>}</>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ sifId: id });
  }

  componentDidMount() {
    this.loadDataList(0, true);
    this.loadSif();
  }

  loadSif() {
    this.setState({ sifResponse: null });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      this.state.sifId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sifResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ renderLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ renderLoading: false });

    let sifId = this.state.sifId;

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      sifId: sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnsweredPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            renderDataList: false,
            roundInEdition: true,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            renderDataList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        if (result.response.list.length <= 0) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          renderLoading: false,
          renderDataList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          renderDataList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Answer.Download +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default QuestionnaireListing;
