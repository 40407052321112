import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import constants from "variables/constants";

import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes) => {
    let matchingRoutes = [];

    routes.forEach((prop, key) => {
      if (prop.layout === "/sif") {
        matchingRoutes.push(
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    });

    return matchingRoutes;
  };

  filterRoutes = (routes) => {
    let matchingRoutes = [];

    routes.forEach((prop) => {
      if (prop.layout === "/sif") matchingRoutes.push(prop);
    });

    return matchingRoutes;
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      )
        return routes[i].name;
    }

    return "Brand";
  };

  render() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const profileId = window.localStorage.getItem(
      constants.LocalStorage.ProfileId
    );

    if (token && profileId == "3") {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={this.filterRoutes(routes)}
            logo={{
              innerLink: "/sif/index",
              imgSrc: require("assets/img/brand/logo2.png"),
              imgAlt: "...",
            }}
          />
          <div className="main-content" ref="mainContent">
            {/* <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          /> */}
            <Switch>{this.getRoutes(routes)}</Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Redirect to={"/auth/Login"}></Redirect>
        </>
      );
    }
  }
}

export default Admin;
