import React from "react";
import Modal from "react-modal";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";

import Wizard from "components/Utils/Wizard";
import AnswerText from "components/Sif/Answer/AnswerText.jsx";
import AnswerNumber from "components/Sif/Answer/AnswerNumber.jsx";
import FileAttachment from "components/FileAttachment/FileAttachment";
import AnswerTextArea from "components/Sif/Answer/AnswerTextArea.jsx";
import AnswerCheckbox from "components/Sif/Answer/AnswerCheckbox.jsx";
import OrderAndProgress from "components/Sif/Answer/OrderAndProgress.jsx";
import AnswerRadioButton from "components/Sif/Answer/AnswerRadioButton.jsx";

import { Answers } from "store/modules/UpdateAnswers/UpdateAnswers";

import { Container, Row, Col } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    Zindex: "999",
  },
};

class SifUpdateAnswere extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalFile: [],
      file: null,
      extensions: ["jpg", "jpeg", "png", "pdf"],
      name: "",

      token: [],
      responseImage: [],
      loading: false,

      initialStep: 1,

      currentStep: 1,
      currentSubStep: 1,
      filteredQuestions: [],

      firstRenderization: false,

      newAnswer: true,

      oldAnswers: [],

      stepNames: [],

      questionOne: [],
      questionTwo: [],
      questionThree: [],
      questionFour: [],
      questionFive: [],

      answerOne: [],
      answerTwo: [],
      answerThree: [],
      answerFour: [],
      answerFive: [],

      previousAnswers: [],
      questionsAnswer: [],

      allQuestionsAwnsered: [],
      ListFiles: [],

      modalIsOpen: false,

      questionnaireTypeId: 0,
      currentQuestion: 0,
      questionId: 0,
      sifId: 0,

      questionIdUpdate: 0,
      answerIdUpdate: 0,

      realForm: [],
      currentQuestionForm: [],

      wizardResponse: null,
      questionsResponse: [],

      formData: { anwserOptions: [], answerPost: {}, answer: [] },
      message: {
        visible: false,
        leftButtomText: "Ok",
        rightButtomText: null,
        text: "",
        title: "",
        closeCallBack: () => {},
        leftButtomClickCallBack: () => {},
        rightButtomClickCallBack: () => {},
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.closeModal = this.closeModal.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.removeFileUploaded = this.removeFileUploaded.bind(this);
  }

  render() {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizzardNodeConfiguration = this.state.stepNames.map((step, index) => {
      return wizzardConfiguration.Configuration.Formater(
        <>
          {step.order}. {step.name}
        </>,
        "#",
        this.getColorForStepId(step.id)
      );
    });

    let hasAttachment = false;
    let questionId = 0;

    let questionsAnswer = [];
    let renderQuestion = [];
    let totalStep = 0;

    if (this.state.questionsResponse) {
      if (this.state.questionsResponse.length > 0) {
        try {
          this.state.questionsResponse[
            this.state.currentQuestion
          ].currentQuestion = this.state.currentQuestion;

          questionsAnswer.push(
            this.state.questionsResponse[this.state.currentQuestion]
          );

          totalStep = this.state.questionsResponse.reduce((acc, elem) => {
            return this.state.currentStep === elem.stepOrder
              ? (totalStep += 1)
              : totalStep;
          }, 0);
        } catch (ex) {
          questionsAnswer = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    const {
      currentQuestionForm,
      oldAnswers,
      answerIdUpdate,
      token,
      responseImage,
    } = this.state;
    let newStoreAnswers = [];
    let currentResponseImage = [];

    let removeInvalidAnexo = [];
    if (responseImage.length > 0) {
      responseImage.forEach((res, index) => {
        if (responseImage[index].token.length) {
          removeInvalidAnexo.push(responseImage[index]);
        }
      });

      currentResponseImage = [...removeInvalidAnexo];
    }

    function updateAnswers(event) {
      if (
        currentQuestionForm.length > 0 &&
        typeof currentQuestionForm[0].value === "string"
      ) {
        currentQuestionForm[0].value = currentQuestionForm[0].value.replace(
          /["[/\]"]/g,
          ""
        );
      }

      if (token.length) {
        currentQuestionForm[0].documentTokens = [...token];
      }

      const Store = Answers.currentState;

      if (Store.Answers && Store.Answers.length) {
        const filteredAnswers = Store.Answers.filter((item) => {
          return item.answerId !== answerIdUpdate;
        });

        if (filteredAnswers.length) {
          newStoreAnswers = [...filteredAnswers];
        }
      }

      let filteredImages = Store.responseImage.filter(
        (item) => item.answerId !== answerIdUpdate
      );

      if (
        filteredImages.length &&
        currentResponseImage.length <= 2 &&
        currentResponseImage.length > 0
      ) {
        event.responseImage = [
          ...filteredImages,
          ...currentResponseImage.map((item) => {
            return {
              ...item,
              answerId: answerIdUpdate,
            };
          }),
        ];
      } else {
        if (
          currentResponseImage.length <= 2 &&
          currentResponseImage.length > 0
        ) {
          event.responseImage = currentResponseImage.map((item) => {
            return {
              ...item,
              answerId: answerIdUpdate,
            };
          });
        } else if (currentResponseImage.length == 0 && filteredImages.length) {
          event.responseImage = [
            ...filteredImages,
            {
              answerId: answerIdUpdate,
              contentResponse: null,
              isContentRecognized: false,
              isTemplateRecognized: false,
              templateResponse: null,
              token: [],
            },
          ];
        } else if (currentResponseImage.length == 0 && !filteredImages.length) {
          event.responseImage = [
            {
              answerId: answerIdUpdate,
              contentResponse: null,
              isContentRecognized: false,
              isTemplateRecognized: false,
              templateResponse: null,
              token: [],
            },
          ];
        }
      }

      if (currentQuestionForm.length) {
        event.newAnswers = currentQuestionForm[0].value;
        event.oldAnswers = oldAnswers;
        event.answerId = currentQuestionForm[0].answerId;
        event.Answers = newStoreAnswers.length
          ? [...newStoreAnswers, ...currentQuestionForm]
          : [...currentQuestionForm];
      } else {
        event.newAnswers = [];
        event.oldAnswers = [];
        event.answerId = 0;
        event.Answers = [];
        event.responseImage = [];
      }
    }

    if (questionsAnswer) {
      if (questionsAnswer.length > 0) {
        renderQuestion = questionsAnswer.map((item, index) => {
          let formData = this.state.formData;

          if (item.questionTypeId === 1) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { allQuestionsAwnsered, ListFiles } = this.state;
            let file = {};

            allQuestionsAwnsered &&
              allQuestionsAwnsered.length &&
              allQuestionsAwnsered.forEach((answer) => {
                if (item.questionId === answer.questionId) {
                  item.previousAnswers = answer.value.split();
                }
              });

            ListFiles &&
              ListFiles.length &&
              ListFiles.forEach((itemFile, index) => {
                if (item.questionId == itemFile.questionId) {
                  file = {
                    response: [...ListFiles[index].responseImage],
                    token: [...ListFiles[index].token],
                    question: itemFile.questionId,
                  };
                }
              });

            let response = [];
            let token = [];

            if (file && file.response && file.response.length) {
              response = this.state.responseImage.length
                ? [...this.state.responseImage, ...file.response]
                : [...file.response];

              token = this.state.token.length
                ? [...this.state.token, ...file.token]
                : [...file.token];
            } else {
              response = this.state.responseImage.length
                ? [...this.state.responseImage]
                : [];
              token = this.state.token.length ? [...this.state.token] : [];
            }

            return (
              <div>
                <div>
                  <AnswerRadioButton
                    onChange={(value) => {
                      let valid = true;

                      if (!value || value === "") valid = false;

                      if (valid) {
                        this.setState({
                          newAnswer:
                            this.state.oldAnswers !== value ? false : true,
                          currentQuestionForm: [
                            {
                              answerOptions: item,
                              notFormetedValue: value,
                              updated:
                                this.state.oldAnswers !== value ? true : false,
                              hasAttachment: item.hasAttachment,
                              answerId: this.state.answerIdUpdate,
                              value: value,
                              type: "radio",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });
                        formData.answerRadioButton = value;
                      }

                      this.setState({ formData: formData });
                    }}
                    stepOrder={item.stepId}
                    questionOrder={item.order}
                    previousAnswers={item.previousAnswers}
                    value={item.answerOptions}
                    title={item.wording}
                    stepName={item.stepName}
                  />
                </div>
                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.id}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          if (item.questionTypeId === 2) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { allQuestionsAwnsered, ListFiles } = this.state;

            let file = {};

            allQuestionsAwnsered &&
              allQuestionsAwnsered.length &&
              allQuestionsAwnsered.forEach((answer) => {
                if (item.questionId === answer.questionId) {
                  item.previousAnswers = answer.formattedValue;
                }
              });

            ListFiles &&
              ListFiles.length &&
              ListFiles.forEach((itemFile, index) => {
                if (item.questionId == itemFile.questionId) {
                  file = {
                    response: [...ListFiles[index].responseImage],
                    token: [...ListFiles[index].token],
                    question: itemFile.questionId,
                  };
                }
              });

            let response = [];
            let token = [];
            let newValues = [];

            if (file && file.response && file.response.length) {
              response = this.state.responseImage.length
                ? [...this.state.responseImage, ...file.response]
                : [...file.response];

              token = this.state.token.length
                ? [...this.state.token, ...file.token]
                : [...file.token];
            } else {
              response = this.state.responseImage.length
                ? [...this.state.responseImage]
                : [];
              token = this.state.token.length ? [...this.state.token] : [];
            }

            const StoreAnswers = Answers.currentState;

            item.answerOptions.forEach((answer, index) => {
              item.previousAnswers.forEach((item) => {
                if (item == answer.id) {
                  newValues[index] = item;
                }
              });
            });

            item.previousAnswers = newValues;

            return (
              <div>
                <div>
                  <AnswerCheckbox
                    onChange={(value, optionId, isDelete) => {
                      let valid = true;
                      let compativeValue = "";

                      if (Object.values(value).length) {
                        compativeValue = JSON.stringify(
                          Object.values(value)
                        ).replace(/["[/\]"]/g, "");
                        this.setState({
                          newAnswer:
                            this.state.oldAnswers != compativeValue
                              ? false
                              : true,
                        });
                      }

                      if (!value || value === "") valid = false;

                      if (valid && value[optionId] !== undefined) {
                        this.setState({
                          currentQuestionForm: [
                            {
                              answerOptions: item,
                              notFormetedValue: value,
                              updated:
                                this.state.oldAnswers !== value ? true : false,
                              hasAttachment: item.hasAttachment,
                              answerId: this.state.answerIdUpdate,
                              value: JSON.stringify(Object.values(value)),
                              type: "checkbox",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        formData.anwserOptions = [...newValues];
                      }

                      // formData.anwserOptions.sort();

                      if (isDelete) {
                        formData.anwserOptions = formData.anwserOptions.filter(
                          (item, i) => value[i] === item
                        );

                        if (formData.anwserOptions.length) {
                          this.setState({
                            currentQuestionForm: [
                              {
                                answerOptions: item,
                                notFormetedValue: value,
                                updated:
                                  this.state.oldAnswers !== value
                                    ? true
                                    : false,
                                hasAttachment: item.hasAttachment,
                                answerId: this.state.answerIdUpdate,
                                value: JSON.stringify(
                                  Object.values(formData.anwserOptions)
                                ),
                                type: "checkbox",
                                documentTokens:
                                  item.hasAttachment == true &&
                                  this.state.token.length
                                    ? this.state.token
                                    : [],
                              },
                            ],
                          });
                        } else {
                          this.setState({
                            currentQuestionForm: [],
                          });
                        }
                      }

                      this.setState({ formData: formData });
                    }}
                    stepOrder={item.stepId}
                    questionOrder={item.order}
                    value={item.answerOptions}
                    previousAnswers={item.previousAnswers}
                    formData={this.state.formData.anwserOptions}
                    title={item.wording}
                    stepName={item.stepName}
                  />
                </div>
                {item.hasAttachment === true && (
                  <div>
                    <FileAttachment
                      disabled={
                        token.length >= 2 || this.state.loading === true
                          ? true
                          : false
                      }
                      loading={this.state.loading}
                      questionId={item.id}
                      file={this.state.file}
                      name={this.state.name}
                      listItem={response}
                      removeFile={this.removeFile}
                      handleChange={this.handleChange}
                      uploadDocument={this.uploadDocument}
                      removeFileUploaded={this.removeFileUploaded}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (item.questionTypeId === 3) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { allQuestionsAwnsered, ListFiles } = this.state;
            let file = {};

            let previousText = [];

            allQuestionsAwnsered && allQuestionsAwnsered.length
              ? allQuestionsAwnsered.map((answer) => {
                  if (answer.questionId === item.questionId) {
                    previousText.push(answer.value);
                  }
                })
              : (previousText = []);

            item.previousAnswers &&
              item.previousAnswers.length &&
              item.previousAnswers.forEach((previous) => {
                previousText = previous;
              });

            ListFiles &&
              ListFiles.length &&
              ListFiles.forEach((itemFile, index) => {
                if (item.questionId == itemFile.questionId) {
                  file = {
                    response: [...ListFiles[index].responseImage],
                    token: [...ListFiles[index].token],
                    question: itemFile.questionId,
                  };
                }
              });

            let response = [];
            let token = [];

            if (file && file.response && file.response.length) {
              response = this.state.responseImage.length
                ? [...this.state.responseImage, ...file.response]
                : [...file.response];

              token = this.state.token.length
                ? [...this.state.token, ...file.token]
                : [...file.token];
            } else {
              response = this.state.responseImage.length
                ? [...this.state.responseImage]
                : [];
              token = this.state.token.length ? [...this.state.token] : [];
            }

            return (
              <div>
                <div>
                  <AnswerText
                    onChange={(value) => {
                      let valid = true;
                      let formData = this.state.formData;

                      if (value === "" || value.lenght > 200) valid = false;
                      if (valid) {
                        this.setState({
                          newAnswer:
                            this.state.oldAnswers !== value ? false : true,
                          currentQuestionForm: [
                            {
                              updated:
                                this.state.oldAnswers !== value ? true : false,
                              hasAttachment: item.hasAttachment,
                              type: "input",
                              answerId: this.state.answerIdUpdate,
                              value: value,
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        formData.answerText = value;
                      }

                      this.setState({ formData: formData });
                    }}
                    stepOrder={item.stepId}
                    questionOrder={item.order}
                    value={previousText}
                    title={item.wording}
                    stepName={item.stepName}
                  />
                </div>

                {item.hasAttachment === true && (
                  <div>
                    <FileAttachment
                      disabled={
                        token.length == 2 || this.state.loading === true
                          ? true
                          : false
                      }
                      loading={this.state.loading}
                      questionId={item.id}
                      file={this.state.file}
                      name={this.state.name}
                      listItem={response}
                      removeFile={this.removeFile}
                      handleChange={this.handleChange}
                      uploadDocument={this.uploadDocument}
                      removeFileUploaded={this.removeFileUploaded}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (item.questionTypeId === 4) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { allQuestionsAwnsered, ListFiles } = this.state;
            let file = {};

            let previousText = [];

            allQuestionsAwnsered && allQuestionsAwnsered.length
              ? allQuestionsAwnsered.map((answer) => {
                  if (answer.questionId === item.questionId) {
                    previousText.push(answer.value);
                  }
                })
              : (previousText = []);

            item.previousAnswers &&
              item.previousAnswers.length &&
              item.previousAnswers.forEach((previous) => {
                previousText = previous;
              });

            ListFiles &&
              ListFiles.length &&
              ListFiles.forEach((itemFile, index) => {
                if (item.questionId == itemFile.questionId) {
                  file = {
                    response: [...ListFiles[index].responseImage],
                    token: [...ListFiles[index].token],
                    question: itemFile.questionId,
                  };
                }
              });

            let response = [];
            let token = [];

            if (file && file.response && file.response.length) {
              response = this.state.responseImage.length
                ? [...this.state.responseImage, ...file.response]
                : [...file.response];

              token = this.state.token.length
                ? [...this.state.token, ...file.token]
                : [...file.token];
            } else {
              response = this.state.responseImage.length
                ? [...this.state.responseImage]
                : [];
              token = this.state.token.length ? [...this.state.token] : [];
            }

            return (
              <div>
                <div>
                  <AnswerTextArea
                    onChange={(value) => {
                      let valid = true;
                      let formData = this.state.formData;

                      if (value === "" || value.lenght > 200) valid = false;
                      if (valid) {
                        this.setState({
                          newAnswer:
                            this.state.oldAnswers !== value ? false : true,

                          currentQuestionForm: [
                            {
                              updated:
                                this.state.oldAnswers !== value ? true : false,

                              hasAttachment: item.hasAttachment,
                              answerId: this.state.answerIdUpdate,
                              value: value,
                              type: "input",
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        formData.answerTextArea = value;
                      }

                      this.setState({ formData: formData });
                    }}
                    stepOrder={item.stepId}
                    questionOrder={item.order}
                    value={previousText}
                    title={item.wording}
                    stepName={item.stepName}
                  />
                </div>

                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.id}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          if (item.questionTypeId === 5) {
            hasAttachment = item.hasAttachment;
            questionId = item.questionId;

            const { allQuestionsAwnsered, ListFiles } = this.state;
            let file = {};

            let previousNumber = [];

            allQuestionsAwnsered && allQuestionsAwnsered.length
              ? allQuestionsAwnsered.map((answer) => {
                  if (answer.questionId === item.questionId) {
                    previousNumber.push(answer.value);
                  }
                })
              : (previousNumber = []);

            item.previousAnswers &&
              item.previousAnswers.length &&
              item.previousAnswers.forEach((previous) => {
                previousNumber = previous;
              });

            ListFiles &&
              ListFiles.length &&
              ListFiles.forEach((itemFile, index) => {
                if (item.questionId == itemFile.questionId) {
                  file = {
                    response: [...ListFiles[index].responseImage],
                    token: [...ListFiles[index].token],
                    question: itemFile.questionId,
                  };
                }
              });

            let response = [];
            let token = [];

            if (file && file.response && file.response.length) {
              response = this.state.responseImage.length
                ? [...this.state.responseImage, ...file.response]
                : [...file.response];

              token = this.state.token.length
                ? [...this.state.token, ...file.token]
                : [...file.token];
            } else {
              response = this.state.responseImage.length
                ? [...this.state.responseImage]
                : [];
              token = this.state.token.length ? [...this.state.token] : [];
            }

            return (
              <div>
                <div>
                  <AnswerNumber
                    onChange={(value) => {
                      let valid = true;
                      let formData = this.state.formData;

                      if (value === "") valid = false;

                      if (valid) {
                        this.setState({
                          newAnswer:
                            this.state.oldAnswers !== value ? false : true,
                          currentQuestionForm: [
                            {
                              updated:
                                this.state.oldAnswers !== value ? true : false,
                              hasAttachment: item.hasAttachment,
                              answerId: this.state.answerIdUpdate,
                              type: "input",

                              value: value,
                              documentTokens:
                                item.hasAttachment == true &&
                                this.state.token.length
                                  ? this.state.token
                                  : [],
                            },
                          ],
                        });

                        formData.answerNumber = value;
                      }

                      this.setState({ formData: formData });
                    }}
                    stepOrder={item.stepId}
                    questionOrder={item.order}
                    value={previousNumber}
                    title={item.wording}
                    stepName={item.stepName}
                  />
                </div>
                <div>
                  {item.hasAttachment === true && (
                    <div>
                      <FileAttachment
                        disabled={
                          token.length == 2 || this.state.loading === true
                            ? true
                            : false
                        }
                        loading={this.state.loading}
                        questionId={item.id}
                        file={this.state.file}
                        name={this.state.name}
                        listItem={response}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        uploadDocument={this.uploadDocument}
                        removeFileUploaded={this.removeFileUploaded}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }

          formData.answer.push({ questionId: item.questionId });

          this.setState({ formData: formData });
        });
      }
    }

    let { newAnswer } = this.state;

    function disabledSubmitButton() {
      if (questionsAnswer.length && questionsAnswer[0].hasAttachment == true) {
        return false;
      } else {
        return newAnswer;
      }
    }

    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span className="float-lg-right x" onClick={this.closeModal}>
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.stringTable.standard.submitQuestionnaire}
          </h2>
          <div className="text-center">
            <button
              id="alertExclusion"
              className="alertExclusion btn btn-group darkButtom mr-4"
              onClick={() => {
                Answers.update(updateAnswers);

                this.setState((state) => ({
                  modalIsOpen: false,
                  waitingResponseSubmit: false,

                  message: {
                    ...state.message,
                    ...{
                      _defaultCloseAction: () => window.history.back(),
                      title: "Aviso",
                      visible: true,
                      text: (
                        <>
                          {this.stringTable.standard.apiSuccess}

                          <ul className="errorSumary">
                            <li>Alterações realizadas com sucesso.</li>
                          </ul>
                        </>
                      ),
                    },
                  },
                }));
              }}
            >
              Sim
            </button>
            <button
              className="btn btn-group mainColorButtom"
              onClick={this.closeModal}
            >
              Não
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            <button
              className="btn btn-group button-no"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            {!this.state.message.leftButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.leftButtomClickCallBack();
                }}
              >
                {this.state.message.leftButtomText}
              </button>
            )}
            {!this.state.message.rightButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.rightButtomClickCallBack();
                }}
              >
                {this.state.message.rightButtomText}
              </button>
            )}
          </div>
        </Modal>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">{"Questionário"}</h1>
              <small className="subtitle">
                {"Responda os dados para atualizar seu score."}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5 pb-5">
            <Col lg="12" xl="12">
              <div className="">
                <Wizard nodes={wizzardNodeConfiguration} />
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pb-4 pl-2">
            <Col lg="12" xl="12">
              <div className="">
                <h2 className="text-dark">
                  {
                    <OrderAndProgress
                      currentStep={this.state.currentSubStep}
                      steps={this.state.filteredQuestions.length}
                    ></OrderAndProgress>
                  }
                </h2>
              </div>
            </Col>
          </Row>

          {questionsAnswer.length &&
          questionsAnswer[0].hasAttachment == true ? (
            <Row lg="12" className="pb-6 pl-5 text-center">
              <Col lg="12" xl="12">
                <p className="subtitle">
                  Você está enviando uma nova resposta. Lembre-se de reenviar os
                  anexos, caso estes existam.
                </p>
              </Col>
            </Row>
          ) : null}

          <Row lg="12" className="pb-2 pl-5">
            <Col lg="12" className="">
              <div className="form-group">
                {renderQuestion.length ? renderQuestion : null}
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group float-right">
                <button
                  disabled={disabledSubmitButton() || this.state.loading}
                  className="mainColorButtom"
                  onClick={() => this.setState({ modalIsOpen: true })}
                >
                  {"Enviar"}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);

    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 3) {
        if (
          !isNaN(parseInt(urlValues[0])) &&
          !isNaN(parseInt(urlValues[1])) &&
          !isNaN(parseInt(urlValues[2]))
        ) {
          this.setState({
            questionIdUpdate: parseInt(urlValues[0]),
            answerIdUpdate: parseInt(urlValues[1]),
            sifId: parseInt(urlValues[2]),
          });
          valid = true;
        }
      }
    }

    if (!valid) {
      window.history.back();
    }
  }

  componentDidMount() {
    this.getStep();

    setTimeout(() => {
      this.getQuestionUpdate();
    }, 200);
    // this.getAnwserUpdate();
    this.getResponseImage();
  }

  onInputChange(event) {
    const target = event.target;
    let name = "unknown";
    let value = "";
    let formData = this.state.formData;

    if (target.id) name = target.id;

    value = target.value;

    if (
      name === "questionTypeId" ||
      name === "hasAttachment" ||
      name === "hasValidation" ||
      name === "hasArtificialIntelligenceRecognition"
    ) {
      if (!isNaN(parseInt(value))) value = parseInt(value);
    }

    if (name === "questionTypeId") formData.anwserOptions = [];

    this.setState({ formData: { ...formData, ...{ [name]: value } } });
  }

  uploadDocument(questionId) {
    this.setState({ loading: true });
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Document.Document;

    const dataForm = new FormData();

    dataForm.append("SifId", this.state.sifId);
    dataForm.append("QuestionId", questionId);
    dataForm.append("File", this.state.file);

    const data = dataForm;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ loading: false });

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState({ loading: false });

          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          this.setState({ loading: false });
          return;
        }

        this.setState({
          token: [...this.state.token, result.response.token],
          responseImage: [...this.state.responseImage, result.response],
          file: null,
          loading: false,
        });
      },
      (error, httpCode) => {
        this.setState({
          loading: false,
          file: null,
        });
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  removeFile() {
    document.getElementById("button-file").value = "";

    this.setState({
      file: "",
    });
  }

  removeFileUploaded(token) {
    const { responseImage } = this.state;
    if (responseImage.length) {
      const files = responseImage.filter((item) => item.token !== token);

      const tokens = files.map((item) => item.token);

      this.setState({ responseImage: files, token: tokens });
      return;
    }
  }

  handleChange(event) {
    if (!event.target.files.length) return;

    const file = event.target.files[0];
    let { type, size } = file;

    if (size > 5242880) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.limiteSize}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    type = type.replace("image/", "");

    type = type.replace("application/", "");

    const foundType = this.state.extensions.find((extension) =>
      extension == type ? true : false
    );

    if (this.state.totalFile.length > 2) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.limiteFile}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    if (!foundType) {
      this.setState((state) => ({
        waitingResponseSubmit: false,
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                {this.stringTable.standard.apiError}

                <ul className="errorSumary">
                  <li>{this.stringTable.fileAttachment.invalidFormat}</li>
                  <li>{this.stringTable.fileAttachment.validFormat}</li>
                </ul>
              </>
            ),
          },
        },
      }));
      return;
    }

    this.setState({
      file: event.target.files[0],
      name: event.target.files[0].name,
    });
  }

  getColorForStepId(stepId) {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    switch (stepId) {
      case 1:
        if (this.state.currentStep === 1) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 1 &&
          this.state.answerOne.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }

      case 2:
        if (this.state.currentStep === 2) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 2 &&
          this.state.answerTwo.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 3:
        if (this.state.currentStep === 3) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 3 &&
          this.state.answerThree.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 4:
        if (this.state.currentStep === 4) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 4 &&
          this.state.answerFour.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      case 5:
        if (this.state.currentStep === 5) {
          return wizzardConfiguration.Status.Active;
        } else if (
          this.state.currentStep !== 5 &&
          this.state.answerFive.length
        ) {
          return wizzardConfiguration.Status.Completed;
        } else {
          return wizzardConfiguration.Status.Disabled;
        }
      default:
        return wizzardConfiguration.Status.Disabled;
    }
  }

  getStep() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Step.Find;
    const data = {
      name: null,
    };

    smoothApi.post(url, data, token, (result) => {
      if (
        !result ||
        !result.systemErrors ||
        result.success == null ||
        !result.validationErrors
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        return;
      }

      if (!result.success) {
        console.error(messages.Errors.ResponseError, result.errors);
        return;
      }

      if (result.response == null) {
        console.error(messages.Errors.BlankResponse, result.errors);
        return;
      }

      this.setState({ stepNames: result.response });
    });
  }

  getQuestionUpdate() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Question.GetQuestion +
      "/" +
      this.state.questionIdUpdate;
    const data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        this.getAnwserUpdate([result.response]);
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getAnwserUpdate(questionsResponse = []) {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnswerGet +
      "/" +
      this.state.answerIdUpdate;
    const data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({ questionsResponse });
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          this.setState({ questionsResponse });
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          this.setState({ questionsResponse });

          return;
        }

        const { questionId, value } = result.response;

        let Store = Answers.currentState;

        Store.Answers && Store.Answers.length
          ? Store.Answers.forEach((_, indexStore) => {
              const filterdAwnsers = Store.Answers.filter(
                (itemStore) => itemStore.answerId == this.state.answerIdUpdate
              );

              if (filterdAwnsers.length) {
                questionsResponse.forEach((question, index) => {
                  if (
                    questionsResponse[index].id == this.state.questionIdUpdate
                  ) {
                    questionsResponse[index].previousAnswers =
                      filterdAwnsers[0].value.split(",");
                  }
                });
              }

              if (!filterdAwnsers.length) {
                questionsResponse.forEach((_, index) => {
                  if (questionsResponse[index].id == questionId) {
                    questionsResponse[index].previousAnswers = value.split(",");
                    return;
                  }
                  return;
                });
              }
            })
          : questionsResponse.forEach((_, index) => {
              if (questionsResponse[index].id == questionId) {
                questionsResponse[index].previousAnswers = value.split(",");
              }
              return;
            });

        const stepsIds = questionsResponse.map((item) => item.stepId);
        const min = Math.min(...stepsIds);

        this.setState({
          oldAnswers: value,
        });

        this.setState({
          questionsResponse,
          currentStep: min,
          initialStep: min,
          filteredQuestions: questionsResponse,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getResponseImage() {
    const Store = Answers.currentState;

    let newList = [];
    let oldTokens = [];

    if (Store.responseImage.length) {
      Store.responseImage.forEach((_, index) => {
        if (Store.responseImage[index].answerId == this.state.answerIdUpdate) {
          if (Store.responseImage[index].token.length) {
            newList.push(Store.responseImage[index]);
            oldTokens.push(Store.responseImage[index].token);
          }
        }
      });
    } else {
      newList = [];
      oldTokens = [];
    }
    this.setState({ responseImage: newList, token: oldTokens });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
}

export default SifUpdateAnswere;
