import React from "react";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";
import template from "template.js";
import messages from "variables/messages";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class SelectPeriod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundId: -1,
      formData: {},
      answeredQuestionnaireResponse: null,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    let questionnaireTypeItems = [];

    if (this.state.answeredQuestionnaireResponse) {
      if (this.state.answeredQuestionnaireResponse.length > 0) {
        try {
          questionnaireTypeItems = this.state.answeredQuestionnaireResponse.map(
            (item) => {
              let order = "";

              order = "Q" + item.order;

              return (
                <>
                  <Row lg="12" className="pb-2 pl-6 questionnaire">
                    <Col lg="12">
                      <div>
                        <span className="q_avatar-question-type font-weight-600">
                          {order}
                        </span>
                        <span className="ml-4 text-dark">{item.name}</span>
                        <div className="float-right pr-6">
                          <Link
                            to={`/admin/Questionnaires/AnswerOptions/?id=${this.state.roundId}-${item.id}-${item.order}`}
                          >
                            <button className="btn btn-sm lightGreen">
                              Visualizar
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row lg="12" className="">
                    <div className="q_separator-question">
                      <hr />
                    </div>
                  </Row>
                </>
              );
            }
          );
        } catch (ex) {
          questionnaireTypeItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    //#endregion

    return (
      <>
        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {
                    this.stringTable.questionnaire.questionnaireType
                      .titleTypeDefault
                  }
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.state.formData["sequenceId"]}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.questionnaire.questionnaireType.subtitleType}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5 pb-5">
            <Col lg="12" xl="12">
              <div className="">
                <h2 className="text-dark">
                  {this.stringTable.questionnaire.questionnaireType.titleList}
                </h2>
              </div>
            </Col>
          </Row>

          {questionnaireTypeItems.length === 0 || questionnaireTypeItems}

          <div className="mt-5 mb-7">
            <button
              type="button"
              className="lightButtom m-auto d-block"
              onClick={() => (window.location.href = "/admin/Questionnaires")}
            >
              {this.stringTable.standard.backButton}
            </button>
          </div>
        </Container>
      </>
    );
  }

  //#region Component Events

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ roundId: id });
  }

  loadSelects() {
    this.getRoundId();
    this.getQuestionnaireType();
  }

  componentDidMount() {
    this.loadSelects();
  }

  getQuestionnaireType() {
    let dataAPI = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.QuestionnaireType.Find;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ answeredQuestionnaireResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getRoundId() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.Get +
      "/" +
      this.state.roundId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;

        ret.sequenceId = "R0" + ret.sequence;

        this.setState({ formData: ret });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  //#endregion

  //#region Events

  //#endregion

  //#region Custom Events

  //#endregion
}

export default SelectPeriod;
