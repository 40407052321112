import React from "react";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import Loading from "components/Utils/Loading.jsx";
import EvaluationAnswered from "components/Utils/EvaluationAnswered.jsx";

import { Container, Row, Col } from "reactstrap";

class AnswerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundId: -1,
      questionnaireTypeId: -1,
      sifId: -1,

      submitLoading: false,
      submitSendEmail: false,
      showSendEmailSuccess: false,

      formData: {},
      answeredQuestionnaireResponse: null,
      mirror: [],

      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    let answeredQuestionnaireItems = [];
    let stepName = "";
    let foodSafety = "Segurança de alimentos";

    let isReadOnly = this.state.formData.isReadOnly;
    let allApprovedQuestions = [];
    let totalQuestions = [];

    if (this.state.mirror) {
      if (this.state.mirror.length > 0) {
        totalQuestions.push(this.state.mirror.length);
        try {
          answeredQuestionnaireItems = this.state.mirror.map((item, index) => {
            let mirror = this.state.mirror;

            if (mirror[index].isApproved == true) {
              allApprovedQuestions.push(mirror[index].isApproved);
            }

            if (mirror[index].stepName === foodSafety) {
              if (mirror[index].substepName != null) {
                mirror[index].stepName =
                  mirror[index].stepName + " - " + mirror[index].substepName;
              }
            }

            if (mirror[index].stepName === stepName)
              mirror[index].stepName = "";
            else stepName = mirror[index].stepName;

            return (
              <>
                <EvaluationAnswered
                  onEditHandler={() => {
                    if (!mirror[index].tempIsApproved === true && !isReadOnly) {
                      mirror[index].edit = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onAproveHandler={() => {
                    if (!mirror[index].tempIsApproved === true && !isReadOnly) {
                      mirror[index].tempIsApproved = true;
                      mirror[index].edit = true;
                      mirror[index].valid = true;
                      mirror[index].isApproved = true;

                      mirror[index].wasAnalyzed = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onSaveHandler={(reason) => {
                    if (!mirror[index].tempIsApproved === true && !isReadOnly) {
                      let valid = true;
                      let wasAnalyzed = true;
                      let errorValidation = "";

                      if (
                        reason === "" ||
                        reason.length < 5 ||
                        reason.length > 200
                      ) {
                        if (reason.length < 5)
                          errorValidation = "Digite pelo menos 5 caracteres.";

                        if (reason === "")
                          errorValidation = "O campo está vazio.";

                        valid = false;
                        wasAnalyzed = false;
                      } else {
                        mirror[index].tempIsApproved = false;

                        if (mirror[index].returnReason)
                          mirror[index].returnReason.reason = reason;
                        else {
                          mirror[index].returnReason = {};
                          mirror[index].returnReason.reason = reason;
                        }
                      }

                      mirror[index].valid = valid;
                      mirror[index].wasAnalyzed = wasAnalyzed;
                      mirror[index].edit = false;
                      mirror[index].errorValidation = errorValidation;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onCancelHandler={() => {
                    if (!mirror[index].tempIsApproved === true && !isReadOnly) {
                      mirror[index].edit = false;
                      mirror[index].valid = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onUndoHandler={() => {
                    if (mirror[index].tempIsApproved !== null) {
                      if (mirror[index].tempIsApproved === false)
                        mirror[index].returnReason.reason = null;

                      mirror[index].tempIsApproved = null;
                      mirror[index].edit = false;
                      mirror[index].wasAnalyzed = false;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onChange={(value) => {
                    let valid = true;

                    if (value === "" || value.lenght > 200) valid = false;

                    mirror[index].valid = valid;
                    mirror[index].edit = true;

                    this.setState({ mirror: mirror });
                  }}
                  attachedFileUrl={mirror[index].documents}
                  edit={mirror[index].edit}
                  valid={mirror[index].valid}
                  value={mirror[index]}
                  approved={mirror[index].tempIsApproved}
                  isArtificialIntelligence={
                    mirror[index].isRecognizedByArtificialIntelligence
                  }
                  hasValidation={mirror[index].hasValidation}
                  isReadOnly={mirror[index].isReadOnly}
                />
                <Row lg="12" className="questionnaire">
                  <div className="q_separator-question">
                    <hr />
                  </div>
                </Row>
              </>
            );
          });
        } catch (ex) {
          answeredQuestionnaireItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    function VerifyAnalyze() {
      if (totalQuestions == allApprovedQuestions.length) {
        return true;
      } else {
        return false;
      }
    }

    return (
      <>
        <div className="ReactModal__Overlay">
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </div>

        <Container fluid className="minHeightFooter questionnaire bg-body mb-8">
          <Row className="pt-6 pl-6">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {
                    this.stringTable.questionnaire.questionnaireType
                      .titleTypeDefault
                  }
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.state.formData["roundSequence"]} -{" "}
                  {this.state.formData["sifNumber"]}
                </h1>
              )}
              <small className="subtitle">
                {
                  this.stringTable.questionnaire.answeredQuestionnaire
                    .answerList.newSubtitleAnswer
                }
              </small>
            </Col>

            <Col lg="6">
              <Link
                to={
                  "/admin/Questionnaires/answeredQuestionnaire/questionnaireListing/?id=" +
                  this.state.roundId
                }
                className="float-right"
              >
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row lg="12" className="pt-8 pl-6 pb-2">
            <Col lg="6" xl="6">
              <div className="">
                <h3 className="text-dark">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.typeQuestionnaire
                  }
                </h3>
              </div>
              <div className="pt-2">
                <h3 className="text-dark font-weight-normal">
                  {this.state.formData["questionnaireTypeName"]}
                </h3>
              </div>
            </Col>
            {isReadOnly && !VerifyAnalyze() ? (
              <Col lg="6" xl="6">
                <div className=" float-right">
                  <h3 className="font-weight-bold q_text-red">
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .answerList.waitingValidation
                    }
                  </h3>
                </div>
              </Col>
            ) : (
              <> </>
            )}
          </Row>
          <Row lg="12" className="">
            <div className="q_separator-question">
              <hr />
            </div>
          </Row>

          {answeredQuestionnaireItems.length === 0 ||
            answeredQuestionnaireItems}

          <div className="container-fluid mb-4 mt-6">
            <Row className="justify-content-center">
              {this.state.formData.CanSendReviewEmail === true && (
                <button
                  className="darkButtom"
                  onClick={() => {
                    this.sendAvaliationEmail();
                  }}
                >
                  {this.state.submitSendEmail ? (
                    <Loading load="Sim" />
                  ) : (
                    "Reenviar e-mail de avaliação"
                  )}
                </button>
              )}
              <button
                disabled={isReadOnly === true ? true : false}
                className="mainColorButtom ml-4"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                {this.state.submitLoading ? <Loading load="Sim" /> : "Salvar"}
              </button>
            </Row>
          </div>

          {!this.state.showRegisterSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.standard.registerSuccess}
                </span>
              </div>
            </Row>
          )}

          {!this.state.showSendEmailSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.sendEmailSuccess
                  }
                </span>
              </div>
            </Row>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 3) {
        if (
          !isNaN(parseInt(urlValues[0])) &&
          !isNaN(parseInt(urlValues[1])) &&
          !isNaN(parseInt(urlValues[2]))
        ) {
          this.setState({
            roundId: parseInt(urlValues[0]),
            questionnaireTypeId: parseInt(urlValues[1]),
            sifId: parseInt(urlValues[2]),
          });
          valid = true;
        }
      }
    }

    if (!valid) window.location.href = "/admin/Questionnaires";
  }

  sendAvaliationEmail() {
    this.setState({ submitSendEmail: true });

    let dataAPI = {
      roundId: this.state.roundId,
      questionnaireTypeId: this.state.questionnaireTypeId,
      sifId: this.state.sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.SendReviewEmail;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        this.setState({ showSendEmailSuccess: true, submitSendEmail: false });
        this.timeOutSubmit();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.getAnsweredQuestionnaire();
  }

  componentDidMount() {
    this.loadSelects();
  }

  getAnsweredQuestionnaire() {
    let dataAPI = {
      roundId: this.state.roundId,
      questionnaireTypeId: this.state.questionnaireTypeId,
      sifId: this.state.sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.ReviewFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let formData = this.state.formData;
        let mirror = this.state.mirror;

        function dateFormat(date) {
          let day = "";
          let month = "";
          let year = "";
          let dateFormated = "";

          day = new Date(date).getDate().toString();
          month = (new Date(date).getMonth() + 1).toString();
          year = new Date(date).getFullYear().toString();

          dateFormated =
            (day.toString().length === 1 ? "0" + day : day) +
            "/" +
            (month.toString().length === 1 ? "0" + month : month) +
            "/" +
            (year.toString().length === 1 ? "0" + year : year);

          return dateFormated;
        }

        formData.currentScore = ret.currentScore;
        formData.questionnaireTypeName = ret.questionnaireTypeName;
        formData.roundSequence = "R0" + ret.roundSequence;
        formData.roundStatusId = ret.roundStatusId;
        formData.sifNumber = ret.sifNumber;
        formData.CanSendReviewEmail = ret.canSendReviewEmail;
        formData.isReadOnly = ret.isReadOnly;

        if (ret.answers) {
          mirror = ret.answers.map((item, index) => {
            if (item.isApproved === true) item.tempIsApproved = true;
            else if (item.isApproved === false) item.tempIsApproved = false;
            else item.tempIsApproved = null;

            if (item.returnReason) {
              item.returnReason.backReason = item.returnReason.reason;
              item.returnReason.insertedDate = dateFormat(
                item.returnReason.insertedDate
              );

              if (item.returnReason.justification)
                item.returnReason.justification.insertedDate = dateFormat(
                  item.returnReason.justification.insertedDate
                );
            }

            item.edit = false;
            item.valid = true;
            item.wasAnalyzed = false;
            item.isReadOnly = ret.isReadOnly;

            return item;
          });
        }

        this.setState({ answeredQuestionnaireResponse: ret, mirror: mirror });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  handleSubmit() {
    this.setState({ submitLoading: true });

    let mirror = this.state.mirror;
    let formData = this.state.formData;
    let review = {};

    let allApproved = true;
    let allAnalyzed = true;

    let validations = {};
    validations.sucess = true;
    validations.errors = [];

    review.roundId = this.state.roundId;
    review.questionnaireTypeId = this.state.questionnaireTypeId;
    review.sifId = this.state.sifId;
    review.answerReviews = [];

    review.answerReviews = mirror.map((item) => {
      let answerReviews = {};

      answerReviews.answerId = item.answerId;
      answerReviews.isApproved = item.tempIsApproved;
      answerReviews.returnReason = null;

      if (!item.tempIsApproved) {
        if (item.returnReason) {
          if (item.returnReason.reason) {
            answerReviews.returnReason = item.returnReason.reason;
          }
        }
      }

      if (!item.wasAnalyzed) {
        allAnalyzed = false;
      }

      if (item.wasAnalyzed) {
        allAnalyzed = true;
      }

      if (!item.isApproved) {
        allApproved = false;
      }

      if (item.isApproved) {
        allApproved = true;
      }

      return item.wasAnalyzed === true ? answerReviews : null;
    });

    review.answerReviews = review.answerReviews.filter((item) => {
      return item !== null;
    });

    if (formData.CanSendReviewEmail && !review.answerReviews.length > 0) {
      if (allApproved) {
        validations.sucess = false;
        validations.errors.push("Todas as respostas já foram análisadas.");
      } else {
        validations.sucess = false;
        validations.errors.push(
          "É necessário realizar a análise de todas as respostas pendentes."
        );
      }
    }

    if (!formData.CanSendReviewEmail && !allAnalyzed && !allApproved) {
      validations.sucess = false;
      validations.errors.push(
        "É necessário realizar a análise de todas as respostas pendentes."
      );
    }

    if (!validations.sucess) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                Por favor verifique os campos destacados em{" "}
                <span className="errorText"> vermelho </span> para prosseguir:
                <ul className="errorSumary">
                  {validations.errors.map((el, currentIndex) => {
                    return <li key={"validationItem_" + currentIndex}>{el}</li>;
                  })}
                </ul>
              </>
            ),
          },
        },
        submitLoading: false,
      }));

      return;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Answer.Put;
    let data = review;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            showRegisterSuccess: false,
          }));

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            submitLoading: false,
            showRegisterSuccess: false,
          }));

          return;
        }

        this.setState((state) => ({
          message: {
            ...state.message,
            ...{
              title: "Aviso",
              visible: true,
              _defaultCloseAction: () => {
                window.history.back();
              },
              text: (
                <>
                  Concluído com sucesso:
                  <ul className="errorSumary">
                    <li>
                      Análise realizada com sucesso, os usuários do SIF
                      receberão e-mail com o status da análise.
                    </li>
                  </ul>
                </>
              ),
            },
          },
          submitLoading: false,
          showRegisterSuccess: false,
        }));

        // this.setState({
        //   // submitLoading: false,
        //   // showRegisterSuccess: true,
        //   // messageSubmit: "salvo com sucesso ",
        // });
        // // this.timeOutSubmit();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          submitLoading: false,
          showRegisterSuccess: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });
      }.bind(this),
      4000
    );
  }
}

export default AnswerList;
