import React from "react";
import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";

import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class SelectPeriod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundId: -1,
      formData: {},
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    return (
      <>
        <Container fluid className="minHeightFooter questionnaire bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {
                    this.stringTable.questionnaire.selectPeriod
                      .titlePeriodDefault
                  }
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.state.formData["sequenceId"]}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.questionnaire.selectPeriod.subtitlePeriod}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="pt-6 pl-5 pb-9">
            <div>
              <Link
                to={`/admin/Questionnaires/roundRegistration/?id=${this.state.roundId}`}
              >
                <div className="q_card-period ">
                  <div className="justify-content-center q_pt-card d-flex">
                    <h3 className="text-white">
                      {
                        this.stringTable.questionnaire.selectPeriod
                          .roundInformation
                      }
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="ml-5">
              <Link
                to={`/admin/Questionnaires/answeredQuestionnaire/questionnaireListing/?id=${this.state.roundId}`}
              >
                <div className="q_card-period">
                  <div className="justify-content-center q_pt-card d-flex">
                    <h3 className="text-white">
                      {
                        this.stringTable.questionnaire.selectPeriod
                          .questionnairesAnswered
                      }
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
          </Row>
          <div className="container-fluid mb-4 mt-9 ml-4">
            <Link to={"/admin/Questionnaires"} className="mr-5">
              <button type="button" className="lightButtom">
                {this.stringTable.standard.backButton}
              </button>
            </Link>
          </div>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ roundId: id });
  }

  componentDidMount() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.Get +
      "/" +
      this.state.roundId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;

        ret.sequenceId = "R0" + ret.sequence;

        this.setState({ formData: ret });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default SelectPeriod;
