import React from "react";

import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import SifEvaluationAnswered from "components/Utils/SifEvaluationAnswered.jsx";

import { Container, Row, Col } from "reactstrap";

class AnswerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundId: -1,
      questionnaireTypeId: -1,
      sifId: -1,

      modalIsOpen: false,

      submitLoading: false,
      updatedLoading: false,
      submitSendEmail: false,
      showSendEmailSuccess: false,

      formData: {},
      answeredQuestionnaireResponse: null,
      mirror: [],

      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    let answeredQuestionnaireItems = [];
    let stepName = "";

    let foodSafety = "Segurança de alimentos";

    if (this.state.mirror) {
      if (this.state.mirror.length > 0) {
        try {
          answeredQuestionnaireItems = this.state.mirror.map((item, index) => {
            let mirror = this.state.mirror;
            let formData = this.state.formData;

            if (mirror[index].stepName === foodSafety) {
              mirror[index].stepName =
                mirror[index].stepName + " - " + mirror[index].substepName;
            }

            if (mirror[index].stepName === stepName)
              mirror[index].stepName = "";
            else stepName = mirror[index].stepName;

            return (
              <>
                <SifEvaluationAnswered
                  disabled={mirror[index].isReadOnly}
                  onSaveHandler={(reason) => {
                    if (mirror[index].isApproved === false) {
                      let valid = true;
                      let errorValidation = "";

                      if (
                        reason === "" ||
                        reason.length < 5 ||
                        reason.length > 200
                      ) {
                        if (reason.length < 5)
                          errorValidation = "Digite pelo menos 5 caracteres.";

                        if (reason === "")
                          errorValidation = "O campo está vazio.";

                        valid = false;
                      } else {
                        mirror[index].isApproved = false;
                        mirror[index].newSifJustify = true;

                        if (mirror[index].returnReason.justification) {
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            formData.todayDate;
                          mirror[
                            index
                          ].returnReason.justification.description = reason;
                        } else {
                          mirror[index].returnReason.justification = {};
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            formData.todayDate;
                          mirror[
                            index
                          ].returnReason.justification.description = reason;
                        }
                      }

                      mirror[index].valid = valid;
                      mirror[index].errorValidation = errorValidation;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onSeeAnalisys={() => {
                    if (mirror[index].isApproved === false) {
                      mirror[index].edit = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onCloseHandler={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].edit = false;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onCancelHandler={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].keepAnswer = false;

                      if (mirror[index].newSifJustify === true) {
                        if (mirror[index].returnReason.justification) {
                          mirror[index].returnReason.justification.description =
                            mirror[
                              index
                            ].returnReason.justification.backDescription;
                          mirror[
                            index
                          ].returnReason.justification.insertedDate =
                            mirror[
                              index
                            ].returnReason.justification.backInsertedDate;
                        }
                        mirror[index].newSifJustify = false;
                        mirror[index].edit = false;
                      }

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onKeepAnswer={() => {
                    if (
                      mirror[index].isApproved === false &&
                      mirror[index].edit === true
                    ) {
                      mirror[index].keepAnswer = true;

                      this.setState({ mirror: mirror });
                    }
                  }}
                  onChangeAnswer={() => {}}
                  onChange={(value) => {
                    let valid = true;

                    if (value === "" || value.lenght > 200) valid = false;

                    mirror[index].valid = valid;
                    mirror[index].edit = true;

                    this.setState({ mirror: mirror });
                  }}
                  attachedFileUrl={mirror[index].documents}
                  isArtificialIntelligence={
                    mirror[index].isRecognizedByArtificialIntelligence
                  }
                  edit={mirror[index].edit}
                  valid={mirror[index].valid}
                  value={mirror[index]}
                  approved={mirror[index].isApproved}
                  hasValidation={mirror[index].hasValidation}
                  questionId={mirror[index].questionId}
                  answerId={mirror[index].answerId}
                  disableKeepAnswer={mirror[index].disableKeepAnswer}
                  sifId={this.state.sifId}
                />
                <Row lg="12" className="questionnaire">
                  <div className="q_separator-question">
                    <hr />
                  </div>
                </Row>
              </>
            );
          });
        } catch (ex) {
          answeredQuestionnaireItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <Container fluid className="minHeightFooter questionnaire bg-body mb-8">
          <Row className="pt-6 pl-6">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {
                    this.stringTable.questionnaire.questionnaireType
                      .titleTypeDefault
                  }
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.state.formData["roundSequence"]} -{" "}
                  {this.state.formData["sifNumber"]}
                </h1>
              )}
              <small className="subtitle">
                {
                  this.stringTable.questionnaireSif.sifAnswerList
                    .newSubtitleAnswer
                }
              </small>
            </Col>
            <Col lg="6">
              <Link
                to={
                  "/industry/Questionnaires/answeredQuestionnaire/questionnaireListing/?id=" +
                  this.state.sifId
                }
                className="float-right"
              >
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Row lg="12" className="pt-8 pl-6 pb-2">
            <Col lg="12" xl="12">
              <div className="">
                <h3 className="text-dark">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .answerList.typeQuestionnaire
                  }
                </h3>
              </div>
              <div className="pt-2">
                <h3 className="text-dark font-weight-normal">
                  {this.state.formData["questionnaireTypeName"]}
                </h3>
              </div>
            </Col>
          </Row>
          <Row lg="12" className="">
            <div className="q_separator-question">
              <hr />
            </div>
          </Row>

          {answeredQuestionnaireItems.length === 0 ||
            answeredQuestionnaireItems}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 3) {
        if (
          !isNaN(parseInt(urlValues[0])) &&
          !isNaN(parseInt(urlValues[1])) &&
          !isNaN(parseInt(urlValues[2]))
        ) {
          this.setState({
            roundId: parseInt(urlValues[0]),
            questionnaireTypeId: parseInt(urlValues[1]),
            sifId: parseInt(urlValues[2]),
          });
          valid = true;
        }
      }
    }

    if (!valid) window.location.href = "/industry/Questionnaires";
  }

  loadSelects() {
    this.getAnsweredQuestionnaire();
  }

  componentDidMount() {
    this.loadSelects();
  }

  getAnsweredQuestionnaire() {
    let dataAPI = {
      roundId: this.state.roundId,
      questionnaireTypeId: this.state.questionnaireTypeId,
      sifId: this.state.sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.ReviewFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let formData = this.state.formData;
        let mirror = this.state.mirror;

        function dateFormat(date) {
          let day = "";
          let month = "";
          let year = "";
          let dateFormated = "";

          day = new Date(date).getDate().toString();
          month = (new Date(date).getMonth() + 1).toString();
          year = new Date(date).getFullYear().toString();

          dateFormated =
            (day.toString().length === 1 ? "0" + day : day) +
            "/" +
            (month.toString().length === 1 ? "0" + month : month) +
            "/" +
            (year.toString().length === 1 ? "0" + year : year);

          return dateFormated;
        }

        formData.todayDate = dateFormat(new Date());
        formData.currentScore = ret.currentScore;
        formData.questionnaireTypeName = ret.questionnaireTypeName;
        formData.roundSequence = "R0" + ret.roundSequence;
        formData.roundStatusId = ret.roundStatusId;
        formData.sifNumber = ret.sifNumber;
        formData.hasReview = ret.hasReview;

        if (ret.answers) {
          mirror = ret.answers.map((item, index) => {
            let sifJustify = false;

            if (item.returnReason) {
              item.returnReason.insertedDate = dateFormat(
                item.returnReason.insertedDate
              );

              if (item.returnReason.justification) {
                sifJustify = true;
                item.returnReason.justification.backDescription =
                  item.returnReason.justification.description;
                item.returnReason.justification.backInsertedDate = dateFormat(
                  item.returnReason.justification.insertedDate
                );
                item.returnReason.justification.insertedDate = dateFormat(
                  item.returnReason.justification.insertedDate
                );
              }
            }

            item.valid = true;
            item.keepAnswer = false;
            item.edit = false;
            item.newSifJustify = false;
            item.sifJustify = sifJustify;
            item.isReadOnly = ret.isReadOnly;
            item.disableKeepAnswer = false;

            return item;
          });
        }

        this.setState({ answeredQuestionnaireResponse: ret, mirror: mirror });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default AnswerList;
