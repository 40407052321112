//#region imports

import React from "react";
import constants from "variables/constants";
import axios from "axios";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";
import template from "template.js";
import Loading from "components/Utils/Loading.jsx";
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import { Redirect } from "react-router-dom";

//#endregion

class LoginAlteraSenha extends React.Component {
  //#region Constructor

  constructor(props) {
    super(props);

    this.state = {
      form: {
        password: "",
        passwordConfirm: "",
        setSuccessConfirm: false,
        erroSenha: false,
      },
      openConfirm: false,
      errorMessageLogin: "",
      loadingLogin: false,
      redirectLogin: false,
      error: "",
      timer: null,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.changePassword = this.changePassword.bind(this);
    this.inputsForm = this.inputsForm.bind(this);
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img alt="Logo" src={this.stringTable.standard.logo} />
            </div>
            <Form role="form" onSubmit={this.changePassword}>
              <Row className="pb-4">
                <Col xs="12" className="text-dark text-center">
                  <small className="ft-16">
                    {this.stringTable.changePassword.welcome}
                  </small>
                </Col>
                <Col xs="12" className="text-dark text-center">
                  <small className="ft-16">
                    {this.stringTable.changePassword.registerUrPassword}
                  </small>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-input text-dark"
                    placeholder={this.stringTable.changePassword.inputPassword}
                    type="password"
                    name="password"
                    value={this.state.form.password}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3 roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-input text-dark"
                    placeholder={this.stringTable.changePassword.inputConfirm}
                    type="password"
                    name="passwordConfirm"
                    value={this.state.form.passwordConfirm}
                    onChange={this.inputsForm}
                  />
                </InputGroup>

                {this.state.setSuccessConfirm || (
                  <DialogConfirm
                    open={this.state.openConfirm}
                    title={" "}
                    text={this.state.errorMessageLogin}
                    handleClose={() => {
                      this.setState({ openConfirm: false });
                    }}
                  />
                )}
                {!this.state.redirectLogin || <Redirect to={"/admin/index"} />}
              </FormGroup>
              {this.state.setSuccessConfirm ? (
                <section className="container">
                  <Row>
                    <Col col="md-12">
                      <div
                        id="msgExclusion"
                        className={
                          " " +
                          (this.state.isVisibleDeleteMsg
                            ? "msgVisibleExclusion"
                            : "")
                        }
                      >
                        <DialogConfirm
                          open={this.state.setSuccessConfirm}
                          title={" "}
                          success={true}
                          text={this.stringTable.login.message.passwordChanged}
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <></>
              )}
              <div className="text-center">
                <Button className="my-4 darkButtom" type="submit">
                  {this.state.loadingLogin ? (
                    <Loading load={true} />
                  ) : (
                    this.stringTable.changePassword.buttomAlter
                  )}
                </Button>
              </div>
            </Form>
          </Container>
        </Col>
      </>
    );
  }

  changePassword(e) {
    e.preventDefault();

    this.setState({
      setSuccessConfirm: false,
      redirectLogin: false,
      openConfirm: false,
      errorMessageEmail: "",
    });

    const { password, passwordConfirm } = this.state.form;

    if (password && passwordConfirm) {
      if (password == passwordConfirm) {
        this.setState({ loadingLogin: true });

        const validationPassowrd = password.replace(
          /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{6,15}$/,
          ""
        );

        if (!!validationPassowrd === false) {
          const url =
            process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Auth.ResetPassword;
          let paramsUrl = new URLSearchParams(
            document.location.search.substring(1)
          );

          const token = paramsUrl.get("token").toString();

          let data = {
            newPassword: password,
            passwordResetCode: token,
          };

          axios
            .post(url, data)
            .then((response) => {
              if (
                !response ||
                !response.data ||
                !response.data.errors ||
                response.data.success == null
              ) {
                this.setState({
                  openConfirm: true,
                  loadingLogin: false,
                  errorMessageLogin: "Resposta fora do padrão esperado",
                });

                this.clearError();
                return;
              }

              if (!response.data.success) {
                this.setState({
                  openConfirm: true,
                  loadingLogin: false,
                  errorMessageLogin: response.data.errors.join(".<br/>"),
                });
                console.log(response.data.errors);

                this.clearError();
                return;
              }

              let timer = this.state.timer;

              this.setState({
                timer: clearTimeout(timer),
                setSuccessConfirm: true,
              });

              this.setState({
                timer: setTimeout(() => {
                  this.setState({
                    openConfirm: true,
                    loadingLogin: true,
                    setSuccessConfirm: false,
                    redirectLogin: true,
                  });
                }, 4000),
              });
            })

            .catch((error) => {
              this.setState({
                renderPersonList: false,
                errorMessage: "Erro ao tentar consultar dados",
                loadingLogin: false,
                redirectLogin: false,
              });

              this.clearError();
            });

          this.clearError();
        } else {
          this.setState({
            openConfirm: true,
            loadingLogin: false,
            errorMessageLogin:
              "Mínimo de 8 caracteres, contendo uma letra minúscula, uma letra maiúscula, um número e um caractere especial (!, @, #, $, %, ^, &, *)",
          });

          this.clearError();
        }
      } else {
        this.setState({
          openConfirm: true,
          setSuccessConfirm: false,
          errorMessageLogin: "As duas senhas não coincidem!",
          loadingLogin: false,
          redirectLogin: false,
        });

        this.clearError();
      }
    } else {
      this.setState({
        openConfirm: true,
        setSuccessConfirm: false,
        errorMessageLogin: "Ambos campos devem ser preenchidos!",
        loadingLogin: false,
        redirectLogin: false,
      });

      this.clearError();
    }
  }

  inputsForm(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form });
  }

  clearError() {
    setTimeout(() => {
      this.setState({
        openConfirm: false,
        loadingLogin: false,
        errorMessageLogin: "",
      });
    }, 10000);
  }
}

export default LoginAlteraSenha;
