export default Object.freeze({
  Endpoint: {
    State: {
      Get: "/state/get",
      GetPaged: "/state/getPaged",
    },

    AccessLevel: {
      Get: "/accessLevel/get",
      GetPaged: "/accessLevel/getPaged",
    },

    Pessoas: {
      Get: "/person/get",
      GetPaged: "/person/getPaged",
      Delete: "/person",
      Search: "/person/search",
      Download: "/person/download",
      Export: "/person/export",
      Post: "/person",
      Wizard: "/person/wizard",
    },

    User: {
      Get: "/user",
      Find: "/user/find",
      PagedFind: "/user/pagedFind",
      Delete: "/user",
      Search: "/user/search",
      Download: "/user/download",
      Export: "/user/export",
      Post: "/user",
      Put: "/user",
      SendFirstAccessEmail: "/user/sendFirstAccessEmail",
    },

    Industry: {
      Get: "/industry",
      Find: "/industry/find",
      PagedFind: "/industry/pagedFind",
      Delete: "/industry",
      Search: "/industry/search",
      Download: "/industry/download",
      Export: "/industry/export",
      Post: "/industry",
      Put: "/industry",
    },

    Sif: {
      Get: "/sif",
      GetByUser: "/sif/GetByUser",
      Find: "/sif/find",
      PagedFind: "/sif/pagedFind",
      Delete: "/sif",
      Search: "/sif/search",
      Download: "/sif/download",
      Export: "/sif/export",
      Post: "/sif",
      Put: "/sif",
    },

    QuestionnaireType: {
      Get: "/questionnaireType",
      Find: "/questionnaireType/find",
      PagedFind: "/questionnaireType/pagedFind",
    },

    AnswerStatus: {
      Get: "/answerStatus",
      Find: "/answerStatus/find",
      PagedFind: "/answerStatus/pagedFind",
    },

    Question: {
      Get: "/question",
      Find: "/question/find",
      PagedFind: "/question/pagedFind",
      Delete: "/question",
      Search: "/question/search",
      Download: "/question/download",
      Export: "/questifon/export",
      Post: "/question",
      Put: "/question",
      Batch: "/question/batch",
      GetStepWizard: "/question/getStepWizard ",
      GetQuestion: "/question",
    },

    Answer: {
      Get: "/answer",
      Find: "/answer/find",
      PagedFind: "/answer/pagedFind",
      AnsweredPagedFind: "/answer/answeredPagedFind",
      ReviewFind: "/answer/reviewFind",
      Delete: "/answer",
      Search: "/answer/search",
      ExportNotAnswered: "/answer/pendingSifExport",
      DownloadNotAnswered: "/answer/pendingSifDownload",
      Download: "/answer/pendingSifDownload",
      Export: "/answer/pendingSifExport",
      Post: "/answer",
      Put: "/answer/review",
      SendReviewEmail: "/answer/sendReviewEmail",
      PendingSifPagedFind: "/answer/pendingSifPagedFind",
      AnwseredExport: "/answer/answeredExport",
      AnwseredDownload: "/answer/answeredDownload",
      Answer: "/Answer/Answer",
      AnswerGet: "/Answer",
      UpdateReturnedAnswer: "/Answer/UpdateReturnedAnswer",
    },

    Questionnaire: {
      Get: "/questionnaire/getSituation",
      GetCurrentQuestionnaire: "/questionnaire/getCurrentQuestionnaire",
      GetPreviousAnswers: "/Questionnaire/GetPreviousAnswers",
      GetScore: "/questionnaire/GetScore",
      JustifyAnswer: "/questionnaire/JustifyAnswer",
      getCurrentQuestionnaireTypes:
        "/questionnaire/getCurrentQuestionnaireTypes",
    },

    Round: {
      Get: "/round",
      GetActive: "/round/getActive",
      Find: "/round/find",
      PagedFind: "/round/pagedFind",
      Delete: "/round",
      Search: "/round/search",
      Download: "/round/download",
      Export: "/round/export",
      Post: "/round",
      Put: "/round",
      Cancel: "/round/cancel",
      GetSequence: "/round/getSequence",
    },

    Sipoa: {
      Find: "/sipoa/find",
    },

    State: {
      Find: "/state/find",
    },

    City: {
      Find: "/city/find",
    },

    Profile: {
      Find: "/profile/find",
      GetPaged: "/profile/getPaged",
      Delete: "/profile",
      Search: "/profile/search",
      Download: "/profile/download",
      Export: "/profile/export",
      Post: "/profile",
    },

    JobTitle: {
      Find: "/jobTitle/find",
      GetPaged: "/jobTitle/getPaged",
      Delete: "/jobTitle",
      Search: "/jobTitle/search",
      Download: "/jobTitle/download",
      Export: "/jobTitle/export",
      Post: "/jobTitle",
    },

    Step: {
      Find: "/step/Find",
      Substep: "/substep/find",
    },

    Document: {
      Document: "/Document",
    },

    Auth: {
      Login: "/auth/login",
      ForgotPass: "/auth/forgotPassword",
      ResetPassword: "/auth/resetPassword",
      FirstAccess: "/auth/changePasswordFirstAccess",
    },

    Dashboard: {
      GetFilter: "/Dashboard/getFilter",
      GetDoughnut: "/Dashboard/GetScoreCardChart",
      GetAvailability: "/Dashboard/GetAvailability",
      GetScoreVSRoundChart: "/Dashboard/GetScoreVsRoundChart",
      GetSubstepPointChart: "/Dashboard/GetSubstepPointChart",
    },

    Score: {
      scoreType: "/scoreType ",
      scoreTypeFind: "/scoreType/find",
    },
  },

  Auth: {
    DefaultHeader: "Authorization",
    DefaultScheme: "Bearer",
  },

  LocalStorage: {
    Token: "MultToken",
    FirstName: "FirstName",
    LastName: "LastName",
    UserPhoto: "UserPhoto",
    AccessLevel: "AccessLevel",
    PersonId: "PersonId",
    EventType: "EventType",
    Language: "language",
    ProfileId: "profileId",
  },

  AllowedExtensions: {
    Document: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "pdf", "doc", "docx"],
  },

  RoundStatus: {
    Active: "Ativo",
    Edition: "Em edição",
  },

  Modal: {
    DefaultStyle: {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "440px",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
      },
    },
  },

  Pagination: {
    DefaultPageSize: 10,
  },
});
