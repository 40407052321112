import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import { objectToFormData } from "object-to-formdata";

import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import DialogConfirm from "components/Utils/DialogConfirm.jsx";
import DataList from "components/Utils/DataList.jsx";

import { Container, Row, Col, Form } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class Sif extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sifLoading: true,
      renderSifList: true,
      sifNotFound: false,
      errorSifNotFound: false,
      errorMessage: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      currentPage: 0,
      confirmExclusao: false,
      openConfirm: false,
      formData: {},
      searchMode: false,
      registerLoading: false,
      profileResponse: null,
      sifResponse: null,
      changeLoading: false,
      exporting: false,
      timer: null,
      filterLoading: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    let profileSelectItems = [];

    if (this.state.profileResponse) {
      if (this.state.profileResponse.length > 0) {
        try {
          profileSelectItems = this.state.profileResponse.map((item) => {
            return (
              <option value={item.id} key={"situation" + item.id}>
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          profileSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (
      this.state.sifResponse &&
      this.state.sifResponse.list &&
      this.state.sifResponse.total
    ) {
      tableCollumns = [
        { name: "N° do SIF", size: 50, property: "sifNumber" },
        { name: "Indústria", size: 50, property: "industryTradeName" },
        { name: "Razão Social", size: 50, property: "corporateName" },
        { name: "", size: 30, property: "editarButton" },
        { name: "", size: 25, property: "excluirButton" },
      ];

      tableData = this.state.sifResponse.list;
      tableData = tableData.map((item) => {
        return {
          ...item,
          ...{
            editarButton: (
              <Link to={`/admin/Sifs/sifRegistration/?id=${item.id}`}>
                <button id="edit" className="btn btn-sm">Editar</button>
              </Link>
            ),
            excluirButton: (
              <button
                type="button"
                className="btn btn-sm exc"
                key={item.id}
                onClick={() => this.openModal(item.id)}
              >
                Excluir
              </button>
            ),
          },
        };
      });

      total = this.state.sifResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este registro?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group darkButtom mr-4"
                key={this.state.sifId}
                onClick={() => this.deleteSif(this.state.sifId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group mainColorButtom"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">{this.stringTable.sif.titleSif}</h1>
              <small className="subtitle">
                {this.stringTable.sif.newSubtitleSif}
              </small>
            </Col>
            <Col lg="6">
              <Link to="/admin/sifs/sifRegistration">
                <button className="darkButtom float-lg-right mt-2">
                  {this.stringTable.sif.buttonRegister}
                </button>
              </Link>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="12">
                <div className="form-group">
                  <label
                    htmlFor="keyword"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.sif.filter.keyword}
                  </label>
                  <input
                    className="form-control input-style"
                    ref="keyword"
                    type="text"
                    id="keyword"
                    placeholder="Digite aqui"
                    value={
                      this.state.formData["keyword"]
                        ? this.state.formData["keyword"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            {!this.state.filterLoading || <h1> Carregando...</h1>}
            {this.state.sifNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openSifNotFound}
                        title={this.stringTable.sif.message.title1}
                        text={this.stringTable.sif.message.sifNotFound}
                        handleClose={() => {
                          this.setState({ openSifNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorSifNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorSifNotFound}
                        title={this.stringTable.sif.message.title1}
                        text={this.stringTable.sif.message.sifNotFound}
                        handleClose={() => {
                          this.setState({ openErrorSifNotFound: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}
            {!this.state.confirmExclusao || (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title={this.stringTable.sif.message.title1}
                        text={this.stringTable.sif.message.successExclusion}
                        success={true}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
            {this.state.confirmExclusao || (
              <section className="container mt-4">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title={this.stringTable.sif.message.title2}
                        text={this.stringTable.sif.message.errorExclusion}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}

            <Row lg="12" className="justify-content-center mt-7 mb-6">
              <div className="">
                <h1 className="titleText">{this.stringTable.sif.titleList}</h1>
              </div>
            </Row>
            <Row className="pt-4 pl-5">
              <Col lg="12">
                {this.state.renderSifList ? (
                  <DataList
                    loading={this.state.sifLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                ) : (
                  <>
                    {this.state.sifNotFound || this.state.errorSifNotFound || (
                      <h1> Carregando...</h1>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.loadSelects();
  }

  loadSelects() {
    this.loadDataList(0, true);
    this.loadProfile();
  }

  clearFormState() {
    this.setState({ formData: {} });
  }

  confirmExclusion(bool) {
    this.loadDataList(0, false);

    if (bool === "true") {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ sifId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  loadProfile() {
    this.setState({ profileResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Profile.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ profileResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ sifLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ sifLoading: false });

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.PagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            sifLoading: false,
            renderSifList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          sifLoading: false,
          renderSifList: true,
          errorMessage: "",
          sifResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          renderSifList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deleteSif(sifId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Delete + "/" + sifId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          this.setState({
            modalIsOpen: false,
            sifLoading: false,
            renderSifList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("false");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            modalIsOpen: false,
            sifLoading: false,
            renderSifList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("false");

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        this.setState({
          modalIsOpen: false,
          sifLoading: false,
          renderSifList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
        });
        this.confirmExclusion("true");
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          renderSifList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "keyword" || name === "profileId") {
      let timer = this.state.timer;

      this.setState({ timer: clearTimeout(timer), filterLoading: true });

      this.setState({
        timer: setTimeout(() => {
          this.submitSearch(this.state.currentPage);
        }, 2000),
      });
    }
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage == undefined || currentPage == null) currentPage = 0;

    this.setState({
      sifLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;

    if (formData.profileId) formData.profileIds = [formData.profileId];
    else formData.profileIds = [];

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.PagedFind;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            errorSifNotFound: true,
            openErrorSifNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total == 0) {
          this.setState({
            sifLoading: false,
            filterLoading: false,
            renderSifList: false,
            sifNotFound: true,
            openSifNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Indústria não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          sifLoading: false,
          filterLoading: false,
          renderSifList: true,
          sifNotFound: false,
          openSifNotFound: false,
          errorSifNotFound: false,
          openErrorSifNotFound: false,
          errorMessage: "",
          sifResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          filterLoading: false,
          renderSifList: false,
          errorSifNotFound: true,
          openErrorSifNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  handleSubmit(sifId) {
    let formData = this.state.formData;

    if (sifId) {
      this.setState({ changeLoading: true });
      formData.id = sifId;
      formData.name = formData.nameChanged;

      delete formData.nameChanged;
    } else this.setState({ registerLoading: true });

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Celebrant.Put;
    let data = formData;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (result.response == null) {
          this.setState({
            errorMessage: messages.Errors.BlankResponse,
            registerLoading: false,
            changeLoading: false,
          });
          this.closeModal();
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          sifLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderSifList: true,
          errorMessage: "",
          sifResponse: result.response,
        });
        this.loadDataList(0, false);
        this.clearFormState();
        this.closeModal();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          sifLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderSifList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    // this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Sif.Download +
            "?fileKey=" +
            result.response.fileKey
        );

        this.setState({ exporting: false });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Sif;
