import React from "react";

import { Link } from "react-router-dom";

import template from "template";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi";

import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList";

import { Container, Row, Col, Form } from "reactstrap";
class Industry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      industryLoading: true,
      renderIndustryList: true,
      industryNotFound: false,
      errorIndustryNotFound: false,
      errorMessage: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      currentPage: 0,
      confirmExclusao: false,
      openConfirm: false,
      formData: {},
      searchMode: false,
      registerLoading: false,
      profileResponse: null,
      industryResponse: null,
      changeLoading: false,
      exporting: false,
      timer: null,
      filterLoading: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.loadDataList = this.loadDataList.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    if (
      this.state.industryResponse &&
      this.state.industryResponse.list &&
      this.state.industryResponse.total
    ) {
      tableCollumns = [
        { name: "Nome fantasia", size: 50, property: "tradeName" },
        { name: "CNPJ Matriz", size: 50, property: "cnpj" },
        { name: "", size: 30, property: "visualizarButton" },
      ];

      tableData = this.state.industryResponse.list;
      tableData = tableData.map((item) => {
        item.cnpj = formater.template(item.cnpj, "00.000.000/0000-00");

        return {
          ...item,
          ...{
            visualizarButton: (
              <Link to={`/industry/Industry/IndustryPreview/?id=${item.id}`}>
                <button className="btn btn-sm edit">Visualizar</button>
              </Link>
            ),
          },
        };
      });

      total = this.state.industryResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <Container fluid className="minHeightFooter bg-body">
        <Row className="pt-6 pl-5">
          <Col lg="6">
            <h1 className="text-dark">
              {this.stringTable.industry.titleIndustry}
            </h1>
            <small className="subtitle">
              {this.stringTable.industry.newSUbtitleIndustry}
            </small>
          </Col>
        </Row>

        <Form className="pt-6 pl-5">
          <Row lg="12" className="justify-content-center mt-7 mb-6">
            <div>
              <h1 className="titleText">
                {this.stringTable.industry.titleList}
              </h1>
            </div>
          </Row>
        </Form>

        <Row className="pt-4 pl-5">
          <Col lg="12">
            {this.state.renderIndustryList ? (
              <DataList
                loading={this.state.industryLoading}
                tableCollumns={tableCollumns}
                tableData={tableData}
                paging={true}
                currentPage={this.state.currentPage}
                pageSize={constants.Pagination.DefaultPageSize}
                total={total}
                pageChangeHandler={(index) => {
                  pageChangeHandler(index);
                }}
                exportHandler={exportHandler}
                exporting={true}
                exportProcessing={this.state.exporting}
              />
            ) : (
              <>
                {this.state.industryNotFound ||
                  this.state.errorIndustryNotFound || <h1>Carregando...</h1>}
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  componentDidMount() {
    this.loadDataList(0, true);
    this.loadProfile();
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage == undefined || currentPage == null) {
      currentPage = 0;
    }

    this.setState({
      industryLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;

    if (formData.profileId) {
      formData.profileIds = [formData.profileId];
    } else {
      formData.profileIds = [];
    }

    formData.currentPage = currentPage;
    formData.pageSize = constants.Pagination.DefaultPageSize;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.PagedFind;

    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            industryLoading: false,
            filterLoading: false,
            renderIndustryList: false,
            errorIndustryNotFound: true,
            openErrorIndustryNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
        }
        if (!result.success) {
          this.setState({
            industryLoading: false,
            filterLoading: false,
            renderIndustryList: false,
            errorIndustryNotFound: true,
            openErrorIndustryNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            industryLoading: false,
            filterLoading: false,
            renderIndustryList: false,
            errorIndustryNotFound: true,
            openErrorIndustryNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total == 0) {
          this.setState({
            industryLoading: false,
            filterLoading: false,
            renderIndustryList: false,
            industryNotFound: true,
            openIndustryNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Indústria não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          industryLoading: false,
          filterLoading: false,
          renderIndustryList: true,
          industryNotFound: false,
          openIndustryNotFound: false,
          errorIndustryNotFound: false,
          openErrorIndustryNotFound: false,
          errorMessage: "",
          industryResponse: result.response,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }

        this.setState({
          industryLoading: false,
          filterLoading: false,
          renderIndustryList: false,
          errorIndustryNotFound: true,
          openErrorIndustryNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ industryLoading: true, currentPage: currentPage });

    if (!firstLoading) {
      this.setState({ industryLoading: false });
    }

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.PagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            industryLoading: false,
            renderIndustryList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          console.error(result.errors);

          this.setState({
            industryLoading: false,
            renderIndustryList: false,
            errorMessage: messages.Errors.ResponseError,
          });

          return;
        }

        if (result.response == null) {
          this.setState({
            industryLoading: false,
            renderIndustryList: false,
            errorMessage: messages.Errors.BlankResponse,
          });

          return;
        }

        this.setState({
          industryLoading: false,
          renderIndustryList: true,
          errorMessage: "",
          industryResponse: result.response,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";

          this.setState({
            industryLoading: false,
            renderIndustryList: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      }
    );
  }

  loadProfile() {
    this.setState({ profileResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Profile.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.BlankResponse, result.systemErrors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.systemErrors);
          return;
        }

        this.setState({ profileResponse: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Industry.Download +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default Industry;
