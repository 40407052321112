import React from "react";
import "../../assets/scss/pages/buttonGroup.scss";

const ButtonGroup = (props) => {
  const changeHandler = props.changeHandler ? props.changeHandler : () => {};
  const id = props.id ? props.id : "";
  const dflex = props.dflex ? props.dflex : "";
  const option1 = props.option1 ? props.option1 : "";
  const option2 = props.option2 ? props.option2 : "";
  const activeIndex =
    props.activeIndex !== null && props.activeIndex !== undefined
      ? props.activeIndex + ""
      : "";

  const disabled = props.disabled ? props.disabled : false;

  let buttonGroupOption1Active = false;
  let buttonGroupOption2Active = false;

  if (activeIndex === "0") buttonGroupOption1Active = true;
  else if (activeIndex === "1") buttonGroupOption2Active = true;
  else {
    buttonGroupOption1Active = false;
    buttonGroupOption2Active = false;
  }

  let changeValue = (value) => {
    changeHandler({ target: { id: id, value: value, avoidValidation: true } });
  };

  return (
    <>
      <div
        className={"btn-group btn-group-toggle" + (dflex ? " d-flex" : "")}
        data-toggle="buttons"
      >
        <label
          id="btn-group-first"
          className={
            "btn btn-group-nsb" + (buttonGroupOption1Active ? " active" : "")
          }
        >
          <input
            disabled={disabled}
            type="radio"
            name="options"
            id="option1"
            autoComplete="off"
            checked=""
            onClick={() => {
              changeValue("0");
            }}
          />{" "}
          {option1}
        </label>
        <label
          id="btn-group-second"
          className={
            "btn btn-group-nsb" + (buttonGroupOption2Active ? " active" : "")
          }
        >
          <input
            disabled={disabled}
            type="radio"
            name="options"
            id="option2"
            autoComplete="off"
            onClick={() => {
              changeValue("1");
            }}
          />{" "}
          {option2}
        </label>
      </div>
    </>
  );
};

export default ButtonGroup;
