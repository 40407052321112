import React from "react";
import { Link } from "react-router-dom";
import constants from "variables/constants";

import { Container, Row } from "reactstrap";

class Profile extends React.Component {
  constructor(props) {
    super(props);
  }

  clearLocalStorage() {
    window.localStorage.setItem(constants.LocalStorage.Token, "");
    window.localStorage.setItem(constants.LocalStorage.FirstName, "");
    window.localStorage.setItem(constants.LocalStorage.LastName, "");
  }

  render() {
    let storageUserFirstName = window.localStorage.getItem(
      constants.LocalStorage.FirstName
    );
    let storageUserLastName = window.localStorage.getItem(
      constants.LocalStorage.LastName
    );
    let storageUserName = storageUserFirstName
      ? storageUserFirstName + " " + storageUserLastName
      : null;
    let userName = "👨🏻‍💼";
    let firstLetters = "👨🏻‍💼";

    if (!storageUserName) storageUserName = "👨🏻‍💼";

    if (typeof storageUserName !== "string") storageUserName = "👨🏻‍💼";

    if (storageUserFirstName) {
      if (storageUserFirstName.length < 15 && storageUserFirstName !== "") {
        firstLetters =
          storageUserFirstName.substring(0, 1).charAt(0).toUpperCase() +
          storageUserLastName.substring(0, 1).charAt(0).toUpperCase();
        storageUserFirstName =
          storageUserFirstName.charAt(0).toUpperCase() +
          storageUserFirstName.slice(1);
        userName =
          storageUserFirstName +
          " " +
          storageUserLastName.substring(0, 1).charAt(0).toUpperCase() +
          (storageUserLastName !== "" ? "." : "");
      } else {
        if (storageUserName.indexOf(" ") !== -1) {
          firstLetters =
            storageUserFirstName.substring(0, 1).charAt(0).toUpperCase() +
            storageUserLastName.substring(0, 1).charAt(0).toUpperCase();
          userName = storageUserName.substring(0, 14) + "...";
          userName = userName.charAt(0).toUpperCase() + userName.slice(1);
        } else {
          if (storageUserFirstName !== "") {
            let nameArray = storageUserName.split(" ");
            let selectedName = "";

            while (nameArray.length > 0) {
              let currentName = nameArray.join(" ");

              if (currentName.length < 17) {
                selectedName = currentName;
                nameArray = [];
              } else {
                nameArray.pop();
              }
            }

            if (!selectedName) {
              selectedName = storageUserName.substring(0, 14) + "...";
            }

            firstLetters =
              storageUserFirstName.substring(0, 1).charAt(0).toUpperCase() +
              storageUserLastName.substring(0, 1).charAt(0).toUpperCase();
            userName = selectedName;
            userName = userName.charAt(0).toUpperCase() + userName.slice(1);
          }
        }
      }
    } else {
      window.location.href = "/auth/login";
    }

    return (
      <>
        {/* Page content */}
        <Container className="pt-4">
          <Row className="xs-12">
            <div className="media align-items-center">
              <span className="avatar avatar-md avatar-rounded">
                {firstLetters}
              </span>
              <div className="user-name ml-3">
                <div>
                  <span className="text-dark">{userName}</span>
                </div>
                <div>
                  <Link to={"/auth/Login"} onClick={this.clearLocalStorage}>
                    <span className="logout coloredText">Sair</span>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
