import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";
import { objectToFormData } from "object-to-formdata";

import Loading from "components/Utils/Loading.jsx";
import DataList from "components/Utils/DataList.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "440px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
};

class TipoCurso extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      courseResponse: null,
      courseLoading: true,
      renderCourseList: true,
      courseNotFound: false,
      openCourseNotFound: false,
      errorMessage: "",
      messageExclusion: "",
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      currentPage: 0,
      confirmExclusao: false,
      openConfirm: false,
      formData: {},
      searchMode: false,
      registerLoading: false,
      changeLoading: false,
      courseChange: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    let temp = this.state.courseResponse;

    if (
      this.state.courseResponse &&
      this.state.courseResponse.list &&
      this.state.courseResponse.total
    ) {
      tableCollumns = [
        { name: "Nome do curso", size: 50, property: "name" },
        { name: "", size: 5, property: "editarButton" },
        { name: "", size: 5, property: "excluirButton" },
      ];

      tableData = this.state.courseResponse.list;
      tableData = tableData.map((item) => {
        return {
          ...item,
          ...{
            editarButton: (
              <button
                type="button"
                className="btn btn-sm edit"
                key={item.id}
                onClick={() => this.openModal(item.id, true)}
              >
                Editar
              </button>
            ),
            excluirButton: (
              <button
                type="button"
                className="btn btn-sm exc"
                key={item.id}
                onClick={() => this.openModal(item.id)}
              >
                Excluir
              </button>
            ),
          },
        };
      });

      total = this.state.courseResponse.total;
    }

    let pageChangeHandler = (index) => {
      this.loadDataList(index, false);
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="float-lg-right x" onClick={this.closeModal}>
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Tem certeza de que deseja <br /> excluir este registro?
            </h2>
            <div className="text-center">
              <button
                id="alertExclusion"
                className="alertExclusion btn btn-group button-yes mr-4"
                key={this.state.courseId}
                onClick={() => this.deleteCourse(this.state.courseId)}
              >
                Sim
              </button>
              <button
                className="btn btn-group button-no"
                onClick={this.closeModal}
              >
                Não
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.courseChange}
            onRequestClose={this.closeModal}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.closeModal();
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              Nome do curso
            </h2>

            <div className="form-group">
              <input
                type="name"
                className="form-control text-dark font-weight-900 mt--2"
                id="nameChanged"
                name="nameChanged"
                placeholder="Digite o nome do curso"
                onChange={this.onInputChange}
              />
            </div>

            <div className="text-center">
              <button
                type="button"
                className="btn button-add"
                onClick={() => {
                  this.handleSubmit(this.state.courseId);
                }}
              >
                {this.state.changeLoading ? <Loading load="Sim" /> : "Editar"}
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body">
          <Row className="pt-4 pl-5">
            <Col lg="6">
              <div>
                <h1 className="text-dark">Tipo de cursos</h1>
                <img
                  className="rectangle-orange"
                  alt="..."
                  src={require("assets/img/brand/home/Rectangle.png")}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <Row className="pt-7 pl-5">
              <Col lg="8">
                <div className="form-group">
                  <label className="text-family font-weight-900 pb-1">
                    Nome do curso
                  </label>
                  <input
                    type="name"
                    className="form-control text-dark font-weight-900 mt--2"
                    id="name"
                    name="name"
                    value={
                      this.state.formData["name"]
                        ? this.state.formData["name"]
                        : ""
                    }
                    placeholder="Digite o nome do curso"
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group mt-4">
                  <button
                    type="button"
                    className="btn button-add"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.registerLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Adicionar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            {!this.state.confirmExclusao || (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title={this.state.nameCourse}
                        text={this.state.messageExclusion}
                        success={true}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
            {this.state.confirmExclusao || (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisiblePerson"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openConfirm}
                        title="Erro"
                        text={this.state.messageExclusion}
                        handleClose={() => {
                          this.setState({ openConfirm: false });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            )}
            <Row className="pt-4 pl-5">
              <Col lg="12">
                {this.state.renderCourseList ? (
                  <DataList
                    loading={this.state.courseLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                  />
                ) : (
                  <>
                    <h1>Falha ao carregar listagem. =[ </h1>
                    <h2>{this.state.errorMessage}</h2>
                  </>
                )}
              </Col>
              <div className="ml-3">
                <Link to="/admin/cadastrosAuxiliares">
                  <button
                    type="button"
                    className="btn btn-outline-light btn-come-back d-block"
                  >
                    Voltar
                  </button>
                </Link>
              </div>
            </Row>
          </Form>
        </Container>
      </>
    );
  }

  componentDidMount() {
    this.loadDataList(0, true);
  }

  clearFormState() {
    this.setState({ formData: {} });
  }

  confirmExclusion(name) {
    this.loadDataList(0, false);

    if (name) {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        nameCourse: name,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id, handleChange) {
    if (!handleChange) this.setState({ courseId: id, modalIsOpen: true });
    else this.setState({ courseId: id, courseChange: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, courseChange: false });
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ courseLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ courseLoading: false });

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.CourseType.GetPaged;
    let data =
      "CurrentPage=" +
      currentPage +
      "&PageSize=" +
      constants.Pagination.DefaultPageSize;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            courseLoading: false,
            renderCourseList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          courseLoading: false,
          renderCourseList: true,
          errorMessage: "",
          courseResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          courseLoading: false,
          renderCourseList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  deleteCourse(courseId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.CourseType.Delete +
      "/" +
      courseId;
    let data = null;

    smoothApi.delete(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          this.setState({
            modalIsOpen: false,
            courseLoading: false,
            renderCourseList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({
            modalIsOpen: false,
            courseLoading: false,
            renderCourseList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null) {
          this.setState({
            modalIsOpen: false,
            courseLoading: false,
            renderCourseList: true,
            confirmExclusao: true,
            messageExclusion: "",
            errorMessage: "",
          });
          this.confirmExclusion("");
          console.error(messages.Errors.BlankResponse, result);
          return;
        }
        this.setState({
          modalIsOpen: false,
          courseLoading: false,
          renderCourseList: true,
          confirmExclusao: false,
          errorMessage: "",
          messageExclusion: "",
          nameCourse: "",
        });
        this.confirmExclusion(result.response.name);
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          courseLoading: false,
          renderCourseList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  handleSubmit(courseId) {
    let formData = this.state.formData;

    if (courseId) {
      this.setState({ changeLoading: true });

      formData.id = courseId;
      formData.name = formData.nameChanged;

      delete formData.nameChanged;
    } else this.setState({ registerLoading: true });

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.CourseType.Put;
    let data = formData;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (result.response == null) {
          this.setState({
            errorMessage: messages.Errors.BlankResponse,
            registerLoading: false,
            changeLoading: false,
          });
          this.closeModal();
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          courseLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderCourseList: true,
          errorMessage: "",
          courseResponse: result.response,
        });
        this.loadDataList(0, false);
        this.clearFormState();
        this.closeModal();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          courseLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderCourseList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }
}

export default TipoCurso;
