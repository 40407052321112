import React from "react";

import template from "template.js";
import constants from "variables/constants";
import { Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    return (
      <>
        <footer className="auth-footer">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="text-center text-xl-left">
                  <p>
                    Feito com
                    <img
                      className="p-1"
                      alt="Amor"
                      src={require("assets/img/brand/heart 1.png")}
                    />
                    pela Mult-Connect
                  </p>
                </div>
              </Col>
              <Col xl="6">
                <div className="text-right">
                  <img
                    width="150"
                    className="pl-1 pb-5"
                    alt="Logo"
                    src={this.stringTable.standard.logo4}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
