import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Col, Container } from "reactstrap";

class ErrorAuth extends Component {
  render() {
    return (
      <Col lg="5" md="7">
        <Container className="mt--8 pb-8">
          <div className="login-logo pl-md-4 pb-5">
            <img
              alt="Nossa Senhora do Brasil"
              src={require("assets/img/brand/logo-nossa-senhora-do-brasil-2 1.png")}
            />
          </div>
          <div className="text-center pt-9">
            <h1 className="text-white">
              <i className="far fa-frown"></i> 404
            </h1>
            <h2 className="text-white"> Oops! Pagina não encontrada!</h2>
          </div>
          <Col xs="12" className="text-white text-center pt-2">
            <Link to="/auth/Login">
              <small className="login-link-form text-underline">
                Voltar para o Login
              </small>
            </Link>
          </Col>
        </Container>
      </Col>
    );
  }
}

export default ErrorAuth;
