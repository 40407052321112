import React from "react";

class AnswerCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      currentStep: 1,
      checkboxSelectItems: [],
      updated: false,
      verifyTypeScore: ["In natura", "Industrializado"],
      isEditing: false,
      submit: false,
    };

    this.questionId = 0;
  }

  render() {
    const defaultTitle = "Questão";
    const defaultSubTitle = "Selecione todas as opções que se aplicam.";
    const defaultValue = "Informe as opções de respostas.";
    const defaultOnChange = () => {};

    let displayNone = this.props.display ? true : false;
    let title = this.props.title ? this.props.title : defaultTitle;
    let subTitle = this.props.subTitle ? this.props.subTitle : defaultSubTitle;
    let stepName = this.props.stepName ? this.props.stepName : "";
    let questionOrder = this.props.questionOrder
      ? this.props.questionOrder
      : "";
    let stepOrder = this.props.stepOrder ? this.props.stepOrder : "";
    let scoreTypeName = this.props.scoreTypeName
      ? this.props.scoreTypeName
      : "";
    let scoreTypeId = this.props.scoreTypeId ? this.props.scoreTypeId : 0;

    let value = this.props.value ? this.props.value : defaultValue;
    let formData = this.props.formData ? this.props.formData : {};
    let onChange = this.props.onChange ? this.props.onChange : defaultOnChange;

    if (typeof title !== "string" && typeof title !== "object")
      title = defaultTitle;

    if (typeof subTitle !== "string" && typeof subTitle !== "object")
      subTitle = defaultSubTitle;

    this.previousAnswers =
      this.props.previousAnswers && this.props.previousAnswers.length
        ? this.props.previousAnswers
        : [];

    let onInputChange = (event) => {
      const target = event.target;
      const id = parseInt(target.id);
      const name = event.target.name;
      let value = "";
      let isDelete = false;
      let valueCheckBox = [];
      let formDataI = this.state.formData;

      if (Object.keys(formDataI).length == 0) {
        formData.map((item, index) => {
          Object.assign(valueCheckBox, { [index]: item });
        });
      } else {
        valueCheckBox = formDataI;
      }

      if (target.type === "checkbox") {
        value = target.checked;
      } else value = target.value;

      console.log("dengro da função", valueCheckBox);
      valueCheckBox = [...new Set(valueCheckBox)];
      console.log("dengro da função DEPOIS", valueCheckBox);

      if (value) {
        this.setState({
          formData: Object.assign(valueCheckBox, { [id]: name }),
        });
      } else {
        delete valueCheckBox[id];

        isDelete = true;

        this.setState({
          formData: valueCheckBox,
        });
      }

      onChange(valueCheckBox, id, isDelete);
    };

    let checkboxSelectItems = [];

    let formulario = this.state.formData.length ? this.state.formData : [];

    if (Object.values(formulario).length) {
      value.forEach((item, index) => {
        value[index] = {
          ...value[index],
          checked: false,
        };

        formulario.filter((formdata) => {
          if (formdata == item.answerOptionId) {
            value[index] = {
              ...value[index],
              checked: true,
            };
          }
        });
      });
    } else {
      value = value.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
    }

    if (value && value.length === 2) {
      try {
        checkboxSelectItems = value.map((item, index) => {
          return (
            <div key={index}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={index}
                  name={item.answerOptionId}
                  onChange={onInputChange}
                  checked={item.checked}
                />
                <label className="custom-control-label pb-3" htmlFor={index}>
                  {item.description}
                </label>
                <br />
              </div>
            </div>
          );
        });
      } catch (ex) {
        checkboxSelectItems = [];
      }
    } else {
      try {
        let verifiedTypeScore = [];
        const { verifyTypeScore } = this.state;

        value.map((item, _) => {
          if (value.length < 2) {
            verifyTypeScore.filter((typeScore) => {
              if (typeScore !== item.description) {
                verifiedTypeScore.push({
                  answerOptionId: item.answerOptionId + 1,
                  description: typeScore,
                  disabled: true,
                  checked: false,
                });
              }
            });
          }
        });

        value = [...value, ...verifiedTypeScore];

        checkboxSelectItems = value.map((item, index) => {
          return (
            <div key={index}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input input-checkbox"
                  id={index}
                  name={item.answerOptionId}
                  onChange={onInputChange}
                  disabled={item.disabled}
                  checked={item.checked}
                />
                <label className="custom-control-label pb-3" htmlFor={index}>
                  {item.description}
                </label>
                <br />
              </div>
            </div>
          );
        });
      } catch (ex) {
        checkboxSelectItems = [];
        console.error("Falha ao interpretar resposta", ex);
      }
    }

    return (
      <div key={stepName}>
        <div
          className="mb-3"
          style={{
            display: displayNone && "none",
            fontWeight: "bold",
            color: "#e64b3c",
          }}
        >
          <span>{stepName}</span>
        </div>
        <div
          className="mb-3"
          style={{
            display: displayNone && "none",

            fontWeight: "bold",
            color: scoreTypeId === 1 ? "#5dcaaa" : "#4cb6f2",
          }}
        >
          <span>{scoreTypeName}</span>
        </div>
        <div className="answerInput">
          <div className="title" style={{ display: displayNone && "none" }}>
            <span>
              {stepOrder}.{questionOrder} {title}
            </span>
          </div>
          <div className="subTitle" style={{ display: displayNone && "none" }}>
            <span>{subTitle}</span>
          </div>

          {checkboxSelectItems.length === 0 || checkboxSelectItems}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let value = this.props.value ? this.props.value : [];

    if (prevProps.submit !== prevState.submit) {
      if (value.length > 1) {
        this.setState({
          formData: [],
        });
      }
      this.setState({
        submit: prevProps.submit,
      });
    }

    if (prevProps.isEditing !== prevState.isEditing) {
      if (value.length > 1) {
        this.setState({
          formData: [],
        });
      }

      if (prevProps.isEditing === true) {
        this.setState({
          formData: prevProps.formData,
        });
      } else {
        this.setState({
          formData: [],
        });
      }

      this.setState({
        isEditing: prevProps.isEditing,
      });
    }

    if (prevProps.currentStep !== prevState.currentStep) {
      if (value.length > 1) {
        this.setState({
          formData: [],
        });
      }
      this.setState({
        currentStep: prevProps.currentStep,
      });
    }
  }
  componentDidMount() {
    let isDelete = false;

    setTimeout(() => {
      this.setState({
        formData: this.props.formData.length ? this.props.formData : [],
      });
    }, 300);

    let item = this.props.item ? this.props.item : {};
    let onChange = this.props.onChange ? this.props.onChange : () => {};

    this.questionId = item.questionId;

    setTimeout(() => {
      this.previousAnswers.forEach((item, index) => {
        index = Object.keys(item)[index];

        onChange(item, index, isDelete);
      });
    }, 300);
  }

  componentWillUnmount() {
    this.setState({ formData: {}, checkboxSelectItems: [] });
  }
}

export default AnswerCheckbox;
