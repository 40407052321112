import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import constants from "variables/constants";

import { Container } from "reactstrap";

import AdminFooter from "components/Footers/AdminFooter";
import SideBar from "components/Sidebar/Sidebar";

import routes from "routes";

class Industry extends React.Component {
  componentDidUpdate() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes) => {
    let matchingRoutes = [];

    routes.forEach((prop, key) => {
      if (prop.layout === "/industry") {
        matchingRoutes.push(
          <Route
            key={key}
            exact
            path={prop.layout + prop.path}
            component={prop.component}
          />
        );
      }
    });

    return matchingRoutes;
  };

  filterRoutes = (routes) => {
    let matchingRoutes = [];

    routes.forEach((prop) => {
      if (prop.layout === "/industry") {
        matchingRoutes.push(prop);
      }
    });

    return matchingRoutes;
  };

  render() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const profileId = window.localStorage.getItem(
      constants.LocalStorage.ProfileId
    );

    if (token && profileId == 2) {
      return (
        <>
          <SideBar
            {...this.props}
            routes={this.filterRoutes(routes)}
            Logo={{
              innerLink: "/industry/index",
              imgSrc: require("assets/img/brand/logo2.png"),
              imgAlt: "Logo",
            }}
          />

          <div className="main-content" ref="mainContent">
            <Switch>{this.getRoutes(routes)}</Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Redirect to="auth/login" />
        </>
      );
    }
  }
}

export default Industry;
