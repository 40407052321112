import React from "react";
import "assets/scss/components.scss";
import "assets/scss/pages/questionnaire.scss";

import Modal from "react-modal";

import Wizard from "components/Utils/Wizard";
import DataList from "components/Utils/DataList.jsx";
import ButtonGroup from "components/Utils/ButtonGroup";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";

import { Container, Row, Col } from "reactstrap";
import { formater } from "libs/formater";
import Checkbox from "components/Abiec/Questionnaire/AnswerCheckbox";
class AnswerOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: { anwserOptions: [] },

      currentQuestion: 0,
      wizardResponse: null,
      stepsWizard: [],
      currentStep: 1,
      oldIndex: null,

      scoreTypes: [],
      verifyTypeScore: ["In natura", "Industrializado"],
      formDataScore: [],
      valuesCheckBox: [],
      isEditing: false,
      submit: false,

      questionsResponse: [],
      questionsBatchDelete: [],
      questionsEditingIndex: null,
      questions: [],
      roundId: null,
      questionnaireTypeId: null,
      questionnaireType: null,
      stepNames: [],
      round: {
        id: 0,
        roundStatusId: 0,
        roundStatusName: "",
        sequence: 0,
      },
      stepId: null,
      subStepList: [],
      message: {
        visible: false,
        leftButtomText: "Ok",
        rightButtomText: null,
        text: "",
        title: "",
        closeCallBack: () => {},
        leftButtomClickCallBack: () => {},
        rightButtomClickCallBack: () => {},
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.onInputChange = this.onInputChange.bind(this);
    this.saveQuestion = this.saveQuestion.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
    this.edit = this.edit.bind(this);
    this.remove = this.remove.bind(this);
    this.addAnwserOption = this.addAnwserOption.bind(this);
    this.getQuestions = this.getQuestions.bind(this);
    this.onAnwserOptionChange = this.onAnwserOptionChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stepNavigate = this.stepNavigate.bind(this);
  }

  render() {
    let filteredTypeScore = [];
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    let wizzardNodeConfiguration = this.state.stepNames.map((step, index) => {
      return wizzardConfiguration.Configuration.Formater(
        <>
          {step.order}. {step.name}
        </>,
        "#",
        this.getColorForStepId(step.id)
      );
    });

    let roundName = "R00";
    let questionnaireTypeName = "Q0";
    let tableCollumns = [];
    let tableData = [];

    let subStepSelectItems = [];
    let scoreTypesItems = [];

    if (this.state.round) {
      if ((this.state.round.sequence + "").length === 1)
        roundName = "R0" + this.state.round.sequence;
      else if ((this.state.round.sequence + "").length === 2)
        roundName = "R0" + this.state.round.sequence;
      else roundName = "R" + this.state.round.sequence;
    }

    if (this.state.questionnaireType) {
      questionnaireTypeName =
        "Q" +
        this.state.questionnaireType.order +
        " - " +
        this.state.questionnaireType.name;
    }

    if (this.state.questions.length > 0) {
      tableCollumns = [
        { name: "Nº.", size: 5, property: "number" },
        { name: "Enunciado", size: 45, property: "name" },
        { name: "Tipo", size: 30, property: "questionTypeName" },
        { name: "", size: 5, property: "upButton" },
        { name: "", size: 5, property: "downButton" },
        { name: "", size: 5, property: "editarButton" },
        { name: "", size: 5, property: "excluirButton" },
      ];

      tableData = this.state.questions.map((item, index) => {
        if (item.questionTypeId === 1)
          item.questionTypeName =
            this.stringTable.questionnaire.answerOptions.typeOptions.select;

        if (item.questionTypeId === 2)
          item.questionTypeName =
            this.stringTable.questionnaire.answerOptions.typeOptions.checkbox;

        if (item.questionTypeId === 3)
          item.questionTypeName =
            this.stringTable.questionnaire.answerOptions.typeOptions.text;

        if (item.questionTypeId === 4)
          item.questionTypeName =
            this.stringTable.questionnaire.answerOptions.typeOptions.textarea;

        if (item.questionTypeId === 5)
          item.questionTypeName =
            this.stringTable.questionnaire.answerOptions.typeOptions.number;

        let upButton = (
          <div
            onClick={() => {
              this.changeIndex(true, index);
            }}
            style={{ cursor: "pointer", textAlign: "center" }}
          >
            <i className="fas fa-arrow-up"></i>
          </div>
        );
        let downButton = (
          <div
            onClick={() => {
              this.changeIndex(false, index);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              transform: "rotate(180deg)",
            }}
          >
            <i className="fas fa-arrow-up"></i>
          </div>
        );

        if (index === 0)
          upButton = (
            <div style={{ color: "#aaa", textAlign: "center" }}>
              <i className="fas fa-arrow-up"></i>
            </div>
          );

        if (index === this.state.questions.length - 1)
          downButton = (
            <div
              style={{
                color: "#aaa",
                textAlign: "center",
                transform: "rotate(180deg)",
              }}
            >
              <i className="fas fa-arrow-up"></i>
            </div>
          );

        if (this.state.round.roundStatusName == constants.RoundStatus.Active) {
          upButton = (
            <div style={{ color: "#aaa", textAlign: "center" }}>
              <i className="fas fa-arrow-up"></i>
            </div>
          );

          downButton = (
            <div
              style={{
                color: "#aaa",
                textAlign: "center",
                transform: "rotate(180deg)",
              }}
            >
              <i className="fas fa-arrow-up"></i>
            </div>
          );
        }

        return {
          ...item,
          ...{
            number: this.state.currentStep + "." + (index + 1),
            name: item.wording,
            upButton: upButton,
            downButton: downButton,
            editarButton:
              this.state.isEditing === false ? (
                <div
                  onClick={() => {
                    this.edit(index);
                  }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <a href="#top" className="text-dark">
                    <i className="fas fa-edit"></i>
                  </a>
                </div>
              ) : (
                <div
                  onClick={() => {}}
                  style={{ cursor: "initial", textAlign: "center" }}
                >
                  <a
                    style={{ cursor: "initial" }}
                    href="#top"
                    className="text-dark"
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </div>
              ),
            excluirButton:
              this.state.round.roundStatusName ==
              constants.RoundStatus.Active ? (
                <div
                  onClick={() => {}}
                  style={{
                    textAlign: "center",
                    color: "#aaa",
                  }}
                >
                  <i className="fas fa-trash"></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.showRemoveConfirm(index);
                  }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <i className="fas fa-trash"></i>
                </div>
              ),
          },
        };
      });
    }

    if (this.state.subStepList && this.state.subStepList.length) {
      try {
        subStepSelectItems = this.state.subStepList.map((item) => {
          return (
            <option
              selected={
                this.state.formData.substepId === item.id ? true : false
              }
              key={"situation" + item.id}
              value={item.id}
            >
              {item.name}
            </option>
          );
        });
      } catch (err) {
        subStepSelectItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    let formDataScore = this.state.formDataScore;

    return (
      <>
        <Modal
          isOpen={this.state.message.visible}
          onRequestClose={() => {
            this.state.message._defaultCloseAction(this);
          }}
          style={constants.Modal.DefaultStyle}
          contentLabel={this.state.message.title}
        >
          <span
            className="float-lg-right x"
            onClick={() => {
              this.state.message._defaultCloseAction(this);
            }}
          >
            X
          </span>
          <h2 className="text-dark text-center pt-4 pb-4 text-modal">
            {this.state.message.text}
          </h2>
          <div className="text-center">
            {!this.state.message.leftButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.leftButtomClickCallBack();
                }}
              >
                {this.state.message.leftButtomText}
              </button>
            )}
            {!this.state.message.rightButtomText || (
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                  this.state.message.rightButtomClickCallBack();
                }}
              >
                {this.state.message.rightButtomText}
              </button>
            )}
          </div>
        </Modal>
        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {roundName + " - " + questionnaireTypeName}
              </h1>
              <small className="subtitle">
                {this.stringTable.questionnaire.answerOptions.subTitle}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="pt-1 pl-5 pb-8">
            <Col lg="12" xl="12">
              <div className="">
                <Wizard
                  onClick={this.stepNavigate}
                  nodes={wizzardNodeConfiguration}
                />
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            {this.state.currentStep == 1 && (
              <Col lg="3" className="">
                <div className="form-group">
                  <label
                    htmlFor="corporateName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.questionnaire.answerOptions.subStep}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <select
                    name="substepId"
                    id="substepId"
                    ref="substepId"
                    disabled={
                      this.state.questionsEditingIndex !== null ||
                      this.state.round.roundStatusName ==
                        constants.RoundStatus.Active
                      // subStepSelectItems.length === 0
                      //   ? true
                      //   : false ||
                      //     this.state.round.roundStatusName ==
                      //       constants.RoundStatus.Active
                    }
                    className={"input-style form-control showFilterActive "}
                    onChange={this.onInputChange}
                  >
                    <option
                      value=""
                      selected={
                        this.state.formData["substepId"] === null ? true : false
                      }
                    >
                      {
                        this.stringTable.questionnaire.answerOptions.typeOptions
                          .default
                      }
                    </option>
                    {subStepSelectItems.length === 0 || subStepSelectItems}
                  </select>
                </div>
              </Col>
            )}
            <Col lg="3" className="">
              <div className="form-group">
                <label
                  htmlFor="corporateName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.questionnaire.answerOptions.typeSelect}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <select
                  name="questionTypeId"
                  id="questionTypeId"
                  ref="questionTypeId"
                  disabled={
                    this.state.questionsEditingIndex !== null ||
                    this.state.round.roundStatusName ==
                      constants.RoundStatus.Active
                  }
                  className={"input-style form-control showFilterActive "}
                  onChange={this.onInputChange}
                >
                  <option
                    value=""
                    selected={
                      this.state.formData["questionTypeId"] === null
                        ? true
                        : false
                    }
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .default
                    }
                  </option>
                  <option
                    value="1"
                    selected={this.state.formData["questionTypeId"] === 1}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .select
                    }
                  </option>
                  <option
                    value="2"
                    selected={this.state.formData["questionTypeId"] === 2}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .checkbox
                    }
                  </option>
                  <option
                    value="3"
                    selected={this.state.formData["questionTypeId"] === 3}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .text
                    }
                  </option>
                  <option
                    value="4"
                    selected={this.state.formData["questionTypeId"] === 4}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .textarea
                    }
                  </option>
                  <option
                    value="5"
                    selected={this.state.formData["questionTypeId"] === 5}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .number
                    }
                  </option>
                </select>
              </div>
            </Col>

            <Col lg="3" className="">
              <div className="form-group">
                <label
                  htmlFor="corporateName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.questionnaire.answerOptions.typeScore}
                </label>
                <Checkbox
                  onChange={(value, optionId, isDelete) => {
                    let valid = true;

                    if (!value || value === "") valid = false;

                    if (valid && value[optionId] !== undefined) {
                      formDataScore.push(value[optionId]);
                    }

                    if (isDelete) {
                      formDataScore = Object.values(value);
                    }

                    this.setState({
                      formDataScore: [...new Set(formDataScore)],
                    });
                  }}
                  value={this.state.valuesCheckBox}
                  formData={this.state.formDataScore}
                  display={true}
                  currentStep={this.state.currentStep}
                  isEditing={this.state.isEditing}
                  submit={this.state.submit}
                />
              </div>
              {/* <div className="form-group">
                <label
                  htmlFor="corporateName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.questionnaire.answerOptions.typeScore}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <select
                  name="scoreTypeId"
                  id="scoreTypeId"
                  ref="scoreTypeId"
                  disabled={
                    this.state.round.roundStatusName ==
                      constants.RoundStatus.Active ||
                    this.state.scoreTypes.length === 1
                  }
                  className={"input-style form-control showFilterActive "}
                  onChange={this.onInputChange}
                >
                  <option
                    value=""
                    selected={
                      this.state.formData["scoreTypeId"] === null ? true : false
                    }
                  >
                    {
                      this.stringTable.questionnaire.answerOptions.typeOptions
                        .default
                    }
                  </option>

                  {scoreTypesItems.length === 0 || scoreTypesItems}
                </select>
              </div> */}
            </Col>
          </Row>

          <Row lg="12" className="pb-5 pl-6 questionnaire">
            <Col lg="4" className="q_card-space">
              <label className="form-control-label label-input">
                {
                  this.stringTable.questionnaire.answerOptions
                    .enableVerification
                }
                <small className="text-gray">
                  {this.stringTable.standard.mandatoryInput}
                </small>
              </label>
              <ButtonGroup
                disabled={
                  this.state.round.roundStatusName ==
                  constants.RoundStatus.Active
                }
                id="hasValidation"
                option1={this.stringTable.standard.no}
                option2={this.stringTable.standard.yes}
                activeIndex={this.state.formData["hasValidation"]}
                dflex="Sim"
                changeHandler={this.onInputChange}
              />
            </Col>

            <Col lg="4" className="q_card-space">
              <label className="form-control-label label-input">
                {this.stringTable.questionnaire.answerOptions.enableUpload}
                <small className="text-gray">
                  {this.stringTable.standard.mandatoryInput}
                </small>
              </label>
              <ButtonGroup
                disabled={
                  this.state.round.roundStatusName ==
                  constants.RoundStatus.Active
                }
                id="hasAttachment"
                option1={this.stringTable.standard.no}
                option2={this.stringTable.standard.yes}
                activeIndex={this.state.formData["hasAttachment"]}
                dflex="Sim"
                changeHandler={this.onInputChange}
              />
            </Col>

            <Col lg="4" className="q_card-space">
              {!this.state.formData["hasAttachment"] || (
                <>
                  <label className="form-control-label label-input">
                    {this.stringTable.questionnaire.answerOptions.enabledIA}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <ButtonGroup
                    disabled={
                      this.state.round.roundStatusName ==
                      constants.RoundStatus.Active
                    }
                    id="hasArtificialIntelligenceRecognition"
                    option1={this.stringTable.standard.no}
                    option2={this.stringTable.standard.yes}
                    activeIndex={
                      this.state.formData[
                        "hasArtificialIntelligenceRecognition"
                      ]
                    }
                    dflex="Sim"
                    changeHandler={this.onInputChange}
                  />
                </>
              )}
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group">
                <label
                  htmlFor="wording"
                  className="form-control-label label-input"
                >
                  {this.stringTable.questionnaire.answerOptions.inputTitle}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <input
                  disabled={
                    this.state.round.roundStatusName ==
                    constants.RoundStatus.Active
                  }
                  className="form-control input-style"
                  ref="wording"
                  type="text"
                  id="wording"
                  name="wording"
                  value={
                    this.state.formData["wording"]
                      ? this.state.formData["wording"]
                      : ""
                  }
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>

          {(this.state.formData.questionTypeId !== 1 &&
            this.state.formData.questionTypeId !== 2) ||
            this.state.formData.anwserOptions.map((item, index) => {
              let id = "anwserOption" + index;
              let descriptionId = id + "Description";
              let scoreId = id + "Score";

              return (
                <Row lg="12" className="pb-2 pl-6" key={id}>
                  <Col lg="8" className="">
                    <div className="form-group">
                      <label
                        htmlFor={descriptionId}
                        className="form-control-label label-input"
                      >
                        {"Resposta " + (index + 1)}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>

                      <input
                        disabled={
                          this.state.round.roundStatusName ==
                          constants.RoundStatus.Active
                        }
                        data-index={index}
                        data-type="description"
                        className="form-control input-style"
                        ref={descriptionId}
                        type="text"
                        id={descriptionId}
                        value={item.description}
                        onChange={this.onAnwserOptionChange}
                      />
                    </div>
                  </Col>

                  <Col lg="4" className="">
                    <div className="form-group">
                      <label
                        htmlFor={scoreId}
                        className="form-control-label label-input"
                      >
                        {"Valor"}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={
                          this.state.round.roundStatusName ==
                          constants.RoundStatus.Active
                        }
                        data-index={index}
                        data-type="score"
                        className="form-control input-style"
                        ref={scoreId}
                        type="text"
                        id={scoreId}
                        value={item.score}
                        onChange={this.onAnwserOptionChange}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group">
                {this.state.round.roundStatusName !==
                  constants.RoundStatus.Active && (
                  <>
                    <>
                      {(this.state.formData.questionTypeId !== 1 &&
                        this.state.formData.questionTypeId !== 2) || (
                        <button
                          onClick={() => {
                            this.addAnwserOption();
                          }}
                          type="button"
                          className="mainColorButtom mr-5"
                        >
                          {
                            this.stringTable.questionnaire.answerOptions
                              .buttomAddAlternative
                          }
                        </button>
                      )}
                    </>
                    <>
                      {this.state.questionsEditingIndex === null ||
                      this.state.questionsEditingIndex < -1 ? (
                        <button
                          className="darkButtom"
                          onClick={() => {
                            this.saveQuestion();
                          }}
                        >
                          {this.state.formData.questionTypeId === 1 ||
                          this.state.formData.questionTypeId === 2
                            ? this.stringTable.questionnaire.answerOptions
                                .buttonAddQuestion
                            : this.stringTable.questionnaire.answerOptions
                                .buttonAddQuestion}
                        </button>
                      ) : (
                        <button
                          className="darkButtom"
                          onClick={() => {
                            this.saveQuestion();
                          }}
                        >
                          {
                            this.stringTable.questionnaire.answerOptions
                              .buttomEdit
                          }
                        </button>
                      )}
                    </>
                  </>
                )}

                {this.state.questionsEditingIndex === null ||
                  this.state.questionsEditingIndex < -1 || (
                    <button
                      onClick={() => {
                        this.cancel();
                      }}
                      type="button"
                      className={`darkButtom ${
                        this.state.round.roundStatusName !==
                          constants.RoundStatus.Active && "ml-5"
                      } `}
                    >
                      {
                        this.stringTable.questionnaire.answerOptions
                          .cancelEditing
                      }
                    </button>
                  )}
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group">
                <div>
                  <div className="title-people pt-4 pb-5">
                    <h1>
                      {
                        this.stringTable.questionnaire.answerOptions
                          .registredAnswer
                      }
                    </h1>
                  </div>

                  {!this.state.questions.length > 0 || (
                    <DataList
                      customItem={true}
                      loading={this.state.listLoading}
                      tableCollumns={tableCollumns}
                      tableData={tableData}
                      paging={false}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row lg="12" className="pb-2 pl-6">
            <Col lg="12" className="">
              <div className="form-group">
                <button
                  onClick={() => {
                    window.location.href = "/admin/Questionnaires";
                  }}
                  type="button"
                  className="mainColorButtom mr-5"
                >
                  {this.stringTable.questionnaire.answerOptions.buttomBack}
                </button>
                {this.state.round.roundStatusName !=
                  constants.RoundStatus.Active && (
                  <button
                    className="darkButtom"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {
                      this.stringTable.questionnaire.answerOptions
                        .buttomSaveSteps
                    }
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 3) {
        if (
          !isNaN(parseInt(urlValues[0])) &&
          !isNaN(parseInt(urlValues[1])) &&
          !isNaN(parseInt(urlValues[2]))
        ) {
          this.setState({
            roundId: parseInt(urlValues[0]),
            questionnaireTypeId: parseInt(urlValues[1]),
            stepId: parseInt(urlValues[2]),
          });
          valid = true;
        }
      }
    }

    if (!valid) window.location.href = "/admin/Questionnaires";
  }

  componentDidMount() {
    this.getQuestions();
    this.getQuestionnaireType();
    this.getSubstep();
    this.getRound();
    this.getStepWizard();
    this.getStep();
  }

  getStep() {
    const token = window.localStorage.getItem(constants.LocalStorage.Token);
    const url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Step.Find;
    const data = {
      name: null,
    };

    smoothApi.post(url, data, token, (result) => {
      if (
        !result ||
        !result.systemErrors ||
        result.success == null ||
        !result.validationErrors
      ) {
        console.error(messages.Errors.UnexpectedFormat, result);
        return;
      }

      if (!result.success) {
        console.error(messages.Errors.ResponseError, result.errors);
        return;
      }

      if (result.response == null) {
        console.error(messages.Errors.BlankResponse, result.errors);
        return;
      }

      this.setState({ stepNames: result.response });
    });
  }

  getSubstep() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Step.Substep;
    let data = {
      stepId: this.state.currentStep,
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );

          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        this.setState({ subStepList: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getRound() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.Get +
      "/" +
      this.state.roundId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        this.setState({ round: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getQuestionnaireType() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.QuestionnaireType.Get +
      "/" +
      this.state.questionnaireTypeId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        let scoreTypes = result.response.scoreTypes.map((item) => {
          return {
            answerOptionId: item.id,
            description: item.name,
            disabled: false,
          };
        });

        if (scoreTypes.length === 1) {
          scoreTypes = scoreTypes.map((item) => {
            return {
              ...item,
              disabled: false,
            };
          });

          this.setState({
            formData: Object.assign(this.state.formData, {
              scoreTypeId: scoreTypes[0].answerOptionId,
            }),
            formDataScore: [scoreTypes[0].answerOptionId],
            scoreTypes: scoreTypes,
            questionnaireType: result.response,
            valuesCheckBox: scoreTypes,
          });

          return;
        } else {
          this.setState({
            formDataScore: [],
          });
        }

        this.setState({
          questionnaireType: result.response,
          scoreTypes: scoreTypes,
          valuesCheckBox: scoreTypes,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  saveQuestion() {
    let validationMessages = [];
    let question = { ...this.state.formData };

    question.questionnaireTypeId = this.state.questionnaireTypeId;
    question.questionScoreTypes = this.state.formDataScore.map((item) => {
      return {
        scoreTypeId: item,
        questionId: question.id ? question.id : 0,
      };
    });

    question.questionScoreTypes = this.nonDuplicated(
      question.questionScoreTypes
    );

    if (!question.wording) {
      validationMessages.push(
        this.stringTable.questionnaire.answerOptions.validationWording
      );
    } else {
      if (question.wording.length < 3 || question.wording.length > 500)
        validationMessages.push(
          this.stringTable.questionnaire.answerOptions.validationWording2
        );
    }

    if (!question.questionTypeId) {
      validationMessages.push(
        this.stringTable.questionnaire.answerOptions.validationType
      );
    } else {
      if (question.questionTypeId === 1 || question.questionTypeId === 2) {
        if (!question.anwserOptions || question.anwserOptions.length < 2) {
          validationMessages.push(
            this.stringTable.questionnaire.answerOptions.validationType3
          );
        } else {
          question.anwserOptions.forEach((item) => {
            if (
              !item.description ||
              item.description.length === 0 ||
              item.description.length > 200
            )
              validationMessages.push(
                this.stringTable.questionnaire.answerOptions.validationOption
              );

            if (isNaN(parseInt(item.score)))
              validationMessages.push(
                this.stringTable.questionnaire.answerOptions.validationOption2
              );
          });
        }
      }
    }

    if (validationMessages.length > 0) {
      this.setState((state) => ({
        message: {
          ...state.message,
          ...{
            title: "Aviso",
            visible: true,
            text: (
              <>
                {this.stringTable.questionnaire.answerOptions.validationModal}

                <ul className="errorSumary">
                  {validationMessages.map((el, currentIndex) => {
                    return (
                      <li key={"validationItem_" + currentIndex}>{el + "."}</li>
                    );
                  })}
                </ul>
              </>
            ),
          },
        },
      }));

      return;
    }

    if (this.state.questionsEditingIndex === null) {
      this.setState((currentState) => ({
        questions: [...currentState.questions, question],
        formData: { anwserOptions: [], substepId: null, scoreTypeId: null },
        isEditing: false,
        submit: true,
      }));

      const { scoreTypes } = this.state;

      if (scoreTypes.length > 1) {
        this.setState({
          formDataScore: [],
        });
      }
    } else {
      let questions = this.state.questions;
      questions[this.state.questionsEditingIndex] = {
        ...questions[this.state.questionsEditingIndex],
        ...question,
      };
      this.setState({
        questionsEditingIndex: null,
        questions: questions,
        formData: { anwserOptions: [], substepId: null, scoreTypeId: null },
        isEditing: false,
        submit: true,
      });

      const { scoreTypes } = this.state;

      if (scoreTypes.length > 1) {
        this.setState({
          formDataScore: [],
        });
      }
    }

    // setTimeout(() => {
    //   this.getQuestionnaireType();
    // }, 200);

    setTimeout(() => {
      this.setState({
        submit: false,
      });
    }, 1000);
  }

  getColorForStepId(stepId) {
    let wizzardConfiguration = Wizard({ getConfiguration: true });
    const { stepsWizard } = this.state;
    if (stepsWizard !== null && stepsWizard.length) {
      switch (stepId) {
        case 1:
          if (this.state.currentStep === 1) {
            return wizzardConfiguration.Status.Active;
          } else if (this.state.currentStep !== 1 && stepsWizard[0].hasRecord) {
            return wizzardConfiguration.Status.Completed;
          } else {
            return wizzardConfiguration.Status.Enabled;
          }

        case 2:
          if (this.state.currentStep === 2) {
            return wizzardConfiguration.Status.Active;
          } else if (this.state.currentStep !== 2 && stepsWizard[1].hasRecord) {
            return wizzardConfiguration.Status.Completed;
          } else {
            return wizzardConfiguration.Status.Enabled;
          }
        case 3:
          if (this.state.currentStep === 3) {
            return wizzardConfiguration.Status.Active;
          } else if (this.state.currentStep !== 3 && stepsWizard[2].hasRecord) {
            return wizzardConfiguration.Status.Completed;
          } else {
            return wizzardConfiguration.Status.Enabled;
          }
        case 4:
          if (this.state.currentStep === 4) {
            return wizzardConfiguration.Status.Active;
          } else if (this.state.currentStep !== 4 && stepsWizard[3].hasRecord) {
            return wizzardConfiguration.Status.Completed;
          } else {
            return wizzardConfiguration.Status.Enabled;
          }
        case 5:
          if (this.state.currentStep === 5) {
            return wizzardConfiguration.Status.Active;
          } else if (this.state.currentStep !== 5 && stepsWizard[4].hasRecord) {
            return wizzardConfiguration.Status.Completed;
          } else {
            return wizzardConfiguration.Status.Enabled;
          }
        default:
          return wizzardConfiguration.Status.Enabled;
      }
    } else {
      return wizzardConfiguration.Status.Enabled;
    }
  }

  addAnwserOption() {
    let formData = this.state.formData;

    if (!formData.anwserOptions) formData.anwserOptions = [];

    formData.anwserOptions.push({ description: "", score: "" });
    this.setState({ formData: formData });
  }

  changeIndex(moveUp, oldIndex) {
    let newIndex = oldIndex + 1;
    let arr = this.state.questions;

    if (!arr) arr = [];

    if (moveUp) newIndex = oldIndex - 1;

    while (oldIndex < 0) oldIndex += arr.length;

    while (newIndex < 0) newIndex += arr.length;

    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;

      while (k--) arr.push(undefined);
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    this.setState({ questions: arr });
  }

  edit(index) {
    let question = this.state.questions[index];
    question.hasArtificialIntelligenceRecognition =
      question.hasArtificialIntelligenceRecognition ? 1 : 0;
    question.hasAttachment = question.hasAttachment ? 1 : 0;
    question.hasValidation = question.hasValidation ? 1 : 0;

    if (!question) return;

    let formDataScore = question.questionScoreTypes
      ? question.questionScoreTypes.map((item) => item.scoreTypeId).sort()
      : [];

    this.setState((currentState) => ({
      questionsEditingIndex: index,
      formData: { ...currentState.formData, ...question },
      formDataScore,
      isEditing: true,
      oldIndex: index,
    }));
  }

  showRemoveConfirm(index) {
    this.setState((state) => ({
      message: {
        ...state.message,
        ...{
          leftButtomText: this.stringTable.standard.yes,
          rightButtomText: this.stringTable.standard.no,
          title: this.stringTable.standard.warning,
          visible: true,
          closeCallBack: () => {},
          leftButtomClickCallBack: () => {
            this.remove(index);
          },
          rightButtomClickCallBack: () => {},
          text: this.stringTable.standard.deleteConfirmation,
        },
      },
    }));
  }

  remove(index) {
    let questions = this.state.questions;
    let question = questions[index];
    let questionsBatchDelete = this.state.questionsBatchDelete;

    if (question) {
      questions.splice(index, 1);

      if (typeof question.id === "number" && question.id > 0)
        questionsBatchDelete.push(question.id);
    }

    this.setState({
      questionsBatchDelete: questionsBatchDelete,
      questions: questions,
      questionsEditingIndex: null,
      formDataScore: [],
      isEditing: false,
      submit: false,
      formData: { anwserOptions: [] },
    });

    this.getQuestionnaireType();
  }

  getQuestions() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Question.Find;
    let data = {
      roundIds: [this.state.roundId],
      stepIds: [this.state.currentStep],
      questionnaireTypeIds: [this.state.questionnaireTypeId],
    };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.systemErrors ||
          !result.validationErrors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(
            messages.Errors.ResponseError,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        if (result.response == null) {
          console.log(
            messages.Errors.BlankResponse,
            result.systemErrors,
            result.validationErrors
          );
          return;
        }

        let treatedResponse = [];
        let questionFilter = [
          "id",
          "wording",
          "substepId",
          "substepName",
          "hasAttachment",
          "hasValidation",
          "hasArtificialIntelligenceRecognition",
          "order",
          "questionnaireTypeId",
          "questionTypeId",
          "scoreTypeId",
          "anwserOptions",
          "questionTypeName",
          "questionScoreTypes",
        ];

        treatedResponse = result.response.map((item) =>
          smoothApi.helpers.filterProperties(item, questionFilter)
        );
        treatedResponse = treatedResponse.sort((item) => item.order);

        this.setState({
          questionsResponse: result.response,
          questions: treatedResponse,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    let name = "unknown";
    let value = "";
    let formData = this.state.formData;

    if (target.id) name = target.id;

    value = target.value;

    if (
      name === "questionTypeId" ||
      name === "hasAttachment" ||
      name === "hasValidation" ||
      name === "hasArtificialIntelligenceRecognition"
    ) {
      if (!isNaN(parseInt(value))) value = parseInt(value);
    }

    if (name === "questionTypeId") formData.anwserOptions = [];

    if (name === "hasValidation" && value === 0) {
      delete formData.hasAttachment;
      delete formData.hasArtificialIntelligenceRecognition;
      this.setState({
        formData: {
          ...formData,
          ...{
            hasValidation: 0,
          },
        },
      });
    }

    if (name === "hasAttachment" && value === 0) {
      delete formData.hasArtificialIntelligenceRecognition;
      this.setState({
        formData: {
          ...formData,
          ...{
            hasAttachment: 0,
          },
        },
      });
    }
    this.setState({ formData: { ...formData, ...{ [name]: value } } });
  }

  onAnwserOptionChange(event) {
    const target = event.target;
    let index = 0;
    let type = "";

    index = parseInt(event.target.getAttribute("data-index"));
    type = event.target.getAttribute("data-type");

    if (isNaN(index) || !type) return;

    let anwserOption = this.state.formData.anwserOptions[index];

    if (!anwserOption) return;

    if (type === "description") anwserOption.description = target.value;

    if (type === "score") {
      anwserOption.score = formater.number(target.value);
    }

    let anwserOptions = this.state.formData.anwserOptions;
    anwserOptions[index] = anwserOption;

    this.setState((currentState) => ({
      formData: { ...currentState.formData, anwserOptions: anwserOptions },
    }));
  }

  cancel() {
    this.setState({
      questionsEditingIndex: null,
      formData: { anwserOptions: [] },
      isEditing: false,
      submit: false,
      formDataScore: [],
    });

    setTimeout(() => {
      // this.getQuestions();
      this.getQuestionnaireType();
    }, 400);
  }

  handleSubmit() {
    let toSaveData = [];

    this.state.questions.forEach((question, index) => {
      let model = { questionRequestModel: {}, answerOptionRequestModel: [] };
      question.order = index + 1;
      question.stepId = this.state.currentStep;
      question.roundId = this.state.roundId;
      question.questionnaireTypeId = this.state.questionnaireTypeId;

      if (question.anwserOptions && question.anwserOptions.length > 0) {
        question.anwserOptions.forEach((anwserOption, index2) => {
          anwserOption.order = index2 + 1;

          if (typeof question.id === "number")
            anwserOption.questionId = question.id;

          model.answerOptionRequestModel.push(anwserOption);
        });
      }

      // delete question.anwserOptions;

      model.questionRequestModel = question;
      toSaveData.push(model);
    });
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Question.Batch;
    let data = {
      toSave: toSaveData,
      toDelete: this.state.questionsBatchDelete,
    };

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.errors || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
          }));
          console.error(messages.Errors.ResponseError, result.errors);

          return;
        }

        if (result.response == null) {
          this.setState({ waitingResponseSubmit: false });
          console.error(messages.Errors.BlankResponse);
        }

        this.setState({
          formDataScore: [],
        });

        setTimeout(() => {
          this.getQuestionnaireType();
        }, 200);

        if (this.state.currentStep == 5) {
          this.stepNavigate(1);
          this.getStepWizard();
          return;
        } else if (this.state.currentStep >= 1 && this.state.currentStep < 5) {
          this.stepNavigate(this.state.currentStep + 1);
          this.getStepWizard();
          return;
        } else {
          this.getStepWizard();
          this.stepNavigate(1);
          return;
        }
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          personLoading: false,
          renderPersonList: false,
          errorMessage: messages.Errors.HttpError,
          waitingResponseSubmit: false,
          formDataScore: [],
        });
      }
    );
  }

  handleQuestion(parameter) {
    let question = this.state.currentQuestion;

    switch (parameter) {
      case "toBack":
        question -= 1;
        break;
      case "toNext":
        question += 1;
        break;

      default:
        question = question;
    }
  }

  getStepWizard() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Question.GetStepWizard;
    let data = {
      RoundId: this.state.roundId,
      QuestionnaireTypeId: this.state.questionnaireTypeId,
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
        }

        if (result.response == null || !result.response.length) {
          console.log(messages.Errors.BlankResponse, result.errors);
        }

        this.setState({ stepsWizard: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  stepNavigate(stepId) {
    this.setState({ currentStep: Number(stepId) });
    const { scoreTypes } = this.state;

    if (scoreTypes.length > 1) {
      this.setState({
        formDataScore: [],
      });
    }

    this.setState({
      isEditing: false,
      submit: false,
      questions: [],
      formData: { anwserOptions: [] },
      questionsBatchDelete: [],
      questionsEditingIndex: null,
    });
    this.getColorForStepId(stepId);

    setTimeout(() => {
      this.getQuestions();
      this.getQuestionnaireType();
    }, 250);
  }

  componentWillUnmount() {
    this.setState({ submit: false });
  }

  nonDuplicated(array) {
    let nonDuplicateAccount = [];

    array.forEach((item, index) => {
      const filteredItem = nonDuplicateAccount.filter((item) => {
        return item.scoreTypeId == array[index].scoreTypeId;
      });

      if (!filteredItem.length) {
        nonDuplicateAccount.push(array[index]);
      }
    });

    return nonDuplicateAccount;
  }
}

export default AnswerOptions;
