import axios from "axios";
import { formater } from "libs/formater";

export const viaCEP = {
  consulta: async (cep) => {
    let filteredCEP = formater.number(cep);

    let returnObj = {
      sucess: false,
      message: "",
      data: null,
    };

    if (filteredCEP.length !== 8) {
      return returnObj;
    }

    let api = axios.create({ baseURL: "https://viacep.com.br/ws/" });

    let apiGet = api
      .get(filteredCEP + "/json/", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (!response) {
          returnObj.sucess = false;
          returnObj.message = "Empty response";
        } else if (!response.data) {
          returnObj.sucess = false;
          returnObj.message =
            "Invalid response format: " + JSON.stringify(response);
        } else if (response.data.erro) {
          returnObj.sucess = false;
          returnObj.message = "CEP não encontrado";
        } else {
          returnObj.sucess = true;
          returnObj.message = "sucess";

          returnObj.data = {
            cep: response.data.cep,
            logradouro: response.data.logradouro,
            complemento: response.data.complemento,
            bairro: response.data.bairro,
            localidade: response.data.localidade,
            uf: response.data.uf,
            unidade: response.data.unidade,
            ibge: response.data.ibge,
            gia: response.data.gia,
          };
        }
      })
      .catch((err) => {
        returnObj.sucess = false;
        returnObj.message = err;
        console.log(err);
      });

    await apiGet;
    return returnObj;
  },
};
