import React from "react";
import "assets/scss/components.scss";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import { diruma } from "libs/diruma";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import Loading from "components/Utils/Loading.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class RoundRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      messageExclusion: "",
      confirmExclusao: false,
      openConfirm: false,
      roundId: -1,
      formData: {},
      round: {
        id: 0,
        isChange: false,
        roundStatusId: 0,
        roundStatusName: "",
        sequence: 0,
      },
      fieldRequiredError: false,
      roundCancelError: false,
      roundCancelSuccess: false,
      roundCancelLoading: false,
      submitLoading: false,
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "startDate",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "endDate",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.roundCancel = this.roundCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.roundId == -1 ? (
                <h1 className="text-dark">
                  {this.stringTable.questionnaire.titleNewRound}
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.stringTable.questionnaire.titleChangeRound}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.questionnaire.subtitleNewRound}
              </small>
            </Col>
          </Row>
          <Row lg="12" className="ml-5 mt-7 ">
            <div className="">
              <h2 className="text-dark">
                {this.stringTable.questionnaire.infoRoundForm}
              </h2>
            </div>
          </Row>
          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="sequenceId"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.questionnaire.form.id}
                  </label>
                  <input
                    disabled
                    className="form-control input-style input-disabled"
                    ref="sequenceId"
                    type="text"
                    id="sequenceId"
                    value={
                      this.state.formData["sequenceId"]
                        ? this.state.formData["sequenceId"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="startDate"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.questionnaire.form.startDate}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className={
                      "form-control input-style " +
                      (this.state.formData["startDate"] ? "filterActive" : "")
                    }
                    type="date"
                    id="startDate"
                    ref="startDate"
                    value={
                      this.state.formData["startDate"]
                        ? this.state.formData["startDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="endDate"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.questionnaire.form.endDate}
                    <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small>
                  </label>
                  <input
                    className={
                      "form-control input-style " +
                      (this.state.formData["endDate"] ? "filterActive" : "")
                    }
                    type="date"
                    id="endDate"
                    ref="endDate"
                    value={
                      this.state.formData["endDate"]
                        ? this.state.formData["endDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="roundStatusName"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.questionnaire.form.status}
                  </label>
                  <input
                    disabled
                    className="form-control input-style input-disabled"
                    ref="roundStatusName"
                    type="text"
                    id="roundStatusName"
                    value={
                      this.state.formData["roundStatusName"]
                        ? this.state.formData["roundStatusName"]
                        : this.stringTable.questionnaire.statusDefault
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>

          <div className="container-fluid mb-4 mt-6">
            <Row>
              <Col lg="12">
                {this.state.roundId != -1 ? (
                  <button
                    disabled={this.state.formData["isOnlyView"] ? true : false}
                    className="mr-5 lightButtom"
                    onClick={() => {
                      this.roundCancel();
                    }}
                  >
                    {this.state.roundCancelLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      this.stringTable.questionnaire.buttonRoundCancel
                    )}
                  </button>
                ) : (
                  <> </>
                )}
                <div className="float-right">
                  <Link to={"/admin/Questionnaires"} className="mr-5">
                    <button type="button" className="lightButtom">
                      {this.stringTable.standard.backButton}
                    </button>
                  </Link>
                  <button
                    disabled={this.state.formData["isOnlyView"] ? true : false}
                    className="darkButtom"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : this.state.round.roundStatusName ===
                      constants.RoundStatus.Edition ? (
                      "Salvar e avançar"
                    ) : (
                      "Avançar"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </div>

          {this.state.fieldRequiredError ? (
            <section className="container">
              <Row>
                <Col col="md-12">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg
                        ? "msgVisibleExclusion"
                        : "")
                    }
                  >
                    <DialogConfirm
                      open={this.state.fieldRequiredError}
                      title={" "}
                      text={this.stringTable.standard.fieldRequiredError}
                      handleClose={() => {
                        this.setState({ fieldRequiredError: false });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            <></>
          )}

          {this.state.roundCancelError ? (
            <section className="container">
              <Row>
                <Col col="md-12">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg
                        ? "msgVisibleExclusion"
                        : "")
                    }
                  >
                    <DialogConfirm
                      open={this.state.roundCancelError}
                      title={this.stringTable.questionnaire.message.title2}
                      text={this.stringTable.questionnaire.message.errorCancel}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            <></>
          )}

          {this.state.roundCancelSuccess ? (
            <section className="container">
              <Row>
                <Col col="md-12">
                  <div
                    id="msgExclusion"
                    className={
                      " " +
                      (this.state.isVisibleDeleteMsg
                        ? "msgVisibleExclusion"
                        : "")
                    }
                  >
                    <DialogConfirm
                      open={this.state.roundCancelSuccess}
                      title={this.stringTable.questionnaire.message.title1}
                      text={
                        this.stringTable.questionnaire.message.successCancel
                      }
                      success={true}
                    />
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            <></>
          )}

          {!this.state.showRegisterSuccess || (
            <Row lg="12" className="justify-content-center pb-5">
              <div>
                <img
                  className="pr-2"
                  alt="..."
                  src={require("assets/img/brand/icon-success.png")}
                />
                <span className="font-weight-600 pl-2">
                  {this.stringTable.standard.registerSuccess}
                </span>
              </div>
            </Row>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ roundId: id });
  }

  componentDidMount() {
    this.loadSelects();
    this.getRoundMethod();
    this.getSequence();
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          submitLoading: false,
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });

        window.location.href =
          "/admin/Questionnaires/QuestionnaireType?id=" + this.state.roundId;
      }.bind(this),
      2000
    );
  }

  openModal(id) {
    this.setState({ personId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      3000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      value = target.value;
    }

    this.setState({
      formData: Object.assign(
        this.state.formData,
        { [name]: value },
        this.state.formData,
        { isChange: true }
      ),
    });
  }

  getSequence() {
    if (this.state.roundId === -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Round.GetSequence;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let formData = this.state.formData;

          formData.sequenceId = "R0" + ret.sequence;

          this.setState({ formData: formData });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  getRoundMethod() {
    if (this.state.roundId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Round.Get +
        "/" +
        this.state.roundId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          function dateFormat(date) {
            let day = "";
            let month = "";
            let year = "";
            let dateFormated = "";

            day = new Date(date).getDate().toString();
            month = (new Date(date).getMonth() + 1).toString();
            year = new Date(date).getFullYear().toString();

            dateFormated =
              (year.toString().length === 1 ? "0" + year : year) +
              "-" +
              (month.toString().length === 1 ? "0" + month : month) +
              "-" +
              (day.toString().length === 1 ? "0" + day : day);

            return dateFormated;
          }

          let ret = result.response;

          ret.sequenceId = "R0" + ret.sequence;
          ret.startDate = dateFormat(ret.startDate);
          ret.endDate = dateFormat(ret.endDate);

          if (ret.roundStatusId === 5) ret.isOnlyView = true;

          ret.isChange = false;

          this.setState({ formData: ret, round: ret });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  roundCancel() {
    this.setState({ roundCancelLoading: true });

    if (this.state.roundId != -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Round.Cancel +
        "/" +
        this.state.roundId;
      let data = null;

      smoothApi.put(
        url,
        data,
        token,

        (result) => {
          if (!result || !result.errors || result.success == null) {
            this.setState({
              roundCancelError: true,
              roundCancelSuccess: false,
              roundCancelLoading: false,
            });
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            this.setState({
              roundCancelError: true,
              roundCancelSuccess: false,
              roundCancelLoading: false,
            });
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          this.setState({
            roundCancelSuccess: true,
            roundCancelError: false,
            roundCancelLoading: false,
          });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  handleSubmit() {
    let validations = diruma.validadeOnChange(
      this.validation,
      this.state.formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState({ fieldRequiredError: true });

      return;
    }

    this.setState({ submitLoading: true });

    let formData = this.state.formData;
    let formDataFilter = ["id", "startDate", "endDate"];
    let formDataAPI = smoothApi.helpers.filterProperties(
      formData,
      formDataFilter
    );

    if (formDataAPI.id) {
      if (formData.isChange) {
        let token = window.localStorage.getItem(constants.LocalStorage.Token);
        let url =
          process.env.REACT_APP_ENDPOINT_BASE_URL +
          constants.Endpoint.Round.Put +
          "/" +
          formDataAPI.id;
        let data = formDataAPI;

        smoothApi.put(
          url,
          data,
          token,

          (result) => {
            if (result.success == null) {
              console.error(messages.Errors.UnexpectedFormat, result);
              this.setState((state) => ({
                message: {
                  ...state.message,
                  ...{
                    title: "Aviso",
                    visible: true,
                    text: (
                      <>
                        Ocorreu um erro durante o registro. Por favor, verifique
                        a <span className="errorText"> mensagem </span> e tente
                        novamente:
                        <ul className="errorSumary">
                          {result.errors.map((el, currentIndex) => {
                            return (
                              <li key={"validationItem_" + currentIndex}>
                                {el + "."}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ),
                  },
                },
                submitLoading: false,
                showRegisterSuccess: false,
                fieldRequiredError: false,
              }));

              return;
            }

            if (!result.success) {
              console.error(messages.Errors.ResponseError, result.errors);

              this.setState((state) => ({
                message: {
                  ...state.message,
                  ...{
                    title: "Aviso",
                    visible: true,
                    text: (
                      <>
                        Ocorreu um erro durante o registro. Por favor, verifique
                        a <span className="errorText"> mensagem </span> e tente
                        novamente:
                        <ul className="errorSumary">
                          {result.errors.map((el, currentIndex) => {
                            return (
                              <li key={"validationItem_" + currentIndex}>
                                {el + "."}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ),
                  },
                },
                submitLoading: false,
                showRegisterSuccess: false,
                fieldRequiredError: false,
              }));

              return;
            }

            this.setState({
              fieldRequiredError: false,
              showRegisterSuccess: true,
              openConfirm: true,
              messageSubmit: "salvo com sucesso ",
            });
            this.timeOutSubmit();
          },

          (error, httpCode) => {
            if (httpCode === 401) window.location.href = "/auth/Login";

            this.setState({
              submitLoading: false,
              fieldRequiredError: false,
              showRegisterSuccess: false,
              errorMessage: messages.Errors.HttpError,
            });
          }
        );
      } else {
        this.timeOutSubmit();
      }
    } else {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Round.Post;
      let data = formDataAPI;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          let ret = result;

          this.setState({
            fieldRequiredError: false,
            showRegisterSuccess: true,
            openConfirm: true,
            roundId: ret.response.id,
            messageSubmit: "salvo com sucesso ",
          });
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    }
  }

  loadSelects() {}

  clearFormState() {
    this.setState({ formData: {} });
  }
}

export default RoundRegistration;
