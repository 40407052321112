import React from "react";
import { Link } from "react-router-dom";

import template from "template";

import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi";

import messages from "variables/messages";
import constants from "variables/constants";

import { Container, Row, Col, Form } from "reactstrap";

class UserPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderSifList: false,
      addSifLoading: false,
      industryId: -1,
      userId: -1,
      userSifsResponse: [],
      industrySugested: {},
      formData: {},
      profileResponse: null,
      sifResponse: null,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    let profileSelectItems = [];

    if (this.state.profileResponse && this.state.profileResponse.length > 0) {
      try {
        profileSelectItems = this.state.profileResponse.map((item) => {
          return (
            <option
              selected={
                this.state.formData.profileId === item.id ? true : false
              }
              value={item.id}
              key={"situation" + item.id}
            >
              {item.name}
            </option>
          );
        });
      } catch (err) {
        profileSelectItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    return (
      <Container fluid className="minHeightFooter bg-body mb-8">
        <Row className="pt-6 pl-5">
          <Col lg="6">
            <h1 className="text-dark">{this.stringTable.user.titlePreview}</h1>
            <small className="subtitle">
              {this.stringTable.user.newSubtitleUser}
            </small>
          </Col>
        </Row>

        <Form className="pt-6 pl-5">
          <Row lg="12">
            <Col lg="6">
              <div className="form-group">
                <label
                  htmlFor="firstName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.user.form.name}
                </label>
                <input
                  className="form-control input-style"
                  ref="firstName"
                  id="firstName"
                  type="text"
                  value={this.state.formData.firstName}
                  disabled
                />
              </div>
            </Col>

            <Col lg="6">
              <div className="form-group">
                <label
                  htmlFor="lastName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.user.form.lastName}
                </label>
                <input
                  className="form-control input-style"
                  type="text"
                  id="lastName"
                  value={this.state.formData.lastName}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="3">
              <div>
                <label htmlFor="cpf" className="form-control-label label-input">
                  {this.stringTable.user.form.cpf}
                </label>
                <input
                  className="form-control input-style"
                  ref="cpf"
                  name="cpf"
                  type="text"
                  value={this.state.formData.cpf}
                  disabled
                />
              </div>
            </Col>

            <Col lg="6">
              <div className="form-group">
                <label
                  htmlFor="email"
                  className="form-control-label label-input"
                >
                  {this.stringTable.user.form.email}
                </label>
                <input
                  className="form-control input-style"
                  type="text"
                  value={this.state.formData.email}
                  disabled
                />
              </div>
            </Col>

            <Col md="3" sm="6">
              <label
                htmlFor="profileId"
                className="form-control-label label-input"
              >
                {this.stringTable.user.form.profile}
              </label>
              <select
                className="input-style form-control showFilterActive"
                name="profileId"
                ref="profileId"
                id="profileId"
                disabled
              >
                <option
                  selected={this.state.formData.profileId ? false : true}
                  value=""
                >
                  Selecione
                </option>
                {profileSelectItems.length === 0 || profileSelectItems}
              </select>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="6">
              <div className="form-group">
                <label htmlFor="">
                  {this.stringTable.user.form.industryProfile.industry}
                </label>
                <input
                  className="form-control input-style"
                  disabled
                  value={this.state.industrySugested.name}
                />
              </div>
            </Col>
            <Col lg="6" md="9" sm="9">
              <div className="form-group">
                <label htmlFor="">
                  {this.stringTable.user.form.industryProfile.function}
                </label>
                <input
                  disabled
                  className="form-control input-style"
                  type="text"
                  value={this.state.formData.jobTitle}
                />
              </div>
            </Col>
          </Row>
        </Form>

        <div className="container-fluid mb-4 mt-6">
          <Row className="justify-content-center">
            <Link to={"/industry/User"} className="mr-5">
              <button type="button" className="lightButtom">
                {this.stringTable.standard.backButton}
              </button>
            </Link>
          </Row>
        </div>
      </Container>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) {
      this.setState({ userId: id });
    }
  }

  componentDidMount() {
    this.getUserMethod();
    this.loadProfile();
  }

  getUserMethod() {
    if (this.state.userId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.User.Get +
        "/" +
        this.state.userId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,
        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.log(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errros);
            return;
          }

          let res = result.response;
          let industrySifSugested = {};
          let userSifsResponse = this.state.userSifsResponse;

          if (res.userIndustry) {
            res.userIndustry.name = res.userIndustry.industryTradeName;
            res.jobTitle = res.userIndustry.jobTitle;

            industrySifSugested.id = res.userIndustry.industryId;
            industrySifSugested.name = res.userIndustry.industryTradeName;

            delete res.userIndustry.industryTradeName;

            this.setState({
              industrySugested: res.userIndustry,
              industryId: res.userIndustry.industryId,
            });
          }

          if (res.userSifs && res.userSifs.length > 0) {
            userSifsResponse = res.userSifs.map((item, index) => {
              if (index === 0) {
                industrySifSugested.id = item.industryId;
                industrySifSugested.name = item.industryTradeName;
              }

              delete item.industryId;
              delete item.industryTradeName;

              return item;
            });

            this.setState({
              userSifsResponse: userSifsResponse,
              renderSifList: true,
              addSifLoading: true,
              industryId: industrySifSugested.id,
            });

            this.loadSif();
          }

          res.cpf = formater.template(res.cpf, "000.000.000-00");

          this.setState({
            formData: res,
            industrySugested: industrySifSugested,
          });
        },
        (erro, httpCode) => {
          if (httpCode == 400) {
            window.location.href = "/auth/Login";
          }
        }
      );
    }
  }

  loadSif() {
    let dataAPI = {};
    let industryId = this.state.industryId;

    dataAPI.industryIds = [];

    dataAPI.keyword = null;

    if (industryId !== -1) {
      dataAPI.industryIds.push(industryId);

      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Find;
      let data = dataAPI;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (!result || !result.response || result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.systemErrors);
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.systemErrors);
            return;
          }

          this.setState({ sifResponse: result.response });
        },
        (error, httpCode) => {
          if (httpCode === 401) {
            window.location.href = "/auth/Login";
          }
        }
      );
    }
  }

  loadProfile() {
    this.setState({ profileResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url = process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Profile.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.systemErrors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.systemErrors);
          return;
        }

        this.setState({ profileResponse: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }
}

export default UserPreview;
