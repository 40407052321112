import React from "react";
import "assets/scss/pages/fileAttachment.scss";

import template from "template";
import constants from "variables/constants";

import Loading from "components/Utils/Loading";
import Collapse from "components/Collapse/Collapse";

class FileAttachment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    let listItem = this.props.listItem ? this.props.listItem : [];
    const file = this.props.file ? this.props.file : "";
    const name = this.props.name ? this.props.name : "";
    const questionId = this.props.questionId ? this.props.questionId : "";

    const disabled = this.props.disabled ? this.props.disabled : false;

    const handleChange = this.props.handleChange
      ? this.props.handleChange
      : () => {};
    const removeFile = this.props.removeFile ? this.props.removeFile : () => {};

    const removeFileUploaded = this.props.removeFileUploaded
      ? this.props.removeFileUploaded
      : () => {};

    const uploadDocument = this.props.uploadDocument
      ? this.props.uploadDocument
      : () => {};

    const loading = this.props.loading ? this.props.loading : false;


    return (
      <div className="file pt-5">
        <h2 className="text-dark">{this.stringTable.fileAttachment.title}</h2>
        <p className="subtitle">{this.stringTable.fileAttachment.subtitle}</p>

        <div className="container-file pt-4 pb-6">
          <input
            disabled={disabled}
            id="button-file"
            onChange={handleChange}
            name="file"
            type="file"
            style={{ display: "none" }}
          />

          <div className="container-file-title">
            <label htmlFor="button-file">
              <span className="btn view">
                {this.stringTable.fileAttachment.titleFileInput}
              </span>
            </label>
            {file !== "" && (
              <div className="file-title">
                <span className="file-name">
                  {this.stringTable.fileAttachment.FileSelected + ": " + name}
                </span>
              </div>
            )}
          </div>

          {file !== "" && (
            <div className="group-buttons-file">
              <button
                className="btn btn-sm remove"
                disabled={disabled}
                onClick={removeFile}
              >
                {this.stringTable.fileAttachment.removeButton}
              </button>
              <button
                className="ml-3 btn btn-sm add"
                disabled={disabled}
                onClick={() => uploadDocument(questionId)}
              >
                {loading ? (
                  <Loading load="Sim" />
                ) : (
                  this.stringTable.fileAttachment.addButton
                )}
              </button>
            </div>
          )}
        </div>

        <div>
          {listItem.map((item, index) => {
            return (
              <div key={index}>
                <Collapse
                  index={index + 1}
                  listItens={item}
                  questionId={questionId}
                  removeFileUploaded={removeFileUploaded}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default FileAttachment;
