import React from "react";

import axios from "axios";
import { Link, Redirect } from "react-router-dom";

import template from "template.js";
import constants from "variables/constants";

import DialogConfirm from "components/Utils/DialogConfirm.jsx";
import Loading from "components/Utils/Loading.jsx";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: "",
        password: "",
      },
      openConfirm: false,
      errorMessageLogin: "",
      LoadingLogin: false,
      redirect: false,
      redirectFirstAccess: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.AuthUser = this.AuthUser.bind(this);
    this.inputsForm = this.inputsForm.bind(this);
  }

  render() {
    if (this.state.redirectFirstAccess) {
      return <Redirect to={"/auth/LoginPrimeiroAcesso"} />;
    } else if (this.state.redirect) {
      const profileId = window.localStorage.getItem(
        constants.LocalStorage.ProfileId
      );

      if (profileId == "1") return <Redirect to={"/admin/index"} />;

      if (profileId == "2") return <Redirect to="/industry/index" />;

      if (profileId == "3") return <Redirect to={"/sif/index"} />;

      if (profileId == "4") return <Redirect to={"/mapa/index"} />;
    }

    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img alt="Logo" src={this.stringTable.standard.logo} />
            </div>
            <Form role="form" onSubmit={this.AuthUser}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-input text-dark"
                    placeholder={this.stringTable.login.inputEmail}
                    type="email"
                    name="email"
                    value={this.state.form.email}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-input text-dark"
                    placeholder={this.stringTable.login.inputPassword}
                    type="password"
                    name="password"
                    value={this.state.form.password}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>
              {!this.state.openConfirm || (
                <DialogConfirm
                  open={this.state.openConfirm}
                  title={" "}
                  text={this.state.errorMessageLogin}
                  handleClose={() => {
                    this.setState({ openConfirm: false });
                  }}
                />
              )}

              <div className="text-center">
                <Button className="darkButtom" type="submit">
                  {this.state.LoadingLogin ? (
                    <Loading load={true} />
                  ) : (
                    this.stringTable.login.buttomEnter
                  )}
                </Button>
              </div>
              <Row className="mt-3">
                <Col xs="12" className="text-white text-center">
                  <Link to="/auth/LoginEsqueciSenha">
                    <h4 className="linkText">
                      {this.stringTable.login.forgotPassword}
                    </h4>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Container>
        </Col>
      </>
    );
  }

  AuthUser(e) {
    const { email, password } = this.state.form;
    if (email && password) {
      this.setState({ LoadingLogin: true });

      const url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Auth.Login;
      let data = {
        email: email,
        password: password,
      };

      axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })

        .then((response) => {
          if (
            !response ||
            !response.data ||
            !response.data.errors ||
            response.data.success == null
          ) {
            this.setState({
              openConfirm: true,
              errorMessageLogin: "Resposta fora do padrão esperado",
              LoadingLogin: false,
            });
            return;
          }
          if (!response.data.success) {
            this.setState({
              openConfirm: true,
              errorMessageLogin: response.data.errors.join(".<br/>"),
              LoadingLogin: false,
            });
            return;
          }

          if (response.data.response == null) {
            this.setState({
              openConfirm: true,
              errorMessageLogin: "Resposta fora do padrão esperado",
              LoadingLogin: false,
            });
            return;
          }

          if (response.data.response.firstAccess) {
            this.setState({ redirectFirstAccess: true });
          }

          window.localStorage.setItem(
            constants.LocalStorage.Token,
            response.data.response.token
          );
          window.localStorage.setItem(
            constants.LocalStorage.FirstName,
            response.data.response.firstName
          );
          window.localStorage.setItem(
            constants.LocalStorage.LastName,
            response.data.response.lastName
          );
          window.localStorage.setItem(
            constants.LocalStorage.ProfileId,
            response.data.response.profileId
          );

          this.setState({
            openConfirm: false,
            errorMessageLogin: "",
            LoadingLogin: false,
            redirect: true,
          });
        })
        .catch((error) => {
          this.setState({
            renderPersonList: false,
            errorMessage: "Erro ao tentar consultar dados",
            LoadingLogin: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        openConfirm: true,
        errorMessageLogin: "Os campos estão vázio",
      });
    }
    e.preventDefault();
  }

  inputsForm(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form });
  }
}

export default Login;
