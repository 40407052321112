import React from "react";
import "assets/scss/pages/questionnaire.scss";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";

import constants from "variables/constants";
import messages from "variables/messages";

import { Container, Row, Col } from "reactstrap";

class SifCurrentScore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreResponse: null,
      timer: null,

      roundId: null,
      sifId: null,

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    let currentScore = "";

    if (this.state.scoreResponse) {
      if (this.state.scoreResponse.score)
        currentScore = this.state.scoreResponse.score;
    }

    return (
      <>
        <Container fluid className="minHeightFooter bg-body questionnaire">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {this.stringTable.questionnaireSif.currentScore.title}
              </h1>
              <small className="subtitle">
                {this.stringTable.questionnaireSif.currentScore.subtitle}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="mt-4 justify-content-center">
            <div className="">
              <img
                className=""
                alt="..."
                src={require("assets/img/brand/icon-score.png")}
              />
            </div>
          </Row>

          <Row lg="12" className="mt-4 justify-content-center">
            <div className="">
              <span className="q_text-dark">
                {this.stringTable.questionnaireSif.currentScore.text1}
              </span>
            </div>
          </Row>

          <Row lg="12" className="justify-content-center">
            <div className="">
              <span className="q_text-dark">
                {this.stringTable.questionnaireSif.currentScore.text2}
              </span>
            </div>
          </Row>

          <Row lg="12" className="mt-4 justify-content-center">
            <div className="">
              <h1 className="q_text-dark q_current-score">
                {currentScore === "" || currentScore}
              </h1>
            </div>
          </Row>

          <Row lg="12" className="mt-6 justify-content-center">
            <div className="">
              <span className="q_text-dark-min font-weight-600">
                {this.stringTable.questionnaireSif.currentScore.text3}{" "}
              </span>
              <span className="q_text-dark-min">
                {this.stringTable.questionnaireSif.currentScore.text4}
              </span>
            </div>
          </Row>

          <Row lg="12" className="mb-6 mt-6 justify-content-center">
            <div className="form-group">
              <button
                onClick={() => {
                  window.location.href = "/sif/index";
                }}
                type="button"
                className="mainColorButtom"
              >
                {this.stringTable.questionnaireSif.currentScore.backHome}
              </button>
            </div>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let valid = false;

    if (typeof id === "string") {
      let urlValues = id.split("-");

      if (urlValues.length === 2) {
        if (!isNaN(parseInt(urlValues[0])) && !isNaN(parseInt(urlValues[1]))) {
          this.setState({
            roundId: parseInt(urlValues[0]),
            sifId: parseInt(urlValues[1]),
          });
          valid = true;
        }
      }
    }

    if (!valid) window.location.href = "/sif/index";
  }

  componentDidMount() {
    this.getScore();
  }

  getScore() {
    this.setState({ scoreResponse: null });

    let dataAPI = {
      roundId: 1,
      sifId: 1,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Questionnaire.GetScore;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ scoreResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }
}

export default SifCurrentScore;
