import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/scss/main.scss";
import "assets/scss/components.scss";

import AdminLayout from "layouts/Admin.jsx";
import SifLayout from "layouts/Sif.jsx";
import IndustryLayout from "layouts/Industry";
import MAPALayout from "layouts/Mapa";

import AuthLayout from "layouts/Auth.jsx";
import BlankLayout from "layouts/Blank.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route
        path="/industry"
        render={(props) => <IndustryLayout {...props} />}
      />
      <Route path="/mapa" render={(props) => <MAPALayout {...props} />} />
      <Route path="/sif" render={(props) => <SifLayout {...props} />} />
      <Route path="/blank" render={(props) => <BlankLayout {...props} />} />
      <Redirect from="/" to="/admin/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
