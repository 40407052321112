import React from "react";

import axios from "axios";
import { Redirect } from "react-router-dom";

import template from "template.js";
import constants from "variables/constants";

import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";

class LoginPrimeiroAcesso extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        password: "",
        passwordConfirm: "",
        setSuccessConfirm: false,
        erroSenha: false,
      },
      openConfirm: false,
      errorMessageLogin: "",
      loadingLogin: false,
      redirectLogin: false,
      error: "",
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.inputsForm = this.inputsForm.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Container className="mt--8">
            <div className="login-logo pl-md-4">
              <img
                alt="Nossa Senhora do Brasil"
                src={this.stringTable.standard.logo}
              />
            </div>
            <Form role="form" onSubmit={this.changePassword}>
              <Row className="pb-4">
                <Col xs="12" className="text-dark text-center">
                  <small className="ft-16">
                    {this.stringTable.firstAcess.welcome}
                  </small>
                </Col>
                <Col xs="12" className="text-dark text-center">
                  <small className="ft-16">
                    {this.stringTable.firstAcess.registerUrPassword}
                  </small>
                </Col>
              </Row>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-dark text-input"
                    placeholder={this.stringTable.firstAcess.inputPassword}
                    type="password"
                    name="password"
                    value={this.state.form.password}
                    onChange={this.inputsForm}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3 roundedInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="cor-input">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="text-dark text-input"
                    placeholder={this.stringTable.firstAcess.inputConfirm}
                    type="password"
                    name="passwordConfirm"
                    value={this.state.form.passwordConfirm}
                    onChange={this.inputsForm}
                  />
                </InputGroup>

                {this.state.setSuccessConfirm || (
                  <DialogConfirm
                    open={this.state.openConfirm}
                    title={" "}
                    text={this.state.errorMessageLogin}
                    handleClose={() => {
                      this.setState({ openConfirm: false });
                    }}
                  />
                )}
                {!this.state.setSuccessConfirm || (
                  <Redirect to={"/admin/index"} />
                )}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4 darkButtom" type="submit">
                  {this.stringTable.firstAcess.buttomEnter}
                </Button>
              </div>
            </Form>
          </Container>
        </Col>
      </>
    );
  }

  changePassword(e) {
    const { password, passwordConfirm } = this.state.form;

    if (password && passwordConfirm) {
      if (password == passwordConfirm) {
        const url =
          process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Auth.FirstAccess;
        const token = window.localStorage.getItem(constants.LocalStorage.Token);
        const auth = constants.Auth.DefaultScheme + " " + token;

        this.setState({ loadingLogin: true });

        let data = {
          newPassword: password,
        };

        let config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        config.headers[constants.Auth.DefaultHeader] = auth;

        axios
          .post(url, data, config)
          .then((response) => {
            if (
              !response ||
              !response.data ||
              !response.data.errors ||
              response.data.success == null
            ) {
              this.setState({
                openConfirm: true,
                errorMessageLogin: "Resposta fora do padrão esperado",
                loadingLogin: false,
              });
              return;
            }

            if (!response.data.success) {
              this.setState({
                openConfirm: true,
                errorMessageLogin: response.data.errors.join(".<br/>"),
                loadingLogin: false,
              });
              console.log(response.data.errors);
              return;
            }

            this.setState({
              openConfirm: true,
              setSuccessConfirm: true,
              loadingLogin: false,
              redirectLogin: true,
            });
          })

          .catch((error) => {
            this.setState({
              renderPersonList: false,
              errorMessage: "Erro ao tentar consultar dados",
            });
          });
      } else {
        this.setState({
          openConfirm: true,
          setSuccessConfirm: false,
          errorMessageLogin: "As duas senhas não coincidem!",
          loadingLogin: false,
          redirectLogin: false,
        });
      }
    } else {
      this.setState({
        openConfirm: true,
        setSuccessConfirm: false,
        errorMessageLogin: "Ambos campos devem ser preenchidos!",
        loadingLogin: false,
        redirectLogin: false,
      });
    }
    e.preventDefault();
  }

  inputsForm(e) {
    let form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form });
  }
}

export default LoginPrimeiroAcesso;
