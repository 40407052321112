import React from "react";

import Line from "components/Graphics/Line/Line";
import { formatDate } from "components/Utils/FormatDate";
import HorizontalBar from "components/Graphics/HorizontalBar/HorizontalBar";

import template from "template";
import messages from "variables/messages";
import { smoothApi } from "libs/smoothApi";
import constants from "variables/constants";

import { Container, Row, Col } from "reactstrap";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score: 0,
      formData: {
        profileId: 2,
      },
      lineScore: 0,
      lineDate: [],
      comparison: "",
      industryId: -1,
      companyName: "",
      sifResponse: [],
      sifNumber: null,
      scoreCard: false,
      participated: true,
      isAvailable: false,
      listTypeScore: [],
      changeText: false,
      firstRender: false,
      sifSelectedText: {},
      scoreDate: new Date(),
      substepPointList: [],
      industrySugested: {},
      userSifsResponse: [],
      powerBiReportUrl: null,
      nationalAverageScore: 0,
      industrySugestedList: [],
      nationalAverageScoreLineBar: 0,

      scoreNotFound: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let storageUserFirstName = window.localStorage.getItem(
      constants.LocalStorage.FirstName
    );

    storageUserFirstName =
      storageUserFirstName.charAt(0).toUpperCase() +
      storageUserFirstName.slice(1);

    let sifSelectItems = [];
    let typeScoreItems = [];

    if (this.state.sifResponse && this.state.sifResponse.length > 0) {
      try {
        sifSelectItems = this.state.sifResponse.map((item) => {
          return (
            <option
              key={"situation" + item.id}
              value={item.id}
              selected={this.state.formData.sifId === item.id ? true : false}
            >
              {item.sifNumber} - {item.corporateName}
            </option>
          );
        });
      } catch (err) {
        sifSelectItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    if (this.state.listTypeScore && this.state.listTypeScore.length > 0) {
      try {
        typeScoreItems = this.state.listTypeScore.map((item) => {
          return (
            <option
              key={"situation" + item.id}
              value={item.id}
              selected={
                this.state.formData.typeScoreId === item.id ? true : false
              }
            >
              {item.name}
            </option>
          );
        });
      } catch (err) {
        typeScoreItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    return (
      <Container
        fluid
        className={`${
          this.state.isAvailable && "minHeightFooter"
        } bg-body dashboard`}
      >
        {this.state.isAvailable ? (
          <>
            <Row className="pl-5 pt-5">
              <Col lg="6">
                <h1 className="text-dark dashboardTitleTwo">
                  Bem-vindo(a), {storageUserFirstName}!
                </h1>
              </Col>
            </Row>
            <Row className="pl-5 pb-4 pt-4">
              <Col lg="4">
                <div>
                  <label
                    htmlFor="profileId"
                    className="form-control-label label-input"
                  >
                    {this.stringTable.user.form.vision}
                  </label>
                  <select
                    id="profileId"
                    ref="profileId"
                    name="profileId"
                    onChange={this.onInputChange}
                    className={"input-style form-control showFilterActive"}
                  >
                    <option
                      value="2"
                      selected={this.state.formData.profileId ? false : true}
                    >
                      Selecione
                    </option>
                    <option
                      value="3"
                      selected={
                        this.state.formData.profileId != 3 ? false : true
                      }
                    >
                      SIF
                    </option>
                  </select>
                </div>
              </Col>

              {this.state.formData.profileId == 3 && (
                <Col lg="4" md="12" sm="12">
                  <div className="input-sif">
                    <label className="form-control-label label-input">
                      {this.stringTable.user.form.sifProfile.sif}
                    </label>
                    <select
                      id="sifId"
                      name="sifId"
                      onChange={this.onInputChange}
                      disabled={sifSelectItems.length === 0 ? true : false}
                      className={
                        "input-style form-control showFilterActive" +
                        (sifSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                    >
                      <option
                        value=""
                        selected={this.state.formData.sifId ? false : true}
                      >
                        Selecione
                      </option>
                      {sifSelectItems.length === 0 || sifSelectItems}
                    </select>
                  </div>
                </Col>
              )}

              <Col lg="4" md="12" sm="12">
                <div className="input-sif">
                  <label className="form-control-label label-input">
                    {this.stringTable.user.form.industryProfile.typeScore}
                  </label>
                  <select
                    id="scoreTypeId"
                    name="scoreTypeId"
                    onChange={this.onInputChange}
                    disabled={typeScoreItems.length === 0 ? true : false}
                    className={
                      "input-style form-control showFilterActive" +
                      (typeScoreItems.length === 0 ? " loadingSelect" : "")
                    }
                  >
                    <option
                      value=""
                      selected={this.state.formData.scoreTypeId ? false : true}
                    >
                      Selecione
                    </option>
                    {typeScoreItems.length === 0 || typeScoreItems}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="container-graphics">
              <Col lg="7">
                <div className="doughnut-line">
                  {this.state.scoreCard ? (
                    <div className="wrapperDoughnut">
                      <div className="text-dark ml-5 mt-5">
                        <span>{this.stringTable.dashboard.scoreCard}</span>
                      </div>
                    </div>
                  ) : (
                    <Col lg="8">
                      <div>
                        <HorizontalBar
                          text="Score"
                          width={150}
                          height={43}
                          suggestedMax={100}
                          legend={true}
                          value={this.state.score}
                          nationalAverageScore={
                            this.state.nationalAverageScoreLineBar
                          }
                        />

                        <span
                          style={{
                            display: "inline-block",
                          }}
                          className="ml-3 mt-3"
                        >
                          {this.state.scoreNotFound
                            ? "Score não encontrado"
                            : "Atualizado em " +
                              formatDate(this.state.scoreDate)}
                        </span>
                      </div>
                    </Col>
                  )}

                  <div className="pt-5">
                    <Line
                      firstValue={this.state.lineScore}
                      secondValue={this.state.nationalAverageScore}
                      lineDate={this.state.lineDate}
                      showNationalScore={
                        this.state.formData.profileId == 2 ||
                        this.state.formData.profileId == 3
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col lg="5">
                {this.state.substepPointList.length ? (
                  this.state.substepPointList.map(
                    (
                      { point, comparison, substepName, nationalAverageScore },
                      index
                    ) => {
                      return (
                        <HorizontalBar
                          key={index}
                          width={150}
                          height={35}
                          value={point}
                          legend={false}
                          suggestedMax={60}
                          text={substepName}
                          comparison={comparison}
                          nationalAverageScore={nationalAverageScore}
                        />
                      );
                    }
                  )
                ) : (
                  <>
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Certificação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Habilitação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Volume"
                      legend={false}
                      suggestedMax={60}
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Análise"
                      legend={false}
                      suggestedMax={60}
                    />
                  </>
                )}
              </Col>

              {this.state.powerBiReportUrl !== null ? (
                <Col lg="5">
                  <div className="ml-4">
                    <span className="text-dark">
                      Mais informações:{" "}
                      <a
                        target="_blank"
                        className="hiperLink"
                        rel="noopener noreferrer"
                        href={this.state.powerBiReportUrl}
                      >
                        Clique aqui
                      </a>
                    </span>
                  </div>
                </Col>
              ) : null}
            </Row>
          </>
        ) : (
          <div className="availability">
            <h1>Não há dados disponíveis</h1>
          </div>
        )}
      </Container>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadSif();
    }, 500);
    this.loadIndustry({ firstRender: true });

    this.getTypeScore();

    this.getAvailability();
    this.setState({ changeText: true, firstRender: false, scoreCard: true });
  }

  getAvailability() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetAvailability;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ isAvailable: result.response.isAvailable });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getTypeScore() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Score.scoreTypeFind;
    let data = {
      name: null,
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        this.setState({
          listTypeScore: result.response,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadSimpleLineBar(industryId, sifId, firstRender) {
    const { formData } = this.state;
    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?sifId=${this.state.formData.sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetDoughnut +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (result.success === false) {
          this.setState({
            scoreNotFound: true,
            score: 0,
            nationalAverageScoreLineBar: 0,
            changeText: false,
            firstRender: false,
            comparison: "",
            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                point: 0,
                comparison: "",
                substepOrder: 1,
                nationalAverageScore: 0,
                substepName: "Certificação",
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 2,
                nationalAverageScore: 0,
                substepName: "Habilitação",
              },
              {
                point: 0,
                substepOrder: 3,
                comparisoFn: "",
                substepName: "Volume",
                nationalAverageScore: 0,
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 4,
                substepName: "Análise",
                nationalAverageScore: 0,
              },
            ],
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            score: 0,
            nationalAverageScoreLineBar: 0,
            scoreNotFound: true,
            changeText: false,
            firstRender: false,
            comparison: "",
            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                point: 0,
                comparison: "",
                substepOrder: 1,
                nationalAverageScore: 0,
                substepName: "Certificação",
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 2,
                nationalAverageScore: 0,
                substepName: "Habilitação",
              },
              {
                point: 0,
                substepOrder: 3,
                comparisoFn: "",
                substepName: "Volume",
                nationalAverageScore: 0,
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 4,
                substepName: "Análise",
                nationalAverageScore: 0,
              },
            ],
          });
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        if (formData.profileId == "3" || formData.profileId == "2")
          this.setState({
            changeText: true,
            firstRender: false,
          });

        const {
          score,
          scoreDate,
          sifNumber,
          comparison,
          corporateName,
          nationalAverageScore,
        } = result.response;

        if (firstRender) {
          this.setState({
            score: score,
            sifNumber,
            scoreDate,
            comparison,
            changeText: true,
            firstRender: false,
            scoreNotFound: false,
            participated: !!score,
            formData: { profileId: "2" },
            companyName: corporateName,
            nationalAverageScoreLineBar: nationalAverageScore,
          });
        } else {
          this.setState({
            score: score,
            comparison,
            scoreDate,
            sifNumber,
            scoreNotFound: false,
            participated: !!score,
            companyName: corporateName,
            nationalAverageScoreLineBar: nationalAverageScore,
          });
        }
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSubstepPointChart(industryId, sifId) {
    const { formData } = this.state;

    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?sifId=${this.state.formData.sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetSubstepPointChart +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    }

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({
            score: 0,
            changeText: false,
            firstRender: true,

            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                point: 0,
                comparison: "",
                substepOrder: 1,
                nationalAverageScore: 0,
                substepName: "Certificação",
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 2,
                nationalAverageScore: 0,
                substepName: "Habilitação",
              },
              {
                point: 0,
                substepOrder: 3,
                comparisoFn: "",
                substepName: "Volume",
                nationalAverageScore: 0,
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 4,
                substepName: "Análise",
                nationalAverageScore: 0,
              },
            ],
          });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null || result.response.length === 0) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ substepPointList: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadScoreVsRoundChart(industryId, sifId) {
    const { formData } = this.state;
    let url;

    if (formData.profileId == "2") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    } else if (formData.profileId == "3") {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?sifId=${sifId}&scoreTypeId=${formData.scoreTypeId}`;
    } else {
      url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Dashboard.GetScoreVSRoundChart +
        `?industryId=${this.state.industryId}&scoreTypeId=${formData.scoreTypeId}`;
    }
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({
            score: 0,
            changeText: false,
            firstRender: true,

            nationalAverageScore: 0,
            lineScore: 0,
            lineDate: new Date(),
            substepPointList: [
              {
                point: 0,
                comparison: "",
                substepOrder: 1,
                nationalAverageScore: 0,
                substepName: "Certificação",
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 2,
                nationalAverageScore: 0,
                substepName: "Habilitação",
              },
              {
                point: 0,
                substepOrder: 3,
                comparisoFn: "",
                substepName: "Volume",
                nationalAverageScore: 0,
              },
              {
                point: 0,
                comparison: "",
                substepOrder: 4,
                substepName: "Análise",
                nationalAverageScore: 0,
              },
            ],
          });

          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let newScore = result.response.map((item) => item.score);
        let newNationalAverageScore = result.response.map((item) =>
          item.nationalAverageScore !== null ? item.nationalAverageScore : []
        );

        const lineDate = result.response.map((item) => item.scoreDate);

        this.setState({
          lineDate,
          lineScore: newScore,
          nationalAverageScore: newNationalAverageScore,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadIndustry(firstRender) {
    let keyword = {
      keyword: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Find;
    let data = keyword;

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        result.response.forEach((item) => {
          if (firstRender) {
            this.setState({
              powerBiReportUrl: item.powerBiReportUrl,
            });
          }

          this.setState({ industryId: item.id, firstRender: false });
          this.loadScoreVsRoundChart(item.id, -1);
          this.loadSubstepPointChart(item.id, -1);
          this.loadSimpleLineBar(item.id, -1, firstRender);
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadSif() {
    let dataAPI = {};
    let industryId = this.state.industryId;
    dataAPI.industryIds = [];
    dataAPI.keyword = null;

    if (industryId !== -1) {
      dataAPI.industryIds.push(industryId);

      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Find;
      let data = dataAPI;

      smoothApi.post(
        url,
        data,
        token,
        (result) => {
          if (!result || !result.response || result.success == null) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          this.setState({ sifResponse: result.response, firstRender: false });
        },
        (error, httpCode) => {
          if (httpCode === 401) {
            window.location.href = "/auth/Login";
          }
        }
      );
    }
  }

  loadSifId(sifId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Sif.Get +
      "/" +
      sifId;
    let data = null;
    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let res = result.response;
        let formData = this.state.formData;

        formData.sifNumber = res.sifNumber;
        formData.corporateName = res.corporateName;
        this.setState({
          formData: formData,
          sifNumber: res.sifNumber,
          sifSelectedText: {
            sifNumber: res.sifNumber,
            sifName: res.corporateName,
          },
          companyName: res.corporateName,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSugestedIndustry(industryId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let formData = this.state.formData;
        let res = result.response;

        delete formData.sifNumber;
        delete formData.corporateName;

        this.setState({
          formData: formData,
          industryId: res.id,
          userSifsResponse: [],
          industrySugested: res,
        });

        this.loadSif();
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;

    let value = "";

    value = target.value;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
      changeText: false,
      scoreDate: new Date(),
    });

    if (name === "scoreTypeId") {
      if (value === "") {
        setTimeout(() => {
          this.setState({
            scoreCard: true,
          });
        }, 200);

        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });

        this.setState({
          score: 0,
          lineScore: [],
          comparison: "",
          nationalAverageScore: [],
          nationalAverageScoreLineBar: 0,
          substepPointList: substepPoint,
          formData: Object.assign(this.state.formData, {
            scoreTypeId: "",
          }),
        });
        return;
      }
      this.loadScoreVsRoundChart(-1, this.state.formData.sifId);
      this.loadSubstepPointChart(-1, this.state.formData.sifId);
      this.loadSimpleLineBar(-1, this.state.formData.sifId, false);

      this.setState({
        scoreCard: false,
      });
      return;
    }

    if (name === "sifId") {
      this.loadSifId(value);
      let substepPoint = this.state.substepPointList.map((item) => {
        return {
          ...item,
          point: 0,
          nationalAverageScore: 0,
        };
      });

      this.setState({
        score: 0,
        lineScore: [],
        comparison: "",
        scoreCard: true,
        nationalAverageScore: [],
        nationalAverageScoreLineBar: 0,
        substepPointList: substepPoint,
        formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
      });
    }

    if (name === "profileId") {
      this.loadIndustry({ firstRender: false });
      this.loadSif(this.state.industryId);

      if (value == "2") {
        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });
        setTimeout(() => {
          this.setState({
            score: 0,
            lineScore: [],
            comparison: "",
            scoreCard: true,
            nationalAverageScore: [],
            substepPointList: substepPoint,
            nationalAverageScoreLineBar: 0,
            formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
          });
        }, 100);

        return;
      }

      if (value == "3") {
        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });

        setTimeout(() => {
          this.setState({
            score: 0,
            lineScore: [],
            comparison: "",
            scoreCard: true,
            sifSelectedText: {},
            nationalAverageScore: [],
            nationalAverageScoreLineBar: 0,
            substepPointList: substepPoint,
            industrySugested: { id: 0, name: "" },
            formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
          });
        }, 100);
      }
    }
  }
}

export default Dashboard;
