import React from "react";
import { Row, Col } from "reactstrap";
import "../../assets/scss/pages/inputSearch.scss";

class InputSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sugestionInputValue: "",
      sugestedPerson: null,
      inputValue: "",
      picture: require("assets/img/brand/maskFamily.png"),
      buttonRemovePerson: false,
      colortransparent: false,
      textEnter: false,
      isExclusion: false,
      removeButton: false,
      teste: null,
    };
  }

  render() {
    const changeHandler = this.props.changeHandler
      ? this.props.changeHandler
      : () => {};
    const deleteChild = this.props.deleteChild
      ? this.props.deleteChild
      : () => {};
    const deleteHandler = this.props.deleteHandler
      ? this.props.deleteHandler
      : () => {};
    const title = this.props.title ? this.props.title : "";
    const pageHandler = this.props.pageHandler ? this.props.pageHandler : "";
    const disabled = this.props.disabled ? this.props.disabled : null;
    const isMandatory = this.props.isMandatory ? this.props.isMandatory : null;
    const hasPhoto = this.props.hasPhoto ? this.props.hasPhoto : false;
    let value = this.props.value ? this.props.value : [];
    let sugestedList = this.props.sugestedList ? this.props.sugestedList : [];
    let fieldId = this.props.fieldId ? this.props.fieldId : "";
    let personId = this.props.personId ? this.props.personId : "";
    let fatherId = this.props.fatherId ? this.props.fatherId : "";
    let motherId = this.props.motherId ? this.props.motherId : "";
    let spouseId = this.props.spouseId ? this.props.spouseId : "";
    let engagedId = this.props.engagedId ? this.props.engagedId : "";
    let fianceeId = this.props.fianceeId ? this.props.fianceeId : "";
    let childrenId = this.props.childrenId ? this.props.childrenId : [];
    let moreChildren = this.props.moreChildren ? this.props.moreChildren : [];
    let moreChildrenId = [];

    this.removeButton = this.props.removeButton
      ? this.props.removeButton
      : false;

    if (!this.state.isExclusion) {
      if (this.state.sugestedPerson == null) {
        if (value) {
          if (Object.values(value).length !== 0) {
            this.setState({
              sugestionInputValue: value.name,
              sugestedPerson: value,
              buttonRemovePerson: true,
            });
          }
        }
      }
    }

    let onChangeInvisible = (e) => {
      let inputValue = e.target.value.toString().toLowerCase();

      let endSearch = 0;

      if (!inputValue) {
        this.setState({
          sugestionInputValue: "",
          sugestedPerson: null,
          colortransparent: false,
          textEnter: false,
        });
        return;
      }

      sugestedList.map((item) => {
        let name = item.name.toString().toLowerCase();

        let search = name.indexOf(inputValue);

        if (moreChildren) {
          if (moreChildren.length !== 0) {
            moreChildren.map((item) => {
              if (moreChildrenId.indexOf(item.id) === -1)
                moreChildrenId.push(item.id);
            });
          }
        }

        if (endSearch === 0) {
          if (search === 0) {
            if (item.id != personId) {
              if (pageHandler === "event") {
                if (item.id != engagedId && item.id != fianceeId) {
                  this.setState({
                    sugestionInputValue: item.name,
                    sugestedPerson: item,
                    colortransparent: true,
                    textEnter: true,
                  });
                  endSearch += 1;
                }
              } else {
                if (
                  item.id != fatherId &&
                  item.id != motherId &&
                  item.id != spouseId
                ) {
                  if (childrenId.indexOf(item.id) === -1) {
                    if (moreChildrenId.indexOf(item.id) === -1) {
                      this.setState({
                        sugestionInputValue: item.name,
                        sugestedPerson: item,
                        colortransparent: true,
                        textEnter: true,
                      });
                      endSearch += 1;
                    }
                  }
                }
              }
            }
          } else {
            this.setState({
              sugestionInputValue: "",
              sugestedPerson: null,
              colortransparent: false,
              textEnter: false,
            });
          }
        }
      });
    };

    let pressEnter = (e) => {
      if (e.which === 13 || e.keyCode === 13) {
        const person = this.state.sugestedPerson;
        onInsert(person);
        e.preventDefault();
      }
    };

    let onInsert = (person) => {
      if (this.state.sugestedPerson) {
        person = this.state.sugestedPerson;

        changeHandler(person.id);

        this.setState({
          buttonRemovePerson: true,
          textEnter: false,
          isExclusion: false,
        });
      } else return;
    };

    let onRemove = (id) => {
      id = this.state.sugestedPerson.id;

      if (
        !(childrenId.indexOf(id) === -1) ||
        !(moreChildrenId.indexOf(id) === -1)
      ) {
        deleteChild(id);
      } else {
        changeHandler(-1);
        deleteHandler();
      }

      this.setState({
        buttonRemovePerson: false,
        sugestionInputValue: "",
        sugestedPerson: null,
        isExclusion: true,
      });

      document.getElementById(fieldId).value = "";
      value = null;
    };

    return (
      <>
        <Row>
          {!hasPhoto || (
            <Col lg="1">
              <div className="form-group">
                <label
                  className={
                    "ws-nowrap " +
                    (pageHandler === "family" ? "text-family" : "label-input")
                  }
                >
                  {title}{" "}
                  {isMandatory ? (
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  ) : (
                    ""
                  )}
                </label>
                {this.state.sugestedPerson &&
                this.state.sugestedPerson.pictureFileName != null ? (
                  <img
                    src={this.state.sugestedPerson.pictureFileName}
                    id="canvas"
                    rel="nofollow"
                    className="imgSugested"
                    alt="..."
                  ></img>
                ) : (
                  <img
                    src={this.state.picture}
                    id="pictureFileName"
                    className="imgUploadedFamily"
                    rel="nofollow"
                    alt="..."
                  ></img>
                )}
              </div>
            </Col>
          )}

          <Col lg={pageHandler === "family" ? 5 : 9}>
            {hasPhoto || (
              <>
                <label
                  className={
                    "ws-nowrap " +
                    (pageHandler === "family" ? "text-family" : "label-input")
                  }
                >
                  {title}{" "}
                  {isMandatory ? (
                    <small className="text-gray">
                      <i> - obrigatório</i>
                    </small>
                  ) : (
                    ""
                  )}
                </label>
              </>
            )}

            {this.state.buttonRemovePerson ? (
              <>
                <input
                  disabled
                  className={
                    "form-control input-style input-Family input-disabled " +
                    (hasPhoto ? "" : "mt-input-not-photo")
                  }
                  value={this.state.sugestionInputValue}
                  id="sugestionInput"
                />
                <input
                  disabled
                  className={
                    "form-control z-index input-style invisibel " +
                    (this.state.colortransparent ? "c-transp" : "text-dark")
                  }
                  id={fieldId}
                  onKeyPress={(e) => {
                    pressEnter(e);
                  }}
                  onChange={onChangeInvisible}
                />
              </>
            ) : (
              <>
                <input
                  disabled={disabled ? true : false}
                  className={
                    "form-control input-style input-Family " +
                    (hasPhoto ? "" : "mt-input-not-photo")
                  }
                  value={this.state.sugestionInputValue}
                  id="sugestionInput"
                />
                <input
                  disabled={disabled ? true : false}
                  className={
                    "form-control z-index input-style invisibel " +
                    (this.state.colortransparent ? "c-transp" : "text-dark")
                  }
                  id={fieldId}
                  onKeyPress={(e) => {
                    pressEnter(e);
                  }}
                  onChange={onChangeInvisible}
                />
              </>
            )}
          </Col>

          {this.removeButton === false && (
            <Col lg="1">
              <div
                className={
                  "form-group " +
                  (hasPhoto ? "div-family-btn-photo" : "div-family-btn")
                }
              >
                {this.state.buttonRemovePerson || (
                  <>
                    <button
                      disabled={disabled ? true : false}
                      type="button"
                      className="btn btn-family-insert"
                      onClick={onInsert}
                    >
                      Inserir
                    </button>
                  </>
                )}
                {!this.state.buttonRemovePerson || (
                  <>
                    <button
                      disabled={disabled ? true : false}
                      type="button"
                      className="btn btn-family-delete"
                      onClick={(e) => {
                        onRemove(e);
                      }}
                    >
                      Excluir
                    </button>
                  </>
                )}
              </div>
            </Col>
          )}
        </Row>
        {this.state.textEnter ? (
          <Row className={hasPhoto ? "m-enter" : "m-enter-not-photo"}>
            <div className="ml-4">
              <small>Pressione "Enter" para inserir</small>
            </div>
          </Row>
        ) : (
          <>&nbsp;</>
        )}
      </>
    );
  }

  componentDidUpdate(prevProps, _) {
    if (!this.state.isExclusion) {
      setTimeout(() => {
        const value = this.props.value ? this.props.value : null;
        const prevState = prevProps.value ? prevProps.value : "";

        if (prevState !== "" && value !== null) {
          if (value.name !== prevState.name) {
            this.setState({
              sugestionInputValue: value.name,
              sugestedPerson: value,
              buttonRemovePerson: true,
            });
          }
        }
      }, 300);
    }
  }
}

export default InputSearch;
