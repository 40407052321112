import React from "react";

class AnswerRadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {},
    };

    this.questionId = 0;
  }

  render() {
    const componentName = "✨ AnswerRadioButton ✨ ";

    const defaultTitle = "Questão";
    const defaultSubTitle = "Selecione a opção que se aplica.";
    const defaultValue = "Informe a opção de resposta.";
    const defaultOnChange = () => {};

    let radioSelectItems = [];

    let title = this.props.title ? this.props.title : defaultTitle;
    let subTitle = this.props.subTitle ? this.props.subTitle : defaultSubTitle;
    let stepName = this.props.stepName ? this.props.stepName : "";
    let questionOrder = this.props.questionOrder
      ? this.props.questionOrder
      : "";
    let stepOrder = this.props.stepOrder ? this.props.stepOrder : "";
    let questionScoreTypes = this.props.questionScoreTypes
      ? this.props.questionScoreTypes
      : [];
    let scoreTypeId = this.props.scoreTypeId ? this.props.scoreTypeId : 0;
    let value = this.props.value ? this.props.value : [];
    let onChange = this.props.onChange ? this.props.onChange : defaultOnChange;

    if (typeof title !== "string" && typeof title !== "object")
      title = defaultTitle;

    if (typeof subTitle !== "string" && typeof subTitle !== "object")
      subTitle = defaultSubTitle;

    let onInputChange = (event) => {
      const target = event.target;
      const id = target.id;
      const name = event.target.name;

      if (target.type === "radio") {
        this.setState({
          selectedOption: name,
        });
      }

      onChange(name);
    };

    if (value && value.length) {
      value.forEach((item, index) => {
        if (item.id) {
          value[index].answerOptionId = item.id;
        }
      });
    }

    if (value) {
      if (value.length > 0) {
        try {
          radioSelectItems = value.map((item, index) => {
            return (
              <div key={index}>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input input-radio"
                    id={index}
                    name={item.answerOptionId}
                    onChange={onInputChange}
                    checked={
                      parseInt(this.state.selectedOption) ===
                      item.answerOptionId
                        ? true
                        : false
                    }
                  />
                  <label className="custom-control-label pb-3" htmlFor={index}>
                    {item.description}
                  </label>
                  <br />
                </div>
              </div>
            );
          });
        } catch (ex) {
          radioSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    return (
      <>
        <div className="mb-3" style={{ fontWeight: "bold", color: "#e64b3c" }}>
          <span>{stepName}</span>
        </div>
        <div className="mb-3">
          {questionScoreTypes.map((item, index) => {
            return (
              <span
                className={index === 1 && "ml-2"}
                style={{
                  fontWeight: "bold",
                  color: item.scoreTypeId === 1 ? "#5dcaaa" : "#4cb6f2",
                }}
              >
                ({item.scoreTypeName})
              </span>
            );
          })}
        </div>
        <div className="answerInput">
          <div className="title">
            <span>
              {stepOrder}.{questionOrder} {title}
            </span>
          </div>
          <div className="subTitle">
            <span>{subTitle}</span>
          </div>

          {radioSelectItems.length === 0 || radioSelectItems}
        </div>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    let item = this.props.item ? this.props.item : {};

    if (item.questionId !== this.questionId) {
      let previousAnswers =
        this.props.previousAnswers && this.props.previousAnswers.length
          ? this.props.previousAnswers
          : [];

      let onChange = this.props.onChange ? this.props.onChange : () => {};

      setTimeout(() => {
        this.setState({
          selectedOption: previousAnswers[0],
        });

        this.questionId = item.questionId;

        onChange(previousAnswers[0]);
      }, 100);
    } else {
      console.warn("Finalizou!");
    }
  }

  componentDidMount() {
    let item = this.props.item ? this.props.item : {};

    this.questionId = item.questionId;
    let previousAnswers =
      this.props.previousAnswers && this.props.previousAnswers.length
        ? this.props.previousAnswers
        : [];

    let onChange = this.props.onChange ? this.props.onChange : () => {};

    setTimeout(() => {
      this.setState({
        selectedOption: previousAnswers[0],
      });

      onChange(previousAnswers[0]);
    }, 100);
  }

  componentWillUnmount() {
    this.setState({
      selectedOption: {},
    });
  }
}

export default AnswerRadioButton;
