import React from "react";
import "assets/scss/components.scss";

import { Link } from "react-router-dom";

import template from "template.js";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import { Container, Row, Col, Form } from "reactstrap";

class IndustryPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      industryId: -1,
      formData: {},
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );
  }

  render() {
    return (
      <Container fluid className="minHeightFooter bg-body mb-8">
        <Row className="pt-6 pl-5">
          <Col lg="6">
            <h1 className="text-dark">
              {this.stringTable.industry.titleChangeIndustry}
            </h1>
            <small className="subtitle">
              {this.stringTable.industry.newSubtitleNewIndustry}
            </small>
          </Col>
        </Row>

        <Form className="pt-6 pl-5">
          <Row lg="12">
            <Col lg="6">
              <div className="form-group">
                <label
                  htmlFor="tradeName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.industry.form.tradeName}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <input
                  className="form-control input-style"
                  ref="tradeName"
                  type="text"
                  id="tradeName"
                  value={
                    this.state.formData["tradeName"]
                      ? this.state.formData["tradeName"]
                      : ""
                  }
                  disabled
                />
              </div>
            </Col>

            <Col lg="6">
              <div className="form-group">
                <label
                  htmlFor="headOfficeCorporateName"
                  className="form-control-label label-input"
                >
                  {this.stringTable.industry.form.corporateName}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <input
                  className="form-control input-style"
                  ref="headOfficeCorporateName"
                  type="text"
                  id="headOfficeCorporateName"
                  value={
                    this.state.formData["headOfficeCorporateName"]
                      ? this.state.formData["headOfficeCorporateName"]
                      : ""
                  }
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row lg="12">
            <Col lg="4">
              <div className="form-group">
                <label
                  htmlFor="cnpj"
                  className="form-control-label label-input"
                >
                  {this.stringTable.industry.form.cnpj}
                  <small className="text-gray">
                    {this.stringTable.standard.mandatoryInput}
                  </small>
                </label>
                <input
                  className="form-control input-style"
                  ref="cnpj"
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  value={
                    this.state.formData["cnpj"]
                      ? this.state.formData["cnpj"]
                      : ""
                  }
                  disabled
                />
              </div>
            </Col>
            <Col lg="8">
              <div className="form-group ">
                <label
                  htmlFor="powerBiReportUrl"
                  className="form-control-label label-input "
                >
                  {this.stringTable.industry.form.Bi}
                  {/* <small className="text-gray">
                      {this.stringTable.standard.mandatoryInput}
                    </small> */}
                </label>
                <input
                  className="form-control input-style LinkBi"
                  ref="powerBiReportUrl"
                  type="text"
                  id="powerBiReportUrl"
                  name="powerBiReportUrl"
                  value={
                    this.state.formData["powerBiReportUrl"]
                      ? this.state.formData["powerBiReportUrl"]
                      : ""
                  }
                  disabled
                />
              </div>
            </Col>
          </Row>
        </Form>

        <div className="container-fluid mb-4 mt-6">
          <Row className="justify-content-center">
            <Link to={"/industry/Industry/IndustryList"} className="mr-5">
              <button type="button" className="lightButtom">
                {this.stringTable.standard.backButton}
              </button>
            </Link>
          </Row>
        </div>
      </Container>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ industryId: id });
  }

  componentDidMount() {
    this.getIndustryMethod();
  }

  getIndustryMethod() {
    if (this.state.industryId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Industry.Get +
        "/" +
        this.state.industryId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let res = result.response;

          res.cnpj = formater.template(res.cnpj, "00.000.000/0000-00");

          this.setState({ formData: res });
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }
}

export default IndustryPreview;
