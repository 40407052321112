import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import { smoothApi } from "libs/smoothApi.js";
import messages from "variables/messages";
import constants from "variables/constants";

import DataList from "components/Utils/DataList.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class SifsNotAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderLoading: true,
      renderList: true,
      filterLoading: false,
      renderNotFound: false,
      errorRenderNotFound: false,
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      confirmExclusao: false,
      openConfirm: false,
      searchMode: false,
      registerLoading: false,
      changeLoading: false,
      exporting: false,

      sifsNotAnsterResponse: null,
      industryResponse: null,
      statusResponse: null,
      timer: null,

      roundId: -1,
      currentPage: 0,

      formData: {},

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let industrySelectItems = [];

    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    if (this.state.industryResponse) {
      if (this.state.industryResponse.length > 0) {
        try {
          industrySelectItems = this.state.industryResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["industryId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.tradeName}
              </option>
            );
          });
        } catch (ex) {
          industrySelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (
      this.state.sifsNotAnsterResponse &&
      this.state.sifsNotAnsterResponse.list &&
      this.state.sifsNotAnsterResponse.total
    ) {
      tableCollumns = [
        { name: "N°. SIF", size: 20, property: "sifNumber" },
        { name: "Indústria", size: 30, property: "industryTradeName" },
        { name: "Razão Social", size: 50, property: "sifCorporateName" },
      ];

      tableData = this.state.sifsNotAnsterResponse.list;
      tableData = tableData.map((item) => {
        return {
          ...item,
          ...{},
        };
      });

      total = this.state.sifsNotAnsterResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-9">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.roundId != -1 ? (
                <>
                  <h1 className="text-dark">
                    {this.state.formData["sequenceId"]} -{" "}
                    {this.stringTable.questionnaire.sifsNotAnswer.title}{" "}
                  </h1>
                </>
              ) : (
                <>
                  <h1 className="text-dark">
                    {this.stringTable.questionnaire.sifsNotAnswer.title}
                  </h1>
                </>
              )}
              <small className="subtitle">
                {this.stringTable.questionnaire.sifsNotAnswer.subtitle}
              </small>
            </Col>
            <Col lg="6">
              <Link to="/admin/Questionnaires" className="float-right">
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="7">
                <div className="form-group">
                  <label
                    htmlFor="keyword"
                    className="form-control-label label-input"
                  >
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .filter.title
                    }
                  </label>
                  <input
                    className="form-control input-style"
                    ref="keyword"
                    type="text"
                    id="keyword"
                    placeholder="Digite aqui"
                    value={
                      this.state.formData["keyword"]
                        ? this.state.formData["keyword"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
              <Col md="5" sm="5" lg="5">
                <label className="form-control-label label-input">
                  {this.stringTable.questionnaire.sifsNotAnswer.filter.industry}
                </label>
                <select
                  name="industryId"
                  id="industryId"
                  ref="industryId"
                  className={
                    "input-style form-control showFilterActive" +
                    (industrySelectItems.length === 0 ? " loadingSelect" : "")
                  }
                  disabled={industrySelectItems.length === 0 ? true : false}
                  onChange={this.onInputChange}
                >
                  <option
                    value="Empty"
                    selected={this.state.formData["industryId"] ? false : true}
                  >
                    Selecione
                  </option>
                  {industrySelectItems.length === 0 || industrySelectItems}
                </select>
              </Col>
            </Row>
            <Row lg="12" className="pt-4">
              <Col lg="12">
                <button
                  type="button"
                  className="lightButtom m-auto float-right"
                  onClick={() => {
                    this.clearFormState();
                  }}
                >
                  {this.stringTable.standard.cleanButton}
                </button>
              </Col>
            </Row>
            {!this.state.filterLoading || <h1> Carregando...</h1>}
            {this.state.renderNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openRoundNotFound}
                        title={
                          this.stringTable.questionnaire.sifsNotAnswer.message
                            .title1
                        }
                        text={
                          this.stringTable.questionnaire.sifsNotAnswer.message
                            .questionnaireNotFound
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorRenderNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorRenderNotFound}
                        title={
                          this.stringTable.questionnaire.sifsNotAnswer.message
                            .title1
                        }
                        text={
                          this.stringTable.questionnaire.sifsNotAnswer.message
                            .questionnaireNotFound
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}
          </Form>
          <Row className="pt-4 pl-5">
            <Col lg="12" className="questionnaire">
              {this.state.renderList ? (
                <>
                  <Row lg="12" className="justify-content-center mt-7 mb-6">
                    <div className="">
                      <h1 className="titleText">
                        {this.stringTable.questionnaire.sifsNotAnswer.titleList}
                      </h1>
                    </div>
                  </Row>

                  <DataList
                    loading={this.state.renderLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                </>
              ) : (
                <>
                  {this.state.renderNotFound ||
                    this.state.errorRenderNotFound || <h1> Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ roundId: id });
  }

  componentDidMount() {
    this.loadSelects();
  }

  loadRound() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.Get +
      "/" +
      this.state.roundId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;

        ret.sequenceId = "R0" + ret.sequence;
        ret.roundId = ret.id;

        this.setState({ formData: ret });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadIndustry() {
    this.setState({ industryResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ industryResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadDataList(0, true);
    this.loadRound();
    this.loadIndustry();
  }

  clearFormState() {
    let formData = this.state.formData;

    delete formData.keyword;
    delete formData.industryId;

    this.setState({
      formData: formData,
      searchMode: false,
      filterLoading: true,
      renderNotFound: false,
      errorRenderNotFound: false,
    });
    this.loadDataList(0, false);
  }

  confirmExclusion(bool) {
    this.loadDataList(0, false);

    if (bool === "true") {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ roundId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({
      renderLoading: true,
      filterLoading: false,
      currentPage: currentPage,
    });

    let roundId = this.state.roundId;

    if (!firstLoading) this.setState({ renderLoading: false });

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      roundId: roundId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.PendingSifPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            renderList: false,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            renderList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            renderList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          renderLoading: false,
          renderList: true,
          errorMessage: "",
          sifsNotAnsterResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          renderList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "keyword" || name === "industryId") {
      let timer = this.state.timer;

      this.setState({ timer: clearTimeout(timer), filterLoading: true });

      this.setState({
        timer: setTimeout(() => {
          this.submitSearch(this.state.currentPage);
        }, 2000),
      });
    }
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage == undefined || currentPage == null) currentPage = 0;

    this.setState({
      filterLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;
    let formDataFilter = ["roundId", "keyword", "industryId"];
    let formDataAPI = smoothApi.helpers.filterProperties(
      formData,
      formDataFilter
    );

    formDataAPI.industryIds = [];
    if (formDataAPI.industryId !== "Empty") {
      formDataAPI.industryIds.push(formDataAPI.industryId);
    }

    delete formDataAPI.industryId;

    formDataAPI.currentPage = currentPage;
    formDataAPI.pageSize = constants.Pagination.DefaultPageSize;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.PendingSifPagedFind;
    let data = formDataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            filterLoading: false,
            renderList: false,
            errorRenderNotFound: true,
            openErrorRenderNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            filterLoading: false,
            renderList: false,
            errorRenderNotFound: true,
            openErrorRenderNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            filterLoading: false,
            renderList: false,
            errorRenderNotFound: true,
            openErrorRenderNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total == 0) {
          this.setState({
            renderLoading: false,
            filterLoading: false,
            renderList: false,
            renderNotFound: true,
            openRoundNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Sif não encontrado",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          renderLoading: false,
          filterLoading: false,
          renderList: true,
          renderNotFound: false,
          openRoundNotFound: false,
          errorRenderNotFound: false,
          openErrorRenderNotFound: false,
          errorMessage: "",
          sifsNotAnsterResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          filterLoading: false,
          renderList: false,
          errorRenderNotFound: true,
          openErrorRenderNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.ExportNotAnswered;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Answer.DownloadNotAnswered +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default SifsNotAnswer;
