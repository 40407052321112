import React from "react";

const OrderAndProgress = (props) => {
  const defaultTitle = "Barra de progresso";
  const defaultWidth = "480px";
  const defaultHeight = "5px";
  const defaultProgressColor = "#EA6255";
  const defaultcurrentStep = 0;
  const defaultSteps = 0;

  let title = props.title ? props.title : defaultTitle;
  let width = props.steps ? props.steps : defaultWidth;
  let height = props.height ? props.height : defaultHeight;
  let progressColor = props.progressColor
    ? props.progressColor
    : defaultProgressColor;
  let currentStep = props.currentStep ? props.currentStep : defaultcurrentStep;
  let steps = props.steps ? props.steps : defaultSteps;

  if (typeof title !== "string" && typeof title !== "object")
    title = defaultTitle;

  if (typeof width !== "string") width = defaultWidth;

  if (typeof height !== "string") height = defaultHeight;

  if (typeof progressColor !== "string") progressColor = defaultProgressColor;

  if (typeof currentStep !== "number") currentStep = defaultcurrentStep;

  if (typeof steps !== "number") steps = defaultSteps;

  if (currentStep > steps) {
    console.error(
      "🔢 Progress 🔢 O passo atual não pode ser maior que a quantidade de passos"
    );
    currentStep = steps;
  }

  if (currentStep < 0) {
    console.error("🔢 Progress 🔢 O passo atual não pode ser menor que zero");
    currentStep = 0;
  }

  let percentage = (currentStep * 100) / steps;

  return (
    <>
      <div className="orderAndProgress">
        <span className="title">{title}</span>

        <div className="placeholder" style={{ height: height, width: width }}>
          <div
            className="bar"
            style={{ width: percentage + "%", backgroundColor: progressColor }}
          ></div>
        </div>

        <span className="subTitle">{currentStep + " de " + steps}</span>
      </div>
    </>
  );
};

export default OrderAndProgress;
