import React from "react";

import template from "template";
import { smoothApi } from "libs/smoothApi";
import messages from "variables/messages";
import constants from "variables/constants";

import Line from "components/Graphics/Line/Line";
import { formatDate } from "components/Utils/FormatDate";
import HorizontalBar from "components/Graphics/HorizontalBar/HorizontalBar";

import { Container, Row, Col } from "reactstrap";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      score: 0,
      lineDate: [],
      formData: {},
      lineScore: 0,
      scoreCard: false,
      changeText: false,
      listTypeScore: [],
      isAvailable: false,
      substepPointList: [],
      scoreDate: new Date(),
      nationalAverageScore: 0,
      nationalAverageScoreLineBar: 0,

      scoreNotFound: false,
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.onInputChange = this.onInputChange.bind(this);
  }

  render() {
    let storageUserFirstName = window.localStorage.getItem(
      constants.LocalStorage.FirstName
    );

    storageUserFirstName =
      storageUserFirstName.charAt(0).toUpperCase() +
      storageUserFirstName.slice(1);

    let typeScoreItems = [];

    if (this.state.listTypeScore && this.state.listTypeScore.length > 0) {
      try {
        typeScoreItems = this.state.listTypeScore.map((item) => {
          return (
            <option
              value={item.id}
              key={"situation" + item.id}
              selected={
                this.state.formData.typeScoreId === item.id ? true : false
              }
            >
              {item.name}
            </option>
          );
        });
      } catch (err) {
        typeScoreItems = [];
        console.error("Falha ao interpretar resposta", err);
      }
    }

    return (
      <Container
        fluid
        className={`${
          this.state.isAvailable && "minHeightFooter"
        } bg-body dashboard`}
      >
        {this.state.isAvailable ? (
          <>
            <Row className="pl-5 pt-5">
              <Col lg="6">
                <h1 className="text-dark dashboardTitleTwo">
                  Bem-vindo(a), {storageUserFirstName}!
                </h1>
              </Col>
            </Row>
            <Row className="pl-5 pb-4 pt-5">
              <Col lg="4" md="12" sm="12">
                <div className="input-sif">
                  <label className="form-control-label label-input">
                    {this.stringTable.user.form.industryProfile.typeScore}
                  </label>
                  <select
                    id="scoreTypeId"
                    name="scoreTypeId"
                    onChange={this.onInputChange}
                    disabled={typeScoreItems.length === 0 ? true : false}
                    className={
                      "input-style form-control showFilterActive" +
                      (typeScoreItems.length === 0 ? " loadingSelect" : "")
                    }
                  >
                    <option
                      value=""
                      selected={this.state.formData.scoreTypeId ? false : true}
                    >
                      Selecione
                    </option>
                    {typeScoreItems.length === 0 || typeScoreItems}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="container-graphics">
              <Col lg="7">
                <div className="doughnut-line">
                  {this.state.scoreCard ? (
                    <div className="wrapperDoughnut">
                      <div className="text-dark ml-5 mt-5">
                        <span>{this.stringTable.dashboard.scoreCard}</span>
                      </div>
                    </div>
                  ) : (
                    <Col lg="8">
                      <div>
                        <HorizontalBar
                          text="Score"
                          width={150}
                          height={43}
                          suggestedMax={100}
                          legend={true}
                          value={this.state.score}
                          nationalAverageScore={
                            this.state.nationalAverageScoreLineBar
                          }
                        />

                        <span
                          style={{
                            display: "inline-block",
                          }}
                          className="ml-3 mt-3"
                        >
                          {this.state.scoreNotFound
                            ? "Score não encontrado"
                            : "Atualizado em " +
                              formatDate(this.state.scoreDate)}
                        </span>
                      </div>
                    </Col>
                  )}
                  <div className="pt-5">
                    <Line
                      showNationalScore={false}
                      lineDate={this.state.lineDate}
                      firstValue={this.state.lineScore}
                      secondValue={this.state.nationalAverageScore}
                    />
                  </div>
                </div>
              </Col>
              <Col className="" lg="5">
                {this.state.substepPointList.length ? (
                  this.state.substepPointList.map(
                    (
                      { point, comparison, substepName, nationalAverageScore },
                      index
                    ) => {
                      return (
                        <HorizontalBar
                          key={index}
                          width={150}
                          height={35}
                          value={point}
                          legend={false}
                          suggestedMax={60}
                          text={substepName}
                          comparison={comparison}
                          nationalAverageScore={nationalAverageScore}
                        />
                      );
                    }
                  )
                ) : (
                  <>
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Certificação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      legend={false}
                      suggestedMax={60}
                      text="Habilitação"
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Volume"
                      legend={false}
                      suggestedMax={60}
                    />
                    <HorizontalBar
                      width={150}
                      height={35}
                      text="Análise"
                      legend={false}
                      suggestedMax={60}
                    />
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className="availability">
            <h1>Não há dados disponíveis</h1>
          </div>
        )}
      </Container>
    );
  }

  componentDidMount() {
    this.getAvailability();
    this.loadSubstepPointChart();
    this.loadSimpleLineBar();
    this.loadScoreVsRoundChart();
    this.getTypeScore();

    this.setState({ changeText: false, scoreCard: true });
  }

  getAvailability() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetAvailability;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ isAvailable: result.response.isAvailable });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  getTypeScore() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Score.scoreTypeFind;
    let data = {
      name: null,
    };

    smoothApi.post(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({
          listTypeScore: result.response,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadSimpleLineBar() {
    const { formData } = this.state;
    let url;

    url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetDoughnut +
      `?scoreTypeId=${formData.scoreTypeId}`;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (result.success === false) {
          this.setState({
            changeText: false,
            scoreNotFound: true,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            changeText: false,
            scoreNotFound: true,
          });
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        const {
          score,
          scoreDate,
          sifNumber,
          comparison,
          corporateName,
          nationalAverageScore,
        } = result.response;

        this.setState({
          scoreDate,
          sifNumber,
          comparison,
          score: score,
          scoreNotFound: false,
          companyName: corporateName,
          nationalAverageScoreLineBar: nationalAverageScore,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSubstepPointChart() {
    const { formData } = this.state;
    let url = "";

    url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetSubstepPointChart +
      `?scoreTypeId=${formData.scoreTypeId}`;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null || result.response.length === 0) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ substepPointList: result.response });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  loadScoreVsRoundChart() {
    let url = "";
    const { formData } = this.state;

    url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Dashboard.GetScoreVSRoundChart +
      `?scoreTypeId=${formData.scoreTypeId}`;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let data = null;

    smoothApi.get(
      url,
      data,
      token,
      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (result.success === false) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let newScore = result.response.map((item) => item.score);
        let newNationalAverageScore = result.response.map((item) =>
          item.nationalAverageScore !== null ? item.nationalAverageScore : []
        );
        const lineDate = result.response.map((item) => item.scoreDate);

        this.setState({
          lineDate,
          lineScore: newScore,
          nationalAverageScore: newNationalAverageScore,
        });
      },
      (error, httpCode) => {
        if (httpCode === 401) {
          window.location.href = "/auth/Login";
        }
      }
    );
  }

  onInputChange(event) {
    let value = "";
    const target = event.target;
    const name = target.id;

    value = target.value;

    this.setState({
      changeText: false,
      scoreDate: new Date(),
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (name === "scoreTypeId") {
      if (value === "") {
        let substepPoint = this.state.substepPointList.map((item) => {
          return {
            ...item,
            point: 0,
            nationalAverageScore: 0,
          };
        });

        this.setState({
          score: 0,
          lineScore: [],
          scoreCard: true,
          comparison: "",
          changeText: false,
          nationalAverageScore: [],
          nationalAverageScoreLineBar: 0,
          substepPointList: substepPoint,
          formData: Object.assign(this.state.formData, { scoreTypeId: "" }),
        });
        return;
      }

      this.setState({
        scoreCard: false,
        changeText: true,
      });

      this.loadScoreVsRoundChart();
      this.loadSubstepPointChart();
      this.loadSimpleLineBar();
      return;
    }
  }
}

export default Dashboard;
