import React, { Component } from "react";

import { Container } from "reactstrap";

class ErrorAuth extends Component {
  render() {
    return (
      <Container fluid className="minHeightFooter">
        <div className="mt-8">
          <div className="text-center pt-6">
            <h1>
              <i className="far fa-frown"></i> 404
            </h1>
            <h1> Oops! Pagina não encontrada!</h1>
          </div>
        </div>
      </Container>
    );
  }
}

export default ErrorAuth;
