import React from "react";
import "assets/scss/pages/Collapse.scss";

import template from "template";
import constants from "variables/constants";

import { Row, Col } from "reactstrap";

class Collapse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapseOpen: false,
    };
  }

  render() {
    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    let listItens = this.props.listItens ? this.props.listItens : [];
    let questionId = this.props.questionId ?  this.props.questionId : 0;
    let indexFile = this.props.index ? this.props.index : null;
    const removeFileUploaded = this.props.removeFileUploaded
      ? this.props.removeFileUploaded
      : () => {};

    const newListItens = [];

    const toggleCollapse = () => {
      this.setState({ collapseOpen: !this.state.collapseOpen });
    };

    if (listItens) {
      if (listItens.isTemplateRecognized || listItens.isContentRecognized) {
        if (listItens.isTemplateRecognized) {
          const parsedTemplateResponse = JSON.parse(listItens.templateResponse);

          newListItens.push({
            title: parsedTemplateResponse.template,
            token: listItens.token,
          });
        }

        if (listItens.isContentRecognized) {
          newListItens.push({
            content: JSON.parse(listItens.contentResponse),
            token: listItens.token,
          });
        }
      } else {
        newListItens.push({
          token: listItens.token,
        });
      }
    }

    return (
      <>
        {newListItens.map((item, index) => {
          return (
            <div key={index}>
              <div>
                {index === 0 ? (
                  <div className="collapse-title">
                    <label className="pt-4 font-weight-bold">
                      Anexo {indexFile != null ? indexFile : null}
                    </label>

                    <Row
                      lg="12"
                      className="pt-2 pl-6 flex justifify-content-center align-items-center"
                    >
                      {listItens.hasArtificialIntelligenceRecognition ? (
                        <Col lg="10" xl="10">
                          <div
                            className="linkText float-right mr-4"
                            onClick={toggleCollapse}
                          >
                            {!this.state.collapseOpen ? (
                              <>
                                {
                                  this.stringTable.questionnaire
                                    .answeredQuestionnaire.answerList
                                    .evaluationAnswered.viewJsonFile
                                }
                                <i
                                  style={{ transform: "rotate(180deg)" }}
                                  className="ml-2 fas fa-arrow-up"
                                ></i>
                              </>
                            ) : (
                              <>
                                {
                                  this.stringTable.questionnaire
                                    .answeredQuestionnaire.answerList
                                    .evaluationAnswered.hideJsonFile
                                }
                                <i className="ml-2 fas fa-arrow-up"></i>
                              </>
                            )}
                          </div>
                        </Col>
                      ) : null}

                      <Col lg="2" xl="2">
                        <div>
                          <button
                            className="deleteButton"
                            onClick={() =>
                              removeFileUploaded(item.token, questionId)
                            }
                          >
                            Excluir
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}

                {this.state.collapseOpen &&
                  item.content &&
                  item.content.length &&
                  item.content.map((result) => {
                    return (
                      <Row lg="12">
                        <Col lg="12">
                          <div className="dataList">
                            <div className="dataRow">
                              <div className="item">
                                <span className="pr-2">{result.field}:</span>
                                <span>{result.value}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default Collapse;
