import React from "react";

import "assets/scss/pages/Dashboard.scss";

import { monthAndYear } from "components/Utils/FormatDate";

import { Line } from "react-chartjs-2";

class LineComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const firstValue = this.props.firstValue ? this.props.firstValue : [];
    const secondValue = this.props.secondValue ? this.props.secondValue : [];
    const lineDate = this.props.lineDate ? this.props.lineDate : [];
    const showNationalScore = this.props.showNationalScore
      ? this.props.showNationalScore
      : false;
    const newListLineDate = [];

    if (lineDate.length) {
      lineDate.forEach((date) => {
        newListLineDate.push(monthAndYear(date));
      });
    }

    const datasetsNationScore = [
      {
        label: "Empresa",
        data: firstValue || null,
        fill: false,
        backgroundColor: "rgba(21, 74, 39, 0.5)",
        borderColor: "rgba(21, 74, 39)",
        yAxisID: "y-axis-2",
      },
    ];

    let data = {
      datasets: [],
    };

    if (showNationalScore) {
      data.datasets = [
        ...datasetsNationScore,
        {
          label: "Brasil",
          data: secondValue || null,
          fill: false,
          backgroundColor: "rgb(188, 137, 46, 0.5)",
          borderColor: "rgb(188, 137, 46)",
          yAxisID: "y-axis-1",
        },
      ];
    } else {
      data.datasets = [
        {
          label: "Empresa",
          data: firstValue || null,
          fill: false,
          backgroundColor: "rgba(21, 74, 39, 0.5)",
          borderColor: "rgba(21, 74, 39)",
          yAxisID: "y-axis-2",
        },
      ];
    }

    data.labels = newListLineDate;

    const options = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            id: "y-axis-1",
            type: "linear",
            display: true,

            position: "left",
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 20,
              min: 0,
              max: 100,
            },
          },
          {
            id: "y-axis-2",
            type: "linear",
            display: true,
            position: "right",

            gridLines: {
              drawOnArea: false,
            },
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              suggestedMax: 100,
              stepSize: 20,
              min: 0,
              max: 100,
            },
          },
        ],
      },
    };
    return (
      <>
        <h3 className="title pl-5 pt-3 pb-3">Evolução do score</h3>
        <Line className="" options={options} data={data} />
      </>
    );
  }
}

export default LineComponent;
