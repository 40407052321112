import React from "react";

import Modal from "react-modal";
import { Link } from "react-router-dom";

import template from "template.js";
import messages from "variables/messages";
import constants from "variables/constants";
import { smoothApi } from "libs/smoothApi.js";
import { objectToFormData } from "object-to-formdata";

import DataList from "components/Utils/DataList.jsx";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form, UncontrolledTooltip } from "reactstrap";

class QuestionnaireListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundLoading: true,
      renderRoundList: true,
      filterLoading: false,
      roundNotFound: false,
      errorRoundNotFound: false,
      roundInEdition: false,
      isVisibleDeleteMsg: false,
      modalIsOpen: false,
      confirmExclusao: false,
      openConfirm: false,
      searchMode: false,
      registerLoading: false,
      changeLoading: false,
      exporting: false,

      answerQuestionnaireResponse: null,
      typeResponse: null,
      statusResponse: null,
      timer: null,

      roundId: -1,
      currentPage: 0,

      formData: {},

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.loadDataList = this.loadDataList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
  }

  render() {
    let typeSelectItems = [];
    let statusSelectItems = [];

    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    function dateFormat(date) {
      let day = "";
      let month = "";
      let year = "";
      let dateFormated = "";

      day = new Date(date).getDate().toString();
      month = (new Date(date).getMonth() + 1).toString();
      year = new Date(date).getFullYear().toString();

      dateFormated =
        (day.toString().length === 1 ? "0" + day : day) +
        "/" +
        (month.toString().length === 1 ? "0" + month : month) +
        "/" +
        (year.toString().length === 1 ? "0" + year : year);

      return dateFormated;
    }

    if (this.state.typeResponse) {
      if (this.state.typeResponse.length > 0) {
        try {
          typeSelectItems = this.state.typeResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["questionnaireTypeId"] === item.id
                    ? true
                    : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          typeSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.statusResponse) {
      if (this.state.statusResponse.length > 0) {
        try {
          statusSelectItems = this.state.statusResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["answerStatusId"] === item.id
                    ? true
                    : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          statusSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (
      this.state.answerQuestionnaireResponse &&
      this.state.answerQuestionnaireResponse.list &&
      this.state.answerQuestionnaireResponse.total
    ) {
      tableCollumns = [
        { name: "N°. SIF", size: 20, property: "sifNumber" },
        { name: "Razão Social", size: 50, property: "sifCorporateName" },
        { name: "Tipo", size: 50, property: "questionnaireTypeName" },
        { name: "Data", size: 50, property: "date" },
        { name: "Status", size: 50, property: "roundStatus" },
        { name: "", size: 40, property: "button" },
      ];

      tableData = this.state.answerQuestionnaireResponse.list;
      tableData = tableData.map((item) => {
        let questionnaireTypeNameTreated = "";
        let statusColor = "";

        questionnaireTypeNameTreated =
          "Q" +
          item.questionnaireTypeId +
          " - " +
          item.questionnaireTypeName.substring(0, 13) +
          "...";

        switch (item.answerStatusId) {
          case 1:
            statusColor = "q_text-blue";
            break;

          case 2:
            statusColor = "q_text-green";
            break;

          case 3:
            statusColor = "q_text-red";
            break;

          default:
            statusColor = "text-dark";
            break;
        }

        let answerDate = dateFormat(item.answerDate);

        return {
          ...item,
          ...{
            button: (
              <Link
                to={`/admin/Questionnaires/answeredQuestionnaire/answerList/?id=${item.roundId}-${item.questionnaireTypeId}-${item.sifId}`}
              >
                <button className="btn btn-sm view">Visualizar</button>
              </Link>
            ),
            questionnaireTypeName: (
              <>
                <span id="questionnaireTypeName">
                  {" "}
                  {questionnaireTypeNameTreated}
                </span>
                <UncontrolledTooltip
                  delay={0}
                  trigger="hover focus"
                  target="questionnaireTypeName"
                >
                  {item.questionnaireTypeName}
                </UncontrolledTooltip>
              </>
            ),
            date: answerDate,
            roundStatus: (
              <span className={statusColor}>{item.answerStatusName}</span>
            ),
          },
        };
      });

      total = this.state.answerQuestionnaireResponse.total;
    }

    let pageChangeHandler = (index) => {
      if (this.state.searchMode) this.submitSearch(index);
      else this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-9">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              <h1 className="text-dark">
                {this.state.roundId == -1 ? (
                  <h1 className="text-dark">
                    {
                      this.stringTable.questionnaire.selectPeriod
                        .titlePeriodDefault
                    }
                  </h1>
                ) : (
                  <h1 className="text-dark">
                    {this.state.formData["sequenceId"]}
                  </h1>
                )}
              </h1>
              <small className="subtitle">
                {
                  this.stringTable.questionnaire.answeredQuestionnaire
                    .subtitleAnswered
                }
              </small>
            </Col>
            <Col lg="3">
              <Link to="/admin/Questionnaires" className="float-right">
                <button type="button" className="lightButtom">
                  {this.stringTable.standard.backButton}
                </button>
              </Link>
            </Col>
            <Col lg="3">
              <Link
                to={
                  "/admin/Questionnaires/answeredQuestionnaire/sifsNotAnswer/?id=" +
                  this.state.roundId
                }
                className="float-right"
              >
                <button type="button" className="mainColorButtom">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire
                      .buttomNotAnswer
                  }
                </button>
              </Link>
            </Col>
          </Row>

          <Form className="pt-6 pl-5">
            <Row lg="12">
              <Col lg="12">
                <div className="form-group">
                  <label
                    htmlFor="keyword"
                    className="form-control-label label-input"
                  >
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .filter.title
                    }
                  </label>
                  <input
                    className="form-control input-style"
                    ref="keyword"
                    type="text"
                    id="keyword"
                    placeholder="Digite aqui"
                    value={
                      this.state.formData["keyword"]
                        ? this.state.formData["keyword"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row lg="12">
              <Col md="3" sm="3" lg="3">
                <label className="form-control-label label-input">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire.filter
                      .type
                  }
                </label>
                <select
                  name="questionnaireTypeId"
                  id="questionnaireTypeId"
                  ref="questionnaireTypeId"
                  className={
                    "input-style form-control showFilterActive" +
                    (typeSelectItems.length === 0 ? " loadingSelect" : "")
                  }
                  disabled={typeSelectItems.length === 0 ? true : false}
                  onChange={this.onInputChange}
                >
                  <option
                    value=""
                    selected={
                      this.state.formData["questionnaireTypeId"] ? false : true
                    }
                  >
                    Selecione
                  </option>
                  {typeSelectItems.length === 0 || typeSelectItems}
                </select>
              </Col>

              <Col md="3" sm="3" lg="3">
                <label className="form-control-label label-input">
                  {
                    this.stringTable.questionnaire.answeredQuestionnaire.filter
                      .status
                  }
                </label>
                <select
                  name="answerStatusId"
                  id="answerStatusId"
                  ref="answerStatusId"
                  className={
                    "input-style form-control showFilterActive" +
                    (statusSelectItems.length === 0 ? " loadingSelect" : "")
                  }
                  disabled={statusSelectItems.length === 0 ? true : false}
                  onChange={this.onInputChange}
                >
                  <option
                    value=""
                    selected={
                      this.state.formData["answerStatusId"] ? false : true
                    }
                  >
                    Selecione
                  </option>
                  {statusSelectItems.length === 0 || statusSelectItems}
                </select>
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="answerStartDate"
                    className="form-control-label label-input"
                  >
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .filter.startDate
                    }
                  </label>
                  <input
                    className={
                      "form-control input-style " +
                      (this.state.formData["answerStartDate"]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    id="answerStartDate"
                    ref="answerStartDate"
                    value={
                      this.state.formData["answerStartDate"]
                        ? this.state.formData["answerStartDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>

              <Col lg="3">
                <div className="form-group">
                  <label
                    htmlFor="answerEndDate"
                    className="form-control-label label-input"
                  >
                    {
                      this.stringTable.questionnaire.answeredQuestionnaire
                        .filter.endDate
                    }
                  </label>
                  <input
                    className={
                      "form-control input-style " +
                      (this.state.formData["answerEndDate"]
                        ? "filterActive"
                        : "")
                    }
                    type="date"
                    id="answerEndDate"
                    ref="answerEndDate"
                    value={
                      this.state.formData["answerEndDate"]
                        ? this.state.formData["answerEndDate"]
                        : ""
                    }
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row lg="12" className="pt-4">
              <Col lg="12">
                <button
                  type="button"
                  className="lightButtom m-auto float-right"
                  onClick={() => {
                    this.clearFormState();
                  }}
                >
                  {this.stringTable.standard.cleanButton}
                </button>
              </Col>
            </Row>
            {!this.state.filterLoading || <h1> Carregando...</h1>}
            {this.state.roundNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openRoundNotFound}
                        title={
                          this.stringTable.questionnaire.error
                            .questionnaireListingTitle
                        }
                        text={
                          this.stringTable.questionnaire.error
                            .questionnaireListingNotFound
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.roundInEdition ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.roundInEdition}
                        title={" "}
                        text={this.state.errorMessage}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}

            {this.state.errorRoundNotFound ? (
              <section className="container">
                <Row>
                  <Col col="md-12">
                    <div
                      id="msgExclusion"
                      className={
                        " " +
                        (this.state.isVisibleDeleteMsg
                          ? "msgVisibleExclusion"
                          : "")
                      }
                    >
                      <DialogConfirm
                        open={this.state.openErrorRoundNotFound}
                        title={
                          this.stringTable.questionnaire.error
                            .questionnaireListingTitle
                        }
                        text={
                          this.stringTable.questionnaire.error
                            .questionnaireListingNotFound
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            ) : (
              <></>
            )}
          </Form>
          <Row className="pt-4 pl-5">
            <Col lg="12" className="questionnaire">
              {this.state.renderRoundList ? (
                <>
                  <Row lg="12" className="justify-content-center mt-7 mb-6">
                    <div className="">
                      {this.state.answerQuestionnaireResponse !== null &&
                      this.state.answerQuestionnaireResponse.list.length ? (
                        <h1 className="titleText">
                          {
                            this.stringTable.questionnaire.answeredQuestionnaire
                              .titleList
                          }
                        </h1>
                      ) : (
                        <h1 className="titleText">
                          Não há questionários respondidos.
                        </h1>
                      )}
                    </div>
                  </Row>

                  <DataList
                    customItem={true}
                    loading={this.state.roundLoading}
                    tableCollumns={tableCollumns}
                    tableData={tableData}
                    paging={true}
                    currentPage={this.state.currentPage}
                    pageSize={constants.Pagination.DefaultPageSize}
                    total={total}
                    pageChangeHandler={(index) => {
                      pageChangeHandler(index);
                    }}
                    exportHandler={exportHandler}
                    exporting={true}
                    exportProcessing={this.state.exporting}
                  />
                </>
              ) : (
                <>
                  {this.state.roundNotFound ||
                    this.state.errorRoundNotFound ||
                    this.state.roundInEdition || <h1> Carregando...</h1>}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ roundId: id });
  }

  componentDidMount() {
    this.loadSelects();
  }

  loadRound() {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Round.Get +
      "/" +
      this.state.roundId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;

        ret.sequenceId = "R0" + ret.sequence;
        ret.roundId = ret.id;

        this.setState({ formData: ret });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadType() {
    this.setState({ typeResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.QuestionnaireType.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ typeResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadStatus() {
    this.setState({ statusResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.AnswerStatus.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ statusResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadSelects() {
    this.loadDataList(0, true);
    this.loadRound();
    this.loadType();
    this.loadStatus();
  }

  clearFormState() {
    let formData = this.state.formData;

    delete formData.keyword;
    delete formData.questionnaireTypeId;
    delete formData.answerStatusId;
    delete formData.answerStartDate;
    delete formData.answereEndDate;

    this.setState({
      formData: formData,
      searchMode: false,
      roundNotFound: false,
      errorRoundNotFound: false,
      roundInEdition: false,
    });
    this.loadDataList(0, false);
  }

  confirmExclusion(bool) {
    this.loadDataList(0, false);

    if (bool === "true") {
      this.setState({
        confirmExclusao: true,
        openConfirm: true,
        messageExclusion: "excluido com sucesso ",
      });
      this.timeOutExclusion();
    } else {
      this.setState({
        confirmExclusao: false,
        openConfirm: true,
        messageExclusion: "na exclusão do registro ",
      });
      this.timeOutExclusion();
    }
  }

  openModal(id) {
    this.setState({ roundId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ roundLoading: true, currentPage: currentPage });

    let roundId = this.state.roundId;

    if (!firstLoading) this.setState({ roundLoading: false });

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      roundId: roundId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnsweredPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            roundLoading: false,
            renderRoundList: false,
            roundInEdition: true,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            roundLoading: false,
            renderRoundList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            roundLoading: false,
            renderRoundList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          roundLoading: false,
          renderRoundList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          roundLoading: false,
          renderRoundList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });

    if (
      name === "keyword" ||
      name === "answerStartDate" ||
      name === "answerEndDate" ||
      name === "answerStatusId" ||
      name === "questionnaireTypeId"
    ) {
      let timer = this.state.timer;

      this.setState({ timer: clearTimeout(timer), filterLoading: true });

      this.setState({
        timer: setTimeout(() => {
          this.submitSearch(this.state.currentPage);
        }, 2000),
      });
    }
  }

  submitSearch(currentPage) {
    if (this.state.formData == null) return;

    if (currentPage == undefined || currentPage == null) currentPage = 0;

    this.setState({
      roundLoading: true,
      currentPage: currentPage,
      searchMode: true,
    });

    let formData = this.state.formData;
    let formDataFilter = [
      "roundId",
      "keyword",
      "questionnaireTypeId",
      "answerStatusId",
      "answerStartDate",
      "answerEndDate",
    ];
    let formDataAPI = smoothApi.helpers.filterProperties(
      formData,
      formDataFilter
    );

    formDataAPI.currentPage = currentPage;
    formDataAPI.pageSize = constants.Pagination.DefaultPageSize;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnsweredPagedFind;
    let data = formDataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState({
            roundLoading: false,
            filterLoading: false,
            renderRoundList: false,
            errorRoundNotFound: true,
            openErrorRoundNotFound: true,
            errorMessage: messages.Errors.UnexpectedFormat,
          });
          return;
        }

        if (!result.success) {
          this.setState({
            roundLoading: false,
            filterLoading: false,
            renderRoundList: false,
            errorRoundNotFound: true,
            openErrorRoundNotFound: true,
            errorMessage: messages.Errors.ResponseError,
          });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            roundLoading: false,
            filterLoading: false,
            renderRoundList: false,
            errorRoundNotFound: true,
            openErrorRoundNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        if (result.response.total == 0) {
          this.setState({
            roundLoading: false,
            filterLoading: false,
            renderRoundList: false,
            roundNotFound: true,
            openRoundNotFound: true,
            errorMessage: messages.Errors.BlankResponse,
            messageExclusion: "Rodada não encontrada",
          });
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          roundLoading: false,
          filterLoading: false,
          renderRoundList: true,
          roundNotFound: false,
          openRoundNotFound: false,
          errorRoundNotFound: false,
          openErrorRoundNotFound: false,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          roundLoading: false,
          filterLoading: false,
          renderRoundList: false,
          errorRoundNotFound: true,
          openErrorRoundNotFound: true,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  handleSubmit(roundId) {
    let formData = this.state.formData;

    if (roundId) {
      this.setState({ changeLoading: true });
      formData.id = roundId;
      formData.name = formData.nameChanged;

      delete formData.nameChanged;
    } else this.setState({ registerLoading: true });

    formData = objectToFormData(formData);

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Celebrant.Put;
    let data = formData;

    smoothApi.put(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.error(messages.Errors.UnexpectedFormat, result);
          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);

          this.setState((state) => ({
            message: {
              ...state.message,
              ...{
                title: "Aviso",
                visible: true,
                text: (
                  <>
                    Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                    <span className="errorText"> mensagem </span> e tente
                    novamente:
                    <ul className="errorSumary">
                      {result.errors.map((el, currentIndex) => {
                        return (
                          <li key={"validationItem_" + currentIndex}>
                            {el + "."}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ),
              },
            },
            registerLoading: false,
            changeLoading: false,
          }));

          this.closeModal();

          return;
        }

        if (result.response == null) {
          this.setState({
            errorMessage: messages.Errors.BlankResponse,
            registerLoading: false,
            changeLoading: false,
          });
          this.closeModal();
          console.error(messages.Errors.BlankResponse);
          return;
        }

        this.setState({
          roundLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderRoundList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
        this.loadDataList(0, false);
        this.clearFormState();
        this.closeModal();
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          roundLoading: false,
          registerLoading: false,
          changeLoading: false,
          renderRoundList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  export() {
    this.setState({ exporting: true });

    if (this.state.formData == null) return;

    let formData = this.state.formData;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.Export;
    let data = formData;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          this.setState({ exporting: false });
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          this.setState({ exporting: false });
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({ exporting: false });

          return;
        }

        this.setState({ exporting: false });

        window.open(
          process.env.REACT_APP_ENDPOINT_BASE_URL +
            constants.Endpoint.Answer.Download +
            "?fileKey=" +
            result.response.fileKey
        );
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({ exporting: false });
      }
    );
  }
}

export default QuestionnaireListing;
