import React from "react";
import { formater } from "libs/formater";

class AnswerTextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onInputTextArea: "",
    };
  }

  render() {
    const defaultTitle = "Questão";
    const defaultSubTitle = "Informe a resposta no campo abaixo.";

    const defaultValue = "";
    const defaultOnChange = () => {};

    let title = this.props.title ? this.props.title : defaultTitle;
    let item = this.props.item ? this.props.item : {};

    let subTitle = this.props.subTitle ? this.props.subTitle : defaultSubTitle;
    let stepName = this.props.stepName ? this.props.stepName : "";
    let questionOrder = this.props.questionOrder
      ? this.props.questionOrder
      : "";
    let stepOrder = this.props.stepOrder ? this.props.stepOrder : "";
    let questionScoreTypes = this.props.questionScoreTypes
      ? this.props.questionScoreTypes
      : [];
    let scoreTypeId = this.props.scoreTypeId ? this.props.scoreTypeId : 0;
    let value = this.props.value ? this.props.value : defaultValue;
    let onChange = this.props.onChange ? this.props.onChange : defaultOnChange;

    let onBlur = this.props.onBlur ? this.props.onBlur : () => {};
    if (typeof title !== "string" && typeof title !== "object")
      title = defaultTitle;

    if (typeof subTitle !== "string" && typeof subTitle !== "object")
      subTitle = defaultSubTitle;

    if (typeof value !== "string") value = defaultValue;

    let onInputChange = (event) => {
      const target = event.target;
      const id = target.id;
      let value = "";

      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        switch (id) {
          case "onInputTextArea":
            value = formater.maxLenght(target.value, 200);
            break;

          default:
            value = target.value;
        }
      }

      this.setState({
        onInputTextArea: value,
      });

      onChange(value);
    };

    return (
      <>
        <div className="mb-3" style={{ fontWeight: "bold", color: "#e64b3c" }}>
          <span>{stepName}</span>
        </div>
        <div className="mb-3">
          {questionScoreTypes.map((item, index) => {
            return (
              <span
                className={index === 1 && "ml-2"}
                style={{
                  fontWeight: "bold",
                  color: item.scoreTypeId === 1 ? "#5dcaaa" : "#4cb6f2",
                }}
              >
                ({item.scoreTypeName})
              </span>
            );
          })}
        </div>

        <div className="answerInput">
          <div className="title">
            <span>
              {stepOrder}.{questionOrder} {title}
            </span>
          </div>
          <div className="subTitle">
            <span>{subTitle}</span>
          </div>

          <div className="input">
            <textarea
              onBlur={() => onBlur(item.questionId)}
              className="form-control input-style"
              type="text"
              value={this.state.onInputTextArea}
              rows="6"
              name="onInputTextArea"
              id="onInputTextArea"
              placeholder="Resposta da questão"
              onChange={onInputChange}
            ></textarea>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const onChange = this.props.onChange ? this.props.onChange : () => {};
    const Input = this.props.value ? this.props.value : "";
    const onBack = this.props.onBack ? this.props.onBack : "";

    if (onBack === false) {
      setTimeout(() => {
        this.setState({
          onInputTextArea: Input,
        });
        onChange(Input);
      }, 100);
    }
  }

  componentDidUpdate(prev, next) {
    console.log({
      prev,
    });
    const Input = this.props.value ? this.props.value : "";
    const onChange = this.props.onChange ? this.props.onChange : () => {};

    if (prev.onBack === true) {
      this.setState({
        onInputTextArea: Input,
      });
      onChange(Input);
    }
  }

  componentWillUnmount() {
    this.setState({
      onInputTextArea: "",
    });
  }
}

export default AnswerTextArea;
