import React from "react";
import "assets/scss/components.scss";

import Modal from "react-modal";
import Select from "react-select";
import { Link } from "react-router-dom";

import template from "template.js";

import { diruma } from "libs/diruma";
import { viaCEP } from "libs/viaCEP";
import { formater } from "libs/formater";
import { smoothApi } from "libs/smoothApi.js";

import messages from "variables/messages";
import constants from "variables/constants";

import Loading from "components/Utils/Loading.jsx";
import DataList from "components/Utils/DataList.jsx";
import InputSearch from "components/Utils/InputSearch";
import ButtonGroup from "components/Utils/ButtonGroup";
import DialogConfirm from "components/Utils/DialogConfirm.jsx";

import { Container, Row, Col, Form } from "reactstrap";

class SifRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renderDataList: true,
      confirmExclusao: false,
      fieldRequiredError: false,
      questionnaireNofFound: false,
      renderLoading: false,
      openConfirm: false,
      deletedSugestionInput: false,

      notNumber: false,

      answerQuestionnaireResponse: null,
      sipoaResponse: null,
      stateResponse: null,

      sifId: -1,
      industryId: -1,
      countyId: -1,
      currentPage: 0,

      updatedCep: false,

      formData: {},
      industrySugested: {},
      countySugested: {},
      sugestedCountyList: {},

      errorMessage: "",
      messageExclusion: "",
      message: {
        visible: false,
        text: "teste",
        title: "",
        closeCallBack: null,
        _defaultCloseAction: (_this) => {
          if (!_this) return;

          if (typeof _this !== "object") return;

          if (!_this.state || !_this.setState) return;

          if (
            typeof _this.state !== "object" ||
            typeof _this.setState !== "function"
          )
            return;

          if (_this.state.message.closeCallBack) {
            if (typeof _this.state.closeCallBack === "function")
              _this.state.message.closeCallBack();
          }

          _this.setState((state) => ({
            message: {
              ...state.message,
              ...{ visible: false, text: "", title: "", closeCallBack: null },
            },
          }));
        },
      },
    };

    this.stringTable = template.Language(
      window.localStorage.getItem(constants.LocalStorage.Language)
    );

    this.validation = {
      refs: () => {
        return this.refs;
      },

      elements: [
        {
          ref: "corporateName",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "sipoaId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "sifNumber",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "zipCode",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "cnpj",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "stateId",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "neighborhood",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "address",
          validations: [diruma.validations.required],
          customValidations: [],
        },
        {
          ref: "number",
          validations: [diruma.validations.required],
          customValidations: [],
        },
      ],
    };

    this.handleCEP = this.handleCEP.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onTabHandler = this.onTabHandler.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearFormState = this.clearFormState.bind(this);
    this.timeOutExclusion = this.timeOutExclusion.bind(this);
    this.onChangeNotNumber = this.onChangeNotNumber.bind(this);
    this.loadSugestedIndustry = this.loadSugestedIndustry.bind(this);
  }

  render() {
    let tableCollumns = [];
    let tableData = [];
    let total = 0;

    let sipoaSelectItems = [];
    let stateSelectItems = [];

    let countySugestedList = [];

    let countySugestedListSelect = [];

    function dateFormat(date) {
      let day = "";
      let month = "";
      let year = "";
      let dateFormated = "";

      day = new Date(date).getDate().toString();
      month = (new Date(date).getMonth() + 1).toString();
      year = new Date(date).getFullYear().toString();

      dateFormated =
        (day.toString().length === 1 ? "0" + day : day) +
        "/" +
        (month.toString().length === 1 ? "0" + month : month) +
        "/" +
        (year.toString().length === 1 ? "0" + year : year);

      return dateFormated;
    }

    if (
      this.state.answerQuestionnaireResponse &&
      this.state.answerQuestionnaireResponse.list &&
      this.state.answerQuestionnaireResponse.total
    ) {
      tableCollumns = [
        { name: "N°. SIF", size: 30, property: "sifNumber" },
        { name: "Razão Social", size: 50, property: "sifCorporateName" },
        { name: "Tipo", size: 110, property: "questionnaireTypeName" },
        { name: "Data", size: 50, property: "date" },
        { name: "Status", size: 40, property: "roundStatus" },
      ];

      tableData = this.state.answerQuestionnaireResponse.list;
      tableData = tableData.map((item) => {
        let statusColor = "";
        let questionnaireTypeNameTreated = "";

        questionnaireTypeNameTreated =
          "Q" + item.questionnaireTypeId + " - " + item.questionnaireTypeName;

        switch (item.answerStatusId) {
          case 1:
            statusColor = "q_text-blue";
            break;

          case 2:
            statusColor = "q_text-green";
            break;

          case 3:
            statusColor = "q_text-red";
            break;

          default:
            statusColor = "text-dark";
            break;
        }

        let answerDate = dateFormat(item.answerDate);

        return {
          ...item,
          ...{
            questionnaireTypeName: questionnaireTypeNameTreated,
            date: answerDate,
            roundStatus: (
              <span className={statusColor}>{item.answerStatusName}</span>
            ),
          },
        };
      });

      total = this.state.answerQuestionnaireResponse.total;
    }

    if (this.state.sugestedCountyList) {
      if (this.state.sugestedCountyList.length > 0) {
        try {
          this.state.sugestedCountyList.forEach((item) => {
            countySugestedList.push({
              id: item.id,
              name: item.name,
            });
          });
        } catch (ex) {
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.sugestedCountyList) {
      if (this.state.sugestedCountyList.length > 0) {
        try {
          this.state.sugestedIndustryList.forEach((item) => {
            countySugestedListSelect.push({
              value: item.id,
              label: item.name,
            });
          });
        } catch (ex) {
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.sipoaResponse) {
      if (this.state.sipoaResponse.length > 0) {
        try {
          sipoaSelectItems = this.state.sipoaResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["sipoaId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          sipoaSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    if (this.state.stateResponse) {
      if (this.state.stateResponse.length > 0) {
        try {
          stateSelectItems = this.state.stateResponse.map((item) => {
            return (
              <option
                selected={
                  this.state.formData["stateId"] === item.id ? true : false
                }
                value={item.id}
                key={"situation" + item.id}
              >
                {item.name}
              </option>
            );
          });
        } catch (ex) {
          stateSelectItems = [];
          console.error("Falha ao interpretar resposta", ex);
        }
      }
    }

    let pageChangeHandler = (index) => {
      this.loadDataList(index, false);
    };

    let exportHandler = (index) => {
      this.export();
    };

    return (
      <>
        <>
          <Modal
            isOpen={this.state.message.visible}
            onRequestClose={() => {
              this.state.message._defaultCloseAction(this);
            }}
            style={constants.Modal.DefaultStyle}
            contentLabel={this.state.message.title}
          >
            <span
              className="float-lg-right x"
              onClick={() => {
                this.state.message._defaultCloseAction(this);
              }}
            >
              X
            </span>
            <h2 className="text-dark text-center pt-4 pb-4 text-modal">
              {this.state.message.text}
            </h2>
            <div className="text-center">
              <button
                className="btn btn-group button-no"
                onClick={() => {
                  this.state.message._defaultCloseAction(this);
                }}
              >
                OK
              </button>
            </div>
          </Modal>
        </>

        <Container fluid className="minHeightFooter bg-body mb-8">
          <Row className="pt-6 pl-5">
            <Col lg="6">
              {this.state.sifId == -1 ? (
                <h1 className="text-dark">
                  {this.stringTable.sif.titleNewSif}
                </h1>
              ) : (
                <h1 className="text-dark">
                  {this.stringTable.sif.titleChangeSif}
                </h1>
              )}
              <small className="subtitle">
                {this.stringTable.sif.newSubtitleNewSif}
              </small>
            </Col>
          </Row>

          <Row lg="12" className="mt-6 ml-5">
            <Col lg="2" className="mr-tabs">
              <div
                className="cursor-pointer"
                id="registrationInformation"
                name="registrationInformation"
                onClick={() => {
                  this.onTabHandler("registrationInformation");
                }}
              >
                <h2
                  className={
                    "ml--3 ws-nowrap" +
                    (this.state.formData["registrationInformation"]
                      ? " tabsAfterClick"
                      : " tabsBeforeClick")
                  }
                >
                  {this.stringTable.sif.form.tabs.registrationInformation}
                </h2>
                {!(this.state.formData["registrationInformation"] === true) || (
                  <img
                    className="rectangle-red"
                    alt="..."
                    src={require("assets/img/brand/rectangle-red.png")}
                  />
                )}
              </div>
            </Col>

            <Col lg="2" className="ml-4">
              <div
                className="cursor-pointer"
                id="questionnairesAnswered"
                name="questionnairesAnswered"
                onClick={() => {
                  let sifId = this.state.sifId;

                  if (sifId) {
                    if (sifId > 0) this.onTabHandler("questionnairesAnswered");
                  }
                }}
              >
                <h2
                  className={
                    "ml--3 ws-nowrap" +
                    (this.state.formData["questionnairesAnswered"]
                      ? " tabsAfterClick"
                      : " tabsBeforeClick")
                  }
                >
                  {this.stringTable.sif.form.tabs.questionnairesAnswered}
                </h2>
                {!(this.state.formData["questionnairesAnswered"] === true) || (
                  <img
                    className="rectangle-red"
                    alt="..."
                    src={require("assets/img/brand/rectangle-red.png")}
                  />
                )}
              </div>
            </Col>
          </Row>

          {!(this.state.formData["registrationInformation"] === true) || (
            <>
              <Form className="pt-6 pl-5">
                <Row lg="12">
                  <Col lg="9" className="">
                    <>
                      <label className="form-control-label label-input">
                        {this.stringTable.sif.form.industry}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <Select
                        className="select-industry"
                        id="industryName"
                        value={{
                          value: this.state.industrySugested.id,
                          label: this.state.industrySugested.tradeName,
                        }}
                        options={this.state.sugestedIndustryList}
                        placeholder=""
                        onChange={(event) =>
                          this.loadSugestedIndustry(event.value)
                        }
                      />
                    </>
                  </Col>

                  <Col lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.isHeadOffice}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <ButtonGroup
                      id="isHeadOffice"
                      option1="Não"
                      option2="Sim"
                      key="isHeadOffice"
                      activeIndex={this.state.formData["isHeadOffice"]}
                      dflex="Sim"
                      changeHandler={this.onInputChange}
                    />
                  </Col>
                </Row>

                <Row lg="12" className="pt-4">
                  <Col lg="9">
                    <div className="form-group">
                      <label
                        htmlFor="corporateName"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.corporateName}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? true
                            : false
                        }
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="corporateName"
                        type="text"
                        id="corporateName"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested
                                .headOfficeCorporateName
                            : this.state.formData["corporateName"]
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="cnpj"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.cnpj}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        disabled={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? true
                            : false
                        }
                        className={
                          "form-control input-style" +
                          (this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? " input-disabled"
                            : "")
                        }
                        ref="cnpj"
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        value={
                          this.state.industrySugested &&
                          this.state.formData["isHeadOffice"] === "1"
                            ? this.state.industrySugested.cnpj
                            : this.state.formData["cnpj"]
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row lg="!2" className="pt-4">
                  <Col md="3" sm="3" lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.sipoa}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <select
                      name="sipoaId"
                      id="sipoaId"
                      ref="sipoaId"
                      className={
                        "input-style form-control showFilterActive" +
                        (sipoaSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                      disabled={sipoaSelectItems.length === 0 ? true : false}
                      onChange={this.onInputChange}
                    >
                      <option
                        value=""
                        selected={this.state.formData["sipoaId"] ? false : true}
                      >
                        Selecione
                      </option>
                      {sipoaSelectItems.length === 0 || sipoaSelectItems}
                    </select>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="sifNumber"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.sifNumber}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="sifNumber"
                        type="text"
                        id="sifNumber"
                        name="sifNumber"
                        value={
                          this.state.formData["sifNumber"]
                            ? this.state.formData["sifNumber"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="zipCode"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.cep}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="zipCode"
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        value={
                          this.state.formData["zipCode"]
                            ? this.state.formData["zipCode"]
                            : ""
                        }
                        onChange={this.handleCEP}
                      />
                    </div>
                  </Col>

                  <Col md="3" sm="3" lg="3">
                    <label className="form-control-label label-input">
                      {this.stringTable.sif.form.state}
                      <small className="text-gray">
                        {this.stringTable.standard.mandatoryInput}
                      </small>
                    </label>
                    <select
                      name="stateId"
                      id="stateId"
                      ref="stateId"
                      className={
                        "input-style form-control showFilterActive" +
                        (stateSelectItems.length === 0 ? " loadingSelect" : "")
                      }
                      disabled={stateSelectItems.length === 0 ? true : false}
                      onChange={this.onInputChange}
                    >
                      <option
                        value=""
                        selected={this.state.formData["stateId"] ? false : true}
                      >
                        Selecione
                      </option>
                      {stateSelectItems.length === 0 || stateSelectItems}
                    </select>
                  </Col>
                </Row>

                <Row lg="!2" className="pt-4">
                  <Col lg="9" className="">
                    <InputSearch
                      title={this.stringTable.sif.form.county}
                      fieldId="count"
                      pageHandler="sifs"
                      // value={this.state.countySugested}
                      // changeHandler={(id) => { this.setState({ countyId: id, deletedSugestionInput: false }); }}
                      sugestedList={countySugestedList}
                      changeHandler={(id) => {
                        if (id < 1) id = null;
                        this.setState((currentState) => ({
                          formData: {
                            ...currentState.formData,
                            ...{ cityId: id },
                          },
                        }));
                      }}
                      value={(() => {
                        if (this.state.formData && this.state.formData.cityId) {
                          let treatedId = parseInt(this.state.formData.cityId);

                          if (!isNaN(treatedId)) {
                            let countySearch = countySugestedList.filter(
                              (item) => item.id === treatedId
                            );

                            if (countySearch.length === 1) {
                              let countySearchArray = countySearch[0];
                              countySearchArray.edit = this.state.updatedCep;
                              return countySearchArray;
                            }
                          }
                        }
                        return null;
                      })()}
                      disabled={false}
                      hasPhoto={false}
                      isMandatory={true}
                    />
                  </Col>

                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="neighborhood"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.neighborhood}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="neighborhood"
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        value={
                          this.state.formData["neighborhood"]
                            ? this.state.formData["neighborhood"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row lg="12" className="pt-4">
                  <Col lg="6">
                    <div className="form-group">
                      <label
                        htmlFor="address"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.publicPlace}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style"
                        ref="address"
                        type="text"
                        id="address"
                        name="address"
                        value={
                          this.state.formData["address"]
                            ? this.state.formData["address"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row lg="12">
                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="number"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.numberHome}
                        <small className="text-gray">
                          {this.stringTable.standard.mandatoryInput}
                        </small>
                      </label>
                      <input
                        className="form-control input-style relative-input"
                        ref="number"
                        type="text"
                        id="number"
                        name="number"
                        value={
                          this.state.formData["number"]
                            ? this.state.formData["number"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>

                    <div className="absolute-input">
                      <input
                        id="notNumber"
                        type="checkbox"
                        name="notNumber"
                        value={this.state.notNumber}
                        onChange={this.onChangeNotNumber}
                      />
                      <label htmlFor="notNumber">Sem número</label>
                    </div>
                  </Col>

                  <Col lg="3">
                    <div className="form-group">
                      <label
                        htmlFor="complement"
                        className="form-control-label label-input"
                      >
                        {this.stringTable.sif.form.complement}
                      </label>
                      <input
                        className="form-control input-style"
                        ref="complement"
                        type="text"
                        id="complement"
                        name="complement"
                        value={
                          this.state.formData["complement"]
                            ? this.state.formData["complement"]
                            : ""
                        }
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>

              <div className="container-fluid mb-4 mt-6">
                <Row className="justify-content-center">
                  <Link to={"/admin/sifs"} className="mr-5">
                    <button type="button" className="lightButtom">
                      {this.stringTable.standard.backButton}
                    </button>
                  </Link>
                  <button
                    className="darkButtom"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.state.submitLoading ? (
                      <Loading load="Sim" />
                    ) : (
                      "Salvar"
                    )}
                  </button>
                </Row>
              </div>

              {!this.state.showRegisterSuccess || (
                <Row lg="12" className="justify-content-center pb-5">
                  <div>
                    <img
                      className="pr-2"
                      alt="..."
                      src={require("assets/img/brand/icon-success.png")}
                    />
                    <span className="font-weight-600 pl-2">
                      {this.stringTable.standard.registerSuccess}
                    </span>
                  </div>
                </Row>
              )}

              {this.state.fieldRequiredError ? (
                <section className="container">
                  <Row>
                    <Col col="md-12">
                      <div
                        id="msgExclusion"
                        className={
                          " " +
                          (this.state.isVisibleDeleteMsg
                            ? "msgVisibleExclusion"
                            : "")
                        }
                      >
                        <DialogConfirm
                          open={this.state.fieldRequiredError}
                          title={" "}
                          text={this.stringTable.standard.fieldRequiredError}
                          handleClose={() => {
                            this.setState({ fieldRequiredError: false });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <></>
              )}
            </>
          )}

          {!(this.state.formData["questionnairesAnswered"] === true) || (
            <>
              <Row className="pt-4 pl-5">
                <Col lg="12" className="questionnaire">
                  {this.state.renderDataList ? (
                    <>
                      <Row lg="12" className="justify-content-center mt-7 mb-6">
                        <div className="">
                          <h1 className="titleText">
                            {
                              this.stringTable.questionnaire
                                .answeredQuestionnaire.titleList
                            }
                          </h1>
                        </div>
                      </Row>
                      <DataList
                        loading={this.state.renderLoading}
                        tableCollumns={tableCollumns}
                        tableData={tableData}
                        paging={true}
                        currentPage={this.state.currentPage}
                        pageSize={constants.Pagination.DefaultPageSize}
                        total={total}
                        pageChangeHandler={(index) => {
                          pageChangeHandler(index);
                        }}
                        exportHandler={exportHandler}
                        exporting={true}
                        exportProcessing={this.state.exporting}
                      />
                    </>
                  ) : (
                    <>
                      {this.state.questionnaireNofFound || (
                        <h1> Carregando...</h1>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {this.state.questionnaireNofFound ? (
                <section className="container mt-4">
                  <Row>
                    <Col col="md-12">
                      <div
                        id="msgExclusion"
                        className={
                          " " +
                          (this.state.isVisibleDeleteMsg
                            ? "msgVisibleExclusion"
                            : "")
                        }
                      >
                        <DialogConfirm
                          open={this.state.questionnaireNofFound}
                          title={" "}
                          text={
                            this.stringTable.sif.form.tabs.questionnaireNofFound
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </section>
              ) : (
                <></>
              )}
            </>
          )}
        </Container>
      </>
    );
  }

  componentWillMount() {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let id = url.searchParams.get("id");

    if (id) this.setState({ sifId: id });
  }

  componentDidMount() {
    this.loadSelects();
    this.getSifMethod();
  }

  timeOutSubmit() {
    setTimeout(
      function () {
        this.setState({
          showSendEmailSuccess: false,
          showRegisterSuccess: false,
        });
      }.bind(this),
      4000
    );
  }

  openModal(id) {
    this.setState({ personId: id, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  timeOutExclusion() {
    setTimeout(
      function () {
        this.setState({ isVisibleDeleteMsg: true });
      }.bind(this),
      4000
    );

    this.setState({ isVisibleDeleteMsg: false });
  }

  onChangeNotNumber(event) {
    const { checked } = event.target;
    this.setState({ notNumber: checked });

    if (checked) {
      this.setState({
        formData: Object.assign(this.state.formData, { number: "S/N" }),
      });

      document.getElementById("number").disabled = true;
    } else {
      this.setState({
        formData: Object.assign(this.state.formData, { number: "" }),
      });

      document.getElementById("number").disabled = false;
    }
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.id;
    let value = "";

    let formData = this.state.formData;

    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      switch (name) {
        case "cnpj":
          value = formater.template(target.value, "00.000.000/0000-00");
          break;

        case "zipCode":
          value = formater.template(target.value, "00000-000");
          break;

        default:
          value = target.value;
      }
    }

    if (name === "stateId") {
      if (value) this.loadCounty(value);
      else {
        this.setState({
          formData: Object.assign(this.state.formData, { cityId: -1 }),
        });
      }
    }

    if (name === "isHeadOffice") {
      if (value === "1") this.loadIndustrySelected();

      if (value === "0")
        this.setState({
          formData: Object.assign(
            this.state.formData,
            { corporateName: "" },
            this.state.formData,
            { cnpj: "" }
          ),
        });
    }

    this.setState({
      formData: Object.assign(this.state.formData, { [name]: value }),
    });
  }

  handleCEP(e) {
    this.setState({
      formData: Object.assign(this.state.formData, {
        zipCode: formater.template(e.target.value, "00000-000"),
      }),
    });

    let treatedCEP = formater.number(e.target.value);

    if (treatedCEP.length !== 8) return;

    viaCEP
      .consulta(treatedCEP)
      .then((result) => {
        if (result.sucess === true) {
          let estado = null;
          let formData = this.state.formData;
          formData["address"] = result.data.logradouro;
          formData["neighborhood"] = result.data.bairro;
          formData["zipCode"] = result.data.cep;
          formData["cityId"] = "";
          formData["stateId"] = "";
          this.setState({ formData: formData });

          if (this.state.stateResponse) {
            estado = this.state.stateResponse.filter((item) => {
              return (
                item.abbreviation.toLocaleLowerCase() ===
                result.data.uf.toLocaleLowerCase()
              );
            });
          }

          if (estado) {
            if (estado.length > 0) {
              formData["stateId"] = estado[0].id;
              this.setState({
                citySearch: result.data.localidade,
                cityResponse: null,
              });
              this.loadCounty(estado[0].id, result.data.localidade);
            }
          }

          this.setState({ updatedCep: true });
        }
      })
      .catch((err) => {
        this.setState({ updatedCep: false });
      });
  }

  onTabHandler(name) {
    if (name === "registrationInformation") {
      this.setState({
        formData: Object.assign(
          this.state.formData,
          { questionnairesAnswered: false },
          { registrationInformation: true }
        ),
      });
    }

    if (name === "questionnairesAnswered") {
      this.setState({
        formData: Object.assign(
          this.state.formData,
          { registrationInformation: false },
          { questionnairesAnswered: true }
        ),
      });
    }
  }

  getSifMethod() {
    let formData = this.state.formData;

    formData.registrationInformation = true;

    this.setState({ formData: formData });

    if (this.state.sifId !== -1) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Sif.Get +
        "/" +
        this.state.sifId;
      let data = null;

      smoothApi.get(
        url,
        data,
        token,

        (result) => {
          if (
            !result ||
            !result.errors ||
            !result.response ||
            result.success == null
          ) {
            console.error(messages.Errors.UnexpectedFormat, result);
            return;
          }

          if (!result.success) {
            console.log(messages.Errors.ResponseError, result.errors);
            return;
          }

          if (result.response == null) {
            console.log(messages.Errors.BlankResponse, result.errors);
            return;
          }

          let ret = result.response;
          let formData = this.state.formData;

          ret.cnpj = formater.template(ret.cnpj, "00.000.000/0000-00");
          ret.zipCode = formater.template(ret.zipCode, "00000-000");

          if (ret.isHeadOffice) ret.isHeadOffice = "1";
          else ret.isHeadOffice = "0";

          if (ret.stateId) this.loadCounty(ret.stateId);

          if (ret.industryTradeName) {
            let industrySugested = {};
            industrySugested.id = ret.industryId;

            industrySugested.tradeName =
              formater.template(ret.cnpj, "00.000.000/0000-00") +
              " - " +
              ret.industryTradeName;
            industrySugested.headOfficeCorporateName = ret.corporateName;
            industrySugested.cnpj = formater.template(
              ret.cnpj,
              "00.000.000/0000-00"
            );

            this.setState({
              industrySugested: industrySugested,
              industryId: ret.industryId,
            });
          }

          if (ret.cityId) {
            let citySugested = {};

            citySugested.name = ret.cityName;

            formData.cityId = ret.cityId;
            this.setState({
              countySugested: citySugested,
              countyId: ret.cityId,
            });
          }

          if (ret.number === null) {
            ret.number = "S/N";
            document.getElementById("number").disabled = true;
            document.getElementById("notNumber").checked = true;
          }

          formData = { ...this.state.formData, ...ret };

          this.setState({ formData: formData });

          this.loadDataList(0, true);
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";
        }
      );
    }
  }

  loadSipoa() {
    this.setState({ sipoaResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sipoa.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sipoaResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadState() {
    this.setState({ stateResponse: null });

    let name = {
      name: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.State.Find;
    let data = name;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ stateResponse: result.response });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadCounty(stateId, localidade = "") {
    this.setState({ countySugestedList: null });

    let dataAPI = {};

    dataAPI.stateIds = [];

    if (stateId) dataAPI.stateIds.push(parseInt(stateId));

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.City.Find;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.error(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.error(messages.Errors.BlankResponse, result.errors);
          return;
        }

        this.setState({ sugestedCountyList: result.response });
        if (localidade !== "") {
          const filteredLocation = result.response.filter(
            (item) => item.name === localidade
          );

          if (filteredLocation.length) {
            this.setState({
              formData: Object.assign(this.state.formData, {
                count: filteredLocation[0].name,
                cityId: filteredLocation[0].id,
              }),
            });
          }
        }
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadDataList(currentPage, firstLoading) {
    this.setState({ renderLoading: true, currentPage: currentPage });

    if (!firstLoading) this.setState({ renderLoading: false });

    let sifId = this.state.sifId;

    let dataAPI = {
      currentPage: currentPage,
      pageSize: constants.Pagination.DefaultPageSize,
      sifId: sifId,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Answer.AnsweredPagedFind;
    let data = dataAPI;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (
          !result ||
          !result.errors ||
          !result.response ||
          result.success == null
        ) {
          console.log(messages.Errors.UnexpectedFormat, result);
          this.setState({
            renderLoading: false,
            renderDataList: false,
            roundInEdition: true,
            errorMessage: result.errors[0],
          });
          return;
        }

        if (!result.success) {
          this.setState({
            renderLoading: false,
            renderDataList: false,
            errorMessage: messages.Errors.ResponseError,
          });
          console.log(result.errors);
          return;
        }

        if (result.response == null) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        if (result.response.list.length <= 0) {
          this.setState({
            renderLoading: false,
            questionnaireNofFound: true,
            renderDataList: false,
            errorMessage: messages.Errors.BlankResponse,
          });
          return;
        }

        this.setState({
          renderLoading: false,
          renderDataList: true,
          errorMessage: "",
          answerQuestionnaireResponse: result.response,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";

        this.setState({
          renderLoading: false,
          renderDataList: false,
          errorMessage: messages.Errors.HttpError,
        });
      }
    );
  }

  loadSugestedIndustry(industryId) {
    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }
        let ret = result.response;

        ret.tradeName =
          formater.template(ret.cnpj, "00.000.000/0000-00") +
          " - " +
          ret.tradeName;

        ret.cnpj = formater.template(ret.cnpj, "00.000.000/0000-00");
        this.setState({
          industrySugested: ret,
          industryId: ret.id,
          deletedSugestionInput: false,
        });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  handleSubmit() {
    let formData = this.state.formData;

    let validations = diruma.validadeOnChange(
      this.validation,
      formData,
      null,
      true
    );

    if (!validations.sucess) {
      this.setState({ fieldRequiredError: true });

      return;
    }

    this.setState({ submitLoading: true });

    if (this.state.industryId) formData.industryId = this.state.industryId;

    if (formData.number == "S/N") {
      formData.number = null;
    }

    formData.cnpj = formater.number(formData.cnpj);
    formData.zipCode = formater.number(formData.zipCode);

    formData.isHeadOffice = formData.isHeadOffice === "1";

    if (formData.isHeadOffice) {
      formData.cnpj = null;
      formData.corporateName = null;
    }

    if (formData.id) {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL +
        constants.Endpoint.Sif.Put +
        "/" +
        formData.id;
      let data = formData;

      smoothApi.put(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            let formData = this.state.formData;

            formData.cnpj = formater.template(
              formData.cnpj,
              "00.000.000/0000-00"
            );

            if (formData.isHeadOffice) formData.isHeadOffice = "1";
            else formData.isHeadOffice = "0";

            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
              formData: Object.assign(formData, { number: "S/N" }),
            }));

            return;
          }

          if (!result.success) {
            console.error(messages.Errors.ResponseError, result.errors);

            let formData = this.state.formData;

            formData.cnpj = formater.template(
              formData.cnpj,
              "00.000.000/0000-00"
            );

            if (formData.isHeadOffice) formData.isHeadOffice = "1";
            else formData.isHeadOffice = "0";

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
              formData: Object.assign(formData, { number: "S/N" }),
            }));

            return;
          }

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: true,
            openConfirm: true,
            messageSubmit: "salvo com sucesso ",
          });
          this.getSifMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    } else {
      let token = window.localStorage.getItem(constants.LocalStorage.Token);
      let url =
        process.env.REACT_APP_ENDPOINT_BASE_URL + constants.Endpoint.Sif.Post;

      let data = formData;

      smoothApi.post(
        url,
        data,
        token,

        (result) => {
          if (result.success == null) {
            let formData = this.state.formData;

            formData.cnpj = formater.template(
              formData.cnpj,
              "00.000.000/0000-00"
            );

            if (formData.isHeadOffice) formData.isHeadOffice = "1";
            else formData.isHeadOffice = "0";

            console.error(messages.Errors.UnexpectedFormat, result);
            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          if (!result.success) {
            let formData = this.state.formData;

            formData.cnpj = formater.template(
              formData.cnpj,
              "00.000.000/0000-00"
            );

            if (formData.isHeadOffice) formData.isHeadOffice = "1";
            else formData.isHeadOffice = "0";

            console.error(messages.Errors.ResponseError, result.errors);

            this.setState((state) => ({
              message: {
                ...state.message,
                ...{
                  title: "Aviso",
                  visible: true,
                  text: (
                    <>
                      Ocorreu um erro durante o registro. Por favor, verifique a{" "}
                      <span className="errorText"> mensagem </span> e tente
                      novamente:
                      <ul className="errorSumary">
                        {result.errors.map((el, currentIndex) => {
                          return (
                            <li key={"validationItem_" + currentIndex}>
                              {el + "."}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ),
                },
              },
              submitLoading: false,
              showRegisterSuccess: false,
              fieldRequiredError: false,
            }));

            return;
          }

          let ret = result;

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: true,
            openConfirm: true,
            sifId: ret.response.id,
            messageSubmit: "salvo com sucesso ",
          });
          this.getSifMethod();
          this.timeOutSubmit();
        },

        (error, httpCode) => {
          if (httpCode === 401) window.location.href = "/auth/Login";

          this.setState({
            submitLoading: false,
            fieldRequiredError: false,
            showRegisterSuccess: false,
            errorMessage: messages.Errors.HttpError,
          });
        }
      );
    }
  }

  loadSelects() {
    this.loadIndustry();
    this.loadSipoa();
    this.loadState();
  }

  loadIndustrySelected() {
    let industryId = this.state.industryId;

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Get +
      "/" +
      industryId;
    let data = null;

    smoothApi.get(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let ret = result.response;
        let industrySugested = this.state.industrySugested;

        industrySugested.name = ret.tradeName;
        industrySugested.headOfficeCorporateName = ret.headOfficeCorporateName;
        industrySugested.cnpj = formater.template(
          ret.cnpj,
          "00.000.000/0000-00"
        );

        this.setState({ industrySugested: industrySugested });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  loadIndustry() {
    let keyword = {
      keyword: null,
    };

    let token = window.localStorage.getItem(constants.LocalStorage.Token);
    let url =
      process.env.REACT_APP_ENDPOINT_BASE_URL +
      constants.Endpoint.Industry.Find;
    let data = keyword;

    smoothApi.post(
      url,
      data,
      token,

      (result) => {
        if (!result || !result.response || result.success == null) {
          console.error(messages.Errors.UnexpectedFormat, result);
          return;
        }

        if (!result.success) {
          console.log(messages.Errors.ResponseError, result.errors);
          return;
        }

        if (result.response == null) {
          console.log(messages.Errors.BlankResponse, result.errors);
          return;
        }

        let industryList = result.response.map(function (item, indice) {
          return {
            value: item.id,
            label:
              formater.template(item.cnpj, "00.000.000/0000-00") +
              " - " +
              item.tradeName,
          };
        });

        this.setState({ sugestedIndustryList: industryList });
      },

      (error, httpCode) => {
        if (httpCode === 401) window.location.href = "/auth/Login";
      }
    );
  }

  clearFormState() {
    this.setState({ formData: {} });
  }
}

export default SifRegistration;
